import {
  LOAD_COMPANIES,
  EMPTY_COMPANIES,
  SET_ACTIVE_COMPANY_ITEM,
  SET_ACTIVE_USER_ITEM,
} from 'actions/types';

const initialState = {
  list: [],
  loaded: false,
  activeItem: null
};


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_COMPANIES:
      let activeItem;
      if(payload.length){
        activeItem  = 0
      }
      return {
        ...state,
        list: [...state.list, ...payload],
        loaded: true,
        activeItem
      };
    case EMPTY_COMPANIES:
      return {
        ...state,
        list: [],
        loaded: false,
        activeItem: null
      };
    case SET_ACTIVE_COMPANY_ITEM:
      return {
        ...state,
        activeItem: payload
      };
    default:
      return state;
  }
}