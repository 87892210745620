import {
  LOAD_FIELD,
  EMPTY_FIELD,
  SET_SATELLITE_GRAPH_DATA,
} from "actions/types";

const initialState = {
  item: null,
  satelliteGraphData: null,
  satelliteGraphDataLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_FIELD:
      return {
        ...state,
        item: payload,
      };
    case EMPTY_FIELD:
      return {
        ...state,
        item: null,
      };
    case SET_SATELLITE_GRAPH_DATA:
      return {
        ...state,
        satelliteGraphData: payload,
      };
    default:
      return state;
  }
}
