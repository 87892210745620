import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

import "./Stripe.style.scss";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe(process.env.REACT_APP_STRIPE_PK);
// const promise = loadStripe("pk_test_51HKJhGF5YkDWyOMw3orJO1f84FdVPH3pOBaxG2q6lTye4JiDBxiQ7Sxst0h4pjRpqRX3KjDou0snKQPSVQ9vOnsP00bsxykjoO");
export function Stripe({props}) {
  console.log(props, 'the props1')
  return (
    <div className="stripe">
      <Elements stripe={promise}>
        <CheckoutForm props={props}/>
      </Elements>
    </div>
  );
}