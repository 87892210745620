import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import mapboxgl from "mapbox-gl";
import Mapbox from "./Mapbox.component";
import { showDirections as showDirectionsAction } from "actions/ui";
import { __i18n } from "i18n";
import {
  pointsSource,
  pointsLayer,
  geoControl,
  mapboxStyle,
} from "./Mapbox.config";
import ReactDOM from "react-dom";
import Popup from "./Popup";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

let currentMarkers = [];

const addPopup = (el, lat, lng, map) => {
  const placeholder = document.createElement("div");
  ReactDOM.render(el, placeholder);

  const marker = new mapboxgl.Popup()
    .setDOMContent(placeholder)
    .setLngLat({ lng: lng, lat: lat })
    .addTo(map);
  document.querySelector("canvas").click();
  return marker;
};

const MapboxContainer = (props) => {
  const { activeItem, fields, selectedReport, mapboxTile } = props;
  const mapRef = React.useRef();
  const [stateMap, setStateMap] = useState(null);
  const [report, setReport] = useState(null);
  const [hdPhotoVisibility, setHdPhotoVisibility] = useState(false);
  const [acFile, setAcFile] = useState(null);
  const [showPopup, setShowPopup] = useState(null);

  useEffect(() => {
    if (activeItem === null) {
      return;
    }
    let field = fields.find((el) => el.id === activeItem);
    if (!field) return;

    if (selectedReport !== null) {
      let activeReport = field.Reports.find((el) => el.uuid === selectedReport);
      setReport(activeReport);
    } else {
      setReport(null);
    }

    mapRef.current.innerHTML = "";
    let map = new mapboxgl.Map({
      container: mapRef.current,
      style: mapboxStyle,
      center: [field.mapDisplay.lng, field.mapDisplay.lat],
      zoom: field.mapDisplay.zoom,
    });

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    // Add geolocate control to the map.
    map.addControl(geoControl());

    map.on("load", function (e) {
      const layers = map.getStyle().layers;
      // Find the index of the first symbol layer in the map style
      let firstSymbolId;
      for (const layer of layers) {
        if (layer === "symbol") {
          firstSymbolId = layer.id;
          break;
        }
      }

      setTimeout(() => {
        console.log(
          activeItem,
          selectedReport,
          "MAPBOX CONTAINER--",
          currentMarkers,
          layers,
          report
        );
      }, 2000);

      map.addSource("maine", pointsSource(field));
      map.addLayer(pointsLayer);

      if (mapboxTile) {
        map.addSource("raster-tiles", {
          type: "raster",
          // tiles: [mapboxTile],
          tiles: [
            "https://d31brp2px8evwo.cloudfront.net/reports/R5D42CW34TADYNT0/tiles/{z}/{x}/{y}.png",
          ],
          // tiles: [
          //   `${process.env.REACT_APP_BASE_URL}/api/shoot/getTiles/{z}/{x}/{y}`,
          // ],
          tileSize: 256,
        });

        map.addLayer(
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
          firstSymbolId
        );
      }

      let data = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: field.polygonPoints,
        },
      };
      map.getSource("maine").setData(data);

      // if (report) {
      //   console.log("HAVE REPORT");
      //   // let r1 = report;
      //   let r1 = { ...report };
      //   // setReport(null);
      //   setReport(r1);
      // } else {
      //   setReport(null);
      //   console.log("DONT HAVE REPORT");
      // }
      // [100, 500, 1000, 2000, 4000].forEach((t) => {
      //   setTimeout(() => { window.dispatchEvent(new Event('resize')); }, t)
      // })
    });

    setStateMap(map);

    return () => {
      // add it, for iphone canvas memory overflow
      // setStateMap(null);
    };
  }, [fields, activeItem, selectedReport, mapboxTile]);

  useEffect(() => {
    console.log("useEffect.container HDPHOTOVISIBILITY] 2", report);
    if (report && report.outputData && report.outputData.length) {
      report.outputData.forEach((point) => {
        const element = (
          <Popup
            props={props}
            point={point}
            hdPhotoVisibility={hdPhotoVisibility}
            setHdPhotoVisibility={setHdPhotoVisibility}
          />
        );

        var popup = addPopup(element, point.lng, point.lat, stateMap);

        var el = document.createElement("div");
        el.className = `marker`;
        el.setAttribute("data-file", `${point.file}`);
        el.onclick = function () {
          let dataAcFile = this.dataset.file;
          setAcFile(dataAcFile);
          setShowPopup(showPopup);
        };

        var marker = new mapboxgl.Marker(el)
          .setLngLat([point.lng, point.lat])
          .addTo(stateMap)
          .setPopup(popup);

        currentMarkers.push(marker);
      });
    }
  }, [report]);

  const state = {
    mapRef,
    hdPhotoVisibility,
    report,
    acFile,
  };

  const containerFunctions = {
    setHdPhotoVisibility,
  };

  return <Mapbox {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({
  activeItem: state.fields.activeItem,
  fields: state.fields.list,
  mapboxTile: state.fields.mapboxTile,
});

export default connect(mapStateToProps, {
  showDirectionsAction,
})(MapboxContainer);
