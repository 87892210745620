import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { varietiesData, topVarietiesData, getTopVarieties, varietiesMapping, getI18nVariety } from './data';

import Item from './Item.component';

const ItemContainer = (props) => {
  const { formData, setFormData } = props.props;
  const [varietyHasFocus, setVarietyHasFocus] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedVariety, setSelectedVariety] = useState(null);
  const [selectedVarietyText, setSelectedVarietyText] = useState(null);

  let topVarieties = getTopVarieties();

  useEffect(() => {
    if(formData.variety){
      setSelectedVarietyText(varietiesData[selectedVariety]);
    }
  }, [])

  const onSearchChange = e => setSearchTerm(e.target.value)

  const onVariety = val => e => {
    setSelectedVariety(val);
    document.querySelector('#dd-variety-menu').click()
  }

  useEffect(() => {
    setSelectedVarietyText(varietiesData[selectedVariety]);
    setFormData({...formData, variety: varietiesData[selectedVariety] });
  }, [selectedVariety])


  const state = {
    varietyHasFocus,
    selectedVariety,
    selectedVarietyText,
    varietiesData,
    searchTerm,
    topVarieties: getTopVarieties()
  };

  const containerFunctions = {
    setVarietyHasFocus,
    onVariety,
    onSearchChange,
    formData
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ItemContainer);