import React from 'react';

import './Item.style.scss'


const Item = (props) => {

  return (
    <section className="terms">
      <div className="container">
        <h1>Terms of Service</h1>
        <p>Updated at 2021-02-14</p>
        <h2>Preamble</h2>
        <p>These terms of service ("Terms of Service") apply to farmAIr, Inc. (651 N Broad St, Suite 206, Middletown, DE 19709, Delaware, U.S.A.) and its group companies, affiliates and subsidiaries (&ldquo;farmAIr,&rdquo; &ldquo;we&rdquo; or &ldquo;us&rdquo;), and the customer, a physical or legal entity signing up for farmAIr services (&ldquo;you&rdquo;). By using our services you are accepting these terms of service and / or by signing the Order Form that incorporates these terms of service by reference, you agree to the terms and conditions set forth below, which constitute a binding, legal agreement between you and farmAIr, Inc.</p>
        <p>Under no circumstances shall farmAIr team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if farmAIr team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof.</p>
        <p>We reserve the right to change the Terms of Service from time to time. You acknowledge and agree that it is your responsibility to review the Terms of Service periodically to learn of any modifications. Your continued access or use of the Services after such posting constitutes your consent to be bound by the modified Terms of Service.</p>
        <h2>Description of Our Service &amp; of the technologies used</h2>
        <p>farmAIr is an Agricultural Technology Company, which has the exclusive rights worldwide to use the patent (Number: 1009898, Title: Method for the detection and evaluation of biotic - abiotic stress in crops, using thermal photographs and artificial intelligence).</p>
        <p>We are using aerial thermal and digital images of your field(s) as our input, which are processed by machine learning algorithms, object-detection algorithms and Convolutional Neural Networks, in order to automatically produce images with depicted delimited Sub-areas of the plant(s) having stress (if any) indicated with pseudo-color (the Output).&nbsp;</p>
        <p>The Sub-areas of the stressed plant(s) are defined based on the temperature differentials and the higher temperature existent on the plant(s) of the image (Output). The data processing is done through our cloud-based application, related applications for mobile, website(s), data collection tools and our platform (hereinafter, the "Service").&nbsp;</p>
        <p>The Service ultimately provides early detection of plant stress and is giving you images of the stressed plant(s) detected from the processed input plotted on a satellite map of your field, track record of your field(s) based on inputs we collected / you provided from time to time, as well as navigation directions to the stressed plant(s).&nbsp;</p>
        <p>We strongly recommend to always seek the advice of your agronomist in order to better utilize our Service and to carefully read the contents of the present, to which you irrevocably consent and abide.&nbsp;</p>
        <p>You can order our Service by using the Order Form that we are providing over our website / platform.</p>
        <h2>Ownership of the Service</h2>
        <p>We retain all right, title and interest in (a) the Service and the visual interfaces, graphics, design, software and systems used to provide the Service; (b) all improvements, updates, modifications and enhancements to the Service and such visual interfaces, graphics, design, software and systems; and (c) all patents, copyrights and other intellectual property rights in the items listed in clauses (a) and (b) of this Section. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Service or any part thereof. farmAIr&rsquo;s name, logo and the product names associated with the Service are trademarks of farmAIr, and no right or license is granted to use them. farmAIr may update the functionality, user interface, software and user documentation, training and educational information of, and relating to the Service from time to time in its sole discretion as part of its ongoing mission to improve the Service and our customers&rsquo; use of the Service. Any rights not expressly granted herein are reserved by farmAIr and its licensors.</p>
        <h2>Intellectual Property</h2>
        <p>The website/app and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by farmAIr, its licensors or other providers of such material and are protected by USA and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of farmAIr. Any unauthorized use of the material is prohibited.</p>
        <h2>Use of the Service: Option Packages</h2>
        <p>The Service is made available to customers through our Order Form, providing different option packages (each, an &ldquo;Option Package&rdquo;) from time to time.&nbsp;</p>
        <p>Subject to these Terms of Service, farmAIr will make the Service available to You using the Option Package specified in the applicable Order Form for the period specified in the Order Form.&nbsp;</p>
        <p>The Option Package you select may have additional terms and conditions that are set forth in the Order Form. Those additional terms and conditions are incorporated into and made a part of these Terms of Service.&nbsp;</p>
        <h2>Fees and Payments</h2>
        <p>You agree to pay the service fees and other fees and charges set forth in the applicable Order Form. All payments for the Services will be invoiced by us. You agree to pay all invoiced charges without deduction or setoff within thirty (30) days of the date of an invoice. No service can be scheduled and / or delivered if is not fully paid (pre-payment). All payment obligations are non-cancellable and all amounts paid are non-refundable and non-creditable &ndash; for more details see below &ldquo;Return and Refund Policy&rdquo;.</p>
        <h2>Taxes</h2>
        <p>Our fees are exclusive of applicable taxes. We make no warranty as to the tax implications of these Services (if any) and you are responsible for paying all taxes, excluding only taxes based on our net income. If we have the legal obligation to pay or collect taxes for which you are responsible, the appropriate amount will be invoiced to and paid by you unless you provide us with a valid tax exemption certificate authorized by the appropriate taxing authority.</p>
        <h2>Payment Disputes</h2>
        <p>If you believe that your account is incorrect, you must contact us in writing within 30 days of the invoice date of the invoice containing the amount in question to be eligible to receive an adjustment or credit. In addition to any other rights granted to us herein, we reserve the right to suspend or terminate this Agreement or your access to the Service if your account falls into arrears. Unpaid invoices and accounts in arrears are subject to interest of one percent (1%) per month on any outstanding balance, or the maximum permitted by law, whichever is less, plus all expenses of collection. We also reserve the right to impose a reconnection fee in the event your access to the Service is suspended for nonpayment and thereafter you settle your account and request access to the Service.</p>
        <h2>Typographical Errors</h2>
        <p>In the event a product and/or service is listed at an incorrect price or with incorrect information due to typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or other payment account in the amount of the charge.</p>
        <h2>Return and Refund Policy</h2>
        <p>If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our Service. If you would like to ask for a refund, the only way would be if you follow the next guidelines:</p>
        <p>You can buy farmAIr services through our platform&rsquo;s Order Form for an agreed a) Option Package / type of service, b) number of photo shootings per year, c) period / years and you always pay in advance.</p>
        <p>As a general principal, if you wish to cancel our services for the period you have ordered and paid, there will be no refund. There may be expectations, but you have to contact us and explain to us the reasons you would like to terminate earlier than committed and we only promise to hear you carefully and do our best.&nbsp;&nbsp;&nbsp;</p>
        <p>If for any reason, we will be unable to provide our services due to unforeseen reasons and you do not wish to reschedule, then we will refund you proportionately, i.e. for the services (shootings / reports) we were unable to deliver.&nbsp;</p>
        <p>As per the above, we will proceed to issue a refund of your purchase. Your refund may take a couple of days to process but you will be notified when you receive your money.</p>
        <h2>Term and Termination</h2>
        <p>This Agreement shall remain in effect until terminated by you or farmAIr.</p>
        <p>farmAIr may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
        <p>This Agreement will terminate immediately, without prior notice from farmAIr, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the website/app and all copies thereof from your computer.</p>
        <p>Upon termination of this Agreement, you shall cease all use of the website/app and delete all copies of the website/app from your computer.</p>
        <p>Termination of this Agreement will not limit any of farmAIr's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>
        <p>This Agreement will be in effect from effective date specified in the Order Form and, unless otherwise terminated pursuant to this Section, the initial term of this Agreement (&ldquo;Initial Term&rdquo;) will expire on the expiration date specified on the Order Form.</p>
        <p>Either party may terminate this Agreement and all Order Forms issued hereunder in the event the other party commits a material breach of any provision of this Agreement which is not remedied within thirty (30) days of written notice from the non-breaching party. In addition, we may terminate this Agreement immediately should you (i) breach the confidentiality provisions of this Agreement or any provision relating to compliance with applicable law, (ii) violate the Terms of Service for use of the farmAIr website.</p>
        <p>Upon expiration or termination of this Agreement you will return to us or destroy all copies of our Confidential Information, cease all access to, and use of, the Service and certify to us in writing that this has been done.</p>
        <h2>Data Collection and Analysis</h2>
        <p>Our advanced algorithms analyze data from three sources: (i) drone&nbsp;flights over real property, and (ii) data that you input or upload into the Service using our web-based data entry or collection tools. The Service works best when the data from all two sources is analyzed, but the addition of data from drone flights and data that you input or upload is entirely at your option. It is your responsibility to obtain drone flight data and other data.</p>
        <p>Depending on the Package you have selected, you can upload data to our platform that was collected by your own drone, or we will schedule one or more drone flights to collect data on your behalf. If you have requested a Package including drone flights, we will arrange and schedule those drone flights with a third-party drone operator (&ldquo;Drone Operator&rdquo;) who will provide that service directly to you. We will relay your requirements and terms for drone services to the relevant Drone Operator. Any cancellation or rescheduling by you of a scheduled drone flight less than 48h before such flight is to take place will result in you being charged with an administrative fee. Any cancellations or rescheduling by a Drone Operator will be notified to you by us as soon as possible and we will reschedule the cancelled/ rescheduled flight with you and the Drone Operator or a new Drone Operator, as agreed between us.</p>
        <p>We will upload the data from the drone flights arranged through our platform and make that data available to you through our website. You acknowledge that your ability to receive drone operation and flight services through the use of the Services does not establish farmAIr as a provider of drone flights. &ndash; for more details see our &ldquo;Return and Refund Policy&rdquo;.</p>
        <h2>Data Ownership and Licenses</h2>
        <p>As between you and farmAIr, the data that you input or upload using our web-based data entry tools and any data collected from drone flights that we arrange on your behalf will not be stored permanently but only the processed images with stressed plants produced from our algorithms will be stored permanently (collectively, the &ldquo;Customer Data&rdquo;).&nbsp;</p>
        <p>In order to enable us to provide the Service to you, you grant us a worldwide, free of charge right and license (with the right to sublicense) to access, store, host, modify, analyze, process, transmit and otherwise use the Customer Data as required in order for us to (i) provide the Service to you, (ii) perform our obligations in this Agreement and the applicable Order Forms, and (iii) comply with your written instructions.</p>
        <p>Accordingly, you agree that we may access, store, host, modify, analyze, process, transmit and otherwise use aggregate, anonymized data generated from or based on your Customer Data and/or your use of the Service in order to improve the Service (and the Machine Learning and Object-Detection algorithms and the Convolutional Neural Networks that are used in the Service) and for other business purposes.</p>
        <p>You are solely responsible for your Customer Data and the consequences of posting or uploading your Customer Data to the Service, including your use of the Service that results in a violation of any individual&rsquo;s or organization&rsquo;s privacy rights. You agree that we have no obligation to retain Customer Data and that your Customer Data may be locked or irretrievably deleted if your account is 30 days or more in arrears.</p>
        <p>farmAIr grants you a revocable, non-exclusive, non-transferable, limited license to download, install and / or use the website/app strictly in accordance with the terms of this Agreement.</p>
        <p>You are agreeing to be bound by these Terms of Service. If you do not agree to these Terms of Service, please do not use the farmAIr Service. In these Terms of Service, "you" refers both to you as an individual and to the entity you represent. If you violate any of these Terms of Service, we reserve the right to cancel your account or block access to your account without notice.</p>
        <h2>Passwords and Customer Accounts</h2>
        <p>In order to use the functionality of the Service, you must create an Online Account. Your Online Account is part of the Service and is used for, among other things, accessing your Customer Data. You agree that the information you provide to us when registering is accurate and that you will keep it accurate and up-to-date at all times. You are solely responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your Online Account. We will regard any communications we receive under your password, user name, and/or account number to have been sent by you. You agree to promptly notify us of any unauthorized access or use of the Service and any loss or theft or unauthorized use of your password or name and/or Service account numbers.</p>
        <h2>Compliance with Law</h2>
        <p>You agree to comply with all applicable laws and regulations in connection with your use of the Service. Without limitation of the foregoing, if you have requested drone flights or elected to upload drone data to the Service, then (a) you are solely responsible for ensuring that you comply with all applicable legal requirements relating to the operation of any aircraft, including any requirement that you detect and avoid other aircraft, (b) you are solely responsible for ensuring that you comply with all applicable legal requirements relating to over flights of land, including trespass and privacy, and (c) you will, at your sole cost and expense, obtain and maintain any necessary licenses, approvals, consents, leases, permits and authorizations required for your use of drone flights, over flights of land, collection of data by drone flights and uploading of your Customer Data to the Service.</p>
        <h2>Drone Flights and Drone Operators</h2>
        <p>Drone Operators are independent contractors and not employees, partners, agents, joint ventures, or franchisees of farmAIr. You hereby acknowledge that farmAIr does not supervise, direct, control or monitor a Drone Operator&rsquo;s service and expressly disclaims any responsibility or liability for the service a Drone Operator provides. farmAIr does not make any representation or warranty with respect to any Drone Operator or the services provided by any Drone Operator, regardless of whether or not a Drone Operator is designated by farmAIr as &ldquo;certified,&rdquo; &ldquo;approved&rdquo; or &ldquo;recommended.&rdquo; You further acknowledge that the ability to obtain drone flights through farmAIr does not establish farmAIr as a provider of drone services. farmAIr cannot ensure that you and a Drone Operator will complete an arranged drone flight. farmAIr has no control over the quality or safety of the drone service that is provided by a Drone Operator, even if we are paid a fee to locate a Drone Operator and schedule one or more a drone flights for you.</p>
        <h2>Third-Party Services</h2>
        <p>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
        <p>You acknowledge and agree that farmAIr shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. farmAIr does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
        <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
        <h2>Confidentiality</h2>
        <p>In connection with this Agreement each party (as the &ldquo;Disclosing Party&rdquo;) may disclose or make available Confidential Information to the other party (as the &ldquo;Receiving Party&rdquo;). Each Receiving Party agrees: (i) to keep confidential all Confidential Information of the Disclosing Party; (ii) not to use or disclose such Confidential Information except to the extent necessary to perform its obligations or exercise rights under this Agreement or as directed by the Disclosing Party; (iii) to protect the confidentiality of the Disclosing Party&rsquo;s Confidential Information in the same manner as it protects the confidentiality of similar information and data of its own (at all times exercising at least a reasonable degree of care in the protection of such Confidential Information) and to make Confidential Information available to authorized persons only on a &ldquo;need to know&rdquo; basis. Notwithstanding the foregoing, this Section will not prohibit the disclosure of Confidential Information to the extent that such disclosure is required by law or order of a court or other governmental authority.</p>
        <p>The term &ldquo;Confidential Information&rdquo; means the Disclosing Party&rsquo;s proprietary technology and business information. Your Confidential Information includes your Customer Data. Our Confidential Information includes (i) the Service; (ii) test results, reports and analysis incorporated into a Custom Report or relating to the Service; (iii) the Feedback; (iv) all passwords, logins and account information for the Service; and (v) any product roadmaps, business plans, technical data, specifications, testing methods, research and development activities that is provided or made available to you from time to time during the term of this Agreement. Our Confidential Information also includes (A) any data, information or materials related to the Service that was provided or made available to you prior to the Effective Date, and (B) any aggregate, anonymized data, information or materials that we generate from your Customer Data and/or your use of the Service.</p>
        <p>Notwithstanding the foregoing, Confidential Information shall not include information which: (i) is known publicly; (ii) is generally known in the industry before disclosure; (iii) has become known publicly, without fault of the Receiving Party; or (iv) the Receiving Party becomes aware of from a third party not bound by non-disclosure obligations to the Disclosing Party and with the lawful right to disclose such information to the Receiving Party.</p>
        <h2>Submissions and Privacy</h2>
        <p>In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data or proposals, including ideas for new or improved products, services, features, technologies or promotions, you expressly agree that such submissions will automatically be treated as non-confidential and non-proprietary and will become the sole property of farmAIr without any compensation or credit to you whatsoever. farmAIr and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.</p>
        <h2>Press Releases</h2>
        <p>You shall not use the name or marks or refer to farmAIr in advertising, publicity, promotional marketing material or press releases without our prior written consent. We may refer to you as a customer of the Service in our online and print marketing materials and may use your name and trademarks in our marketing materials or press releases that specifically describe the Service; provided that we will remove your name and trademarks from our future marketing materials within 60 days of receipt of a written request from you to do so.</p>
        <h2>Promotions</h2>
        <p>farmAIr may, from time to time, include contests, promotions, sweepstakes, or other activities (&ldquo;Promotions&rdquo;) that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules.</p>
        <p>Additional terms and conditions may apply to purchases of goods or services on or through the Services, which terms and conditions are made a part of this Agreement by this reference.</p>
        <h2>Links to Other Websites</h2>
        <p>This Terms of Service applies only to the Services. The Services may contain links to other websites not operated or controlled by farmAIr. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Terms of Service are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website&rsquo;s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
        <h2>Copyright Infringement Notice</h2>
        <p>If you are a copyright owner or such owner&rsquo;s agent and believe any material on our website/app constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including your address, telephone number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners; and (e) the a statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.</p>
        <h2>Professional Advice (Agronomist Consultation)</h2>
        <p>We strongly advise consulting your agronomist at every stage of using our &ldquo;Service&rdquo; due to the limitation of our &ldquo;Service&rdquo; to separate the abiotic or biotic stress of plants and any prognosis and prediction plant&rsquo;s disease. Agronomist consultation is important in all stages of your crop growth, regardless of use of our &ldquo;Service&rdquo;.</p>
        <h2>Restrictions</h2>
        <p>You agree not to, and you will not permit others to:</p>
        <p>&nbsp;&nbsp;-License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the website/app or make the platform available to any third party.</p>
        <p>&nbsp;&nbsp;-Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the website/app.</p>
        <p>&nbsp;&nbsp;-Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of farmAIr or its affiliates, partners, suppliers or the licensors of the website/app.</p>
        <p>Furthermore, You agree to use the Service only for the ordinary course of your business. You may not access the Service for purposes of monitoring its availability, performance or functionality or for any other benchmarking or competitive purposes. You agree not to (a) license, sublicense, sell, resell, rent, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service or any part thereof; (b) reproduce, modify or make derivative works based upon the Service or the audio and visual information, documents, software, graphics, products, and services contained or made available to you in the course of using the Service; or (c) reverse engineer or access the Service in order to (i) build a competitive product or service, (ii) build a product using similar ideas, features, functions or graphics of the Service, or (iii) copy any ideas, features, functions or graphics of the Service. You agree not to access the Service by any means other than through the interfaces that are provided by farmAIr.</p>
        <h2>No Warranties</h2>
        <p>farmAIr warrants that: (i) the Service will achieve in all material respects the functionality for that Option Package described on our website; and (ii) the functionality for your Option Package will not be materially decreased during the term of this Agreement. Your sole and exclusive remedy for our breach of this warranty will be that farmAIr will be required to use its commercially reasonable efforts to restore such functionality and if not possible will refund you as per our Return &amp; Refund Policy mentioned on the present.</p>
        <p>The <strong>website/app</strong> is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty or representation of any kind, whether express or implied. To the maximum extent permitted under applicable law, farmAIr, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website/app, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice.&nbsp;</p>
        <p>Without limiting the foregoing, neither farmAIr nor any farmAIr's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the website/app, or the information, content, and materials or products included thereon; (ii) that the website/app will be uninterrupted or error-free or that any errors or defects can or will be corrected; (iii) as to the accuracy, reliability, or currency of any information or content provided through the website/app; (iv) that the website/app, its servers, the content, or e-mails sent from or on behalf of farmAIr are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components, (v) be compatible or work with any other software, systems or services,&nbsp; meet any performance or reliability standards, or overall (vi) that he website/app will meet your expectations and / or requirements and ultimately, achieve any intended results.</p>
        <p>farmAIr is a distributor and not a publisher of the content <strong>supplied by third parties</strong>; as such, farmAIr exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the farmAIr Service.&nbsp;</p>
        <p>Without limiting the foregoing, farmAIr specifically disclaims all warranties and representations in any content transmitted on or in connection with the farmAIr <strong>Service</strong> or on sites that may appear as links on the farmAIr Service, or in the products provided as a part of, or otherwise in connection with, the farmAIr Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by farmAIr or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty.&nbsp;</p>
        <p>Price and availability information is subject to change without notice.&nbsp;</p>
        <p>Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.</p>
        <p>The warranties set forth in this Section are made to and for you only. Such warranties will only apply if the applicable Service has been used in accordance with the instructions provided on our website, this Agreement and applicable law.</p>
        <p>You assume all responsibility for determining whether the Service and the information or reports generated by use of the Service are accurate or sufficient for your purposes.</p>
        <h2>Limitation of Liability</h2>
        <p>Notwithstanding any damages that you might incur, the entire liability of farmAIr and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the greater of (i) USD 1000 (One Thousand United States Dollars) or (ii) the service fee that we received for your use of the Services in the six (6) month period prior to the event giving rise to the liability and this shall be your only remedy regardless of what legal theory is used to determine that we or the farmAIr Parties were liable for the harm, damages, injury or loss.</p>
        <p>To the maximum extent permitted by applicable law, in no event shall farmAIr or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the website/app, third-party software and/or third-party hardware used with the website/app, or otherwise in connection with any provision of this Agreement), even if farmAIr or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
        <p>You agree that the fees which farmAIr is charging under this Agreement do not include consideration for assumption by farmAIr of the risk of your incidental or consequential damages or any losses associated with personal injury or death. You understand and agree that farmAIr does not provide insurance and is not an insurer of your property or the personal safety of persons in or around your property. If you feel that you need insurance, you should obtain it from a third party.&nbsp;</p>
        <p>You understand and agree that&nbsp;</p>
        <ol>
          <li>the "Service" can detect plant stress taking into account only the canopy temperature of the plant(s) of the aerial thermal-digital image taken and can NOT separate the factors either biotic or abiotic that caused the temperature rise in canopy of the plant(s) that caused stress, as well as can NOT perform any kind of disease prognosis and prediction.</li>
          <li>the actual condition of the plant may differ as long as the result of the method reflects the condition of the plant at the time the image was taken, with all the limitations that this means.</li>
          <li>the analysis of the taken images is done by machine learning algorithms, object detection algorithms and Convolutional Neural Networks (due to a specific training dataset of plants and other constraints such as different pseudo-coloring patterns) there is a possibility of error such as a NON-delineated outline of the stressed sub-area, False Positive plant stress indication, False Negative plant stress indication.</li>
          <li>There are several exogenous factors for which the detection of plant stress may be incorrect, indicatively (but not limited):</li>
        </ol>
        <ul>
          <li>environmental factors, such as taking images during rain, drought or voluntary non-irrigation of the field.</li>
          <li>Incorrect image height capture in relation to the camera's characteristics.</li>
          <li>failure to use proper equipment, Unmanned Aerial Vehicles and Thermal-Digital Camera Systems.</li>
          <li>incorrect use of the correct equipment</li>
          <li>Do not supply the system with images of specific specifications.</li>
          <li>Weeds existence between the plants.</li>
        </ul>
        <ol>
          <li>we use and store meta-data of the images of your field such as geolocation data so as to avoid improper use of our Service. For example if you choose the package &ldquo;Bring your own Images&rdquo; you can only upload images of your field which you paid for, if you upload images from another field, the images will be considered invalid.</li>
        </ol>
        <ol>
          <li>The accuracy of the extra information for each crop field requested affects the shooting process and there may be failures if you provide incorrect data.</li>
        </ol>
        <ol>
          <li>The maximum size of images which you can upload per time is one (1) Terra-byte.</li>
        </ol>
        <ol>
          <li>&Tau;he area and the location of the field are automatically calculated and you agree on the accuracy of the information when purchasing the &ldquo;Service&rdquo;.</li>
        </ol>
        <ol>
          <li>In case you choose the package "End-to-End" you have the responsibility to choose yourself the range of dates you want the images to be taken, as well as any other actions required by you for any communication with the &ldquo;Drone Operator&rdquo; and/or &ldquo;Drone Pilot&rdquo;.</li>
        </ol>
        <p>To the maximum extent permitted by law, in no event shall farmAIr or any other farmAIr Party be liable to you or anyone else for lost profits, business interruption, loss of data or loss of revenue or for incidental, consequential, punitive, cover, special, reliance or exemplary damages, or indirect damages of any type or kind however caused, whether from breach of warranty, breach or repudiation of contract, negligence, or any other legal cause of action from or in connection with this Agreement (and whether or not farmAIr or another farmAIr Party has been advised of the possibility of such damages).</p>
        <p>You further agree that the limitation of liability in these Terms of Service shall apply (a) even if it is determined that we or an farmAIr Party caused the harm, damages, injury or loss to you or someone in or around your premises (including employees and invitees) and (b) to all harm, damages, injury or loss incurred including actual, direct, incidental or consequential damages, property damage and losses due to business interruption, loss of profits, loss of data, personal injury or death. Multiple claims shall not increase these limits.</p>
        <p>You agree that the result of the limitations on liability in this section may prevent you from recovering losses or damages in connection with otherwise valid claims. You further agree that the limitations on liability in this Section represent a fair allocation of risk between you and us, and that we would not be entering into this Agreement without these limitations of liability.</p>
        <p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold farmAIr and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the website/app; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
        <p>You agree to indemnify, hold harmless, and defend farmAIr and our officers, directors, employees, agents, affiliates, successors and permitted assigns (collectively, &ldquo;farmAIr Parties&rdquo;) against any and all losses and damages incurred by a farmAIr Party arising out of or relating to any claim or cause of action brought or asserted by third party: (a) relating to a breach or non-fulfillment of any covenant or undertaking by you in this Agreement or an Order Form; (b) alleging your negligent or more culpable acts or omissions; (c) alleging that the Customer Data (or its collection by a Drone Operator or use by us to provide the Service) infringes the rights of, or has caused harm to, a third party; (d) relating to your access to or use of the Service; or (e) relating to any failure by you to comply with any applicable law or regulations.</p>
        <h2>General Provisions</h2>
        <p>This Agreement (a) may be amended only by written instrument signed or accepted electronically by the parties hereto; (b) shall not be assigned by you without our prior written consent; (c) may be assigned by us without your consent to any of our affiliates or any person or entity that acquires all or substantially all stock or assets of us or one or more of our affiliates; (d) shall be binding upon the respective permitted successors, trustees and assigns of the parties hereto; (e) does not create a partnership, joint venture, employment or other similar relationship between the parties hereto; and (f) shall not be construed as giving any person or entity, other than the parties hereto and their successors and permitted assigns, any right, remedy or claim under or in respect of this Agreement.</p>
        <p>farmAIr will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you.&nbsp;</p>
        <p>The Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those persons who choose to access the farmAIr Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms of Service (which include and incorporate the farmAIr Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between you and farmAIr concerning its subject matter, and cannot be changed or modified by you. The section headings used in this Agreement are for convenience only and will not be given any legal import.</p>
        <h2>Waiver</h2>
        <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.</p>
        <p>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
        <h2>Force Majeure</h2>
        <p>farmAIr will not be liable for any failure or delay in the availability of the Service or any other performance of its obligations under this Agreement or an Order Form on account of strikes, shortages, riots, insurrection, fires, flood, storm, explosions, acts of God, war, governmental action, labor conditions, earthquakes, material shortages, or any other cause that is beyond farmAIr&rsquo; reasonable control, including any failure of internet services provided by any third party in connection with your use of the Service.</p>
        <h2>Severability</h2>
        <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
        <p>This Agreement, together with the Privacy Policy and any other legal notices published by farmAIr on the Platform, Order Form, Website, app, etc, shall constitute the entire agreement between you and farmAIr concerning the Services. You may be subject to additional terms and conditions that apply when you use or purchase other farmAIr&rsquo;s services, which farmAIr will provide to you at the time of such use or purchase. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and farmAIr."&rsquo;s" failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND farmAIr AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
        <h2>Changes To Our Terms of Service</h2>
        <p>You acknowledge and agree that farmAIr may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at farmAIr&rsquo; sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform farmAIr when you stop using the Service. You acknowledge and agree that if farmAIr disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account.</p>
        <p>If we decide to change our Terms of Service, we will post those changes on this page, and/or update the Terms of Service modification date below.</p>
        <h2>Updates to Our website/app</h2>
        <p>farmAIr reserves the right to modify, suspend or discontinue, temporarily or permanently, the website/app or any service to which it connects, with or without notice and without liability to you. farmAIr may from time to time provide enhancements or improvements to the features/ functionality of the website/app, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").</p>
        <p>Updates may modify or delete certain features and/or functionalities of the website/app. You agree that farmAIr has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the website/app to you.</p>
        <p>You further agree that all Updates will be (i) deemed to constitute an integral part of the website/app, and (ii) subject to the terms and conditions of this Agreement.</p>
        <h2>Updates to Our Terms</h2>
        <p>We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.</p>
        <h2>Notice of Dispute</h2>
        <p>In the event of a dispute, you or farmAIr must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to:&nbsp;info@farmair.io. farmAIr will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and farmAIr will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or farmAIr may commence mediation.</p>
        <h2>Governing Law</h2>
        <p>The Courts of Athens are competent for any dispute arising from the interpretation or execution of the present Agreement. Prior recourse to mediation is mandatory. Governing law is the Greek law.</p>
        <h2>Contact Us</h2>
        <p>Don't hesitate to contact us if you have any questions.</p>
        <p>&nbsp;-Via Email:&nbsp; info@farmair.io</p>
      </div>
    </section>

  )
}

export default Item;