import { __i18n } from 'i18n';

export const formatLocation = (location) => {
  return Object.values(location).filter(Boolean).join(', ');
}

export const getAreaValue = area => {
  let h = area/10000
  let fixed2 = h.toFixed(2);

  return fixed2;
}

export const getAreaString = fixed2 => {
  if(!fixed2) return null;

  let withComma =  fixed2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${withComma} ${__i18n('hectares')}`
}

export const formatArea = (area) => {
  let areaVal = getAreaValue(area);
  return getAreaString(areaVal);
}

// export const formatPrice = (price) => {
//   return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
// }
export const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(price)
}

export const formatRole = (role) => {
  let str = role.replace('_', ' ');
  if(role === 'drone_admin'){
    return __i18n('Drone Operator')
  }
  if(role === 'drone_operator'){
    return __i18n('Drone Pilot')
  }
  let otherRole = str.charAt(0).toUpperCase() + str.slice(1);
  return __i18n(otherRole)
}