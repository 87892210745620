import React from 'react'
import moment from 'moment'
import { IoMdArrowDropright } from 'react-icons/io'
import { __i18n } from 'i18n';

const validReport = (props) => {
  const { field, selectedReport } = props
  // let report = field.Reports[selectedReport];
  let report = field.Reports.find(el => el.uuid === selectedReport);
  let { validFrom, validTo } = report

  if ((moment() > moment(validFrom)) &&
    (moment() < moment(validTo))) {
    return true
  }
  return false
}

const renderReportCta = (props, type = 'btn') => {
  const { onReportAction, onUploadData, field, selectedReport } = props

  // let report = field.Reports[selectedReport]
  let report = field.Reports.find(el => el.uuid === selectedReport)

  console.log(report, 'renderReportCta')

  let className, arrow, btnText, cbFn;
  if (type === 'btn') {
    className = 'btn btn-primary c-btn-lg';
  } else {
    className = 'btn cta-text'
    arrow = <IoMdArrowDropright className="ml-1" />;
  }

  if (report.service === 'e2e') {
    btnText = __i18n('Schedule Photo Shoot');
    cbFn = onReportAction;
  } else {
    btnText = __i18n('Upload Dataset');
    cbFn = onUploadData;
  }

  return validReport(props) ?
    <button className={className}
      onClick={cbFn}>{btnText}{arrow}
    </button>
    : ''
}

export {
  validReport,
  renderReportCta,
}