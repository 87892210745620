import api from 'utils/api';
import {
  NUM_OF_ITEMS_IN_CART
} from 'actions/types';
import { setAlert } from 'actions/alert'

const getCouponByCode = (couponCode) => async dispatch => {
  try {
    const res = await api.get(`/coupon/code/${couponCode}`);
    let { data, status } = res;
    let { msg, errorMsg } = data;
    if(errorMsg){
      dispatch(setAlert(msg, 'danger'))
      return null;
    }else {
      return data
    }
  } catch (err) {

    console.log(err, 'the error')
  }
}

export {
  getCouponByCode,
}