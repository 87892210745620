function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


const validAge = (age) => {
  let validAge = age
  if(age !== ''){
    validAge = Math.trunc(age)
    if(age < 1){
      validAge = 1
    }
    if(age > 2000){
      validAge = 2000
    }
  }
  return validAge;
}

function roundedToFixed(_float, _digits){
  // var rounded = Math.pow(10, _digits);
  // return (Math.round(_float * rounded) / rounded).toFixed(_digits);
  return Math.round( _float * 10 ) / 10;
}

const validMeter = ({m, min = 0}) => {
  let validHeight = m

  if(m !== ''){
    validHeight = roundedToFixed(m, 1)
    if(m < min){
      validHeight = min;
    }
  }
  return validHeight
}

const validInteger = ({val, max = 2000 }) => {
  let validInt = parseInt(val);

  if(validInt > max){
    return max;
  }else {
    return validInt
  }
}


export {
  validAge,
  validMeter,
  validInteger,
  validateEmail
}


