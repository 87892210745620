import {
  LOAD_REPORTS,
  EMPTY_REPORTS
} from 'actions/types';

const initialState = {
  list: [],
};


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_REPORTS:
      return {
        ...state,
        list: [...state.list, ...payload]
      };
    case EMPTY_REPORTS:
      return {
        ...state,
        list: []
      };
    default:
      return state;
  }
}