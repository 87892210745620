import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchNotifications, fetchNumOfUnreadNotifications } from 'actions/notification/list'
import List from './List.component';
import { showNotifications as showNotificationsAction } from 'actions/ui'

const ListContainer = (props) => {
  const { notifications,showNotifications, showNotificationsAction, 
    fetchNumOfUnreadNotifications,fetchNotifications, companies, showNewCompany } = props

  const [loading, setLoading] = useState(true);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching companies')
      await fetchNotifications();
      setLoading(false);
    })()
  }, []);

  const handleClose = () => {
    setModalVisibility(false)
    showNotificationsAction(false)
  }

  useEffect(() => {
    setModalVisibility(showNotifications)
  }, [showNotifications])

  const onSubmitForm = async (values) => {
    console.log(values)
    // await createCompany(values)
    handleClose()
  }

  const state = {
    modalVisibility,
    notifications,
    loading
  };

  const containerFunctions = {
    handleClose,
    onSubmitForm
  };


  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.list,
  showNotifications: state.ui.showNotifications
});

export default connect(mapStateToProps, { 
  fetchNotifications,
  showNotificationsAction,
  fetchNumOfUnreadNotifications
 })(ListContainer);