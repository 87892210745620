import React from 'react'
import { renderTable } from 'component/Field/utils/renderRow'
import { withTranslation } from 'react-i18next'

const renderFieldOwner = (props) => {
  const { firstName, lastName, email, mobile, location, t } = props

  return (
    renderTable({
      title: t('Field Owner'),
      rows: [
        [t('Owner Name'), `${firstName} ${lastName}`],
        [t('Email'), email],
        [t('Telephone'), mobile],
        [t('Location'), location],
      ]
    })
  )
}

export default withTranslation()(renderFieldOwner)