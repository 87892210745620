import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment'
import ScheduleShoot from './ScheduleShoot.component';
import { fetchFields } from 'actions/field/list'
import { createShoot, updateShoot } from 'actions/shoot'
import { updateShootDates } from 'actions/shoot/update';
import { fetchShoots } from 'actions/shoot/list'
import { setAlert } from 'actions/alert'

const ScheduleShootContainer = (props) => {
  const { userRole, fetchShoots, updateShootDates, setAlert} = props
  let { showConfirmShoot, setShowConfirmShootFn, setShowConfirmShoot, shoot } = props.props

  const [loading, setLoading] = useState(null)
  console.log(props.props, 'props props')

  /* datePicker 1 */
  const [dates1, setDates1] = useState({
    start: moment(shoot.dateRange.start),
    end: moment(shoot.dateRange.end)
  });
  const [focus1, setFocus1] = useState(false);
  const handleDateChange1 = ({ startDate, endDate }) => setDates1({...dates1, start: startDate, end: endDate })
  const handleFocusChange1 = (focused) => setFocus1(focused);

  /* datePicker 2 */
  const [dates2, setDates2] = useState({
    start: moment(shoot.dateRange.start),
    end: moment(shoot.dateRange.end)
  });
  const [focus2, setFocus2] = useState(false);
  const handleDateChange2 = ({ startDate, endDate }) => setDates2({...dates1, start: startDate, end: endDate })
  const handleFocusChange2 = (focused) => setFocus2(focused);

  const isOutsideRange = day => {
    return day.isBefore(moment(shoot.dateRange.start)) || day.isAfter(moment(shoot.dateRange.end));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let startDate1 = dates1.start.toISOString();
    let endDate1 = dates1.end.toISOString();
    let startDate2 = dates2.start.toISOString();
    let endDate2 = dates2.end.toISOString();

    console.log(startDate1, endDate1, startDate2, endDate2)
    let payload = {
      shootUuid: shoot.uuid,
      fieldUuid: shoot.field.uuid,
      status: 'confirmed_shoot_dates',
      fieldOwnerUuid: shoot.owner.uuid,
      shootDays: {
        confirmed: {
          start: startDate1,
          end: endDate1
        },
        alternative: {
          start: startDate2,
          end: endDate2
        }
      }
    }

    setLoading(true)
    if(await (updateShootDates(payload))){
      await fetchShoots({role: userRole, keepActiveItem: true})
      setShowConfirmShoot(false);
      setAlert(`Photo Shoot status has been updated`, 'success');
    }
    setLoading(false)
  }

  const state = {
    focus1,
    dates1,
    focus2,
    dates2,
    showConfirmShoot
  };

  const containerFunctions = {
    setDates1,
    setFocus1,
    handleDateChange1,
    handleFocusChange1,
    setDates2,
    setFocus2,
    handleDateChange2,
    handleFocusChange2,
    onSubmit,
    isOutsideRange,
    setShowConfirmShootFn
  };

  return (
    <ScheduleShoot
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user.role
});

export default connect(mapStateToProps, {
  fetchShoots,
  updateShootDates,
  setAlert,
})(ScheduleShootContainer);