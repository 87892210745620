import api from 'utils/api';
import {
  LOAD_PROFILE,
  EMPTY_PROFILE,
} from 'actions/types';

const updatePassword = (payload) => async dispatch => {
  console.log('[Action] Updating Password')

  try {
    const res = await api.put('/user/update/password', payload);
    console.log(res, 'the res')
    if(res.status === 200) {
      return true
    } else {
      return false;
    }
  } catch (err) {
  }
};

const deleteAccount = (payload) => async dispatch => {
  console.log('[Action] deleteAccount')

  try {
    const res = await api.put('/user/delete/account', payload); // user
    console.log(res, 'the res')
    if(res.status === 200) {
      return true
    } else {
      return false;
    }
  } catch (err) {
  }
};

export {
  updatePassword,
  deleteAccount
}