import React from 'react';
import { withTranslation } from 'react-i18next'
import { getTopVarieties, getI18nVariety } from './data';
import './Item.style.scss'
import { Dropdown } from 'react-bootstrap'
import { BiSearch } from 'react-icons/bi'
import { VscChromeClose } from 'react-icons/vsc'
import { greekStringToSlug } from 'utils/convert'


const renderSearchBar = (props) => {
  const { onSearchChange, searchValue, t } = props;

  return (
    <section>
      <div className="search-group">
        <BiSearch size={24} />
        <input type="text" name="search" id="search"
          className="form-control input-search"
          onChange={onSearchChange}
          value={searchValue}
          autoComplete="off"
          placeholder={`${t('Search Variety')}`}
        />
    </div>
    </section>
  )
}

const renderNoResults = (props) => {
  const { searchTerm, t, topVarieties } = props;

  let shouldRenderTop = topVarietiesIncludeSearchTerm(props)
  let shouldRenderAll = allVarietiesIncludeSearchTerm(props)

  if(shouldRenderTop || shouldRenderAll) return null;

  return (
    <section className="search-results">
      <p className="py-3">{t('No results found for')}: "{searchTerm}"</p>
    </section>
  )
}


const elIncludesSearchTerm = ({el, searchTerm}) => {
  if(!el || !searchTerm) return true;

  let c1 = !el.toUpperCase().includes(searchTerm.toUpperCase())
  let c2 = !greekStringToSlug(el).toUpperCase().includes(searchTerm.toUpperCase());
  return !(c1 && c2)
}

const topVarietiesIncludeSearchTerm = ({searchTerm, topVarieties}) => {
  if(!searchTerm) return true;

  let hideItems = 0;
  Object.values(topVarieties).map((el, i) => {
    if(!elIncludesSearchTerm({el, searchTerm })){
      hideItems++;
    }
  })

  return hideItems < Object.values(topVarieties).length;

}

const allVarietiesIncludeSearchTerm = (props) => {
  const { varietiesData, searchTerm } = props

  if(!searchTerm) return true;
  let hideItems = 0;

  varietiesData.map((el, i) => {
    if(!elIncludesSearchTerm({el, searchTerm })){
      hideItems++;
    }
  })

  return hideItems < varietiesData.length;

}

const renderTopVarieties = (props) => {
  const { t, onVariety, selectedVariety, searchTerm, topVarieties } = props

  let shouldRender = topVarietiesIncludeSearchTerm(props);

  if(!shouldRender){
    return null;
  }

  // let topVarieties = getTopVarieties();

  return (
    <section className="variety-options">
      <h4>{t('Top Options')}</h4>
      <ul >
        {Object.values(topVarieties).map((el, i) => {
          let hide = false;

          if(searchTerm){
            if(!elIncludesSearchTerm({el, searchTerm })){
              hide = true;
            }
          }
          return (
            hide ? null :
            <li key={el} onClick={onVariety(i)}
                className={`${selectedVariety === i ? 'active' : ''} ${hide ? 'hidden' : ''} `}>
              <span>{el}</span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

const renderAllVarieties = (props) => {
  const { t, onVariety, selectedVariety, varietiesData, searchTerm } = props

  let hideItemsAll = 0;

  let shouldRender = allVarietiesIncludeSearchTerm(props);
  if(!shouldRender){
    return null;
  }

  return (
    <section className={`variety-options`}>
      <h4>{t('All Varieties')}</h4>
      <ul className="options">
        {varietiesData.map((el, i) => {
          let hide = false;
          if(searchTerm){
            if(!elIncludesSearchTerm({el, searchTerm })){
              hide = true;
              hideItemsAll++;
            }
          }
          return (
            hide ? null :
            <li key={el} onClick={onVariety(i)}
                className={`${selectedVariety === i ? 'active' : ''} ${hide ? 'hidden' : ''}`}
            ><span>{el}</span></li>
          )
        })}
      </ul>
      {hideItemsAll == varietiesData.length ?
          <div>
            <p>{t('No results found for')}: "{searchTerm}"</p>
          </div> : null
        }
  </section>
  )
}

const Item = (props) => {
  const { t, selectedVarietyText, searchTerm, formData } = props
  const { variety } = formData;

  return (
    <Dropdown className="section-variety">
      <Dropdown.Toggle variant="white" id="dd-variety-menu">
        <div className="w-100" id="dropdown-basic">
          <div className="form-group">
            <label htmlFor="selectedVarietyText">{t('Variety')}</label>
            <input id="selectedVarietyText" name="selectedVarietyText" type="text"
              value={getI18nVariety(variety)}
              className="form-control"
              required
              autoComplete="off"
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="menu-variety">
        <div className="inner">
          <div className="top">
            <h3>{t('Choose Variety')}</h3>
            <VscChromeClose className="cursor-pointer"
            onClick={(e) => document.querySelector('#dd-variety-menu').click()} />
          </div>

          {renderSearchBar(props)}
          {renderNoResults(props)}
          {renderTopVarieties(props)}
          {renderAllVarieties(props)}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}


export default withTranslation()(Item);