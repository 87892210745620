import axios from 'axios';
import store from 'store';
import { LOGOUT, MAINTANCE } from '../actions/types';
import { setAlert } from 'actions/alert';
import { showFullLoader } from 'actions/ui';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true //force browser to sent httpsonly cookies with XHR req
});
/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired
 logout the user if the token has expired
**/



api.interceptors.response.use(
  res => res,
  err => {
    console.log(err, '[Utils Api] Response error', err.response)
    store.dispatch(showFullLoader(false))

    if(!err.response || !err.response.status){
      console.log('No response or status')
      return Promise.reject(err);
    }

    if(!err?.response?.data?.localizeAlert){
      if (err.response.status === 400) { // bad request, loop through errors and show Alert
        const errors = err.response.data.errors;
        if (errors) {
          console.log('errors');
          if(Array.isArray(errors)){
            errors.forEach(error => store.dispatch(setAlert(error.msg, 'danger')));
          } else {
            store.dispatch(setAlert(errors, 'danger'))
          }
        }
      }

      if (err.response.status === 401) { // if server-side responded with unauthorized action
        store.dispatch({ type: LOGOUT });
      } else if (err.response.status === 500) {
        //TODO redirect to maintance when status 500
        // store.dispatch({ type: MAINTANCE });
      }
    }

    return Promise.reject(err);
  }
);

export default api;