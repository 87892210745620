import React from "react";
import { Modal, Button } from "react-bootstrap";
import { VscChromeClose } from "react-icons/vsc";
import "./Reports.style.scss";
import { Form } from "react-bootstrap";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { AiFillFolderAdd } from "react-icons/ai";
import { IoMdRefresh } from "react-icons/io";
import { formatStatus } from "component/Shoot/utils/formatStatus";
import { BiCloudUpload } from "react-icons/bi";
import { BsCameraVideo } from "react-icons/bs";
import { renderReportName } from "./Reports.utils";

function canSelectReport(report) {
  let { validFrom } = report;

  return moment() > moment(validFrom);
}

function compareReportCreatedDate(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
function compareReportUpdatedDate(a, b) {
  if (a.updatedAt > b.updatedAt) {
    return -1;
  }
  if (a.updatedAt < b.updatedAt) {
    return 1;
  }
  return 0;
}

const renderSort = (props) => {
  const { setSortByFn, sortBy, t } = props;

  return (
    <section className="reports-sorting">
      <p>{t("Select sorting of the list")}</p>
      <div className="actions-wrap">
        <button
          onClick={setSortByFn("lastUpdated")}
          className={`${sortBy === "lastUpdated" ? "active" : ""}`}
        >
          {t("By last updated")}
        </button>
        <button
          onClick={setSortByFn("lastAdded")}
          className={`${sortBy === "lastAdded" ? "active" : ""}`}
        >
          {t("By added date")}
        </button>
      </div>
    </section>
  );
};

const renderFirstLine = ({ props, report, selectableReport, t, iconSz }) => {
  const { shootData, user } = props;

  if (!report) return null;

  const isAdminUser = user?.role === "admin";

  console.log(user, "the user");

  if (report?.status === "complete") {
    return (
      <p className="report-option-completed">
        {isAdminUser ? (
          <>
            <span className="icon">
              <BiCloudUpload size={22} />
            </span>
            {report?.numOfInputImgs}
          </>
        ) : null}
        <span
          className="icon second"
          style={{ marginLeft: isAdminUser ? "" : 0 }}
        ></span>
        {report?.numOfOutputImgs}
      </p>
    );
  }

  if (report.status === "processing_pending") {
    let shoot = shootData?.find((el) => el.reportId === report.id);
    console.log("FIRST LINE", shoot);
    if (shoot?.dateRange?.start) {
      return (
        <p className="date">
          <span className="icon">
            <BsCameraVideo size={iconSz} />
          </span>
          {`${moment(shoot?.dateRange?.start).format("MMM D")} - ${moment(
            shoot?.dateRange?.end
          ).format("MMM D")}`}
        </p>
      );
    }
  }

  return (
    <p className="date">
      <span
        title={selectableReport ? t("Date updated") : t("Valid from")}
        className="icon"
      >
        {selectableReport ? (
          <IoMdRefresh size={iconSz} />
        ) : (
          <IoMdRefresh size={iconSz} />
        )}
      </span>
      {selectableReport
        ? moment(report.updatedAt).fromNow()
        : moment(report.validFrom).format("MM/DD/YY")}
    </p>
  );
};

const renderReports = (props) => {
  const { field, onReport, selectedReport } = props.props;
  const { sortBy, t } = props;

  console.log(sortBy, "the sortBy");

  if (!field?.Reports.length) return null;
  let Reports = field.Reports;

  let reportsToRender;

  console.log(Reports, "the reports");

  if (sortBy == "lastUpdated") {
    reportsToRender = [...Reports];
    reportsToRender.sort(compareReportUpdatedDate);
  } else {
    reportsToRender = [...Reports];
    reportsToRender.sort(compareReportCreatedDate);
  }

  const iconSz = 20;

  return (
    <Modal.Body className="">
      {renderSort(props)}
      <Form.Group controlId="reports-radios-group">
        {reportsToRender.map((report, i) => {
          let selectableReport = canSelectReport(report);

          return (
            <div
              key={report.uuid}
              className={`form-group report-option form-radio ${
                !selectableReport ? "not-selectable" : ""
              }  ${selectedReport === report.uuid ? "active" : ""} ${
                report.status
              }`}
            >
              <label
                htmlFor="check-1"
                className="form-radio-label"
                onClick={selectableReport ? onReport(report.uuid) : null}
              >
                <header>
                  <input
                    type="radio"
                    id={`check-${report.uuid}`}
                    className="form-control"
                    checked={selectedReport === report.uuid}
                  />
                  <h5>
                    {t("Report")}
                    <br />
                    {renderReportName(report)}
                    <br />
                  </h5>
                </header>
                <main>
                  {/* <p className="date d-none">
                    <span title={t('Created At')} className="icon"><AiFillFolderAdd size={iconSz} /></span>
                    {moment(report.createdAt).fromNow()}
                  </p> */}
                  {renderFirstLine({
                    props,
                    report,
                    selectableReport,
                    t,
                    iconSz,
                  })}
                  <p className="status">{formatStatus(report, t)}</p>
                </main>
              </label>
            </div>
          );
        })}
      </Form.Group>
    </Modal.Body>
  );
};

const Reports = (props) => {
  const { field, selectedReport } = props.props;
  const { onBtnReport, modalVisibility, handleClose, t } = props;
  let Reports;
  let reportTxt = t("No reports added");

  if (selectedReport) {
    console.log(Reports, selectedReport, "reports and selected", field.Reports);
    let theReport = field.Reports.find((el) => el.uuid === selectedReport);
    reportTxt = renderReportName(theReport);
  }
  if (!selectedReport && field?.Reports.length) {
    reportTxt = t("Select Report");
  }

  if (field?.Reports.length) {
    Reports = field.Reports;
  } else {
    return (
      <div className="reports-empty btn-select-report">
        <h4>{t("Reports")}</h4>
        {reportTxt}
      </div>
    );
  }

  return (
    <div className="new-company new-report">
      <button
        className={`btn btn-light c-btn-lg btn-select-report ${
          selectedReport ? "has-selected-report" : ""
        }`}
        onClick={onBtnReport}
      >
        <h4>{t("Reports")}</h4>
        <p>{reportTxt}</p>
      </button>

      <Modal
        show={modalVisibility}
        onHide={handleClose}
        dialogClassName="modal-select-report aside-modal-action"
        backdropClassName="modal-backdrop-select-report aside-modal-backdrop-action"
      >
        <header className="d-flex align-items-center justify-content-between">
          <Modal.Title>{t("Select Report")}</Modal.Title>
          <Button variant="reset" onClick={handleClose}>
            <VscChromeClose size={28} />
          </Button>
        </header>

        <main className="main">{renderReports(props)}</main>
      </Modal>
    </div>
  );
};

export default withTranslation()(Reports);
