import React from 'react';
import { withTranslation } from 'react-i18next'
import { RiCalendarTodoLine } from 'react-icons/ri'

import './Sms.style.scss'


const Sms = (props) => {

  return (
    <section className="section-sms">
      <button className="btn btn-light">
        <RiCalendarTodoLine size={26} />
      </button>
    </section>
  )
}

export default withTranslation()(Sms);