import React from 'react';
import Logo from 'component/reusable/icon/Logo'
import './Item.style.scss'


const Item = (props) => {

  return (
    <header className="guest-header">
      <Logo />
    </header>
  )
}

export default Item;