import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Cart from './Cart.component';

const CartContainer = ({props}) => {

  const state = {

  };

  const containerFunctions = {
    props
  };

  return (
    <Cart
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CartContainer);