import React from 'react';
import { withTranslation } from 'react-i18next'

import './Item.style.scss'

const renderAppliedCoupon = (props) => {
  const { t } = props
  const { appliedCoupon, setAppliedCoupon } = props.props

  if(!Object.keys(appliedCoupon)) return null;

  const { code, discountPercent } = appliedCoupon;

  return (
    <div className="applied-coupon my-3 fade-in">
      <p className="mb-3">{t('Applied Coupon')}: <strong>{code}</strong> ({discountPercent}%)</p>
      <button className="btn btn-danger" onClick={() => {
        setAppliedCoupon({})
      }}
      >{t('Remove Coupon')}</button>
    </div>
  )
}


const Item = (props) => {
  const { t, showSubmitBtn, setCouponCodeFn, couponCode, onSubmit } = props
  const { appliedCoupon } = props.props

  return (
    <section className="discount-coupon-wrap">
      <h5>{t('Do you have a discount coupon?')}</h5>
      {
        appliedCoupon && Object.keys(appliedCoupon).length ?
          renderAppliedCoupon(props)
          :
          <>
            <form action="" onSubmit={onSubmit}>
              <div className="">
                <div className="form-group mb-4">
                  <label htmlFor="formattedArea">{t('Coupon Code')}</label>
                  <input id="coupon-code" name="coupon-code" type="text"
                    value={couponCode}
                    onChange={setCouponCodeFn}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <button className={`btn c-btn-lg btn-primary w-100
                  ${showSubmitBtn ? 'show fade-in' : 'hide'}`} type="submit"
              >{t('Apply Coupon')}</button>
            </form>
          </>}

    </section>
  )
}

export default withTranslation()(Item);