import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateShoot, updateShootDroneAdmin } from 'actions/shoot/update'
import { fetchShoots } from 'actions/shoot/list'
import Shoot from './Shoot.component';
import { setAlert } from 'actions/alert'
import { showUploadData } from 'actions/ui'


const ShootContainer = ({ shoots, droneAdmins, reduxActiveItem, updateShoot, setAlert, fetchShoots,
  updateShootDroneAdmin,  showUploadData, droneOperators, userRole }) => {
  const [loading, setLoading] = useState(true);
  const [shoot, setShoot] = useState({});

  const [showConfirmShoot, setShowConfirmShoot] = useState(null);

  const [selectedDroneAdmin, setSelectedDroneAdmin] = useState(null);
  const [selectedDroneOperator, setSelectedDroneOperator] = useState(null);


  useEffect(() => {
    /* close open dropdown on item change */
    setSelectedDroneAdmin(null)
    setSelectedDroneOperator(null)
  }, [reduxActiveItem])

  const onUploadData = () => {
    let field = {...shoot.field};
    field.Reports = [];
    field.Reports.push(shoot.report)

    console.log('selected field report', field)
    let payload = {
      value: true,
      field,
      selectedReport: field.Reports[0].uuid
    };
    showUploadData(payload)
    console.log('shoot', payload)
  }


  useEffect(() => {
    (async () => {
      if(reduxActiveItem !== null){
        let activeShoot = shoots.find(el => el.uuid === reduxActiveItem)
        setShoot(activeShoot)
      }
      setLoading(false);
    })()
  }, [ shoots, reduxActiveItem]);

  const  onSelectUserClick = ({type, i}) => e => {
    if(type == 'droneAdmins'){
      setSelectedDroneAdmin(i)
    } else {
      setSelectedDroneOperator(i)
    }
  }

  const onSubmitUser = type => async () => {
    let payload;
    if(type === 'droneAdmins'){
      let droneAdmin = droneAdmins[selectedDroneAdmin];
        payload = {
          droneAdminId: droneAdmin.id
        }
    }else {
      let droneOperator = droneOperators[selectedDroneOperator];
      payload = {
        droneOperatorId: droneOperator.id
      }
    }
    payload.type = type;
    console.log(shoot, 'the shootf', payload)

    setSelectedDroneAdmin(null)
    setSelectedDroneOperator(null)
    let res = await updateShoot(shoot.uuid, payload)
    if(res) {
      let subjTitle = type === 'droneAdmins'? 'Drone Operator' : 'Drone Pilot'
      setAlert(`${subjTitle} has been successfully assigned to Photo Shoot`, 'success');
      setLoading(true)
      await fetchShoots({role: userRole, keepActiveItem: true})
      // updateShootDroneAdmin(reduxActiveItem, droneAdmin)
      setLoading(false)
      // await refetchShoot()
    }
  }

  const fetchShootsFn = async () => {
    try {
      await fetchShoots({role: userRole, keepActiveItem: true})
    }catch(err){
      console.log(err)
    }
  } 

  const setShowConfirmShootFn = (val) => e => {
    console.log('setting val confirm', val)
    setShowConfirmShoot(val)
  }


  const state = {
    shoot,
    droneAdmins,
    droneOperators,
    loading,
    selectedDroneAdmin,
    selectedDroneOperator,
    showConfirmShoot,
    userRole,
  };

  const containerFunctions = {
    onSelectUserClick,
    onSubmitUser,
    setShowConfirmShootFn,
    setShowConfirmShoot,
    onUploadData,
    fetchShootsFn
  };

  return (
    <Shoot
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  shoots: state.shoots.list,
  droneAdmins: state.shoots.droneAdmins,
  droneOperators: state.shoots.droneOperators,
  reduxActiveItem: state.shoots.activeItem,
  userRole: state.auth.user.role
});

export default connect(mapStateToProps, {
  updateShoot,
  setAlert,
  fetchShoots,
  updateShootDroneAdmin,
  showUploadData,
})(ShootContainer);