import api from 'utils/api';

const changeLanguage = (payload) => async dispatch => {
  console.log('[Action] Change user language')

  try {
    const res = await api.post('/user/settings/changeLanguage', payload);
    return true;
  } catch (err) {
    console.log(err)
    return false;
  }
};

export {
  changeLanguage,
}