import React from 'react'
import { TiTick } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const renderOrderPlacementSuccess = (props) => {
  const { t } = props

  return (
    <div className="step2 text center">
      <div className="mb-5 text-center">
        <TiTick size={100} strokeWidth={0}/>
      </div>
      <p>{t('Your order has been successfully placed!')}</p>
      <div className="btn-wrap text-center">
        <Link to="/fields" className="btn btn-primary c-btn-lg">{t('Back to My Fields')}</Link>
      </div>
    </div>
  )
}

export default withTranslation()(renderOrderPlacementSuccess);