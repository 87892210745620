import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import mapboxgl from "mapbox-gl";
import Dashboard from "./Dashboard.component";
import {
  pointsSources,
  pointsLayer,
  pointsLayerFn,
  geoControl,
  mapboxStyle,
} from "component/Mapbox/Mapbox.config";
import { MdTextFields } from "react-icons/md";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const DashboardContainer = ({ fields }) => {
  const mapRef = React.useRef();

  useEffect(() => {
    const field = fields.length ? fields[0] : {};
    mapRef.current.innerHTML = "";
    let map = new mapboxgl.Map({
      container: mapRef.current,
      style: mapboxStyle,
      center: [
        (fields.length > 0 && fields[0].mapDisplay.lng) || 10,
        (fields.length > 0 && fields[0].mapDisplay.lat) || 15,
      ],
      zoom: 5,
    });

    map.once("load", () => {
      map.resize();
    });

    map.on("load", function (e) {
      map.addSource("maine", pointsSources(fields));
      map.addLayer(pointsLayerFn({ lineWidth: 4, lineOpacity: 1 }));
      map.getSource("maine").setData(pointsSources(fields));
    });

    map.on("load", () => {
      let els = fields.map((el) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: el.polygonPoints[0][0],
          },
          properties: {
            name: el.title,
            test: "testing dev",
          },
        };
      });
      map.addSource("earthquakes", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: els,
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "earthquakes",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "earthquakes",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "earthquakes",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 4,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("earthquakes")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const name = e.features[0].properties.name;
        const tsunami = e.features[0].properties.tsunami === 1 ? "yes" : "no";

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(`${name}`)
          .addTo(map);
      });

      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(geoControl());

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    });
  }, [fields]);

  const state = {
    mapRef,
  };

  const containerFunctions = {};

  return (
    <Dashboard
      // { ...this.props }
      {...state}
      {...containerFunctions}
    />
  );
};

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
