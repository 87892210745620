import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AnonymousRoute = ({ // Only Not Logged in
  component: Component,
  auth: { user },
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      user ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

AnonymousRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AnonymousRoute);