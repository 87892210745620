import React from 'react';
import { MdReceipt } from 'react-icons/md';
import moment from 'moment'
import './Item.style.scss'
import { formatLocation, formatArea, formatPrice as fp } from 'utils/format'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import Loader from 'component/reusable/Loader'
import { renderTable } from 'component/Field/utils/renderRow'
import { withTranslation } from 'react-i18next'
import Cart from 'component/Cart/Component/Cart'

const renderInvoiceDetails = (props) => {
  const { order, t } = props

  if (!order || order.transactionType !== 'invoice' || !order.paymentDetails) return ''

  const { firmTitle, firmActivity, telephone,
    taxAuthority, city, email, state, vatId, address, country, zipCode, } = order.paymentDetails

  if (!firmTitle) return ''

  return (
    renderTable({
      title: t('Invoice Info'),
      rows: [
        [t('VAT Number'), vatId],
        [t('Company Name'), firmTitle],
        [t('Activity'), firmActivity],
        [t('Telephone'), telephone],
        [t('Email'), email],
        [t('Tax Authority'), taxAuthority],
        [t('Address'), address],
        [t('City'), city],
        [t('State'), state],
        [t('Country'), country],
        [t('Post Code'), zipCode],
      ],
      className: 'col-6'
    })
  )
}

const renderBillingAddress = (props) => {
  const { order, t } = props

  if (!order || order.transactionType !== 'receipt' || !order.billingAddress) return ''

  const { city, state, line_1, country, zip_postcode } = order.billingAddress

  return (
    renderTable({
      title: t('Billing Address'),
      rows: [
        [t('Country'), country],
        [t('State'), state],
        [t('City'), city],
        [t('Street Line 1'), line_1],
        [t('Post code'), zip_postcode]
      ],
      className: 'col-6'
    })
  )
}

const renderOrderInfo = (props) => {
  const { order, t } = props
  console.log(order, 'the order');
  let payMethodTxt = order?.paymentMethod === 'wire_transfer' ? t('Wire Transfer') : 'Stripe'
  let transactionType = order.transactionType.charAt(0).toUpperCase() + order.transactionType.slice(1);

  let infoRows = [
    [t('Transaction Type'), t(transactionType)],
    [t('Payment Method'), payMethodTxt],
    [t('Total Price'), fp(order.total)],
    [t('Discount'), fp(order.total - order.finalPrice)],
    [t('Final Price'), fp(order.finalPrice)],
  ];

  if (order.coupon) {
    infoRows.push([t('Coupon'), `${order.coupon.code} (-${order.coupon.discountPercent}%)`])
  }

  return (
    <section className="order-info">
      <div className={`status mb-4 ${order.status === "pending_payment" ? 'bg-warning' : 'bg-success'}`}>
        <h4>{t('Status')}</h4>
        <div>
          <p className="status">{order.status === 'pending_payment' ? t('Pending Confirmation') : t('Complete')}</p>
        </div>
      </div>
      <div className="mb-5">
        <h6>{t('Order Id')}: {order.uuid}</h6>
        <h6>{t('Entity Id')}: {order.id}</h6>
      </div>
      {
        renderTable({
          title: t('Order Info'),
          rows: infoRows,
          className: 'col-6'
        })
      }
      {renderInvoiceDetails(props)}
      {renderBillingAddress(props)}
    </section>
  )
}



const renderCustomerInfo = (props) => {
  const { order, t } = props
  const user = order.user
  if (!user) return
  return (

    renderTable({
      title: t('Customer Info'),
      rows: [
        [t('Full Name'), `${user?.firstName} ${user?.lastName}`],
        [t('Email'), user.email],
        [t('Telephone'), user.mobile],
        [t('Location'), user.location]
      ],
      className: 'col-6'
    })
  )
}

const renderReportsInfo = (props) => {
  const { order, t } = props

  const reports = order.Reports
  if (!reports.length) return null;

  return (
    <section className="reports-info">
      {renderTable({
        title: t('Reports Info'),
        rows: [
          [t('Num of Reports'), reports.length],
        ],
        className: 'col-6'
      })}

      <div className="order-report">
        {reports.map((el, i) => {
          return <>
            <h5>{t('Report')} # {++i}</h5>
            {renderTable({
              rows: [
                [t('Field Name'), el.Fields[0].title],
                [t('Location'), formatLocation(el.Fields[0].location)],
                [t('Service'), el.service],
                [t('Valid from'), moment(el.validFrom).format("MMM D, YYYY")],
                [t('Valid To'), moment(el.validTo).format("MMM D, YYYY")],
                [t('Size'), formatArea(el.Fields[0].area)],
                [t('Uuid'), el.uuid],
              ],
              className: 'col-6'
            })}
          </>
        })}
      </div>
    </section>
  )
}


const renderStatusDropdown = (props) => {
  const { order, onStatusClick, selectedStatus, onSubmitStatusUpdate, t } = props

  const reports = order.Reports
  return (
    <section className="change-status">
      <h5 className="mb-4">{t('Change Order Status')}</h5>
      <DropdownButton variant="light" id="dropdown-reports-button" title={`${t('Select Status')}`}>
        <Dropdown.Item onClick={onStatusClick(0)}>{t('Pending Confirmation')}</Dropdown.Item>
        <Dropdown.Item onClick={onStatusClick(1)}>{t('Complete')}</Dropdown.Item>
      </DropdownButton>
      { selectedStatus !== null ?
        <>
          <div>
            <p className="my-4">{t('Selected Status')}: <strong>{selectedStatus == 0 ? t('Pending Confirmation') : t('Complete')}</strong></p>
          </div>
          <button className="btn btn-primary c-btn-lg" onClick={onSubmitStatusUpdate}>{t('Update Status')}</button>
          <div className="text-center cancel-update-wrap mt-4">
            <span onClick={onStatusClick(null)} className="">{t('Cancel')}</span>
          </div>
        </>
        : ''}
    </section>
  )
}

const computeCartItems = (Reports = []) => {
  if (!Reports.length) return null;

  let cartItems = []

  Reports.forEach(report => {
    let fieldUuid = report.Fields[0].uuid;
    if (!cartItems.find(el => el.uuid == fieldUuid)) {
      cartItems.push({ ...report.Fields[0], Reports: [] })
    }
  })

  Reports.forEach(report => {
    let fieldUuid = report.Fields[0].uuid;
    cartItems.forEach((el, i) => {
      if (el.uuid == fieldUuid) {
        cartItems[i].Reports.push(report)
      }
    })

  })
  console.log(cartItems, 'CART ITEMS')
  return cartItems;

}

const renderOrderCart = (props) => {
  const { order, t } = props
  const { Reports = [] } = order;

  console.log(order, 'THE ORDER---')

  const cartItems = computeCartItems(Reports);
  const handleDeleteClick = () => { return null };
  const setCartStepFn = () => { return null };

  let computedProps = {
    cartItems,
    handleDeleteClick,
    setCartStepFn,
    t,
    layout: 'order'
  }

  return (
    <div className="order-cart-inner">
      <Cart props={computedProps} />
    </div>
  )
}

const renderHeader = (props) => {
  const { order: { user = {}}, order, t } = props

  return (
    <header className={`${order.status == 'complete' ? 'complete' : 'pending'}`}>
      <figure>
        <MdReceipt size={25} />
      </figure>
      <h2 className="price-bg">
      {fp(order?.finalPrice)}
      </h2>
      <h3>
      {`${user?.firstName} ${user?.lastName}`}
      </h3>
  </header>
  )
}


const renderOrder = (props) => {
  const { order, t } = props
  return (
    <>
      {renderHeader(props)}
      <main className="mt-5">
        {renderOrderInfo(props)}
        {renderCustomerInfo(props)}
        {renderReportsInfo(props)}
        <strong>{t('Created At')}</strong> <p>{moment(order.createdAt).format("MMM D, YYYY")}</p>
      </main>
      <div className="bottom mt-5">
        {renderStatusDropdown(props)}
      </div>
    </>
  )
}

const renderCart = (props) => {
  return (
    <section className="order-cart">
      <header>
        <h4>Order Cart</h4>
      </header>
      {renderOrderCart(props)}
    </section>
  )
}


const Item = (props) => {
  const { order, loading, t } = props

  return (
    <section className="order-item grid-3 fade-in">
      <div className="inner container">
        {loading && <Loader />}

        <div className="row">
          <div className="grid-3-cart col-5">
            {!loading && order && order.finalPrice && renderOrder(props)}

          </div>

          <div className="grid-3-cart col-7">
            {!loading && order && order.finalPrice && renderCart(props)}
          </div>
        </div>
        {!loading && !order && <div>{t('No order selected')}</div>}
      </div>
    </section>

  )
}

export default withTranslation()(Item);