import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProfile, uploadImage, removeAvatar } from 'actions/settings/index.js'
import TabProfile from './Profile.component';
import { loadUser } from 'actions/auth';
import { useLocation } from 'react-router-dom';
import { fetchUsers, setActiveItem } from 'actions/user/list'
import { updateUser } from 'actions/user/update'
import { setActiveItem as setActiveNavItem } from 'actions/settings'
import { __i18n } from 'i18n';

const ProfileContainer = ({ fetchProfile, userId, profile, uploadImage, removeAvatar,loadUser,
  fetchUsers,updateUser, setActiveNavItem }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    location: '',
    mobile: '',
    email: '',
  });

  useEffect(() => {
    setFormData({...formData, ...profile})
  }, [profile])

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if(!showUpdate){
      setShowUpdate(true)
    }
  }

  const onBack = () => setActiveNavItem(null)

  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching profile')
      await fetchProfile({ id: userId });
      setLoading(false);
    })()
  }, []);

  const updateUsers = async () => {
    await fetchProfile({ id: userId });
    await loadUser();
    if(location.pathname === '/users'){
      await fetchUsers({ activeUserId: userId })
    }
    setUploadingImage(false)
  }


  const onImageChange = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {

      let file = e.target.files[0];
      let totalSizeMb = file.size  / Math.pow(1024,2);

      if(totalSizeMb > 2){ // 2 mb
        document.getElementById('user-avatar-form').reset()
        alert(__i18n('Please choose an image less than 2 MB'))
        return false;
      }


      setUploadingImage(true)
      await uploadImage(file)
      updateUsers()
    }
  };

  const onRemovePhoto = async (e) => {
    e.preventDefault();
    setUploadingImage(true)
    if(await removeAvatar()){
      updateUsers()
      return true
    } else {
      return false
    }
  }

  const onSubmit = async (e) => {
    const { firstName, lastName, location, mobile } = formData
    const payload = { firstName, lastName, location, mobile }
    e.preventDefault();
    if(await updateUser(payload)){
      updateUsers()
      setShowUpdateSuccess(true)
      setShowUpdate(false)
      setTimeout(() => {
        setShowUpdateSuccess(false)
      }, 5000)
    } else {
      setShowErrorMessage(true)
      setTimeout(() => {
        setShowErrorMessage(false)
      }, 3000)
    }
  }


  const state = {
    loading,
    formData,
    profile,
    onChange,
    showUpdate,
    showUpdateSuccess,
    showErrorMessage
  };

  const containerFunctions = {
    onImageChange,
    onRemovePhoto,
    uploadingImage,
    onSubmit,
    onBack,
  };

  return (
    <TabProfile
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  profile: state.settings.profile
});

export default connect(mapStateToProps, { 
  fetchProfile,
  uploadImage,
  removeAvatar,
  loadUser,
  fetchUsers,
  updateUser,
  setActiveNavItem
})(ProfileContainer);