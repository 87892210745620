import React, { useState } from "react";
import "./SatelliteCalendarGraphs.style.scss";
import { withTranslation } from "react-i18next";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const computeGraphData = (data) => {
  let graphData = [];
  data.forEach((item) => {
    const { min, max, mean, dt } = item;
    graphData.push({
      name: moment.unix(dt).format("MMM DD"),
      min: (min || 0).toFixed(2),
      max: (max || 0).toFixed(2),
      mean: (mean || 0).toFixed(2),
    });
  });
  return graphData;
  // return graphData.reverse();
};

const SatelliteCalendarStats = (props) => {
  const { satelliteGraphData, t } = props;
  const { data } = satelliteGraphData || {};

  console.log(data, "the data");

  if (!satelliteGraphData || !data || !data.length) return null;

  let computedGraphData = computeGraphData(data);

  return (
    <section className="satellite-graphs">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} height={200} data={computedGraphData}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" tickMargin={15} width={6} />
          <YAxis tickMargin={15} />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="max"
            stroke="#1c4d00"
            // activeDot={{ r: 8 }}
            strokeWidth={2.4}
          />
          <Line
            type="monotone"
            dataKey="mean"
            stroke="#ffaf0b"
            activeDot={{ r: 0 }}
            strokeWidth={2.4}
            strokeOpacity={0.5}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="min"
            stroke="#df4338"
            activeDot={{ r: 0 }}
            strokeWidth={2.4}
            strokeOpacity={0.1}
            dot={false}
          />
          {/* <Line type="monotone" dataKey="mean" stroke="#ffaf0b" /> */}
          {/* <Line type="monotone" dataKey="min" stroke="#df4338" /> */}
        </LineChart>
      </ResponsiveContainer>
    </section>
  );
};

export default withTranslation()(SatelliteCalendarStats);
