import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { __i18n } from 'i18n';
import Annotate from './Annotate.component';

const AnnotateContainer = (props) => {
  const { onChange, value } = props

  const [showOther, setShowOther] = useState(null);

  const onChange2 = (e) => onChange(e.target.value); 

  const state = {
    value,
    showOther
  };

  const containerFunctions = {
    props,
    onChange: onChange2,
    setShowOther
  };

  return (
    <Annotate
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AnnotateContainer);