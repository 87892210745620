

export const groupFieldsByReportType = (fields) => {
  console.log(fields, 'the fields')
  let groups = {}
  fields.forEach((field, i) => {
    field.Reports.forEach(report => {
      let groupKey = `${field.id}_${report.service}`
      if (!groups[groupKey]) {
        groups[groupKey] = {}
        groups[groupKey].field = field
        // delete groups[groupKey].field.Reports;
        groups[groupKey].reports = []
      }

      let theReport = groups[groupKey].reports.find(el => el.uuid == report.uuid)
      if(!theReport){
        groups[groupKey].reports.push(report)
      }
      
    })
  })
  return groups;
}