const initialFiltersReport = {
  processing_pending: false,
  processing_data: false,
  complete: false,
  pending_payment: false,
  processing: false,
};
const initialFiltersShoot = {
  unassigned: false,
  assigned_to_drone_admin: false,
  assigned_to_drone_operator: false,
  confirmed_shoot_dates: false,
  complete: false,
};

export {
  initialFiltersReport,
  initialFiltersShoot,
}