import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { setActiveItem } from 'actions/field/list'
import { connect } from 'react-redux';
import { getAreaValue } from 'utils/format'
import Li from './Li.component';

const LiContainer = (props) => {
  const { theKey, fieldGroup, setActiveItem, activeItem } = props

  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(null);
  const [groupDisplay, setGroupDisplay] = useState({
    locationTitle: null,
    totalArea: null
  })

  useEffect(() => {
    let location = fieldGroup[0].location;
    let locationTitle = `${location.region}, ${location.country}`
    let totalArea = 0;
    fieldGroup.forEach(field => {
      // needed to round 2 digits each before addition
      // and round to 2 digits before setting
      totalArea += parseFloat(getAreaValue(field.area))
    })


    setGroupDisplay({...groupDisplay, locationTitle, totalArea: totalArea.toFixed(2) })
    setKey(theKey);
  }, [fieldGroup])

  const setOpenDom = val => e => setOpen(val)

  const onField = i => e => {
    setActiveItem(i)
  }

  const state = {
    fieldGroup,
    open,
    groupDisplay,
    activeItem,
    theKey,
  };

  const containerFunctions = {
    setOpen,
    setOpenDom,
    onField
  };

  return (
    <Li
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setActiveItem })(LiContainer);