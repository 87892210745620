import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAddresses } from 'actions/user/address/get'
import { fetchCompanies } from 'actions/company/list'
import BillingInfo from './BillingInfo.component';

const BillingInfoContainer = (props) => {
  const { fetchAddresses, fetchCompanies, user, } = props
  const { setCartStepFn, setBillingAddress, setSelectedTransaction, selectedTransaction,
    company, setCompany} = props.props

  const [addresses, setAddresses] = useState([])
  const [loading, setLoading] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);


  const informParent = async () => {
    console.log('informing parent')
    setLoading(true);
    let data = await fetchCompanies();
    if(data?.companies.length){
      setCompany(data.companies[0])
    }
    let data2 = await fetchAddresses();
    if(data2){
      setAddresses([...addresses,...data2.Addresses])
      console.log('setting', data2.Addresses)
    }
    setLoading(false);
  }


  const fetchCompany = async () => {
    let data = await fetchCompanies();
    if(data?.companies.length){
      setCompany(data.companies[0])
      setCartStepFn(3)
    }
    console.log(company, 'the company')
  }


  const setSelectedAddressFn = val => e => {
    setSelectedAddress(val);
    setBillingAddress(addresses[selectedAddress])
    console.log('the payload', val, addresses[selectedAddress])
  }
  const setSelectedTransactionFn = val => e => setSelectedTransaction(val);


  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching addressed')

      if(['b2b_admin', 'b2b_user'].includes(user.role)){
        await fetchCompany();
      }

      let data = await fetchAddresses();
      if(data){
        setAddresses([...addresses,...data.Addresses])
        console.log('setting', data.Addresses)
      }

      console.log('addresses', addresses)
      console.log(data, 'the data')
      setLoading(false);
    })()
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    let data = await fetchAddresses();
    setAddresses([...data.Addresses])
    setLoading(false);
    console.log('handling')
  }


  const state = {
    loading,
    addresses,
    selectedAddress,
    user,
    selectedTransaction,
    company,
  };

  const containerFunctions = {
    handleCreate,
    setSelectedAddressFn,
    setCartStepFn,
    setSelectedTransactionFn,
    informParent,
  };

  return (
    <BillingInfo
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  fetchAddresses,
  fetchCompanies,
})(BillingInfoContainer);