import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { validateEmail } from 'utils/validate'
import { submitNewPassword } from 'actions/auth';

import NewPassword from './NewPassword.component';


const NewPasswordContainer = ({ submitNewPassword }) => {

  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const [successRequest, setSuccessRequest] = useState(false)

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });


  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = (password_reset_token) => async (e) => {
    e.preventDefault();

    const { password, confirmPassword } = formData

    if(password !== confirmPassword){
      setPasswordsDoNotMatch(true)
      setTimeout(() => {
        setPasswordsDoNotMatch(false)
      }, 3000)
      return null;
    }


    console.log(formData, 'the form Data', password_reset_token, 'token')
    let success = await submitNewPassword({ password_reset_token, password })

    if(success){
      setSuccessRequest(true);
    }else {
      setRequestError(true)
      setTimeout(() => {
        setRequestError(false)
      }, 5000)
    }
  }

  const state = {
    formData,
    successRequest,
    passwordsDoNotMatch,
    requestError
  };

  const containerFunctions = {
    onChange,
    onSubmit
  };

  return (
    <NewPassword
      { ...state }
      { ...containerFunctions }
    />
  )
}

export default connect(null, { submitNewPassword })(NewPasswordContainer);