import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCompanies, setActiveItem } from 'actions/company/list'
import UserCompany from './UserCompany.component';
import {  uploadImage, removeAvatar } from 'actions/settings/company.js'
import { setActiveItem as setActiveNavItem } from 'actions/settings'
import { __i18n } from 'i18n';
import { fetchAddresses } from 'actions/user/address/get'

const ItemContainer = (props) => {
  const { fetchCompanies, uploadImage, removeAvatar, setActiveNavItem, fetchAddresses } = props

  const [addresses, setAddresses] = useState([])
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);

  const fetchCompany = async () => {
    let data = await fetchCompanies();
    if(data?.companies.length){
      setCompany(data.companies[0])
    }
  }

  useEffect(() => {
    (async () => {
      await fetchCompany();
      let data = await fetchAddresses();
      if(data){
        setAddresses([...addresses,...data.Addresses])
        console.log('setting', data.Addresses)
      }
      setLoading(false);
    })()
  }, []);

  const onImageChange = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      console.log('image change', img)

      let file = e.target.files[0];
      let totalSizeMb = file.size  / Math.pow(1024,2);

      if(totalSizeMb > 2){ // 2 mb
        document.getElementById('company-avatar-form').reset()
        alert(__i18n('Please choose an image less than 2 MB'))
        return false;
      }

      setUploadingImage(true)
      await uploadImage(img)
      await fetchCompany();
      setUploadingImage(false)
    }
  };

  const onRemovePhoto = async (e) => {
    e.preventDefault();
    setUploadingImage(true)
    await removeAvatar()
    setUploadingImage(false)
    await fetchCompany();
  }

  const onBack = () => setActiveNavItem(null)

  const state = {
    company,
    loading,
    match: props.match,
    uploadingImage,
    addresses,
  };

  const containerFunctions = {
    onImageChange,
    onRemovePhoto,
    onBack,
  };

  return (
    <UserCompany
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  companies: state.company.list.list,
  activeItem: state.company.list.activeItem
});

export default connect(mapStateToProps, {
  fetchCompanies,
  uploadImage,
  removeAvatar,
  setActiveNavItem,
  fetchAddresses,
})(ItemContainer);