import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import React from 'react';

import Login from './About.component';

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({});

export class LoginContainer extends PureComponent {

  state = {};

  containerFunctions = {};

  render() {
    return (
        <Login
          { ...this.props }
          { ...this.state }
          { ...this.containerFunctions }
        />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);