import React from 'react';

import './Companies.style.scss'

import List from 'component/Company/List'
import Item from 'component/Company/Item'

const Companies = (props) => {

  return (
    <>
      <List />
      <Item />
    </>
  )
}

export default Companies;