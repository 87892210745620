import {
  LOAD_SHOOTS,
  EMPTY_SHOOTS,
  LOAD_DRONE_ADMINS,
  EMPTY_DRONE_ADMINS,
  EMPTY_DRONE_OPERATORS,
  LOAD_DRONE_OPERATORS,
  SET_ACTIVE_SHOOT_ITEM,
  UPDATE_SHOOT_DRONE_ADMIN
} from 'actions/types';

const initialState = {
  list: [],
  droneAdmins: [],
  droneOperators: [],
  activeItem: null
};


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_SHOOTS:
      let newActiveItem

      if(state.activeItem && payload.keepActiveItem){
        newActiveItem = state.activeItem;
      }else {
        if(payload?.list?.length){
          newActiveItem = payload.list[0].uuid
        }
      }
      return {
        ...state,
        list: [...state.list, ...payload.list],
        activeItem: newActiveItem
      };
    case EMPTY_SHOOTS:
      let extra = {}
      if(!payload.keepActiveItem){
        extra.activeItem = null
      }
      return {
        ...state,
        list: [],
        ...extra
      };
    case LOAD_DRONE_ADMINS:
      return {
        ...state,
        droneAdmins: [...state.droneAdmins, ...payload]
      };
    case EMPTY_DRONE_ADMINS:
      return {
        ...state,
        droneAdmins: []
      };
    case LOAD_DRONE_OPERATORS:
      return {
        ...state,
        droneOperators: [...state.droneOperators, ...payload]
      };
    case EMPTY_DRONE_OPERATORS:
      return {
        ...state,
        droneOperators: []
      };
    case SET_ACTIVE_SHOOT_ITEM:
      return {
        ...state,
        activeItem: payload
      };
    case UPDATE_SHOOT_DRONE_ADMIN:
      const {activeItem, droneAdmin } = payload;
      return {
        ...state,
        list: state.list.map(
          (item, i) => i === activeItem ? {...item, droneAdmin}
                                  : item
        ),
      };
    default:
      return state;
  }
}