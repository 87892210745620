import api from "utils/api";
import { LOAD_CART, EMPTY_CART } from "actions/types";
import { groupFieldsByReportType } from "component/Cart/utils/cartItems";
import { setNumOfItemsInCart } from "actions/cart";

const fetchCart = () => async (dispatch) => {
  console.log("Fetching! CART");

  try {
    dispatch({
      type: EMPTY_CART,
    });
    console.log("[Action] Fetch Cart");
    const res = await api.get("/fields?type=cart");

    if (res.data.data) {
      let fields = res.data.data;
      let groups = groupFieldsByReportType(fields);
      console.log(groups, "the action Groups!", Object.keys(groups).length);
      dispatch(setNumOfItemsInCart(Object.keys(groups).length));
    }

    dispatch({
      type: LOAD_CART,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {}
};

export { fetchCart };
