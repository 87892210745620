import api from "utils/api";

const deleteReports = (group) => async (dispatch) => {
  console.log(group, "the group deleete");
  try {
    const res = await api.put("/field/reports/delete", { ...group });
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    alert(err);
  }
};

export { deleteReports };
