import React from 'react';
import { CgOrganisation } from 'react-icons/cg';
import { FaPhoneAlt } from 'react-icons/fa';
import { RiBillLine,  RiSettings3Fill } from 'react-icons/ri';
import { AiOutlineLink } from 'react-icons/ai';
import './Item.style.scss'
import { Link } from 'react-router-dom'
import { Can } from 'component/Router/Can'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { renderAvatar } from 'component/reusable/User'
import { RiLogoutBoxFill } from 'react-icons/ri'
import { setActiveItem } from 'actions/field/list';
import { formatRole } from 'utils/format'
import { useTranslation } from 'react-i18next';

const Item = (props) => {
  const location = useLocation();
  const { pathname } = location
  const { user, onLogout, setActiveNavItem, activeNavItem } = props
  const { t } = useTranslation();

  console.log(pathname, user, 'the loc')

  const addDesktopActive = (uri) => {
    if(window.innerWidth >= 768){
      if(pathname.endsWith(uri)){
        return 'active'
      }
    }
    return ''
  }

  return (
    <>
      <section className={`company-list grid-2 profile-sidebar ${activeNavItem ? 'active': ''}`}>
        <header>
          <h2>{t('Profile')}</h2>
        </header>
        <div>
          <ul className="menu">
            <li className={`profile ${addDesktopActive('profile/')}`} 
                onClick={setActiveNavItem('profile')}>
              <Link to="/profile/">
                <div className="d-flex">
                  <div className="left">
                    {renderAvatar(user)}
                  </div>
                  {user ?
                    <div className="right">
                      <h4>{user.firstName} {user.lastName}</h4>
                      <p>{user.email}</p>
                      <p>{formatRole(user.role)}</p>
                    </div>
                    : ''}
                </div>
              </Link>
            </li>
            <Can do="read" a={"UserCompany"}>
              <li className={`company ${addDesktopActive('company')}`}
                onClick={setActiveNavItem('company')}
              ><CgOrganisation size={24} /><Link to="/profile/company">{t('Company Info')}</Link></li>
            </Can>
            <Can do="read" a={"UserOrders"}>
              <li className={`${addDesktopActive('orders')}`}
                onClick={setActiveNavItem('orders')}
              >
                <RiBillLine size={24} /><Link to="/profile/orders">{t('Orders')}</Link>
              </li>
            </Can>
            <li className={`${addDesktopActive('address-book')}`}
              onClick={setActiveNavItem('address-book')}
            >
              <FaPhoneAlt size={24} /><Link to="/profile/address-book">{t('Address Book')}</Link>
            </li>
            <li className={`${addDesktopActive('settings')}`}
              onClick={setActiveNavItem('settings')}
            >
              <RiSettings3Fill size={24} />
              <Link to="/profile/settings">{t('Settings')}</Link>
            </li>
            <li className={`about ${addDesktopActive('about')}`}
              onClick={setActiveNavItem('about')}
            >
              <AiOutlineLink size={24} />
              <Link to="/profile/about">{t('About')}</Link>
            </li>
            <li>
              <RiLogoutBoxFill size={24} />
              <button className="btn-reset w-100 border-0 logout" onClick={onLogout}>
                {t('Logout')}
            </button>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
})(Item);