import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCart } from 'actions/field/find'
import { deleteReports } from 'actions/field/reports'
import { createOrder } from 'actions/orders/create'
import List from './List.component';

const ListContainer = (props) => {
  const {fetchCart, cartItems, deleteReports, createOrder } = props
  const [selectedTransaction, setSelectedTransaction] = useState(0);
  const [company, setCompany] = useState([]);

  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState({});
  const [cartStep, setCartStep] = useState(0);
  const [billingAddress, setBillingAddress] = useState({})
  const [paymentMethod, setPaymentMethod] = useState('wire_transfer');

  const [appliedCoupon, setAppliedCoupon] = useState({});

  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching cart')
      await fetchCart();
      setLoading(false);
    })()
  }, []);

  const onClose = () => {
    setGroupToDelete({})
    setShowDeleteModal(false)
  }

  const onSubmitDelete = async () => {
    console.log('submitting', groupToDelete)
    setLoading(true)
    setShowDeleteModal(false)
    if(await deleteReports(groupToDelete)){
      await fetchCart();
      setLoading(false);
    }
  }

  const setGroupToDeleteFn = (group) => {
    setGroupToDelete({...groupToDelete, ...group})
  }

  const handleDeleteClick = ({value, group}) => e => {
    console.log('clicking ', value, group)
    setShowDeleteModal(value)
    setGroupToDelete(group)
  }

  const setCartStepFn = (val) => e => {
    console.log(val, 'the val')
    setCartStep(val)
  }

  const submitStripeOrder = async () => {
    console.log('submitStripeOrder')
    setPaymentMethod('stripe')
    await placeOrder();
  }

  const placeOrder = async (e) => {
    console.log('placing order')
    let selectedTransactionTxt = selectedTransaction === 0 ? 'receipt'
                                  : 'invoice';
    let payMethod = 'wire_transfer';
    if(e){
      e.preventDefault();
    }else {
      payMethod = 'stripe'
    }

    let couponCode = appliedCoupon ? appliedCoupon.code : null
    setLoading(true)

    let sameAsCompanyAddress = {...billingAddress};
    if(selectedTransaction === 1) {
      sameAsCompanyAddress = {
        ...company.invoiceDetails
      }
    }

    const payload = {
      cartItems,
      billingAddress: sameAsCompanyAddress,
      transactionType: selectedTransactionTxt,
      paymentMethod: payMethod,
      couponCode
    }

    payload.paymentDetails = selectedTransaction === 0 ? '' : company.invoiceDetails;

    console.log(payload, 'the payload')
    if(!payload.billingAddress){
      alert('No Address');
      return;
    }
    if(await createOrder(payload)) {
      await fetchCart();
      setCartStep(3)
    }
    setLoading(false)
  }

  const state = {
    loading,
    cartItems,
    showDeleteModal,
    cartStep,
    selectedTransaction,
    company,
    appliedCoupon,
  };

  const containerFunctions = {
    onClose,
    onSubmitDelete,
    setGroupToDelete: setGroupToDeleteFn,
    handleDeleteClick,
    setCartStepFn,
    placeOrder,
    setBillingAddress,
    setSelectedTransaction,
    setCompany,
    submitStripeOrder,
    setPaymentMethod,
    setAppliedCoupon
  };

  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.list,
});

export default connect(mapStateToProps, { 
  fetchCart,
  deleteReports,
  createOrder,
 })(ListContainer);