import api from 'utils/api';


const fetchAddresses = (payload) => async dispatch => {
  console.log('[Action] Fetch address')

  try {
    const res = await api.get('/user/address', payload);
    if(res.status === 200){
      return res.data;
    }else {
      return false
    }


    // dispatch({ type: SHOW_NEW_USER, payload: { value: false } });
  } catch (err) {
    alert(err);
    return false
  }
};


export {
  fetchAddresses,
}