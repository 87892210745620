import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Reports from './Reports.component';


const ReportsContainer = ({ props, shootData, user }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [sortBy, setSortBy] = useState('lastUpdated')

  const handleClose = () => {
    setModalVisibility(false)
  }

  const onBtnReport = () => {
    console.log('clicking report')
    setModalVisibility(true)
  }

  const setSortByFn = val => e => {
    setSortBy(val)
  }

  const state = {
    modalVisibility,
    sortBy,
    user
  };

  const containerFunctions = {
    handleClose,
    onBtnReport,
    setSortByFn,
    props,
    shootData
  };

  return (
    <Reports
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  shootData: state.fields.shootData,
  user: state.auth.user
});

export default connect(mapStateToProps, {

})(ReportsContainer);

