import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { showNewReport, showScheduleShoot, showUploadData } from "actions/ui";
import Item from "./Item.component";
import { setActiveItem } from "actions/field/list";
import axios from "axios";

let prevSelectedReport = null;

const ItemContainer = (props) => {
  const {
    fields,
    activeItem,
    showNewReport,
    showScheduleShoot,
    showUploadData,
    shootData,
    setActiveItem,
    user,
  } = props;

  const [loading, setLoading] = useState(true);
  const [field, setField] = useState(null);
  const [showEditDetails, setShowEditDetails] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    console.log(activeItem, "ACTIVE IMEM--");
    setSelectedReport(null);
  }, [activeItem]);

  useEffect(() => {
    (async () => {
      if (activeItem !== null) {
        let activeField = fields.find((el) => el.id === activeItem);
        setField(activeField);
      }
      setLoading(false);
    })();
  }, [fields, activeItem]);

  const onEditFieldDetails = () => {
    console.log("edit..");
    setShowEditDetails(true);
  };

  const informParent = () => {
    setShowEditDetails(false);
  };

  const onUploadData = () => {
    showUploadData({
      value: true,
      field,
      selectedReport,
    });
  };

  const onNewReport = () => showNewReport(true);

  const onReport = (i) => (e) => {
    setSelectedReport(i);
    let elem = document.querySelector(
      ".modal-select-report header button.btn-reset"
    );
    if (elem) {
      setTimeout(() => {
        elem.click();
      }, 300);
    }
  };

  const onReportAction = (e) => {
    let report = field.Reports.find((el) => el.uuid === selectedReport);
    console.log(report, "the report");
    showScheduleShoot({ value: true, report });
  };

  const onBack = () => {
    console.log("on back");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setActiveItem(null);
  };

  const downloadKml = async (e) => {
    e.preventDefault();
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/reports/download/kml`,
      {
        fieldId: field.id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([res.data.kml]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${field.uuid}.kml`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const state = {
    field,
    loading,
    selectedReport,
    showUploadData,
    shootData,
    showEditDetails,
    activeItem,
    user,
  };

  const containerFunctions = {
    onNewReport,
    onReport,
    onReportAction,
    onUploadData,
    onEditFieldDetails,
    informParent,
    onBack,
    downloadKml,
  };

  return <Item {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({
  fields: state.fields.list,
  shootData: state.fields.shootData,
  activeItem: state.fields.activeItem,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  showNewReport,
  showScheduleShoot,
  showUploadData,
  setActiveItem,
})(ItemContainer);
