import api from 'utils/api';
import { setAlert } from 'actions/alert'
import {
  LOAD_COMPANIES,
  EMPTY_COMPANIES,
  SET_ACTIVE_COMPANY_ITEM,
} from 'actions/types';


const fetchCompanies = () => async dispatch => {
  console.log('Fetching! action')

  try {
    dispatch({
      type: EMPTY_COMPANIES
    });
    const res = await api.get('/companies');

    dispatch({
      type: LOAD_COMPANIES,
      payload: res.data.companies
    });
    console.log(res, 'the companies')
    return res.data;

    
  } catch (err) {
    alert(err)
  }
}

const setActiveItem = (val) => async dispatch => {
  try {
    dispatch({
      type: SET_ACTIVE_COMPANY_ITEM,
      payload: val
    });
  } catch (err) {
    alert(err)
  }
}

export {
  fetchCompanies,
  setActiveItem,
}