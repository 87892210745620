import React from 'react';
import { Form, Button } from 'react-bootstrap'
import ChangeLanguage from './component/ChangeLanguage'
import './Security.style.scss'
import IconBack from 'component/reusable/icon/Back'
import { withTranslation } from 'react-i18next'


const DeleteAccount = (props) => {
  const { deleteEmailVal, setDeleteEmailValFn, showDeleteEmailBtn, onSubmitDelete, t } = props.props
  return (
    <section className="settings-section delete-account">
      <header>
        <h3>{t('Delete Account')}</h3>
        <p>{t('Once you delete your account, there is no going back. Please be certain.')}</p>
      </header>
      <form onSubmit={onSubmitDelete}>
        <div className="form-group delete-email mb-3">
          <label htmlFor="" className="form-label">{t('Enter email')}</label>
          <input type="text" className="form-control" value={deleteEmailVal} onChange={setDeleteEmailValFn}/>
        </div>
        <Button variant="danger" type="submit" className="c-btn c-btn-lg w-100"
              disabled={!showDeleteEmailBtn}>
              {t('Submit')}
          </Button>
      </form>
    </section>

  )
}


const Security = (props) => {
  const { onSubmit, onChange, formData, wrongOldPassword, passwordsDoNotMatch, weakNewPassword,
    updateSuccess, loading, onBack, t } = props

  return (
    <div className={`company-item grid-3 ${loading ? 'loading' : ''}`}>
      <div onClick={onBack}><IconBack layout="block" /></div>
      <div className="inner">
        <header>
          <h1>{t('Change Password')}</h1>
          <p>{t("Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter")}</p>
        </header>
        {updateSuccess && <p className="text-success">{t('Password updated successfully!')}</p>}
        <>
        <Form className="tab-security-form" onSubmit={onSubmit}>
          <Form.Group controlId="password">
            <Form.Label>{t('Current Password')}</Form.Label>
            <Form.Control type="password" placeholder=""
              name="password" onChange={onChange} value={formData.password}
              required
              autoFocus />
            <Form.Control.Feedback type="invalid"
              className={`${wrongOldPassword ? "active" : ""}`} >
              {t('Current password is not correct')}
          </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Label>{t('New Password')}</Form.Label>
            <Form.Control type="password" placeholder=""
              name="newPassword" onChange={onChange} value={formData.newPassword}
              pattern=".{5,256}"
              required />
            <Form.Control.Feedback type="invalid"
              className={`${weakNewPassword ? "active" : ""}`} >
              {t("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters")}
          </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="newPasswordConfirm">
            <Form.Label>{t('Confirm New Password')}</Form.Label>
            <Form.Control type="password" placeholder=""
              pattern=".{5,256}"
              name="newPasswordConfirm" onChange={onChange} value={formData.newPasswordConfirm}
              required
            />
            <Form.Control.Feedback type="invalid"
              className={`${passwordsDoNotMatch ? "active" : ""}`} >
              {t('Confirm password do not match New Password')}
          </Form.Control.Feedback>
          </Form.Group>
          <div className="btn-wrap">
            <Button variant="primary" type="submit" className="c-btn c-btn-lg w-100">
              {t('Submit')}
          </Button>
          </div>
        </Form>
        <ChangeLanguage />
        <DeleteAccount props={props}/>
        </>
        {loading ? 'loading...' : ''}
      </div>
    </div>
  )
}

export default withTranslation()(Security);