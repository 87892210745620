import React, { Component, useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'


const FormGroup = ({ props, opts }) => {

  const [groupClass, setGroupClass] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    let className = ''
    // if(props.formData[opts.name]){
    //   className = 'label-shrink';
    // } else {
    // }
    // className = opts.disabled ? `${className} disabled`: className;
    // setGroupClass(className);

    // if(props.formData[opts.name] && opts.placeAfter) {
    //   setValue(`${props.formData[opts.name]} ${opts.placeAfter}`);
    // }else {
    //   setValue(props.formData[opts.name]);
    // }

  }, [props.formData[opts.name]])

  const onFocus = () => {
    // setGroupClass('focused');
  }

  const onFocusOut = () => {
    // if(props.formData[opts.name]){
    //   setGroupClass('label-shrink');
    // }else {
    //   setGroupClass('');
    // }
  }

  const getPattern = () => {
    if(opts.pattern) return opts.pattern;
    if(opts.type === 'password'){
      // escape backslashes
      return  "(?=.*[\\d,`~!@#$%^&*);'[\\]\\x22{}.]).{8,16}";
    }
  }

  const extraClassName = opts.className ? opts.className : ''

  // const onChange = (event) => {

  //   const caret = event.target.selectionStart
  //   const element = event.target
  //   window.requestAnimationFrame(() => {
  //     element.selectionStart = caret
  //     element.selectionEnd = caret
  //   })
  //   setValue(value)
  //   // your code
  // }

  return (
    <Form.Group controlId={`form-${opts.name}`} className={`${groupClass} ${extraClassName}`}>
        <Form.Label>{ opts.label }</Form.Label>
        <Form.Control
          name={opts.name}
          type={opts.type}
          required={opts.required}
          pattern={getPattern()}
          onChange={props.onChange}
          // value={value}
          onFocus={onFocus}
          onBlur={onFocusOut}
          disabled={opts.disabled}
          // onChange={onChange}
        />
        {/* { opts.pattern } */}
        <Form.Control.Feedback type="invalid">{opts.invalidFeedback}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default FormGroup;