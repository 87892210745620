import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import "./Agro.style.scss";
import api from "utils/api";
const AgroComponent = (props) => {
  const { shoots } = props;
  const [showAgroRes, setShowAgroRes] = useState(null);
  const [populateSoilDataRes, setPopulateSoilDataRes] = useState(null);

  const onCreateClick = async () => {
    const { data = {} } = await api.post("/agro/createPolygons");
    const { agroIdsCreated } = data || {};
    setShowAgroRes(agroIdsCreated);
    console.log("click");
  };

  const onPopulateSoilData = async () => {
    const { data = {} } = await api.get("/agro/populateReportsSoilData");
    // setShowAgroRes(agroIdsCreated);
    console.log("response", data);
  };

  const onPopulateSatelliteImages = async () => {
    const { data = {} } = await api.get("/agro/populateReportsSatelliteImgs");
    const { agroIdsCreated } = data || {};
    // setShowAgroRes(agroIdsCreated);
    console.log("click", data);
  };
  const onPopulateSatelliteStats = async () => {
    const { data = {} } = await api.get("/agro/populateReportsSatelliteStats");
    const { agroIdsCreated } = data || {};
    // setShowAgroRes(agroIdsCreated);
    console.log("click", data);
  };
  const onDeleteReportsSoilData = async () => {
    const { data = {} } = await api.get("/agro/deleteReportsSoilData");
    const { agroIdsCreated } = data || {};
    // setShowAgroRes(agroIdsCreated);
    console.log("click", data);
  };
  const onDeleteReportsSatelliteImgs = async () => {
    const { data = {} } = await api.get("/agro/deleteReportsSatelliteImgs");
    const { agroIdsCreated } = data || {};
    // setShowAgroRes(agroIdsCreated);
    console.log("click", data);
  };
  const onPopulateReportsShootDays = async () => {
    const { data = {} } = await api.get("/agro/populateReportsShootDays");
    const { agroIdsCreated } = data || {};
    // setShowAgroRes(agroIdsCreated);
    console.log("click", data);
  };

  const [pass, setPass] = useState(null);
  const isDev = pass === "dev";
  return (
    <section className="agro-settings">
      <header>
        <h4>Actions</h4>
        <div className="form-group">
          <input
            type="password"
            id="devpass"
            value={pass}
            placeholder="pass"
            className="form-input"
            onChange={(e) => setPass(e.target.value)}
          />
        </div>

        <button
          className="btn btn-light"
          onClick={onCreateClick}
          disabled={!isDev}
        >
          Create Fields Polygons
        </button>
        <button
          className="btn btn-light"
          onClick={onPopulateReportsShootDays}
          disabled={!isDev}
        >
          Populate Reports Shoot Days
        </button>
        <button
          className="btn btn-light"
          onClick={onPopulateSoilData}
          disabled={!isDev}
        >
          Populate Reports Soil Data
        </button>
        <button
          className="btn btn-light"
          onClick={onPopulateSatelliteImages}
          disabled={!isDev}
        >
          Populate Reports Satellite Images
        </button>
        <button
          className="btn btn-light"
          onClick={onPopulateSatelliteStats}
          disabled={!isDev}
        >
          Populate Reports Satellite Stats
        </button>
        <button
          className="btn btn-light"
          onClick={onDeleteReportsSoilData}
          disabled={!isDev}
        >
          Delete Reports Soil Data
        </button>
        <button
          className="btn btn-light"
          onClick={onDeleteReportsSatelliteImgs}
          disabled={!isDev}
        >
          Delete Reports Satellite Imgs
        </button>
      </header>
      <main>
        {showAgroRes !== null && (
          <>
            <p>Agro Ids Created: {setShowAgroRes}</p>
          </>
        )}
      </main>
    </section>
  );
  // }
};

export default AgroComponent;
