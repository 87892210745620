import React from 'react';

import './Orders.style.scss'

import List from 'component/Orders/List'
import Item from 'component/Orders/Item'

const Orders = (props) => {

  return (
    <>
      <List />
      <Item />
    </>
  )
}

export default Orders;