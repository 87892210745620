import {
  LOAD_ORDERS,
  EMPTY_ORDERS,
  SET_ACTIVE_ORDER_ITEM
} from 'actions/types';

const initialState = {
  list: [],
  activeItem: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ORDERS:
      let opts = {};
      if(!payload?.keepActiveItem){
        opts.activeItem = payload.orders.length ? payload.orders[0].uuid : null
      }
      return {
        ...state,
        list: [...state.list, ...payload.orders],
        ...opts
      };
    case EMPTY_ORDERS:
      return {
        ...state,
        list: [],
        activeItem: payload?.keepActiveItem ? state.activeItem : null
      };
    case SET_ACTIVE_ORDER_ITEM:
      return {
        ...state,
        activeItem: payload
      };
    default:
      return state;
  }
}