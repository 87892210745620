import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import api from "utils/api";
import { setMapboxTile } from "actions/field/mabbox";
import { setSatelliteGraphData } from "actions/field/agro";
import SatelliteCalendarStats from "./SatelliteCalendarStats.component";
import axios from "axios";

const SatelliteCalendarStatsContainer = (props) => {
  const {
    fields,
    activeItem,
    setMapboxTile,
    mapboxTile,
    setSatelliteGraphData,
  } = props;

  const [field, setField] = useState(null);
  const [satelliteData, setSatelliteData] = useState(null);
  const [activeStat, setActiveStat] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [showSelectHint, setShowSelectHint] = useState(false);

  const onSelectStat = (val) => (e) => setActiveStat(val);
  const onClickDate = (val) => (e) => {
    console.log(val, "on click", satelliteData);
    setActiveDate(activeStat ? val : null);
    if (!activeStat) {
      setShowSelectHint(true);
      setTimeout(() => {
        setShowSelectHint(false);
      }, 1600);
    }
  };
  const onUnselect = () => {
    setActiveStat(null);
    setActiveDate(null);
  };

  useEffect(() => {
    if (activeDate !== null) {
      let item = satelliteData[activeDate].tile[activeStat];
      console.log(item, "use-effect");
      setMapboxTile(item.replace("http:", ""));
    }
    if (activeStat === null) {
      setMapboxTile(null);
    }
  }, [activeStat, activeDate]);

  useEffect(() => {
    (async () => {
      if (activeItem !== null) {
        setField(fields.find((el) => el.id === activeItem));
      }
      // setLoading(false);
    })();
  }, [fields, activeItem]);

  useEffect(() => {
    async function fetchAgroSatelliteStats(agroId) {
      try {
        // let agroId = "61581faaa81b764fb5680f97";
        const { data } = await api.get(
          `/agro/getSatelliteImgsData?agroId=${agroId}`
        );

        const { agroImageSearchData } = data || {};
        // let filteredData = agroImageSearchData.reverse();

        setSatelliteData(agroImageSearchData.reverse());
        console.log(agroImageSearchData, "satelliteimgsdata");
      } catch (err) {
        console.log("AgroSatelliteImgsData", err);
      }
    }

    if (field) {
      let { agroId } = field;
      setSatelliteData(null);
      fetchAgroSatelliteStats(agroId);
    }
  }, [field]);

  useEffect(() => {
    if (!activeStat) {
      setSatelliteGraphData(null);
    }

    if (activeStat && satelliteData) {
      console.log(activeStat, satelliteData, "---");

      const stats = (satelliteData || []).map((el) => el.stats);
      const selectedIndexStatsUrlsArray = stats.map((el) => el[activeStat]);

      console.log(selectedIndexStatsUrlsArray, "selectedIndexStatsArray");

      let promises = [];
      let arrOfDswiPromises = selectedIndexStatsUrlsArray.map((el) =>
        fetchAgroSatelliteStats(el)
      );

      arrOfDswiPromises.forEach((el) => promises.push(el));
      let values = fetchAgroSatelliteStats2(promises);
    }

    async function fetchAgroSatelliteStats(url) {
      try {
        const res = await axios.get(url);
        return res;
      } catch (err) {
        console.log("AgroSatelliteImgsData", err);
      }
    }

    async function fetchAgroSatelliteStats2(promises) {
      const values = await Promise.all(promises || []);

      let selectedIndexStatsResults = values.map((el, i) => ({
        ...el?.data,
        dt: satelliteData[i].dt,
      }));

      setSatelliteGraphData({
        data: selectedIndexStatsResults,
        type: activeStat,
      });
      console.log(
        values,
        "THE PROMISES VALUES",
        satelliteData,
        selectedIndexStatsResults
      );
      return values;
    }
  }, [activeStat]);

  const state = {
    satelliteData,
    activeStat,
    mapboxTile,
    activeDate,
    showSelectHint,
  };

  const containerFunctions = {
    onSelectStat,
    onClickDate,
    onUnselect,
  };

  return <SatelliteCalendarStats {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({
  fields: state.fields.list,
  shootData: state.fields.shootData,
  activeItem: state.fields.activeItem,
  mapboxTile: state.fields.mapboxTile,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  setMapboxTile,
  setSatelliteGraphData,
})(SatelliteCalendarStatsContainer);
