import React from 'react'
import { withTranslation } from 'react-i18next'

const GoogleMapsButton = (props) => {
  const { field = {}, t } = props;

  let coords = field?.polygonPoints[0];

  if(!coords) return null;

  return (
    <button type="submit" className="btn btn-light my-3">
      <a target="_blank" href={` http://www.google.com/maps/place/${coords[0][1]},${coords[0][0]}`}>
        {t('Link to Google Maps')}
      </a>
    </button>
  )
}

export default withTranslation()(GoogleMapsButton);
