import React from 'react';
import { formatLocation, formatPrice, formatArea } from 'utils/format'
import { RiImageAddFill } from 'react-icons/ri'
import { AiFillDelete } from 'react-icons/ai'
import { computePrice, computeDuration } from 'component/Cart/utils/price'
import { groupFieldsByReportType } from 'component/Cart/utils/cartItems'
import { renderAvatar } from 'component/reusable/User'
import PriceBox from 'component/Cart/reusable/PriceBox'
import DiscountCoupon from './component/DiscountCoupon'
import './Cart.style.scss'
import { withTranslation } from 'react-i18next'

const renderItemGroup = (group, handleDeleteClick, key, t, layout) => {
  const { field, reports } = group

  let duration = computeDuration(group)
  let price
  let service = reports[0].service === 'e2e' ? 0 : 1
  if (group.field && group.reports) {
    price = computePrice({
      duration,
      numOfShoots: reports?.length,
      hectares: field.area,
      service
    })
  }

  console.log(group, 'THE GROUP')

  return (
    <div className="row cart-row" key={key}>
      <div className="c1">
        {renderAvatar(field, { size: 'xs', icon: 'field' })}
        <div className="right">
          <h6 title={field.title}>{field.title}</h6>
          <p>{formatLocation(field.location)}</p>
        </div>
      </div>
      <div className="c2">
        <p className="legend">{t('Service')}</p>
        {
          reports[0].service === 'e2e' ? <div className="main">
            <RiImageAddFill size={30} />
            <h6>{t('E2E')}</h6>
          </div> : <div className="main">
              <RiImageAddFill size={30} />
              <h6>{t('BYOI')}</h6>
            </div>
        }
      </div>
      <div className="c3">
        <p className="legend">{t('Yrs. Duration')}</p>
        <h6>{computeDuration(group)}</h6>
      </div>
      <div className="c4">
        <p className="legend">{t('Num. Reports')}</p>
        <h6>{reports.length}</h6>
      </div>
      <div className="c5">
        <p className="legend">{t('Price')}</p>
        <h6>{formatPrice(price)}</h6>
      </div>
      { layout === 'userCart' ?
      <div className="c6">
        <button className="btn btn-light" onClick={handleDeleteClick({ value: true, group })} >
          <AiFillDelete size={24} />
        </button>
      </div>
      : <div className="c6"><h6>{formatArea(field?.area).split(' ')[0]} ha</h6></div> }
    </div>
  )
}


const Cart = ({ props, t }) => {
  const { cartItems, handleDeleteClick, setCartStepFn, layout = 'userCart' } = props
  console.log('the cart', props, t)
  if (!cartItems.length) {
    return <div className="grid-empty">{t('No cart items')}</div>
  }

  let itemGroups = groupFieldsByReportType(cartItems)

  console.log(itemGroups, 'ITEMS GROUPS')
  // let totalArea = 

  return (
    <>
      <div className="container-fluid cart-items inner fade-in">
        <div className="body">
          <div className="row header">
            <div className="c1"><h6>{t('Field')}</h6></div>
            <div className="c2"><h6>{t('Service')}</h6></div>
            <div className="c3"><h6>{t('Yrs. Duration')}</h6></div>
            <div className="c4"><h6>{t('Num. Reports')}</h6></div>
            <div className="c5"><h6>{t('Price')}</h6></div>
            { layout === 'userCart' ?
            <div className="c6"></div>
            :  <div className="c6"><h6>{t('Size')}</h6></div>}
          </div>

          {Object.keys(itemGroups).map(key => {
            return renderItemGroup(itemGroups[key], handleDeleteClick, key, t, layout)
          })}
           { layout === 'userCart' ?
          <DiscountCoupon props={props} />
          : null}
          <PriceBox props={props} />


        </div>

        { layout === 'userCart' ?
        <div className="bottom bottom-wrap">
          <div className="btn-wrap container-sm">
            <button className="btn btn-primary c-btn-lg"
              onClick={setCartStepFn(1)}>{t('Proceed to Billing Information')}
            </button>
          </div>
        </div>
        : null}

      </div>
    </>
  )
}


export default withTranslation()(Cart);