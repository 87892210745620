import {
  LOAD_USERS,
  EMPTY_USERS,
  SET_ACTIVE_USER_ITEM,
} from 'actions/types';

const initialState = {
  list: [],
  activeItem: null
};


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_USERS:
      let activeItem;
      const { activeItemId = null }  = payload

      console.log(activeItemId, 'reduc')
      if(payload.list.length){
        if(activeItemId){
          activeItem = activeItemId === -1 ? null : activeItemId
        } else {
          activeItem  = payload.list[0].id
        }
      }
      return {
        ...state,
        list: [...state.list, ...payload.list],
        activeItem
      };
    case EMPTY_USERS:
      return {
        ...state,
        list: []
      };
    case SET_ACTIVE_USER_ITEM:
      return {
        ...state,
        activeItem: payload
      };
    default:
      return state;
  }
}