import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { showDirections as showDirectionsAction } from 'actions/ui';

import Popup from './Popup.component';

const PopupContainer = ({props, point, setHdPhotoVisibility, hdPhotoVisibility}) => {


  const onMouseUp = () => {
    document.body.classList.remove('selected')
  };

  useEffect(() => {
    document.body.addEventListener('mouseup', onMouseUp);
    return () => window.removeEventListener("resize", onMouseUp);
  }, []);


  const state = {
    props,
    point,
    hdPhotoVisibility
  };

  const containerFunctions = {
    setHdPhotoVisibility
  };

  return (
    <Popup
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

// export default connect(mapStateToProps, {})(PopupContainer);
export default PopupContainer;