import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Weather from './Weather.component';

const WeatherContainer = (props) => {
  const { activeItem, fields } = props
  const [field, setField] = useState(null)


  useEffect(() => {

    let acField = fields.find(el => el?.id === activeItem )
    setField(acField)

    return () => {
      setField(null)
    }
  }, [activeItem])


  const state = {
    field
  };

  const containerFunctions = {

  };

  return (
    <Weather
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  activeItem: state.fields.activeItem,
  fields: state.fields.list
});

export default connect(mapStateToProps, {})(WeatherContainer);