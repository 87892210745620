import { __i18n } from 'i18n';

const transformDbData = (point, camera) => {

  console.log(point, 'point')
  if(!point || !point.bboxes) return [];

  let { bboxes } = point

  return Object.entries(bboxes).map(el => {
    let key = el[0];
    let val = el[1];
    let { thr_coords = {} } = val;
    let { x, y, w, h } = thr_coords

    // ### FARMAIR: scale image if camera =1
    let x1 = x, y1 = y, w1 = w, h1 = h;
    if(camera == 1){
      // x1 = x*1.5;
      // y1 = y*1.5;
      // w1 = w*1.5;
      // h1 = h*1.5;
    }

    return {
      id: key,
      mark: {
        x: x1,
        y: y1,
        width: w1,
        height:h1,
        type: "RECT"
      },
      comment: __i18n(val.comment)
    }
  })
}

export {
  transformDbData
}