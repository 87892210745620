import React from 'react';
import { Button, Collapse } from 'react-bootstrap'
import { CgOrganisation } from 'react-icons/cg'
import { RiArrowDropDownFill } from 'react-icons/ri'
import IconField from 'component/reusable/icon/Field'
import './Li.style.scss'
import { formatLocation, formatArea, formatPrice as fp } from 'utils/format'
import moment from 'moment'
import CartRow from 'component/Cart/List/cartRow/CartRow'
import { formatStatus } from 'component/Shoot/utils/formatStatus'
import { withTranslation } from 'react-i18next'

const renderAppliedCoupon = (order, t) => {
  const { coupon } = order;
  if(!coupon) return null;

  const { code, discountPercent } = coupon

  return (
    <div className="applied-coupon-wrap mb-3">
      <p><strong>{'Applied Coupon'}</strong>: {code} (-{discountPercent}%)</p>
    </div>
  )
}


const renderGroupItems = (order) => {
  return (
      <div className={`c-list-group-item`}>

        <CartRow order={order} />
      </div>
  ) 
}

const Li = ({ key, order, open, setOpenDom, t }) => {
  return (
    <div className={`c-list-group lg-${key}`}>
      <div className="order-row-wrap">
        <Button
          onClick={setOpenDom(!open)}
          aria-controls={`collapse-field-${order.createdAt}`}
          aria-expanded={open}
        >
          <div className="order-row">
            <div className="inner">
              <div className="date">
                <p>{moment(order.createdAt).format("MMM D, YYYY")}</p>
              </div>
              <div className="price">
                <p>{fp(order.finalPrice)}</p>
              </div>
              <div className={`status ${order.status}`}>
                <p>{formatStatus(order, t)}</p>
              </div>
            </div>
          </div>
          <RiArrowDropDownFill size={32} />
        </Button>
      </div>
      <Collapse in={open}>
        <div id={`collapse-field-${order.uuid}`}>
          {renderGroupItems(order)}
          {renderAppliedCoupon(order, t)}
        </div>
      </Collapse>
    </div>
  );
}

export default withTranslation()(Li);