import React from 'react'
import { formatArea } from 'utils/format'
import moment from 'moment'
import { renderTable } from 'component/Field/utils/renderRow'
import { validAge, validMeter, validInteger } from 'utils/validate'
import { withTranslation } from 'react-i18next'
import { getI18nVariety } from 'component/Field/NewField/Form/Variety/data'

const RenderFieldDetails = (props) => {

  const { field, t } = props
  return (
    <>
      {
        renderTable({
          title: t('Field Details'),
          rows: [
            [t('Size'), formatArea(field.area)],
            [t('Variety'), getI18nVariety(field.variety)],
            [t('Age'), validAge(field.age), `(${t('years')})`],
            [t('Height'), validMeter({ m: field.avgPlantHeight }), '(m)'],
            [t('Altitude'), validInteger({ val: field.altitude }), '(m)'],
            [t('Slope'), validInteger({ val: field.slope }), '(%)'],
            [t('Vineyard Type'), t(field?.usedFor)],
            [t('Created At'), moment(field.createdAt).format("MMM D, YYYY")],
          ],
          className: 'col-6'
        })
      }
    </>
  )
}

export default withTranslation()(RenderFieldDetails)