import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getCouponByCode } from 'actions/coupon'
import { setAlert } from 'actions/alert'

import Item from './Item.component';

const computeTotalCartHectares = (props) => {
  const { cartItems } = props.props;

  let itemsUuidArea = cartItems.map(el => {return ({uuid: el.uuid, area: el.area})})

  // unique cart Items
  let filteredList = [...new Set(itemsUuidArea.map(JSON.stringify))].map(JSON.parse);

  // the sum of each field hectares
  return parseInt(filteredList.reduce((a, b) => ({area: a.area + b.area})).area)/10000;

}

const ItemContainer = (props) => {
  const { getCouponByCode, setAlert } = props
  const { setAppliedCoupon } = props.props;
  const [showSubmitBtn, setShowSubmitBtn] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [loading, setLoading] = useState(null);

  const setCouponCodeFn = e => setCouponCode(e.target.value)

  useEffect(() => {
    setShowSubmitBtn((couponCode && couponCode.length))
  }, [couponCode])


  const onSubmit = async (e) => {
    e.preventDefault();
    setAppliedCoupon({})
    setLoading(true);
    let data = await getCouponByCode(couponCode)
    if(data){
      const { details } = data;

      if(details){
        const { minTotalHectares } = details

        if(minTotalHectares){
          if(computeTotalCartHectares(props) > parseInt(minTotalHectares)){
            setAppliedCoupon(data);
          }else {
            setAlert(`Total number of hectares in cart below the limit of coupon`, 'danger');
          }
        }else {
          setAppliedCoupon(data);
        }

      }else {
        setAppliedCoupon(data);
      }
    }
    setLoading(false)
  }



  const state = {
    showSubmitBtn,
    couponCode,
    loading,
    props: props.props
  };

  const containerFunctions = {
    setCouponCodeFn,
    onSubmit
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getCouponByCode,
  setAlert
})(ItemContainer);