import React from 'react';
import IconBack from 'component/reusable/icon/Back'
import './Item.style.scss'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Item = (props) => {

  let { onBack, t } = props

  return (
    <div className={`company-item grid-3 about-page`}>
      <div onClick={onBack}><IconBack layout="block" /></div>
      <div className="inner">
        <header>
          <h1>{t('About')}</h1>
          <ul className="footer-links mb-5">
            <li ><Link to="/payment-methods">{t('Payment Methods')}</Link></li>
            <li><Link to="/privacy-policy">{t('Privacy Policy')}</Link></li>
            <li><Link to="/terms-of-service">{t('Terms of Service')}</Link></li>
          </ul>
          <p>Copyright  &copy; 2020 -2021<br/> farmAIr, Inc. - All Rights Reserved</p>
        </header>
      </div>
    </div>
  )
}

export default withTranslation()(Item);