import React, { useState, useEffect } from "react";
import "./Chat.style.scss";

const ChatComponent = ({ onSendSocketMessage }) => {
  const [message, setMessage] = useState(null);

  const sendMessage = (msg) => {
    if (msg) {
      onSendSocketMessage(msg);
      setMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log("enter key");
      sendMessage(message);
    }
  };

  return (
    <section className="agro-settings chat-section">
      <header>
        <h4>Actions</h4>
        <div className="form-group">
          <input
            type="text"
            id="devmessage"
            value={message}
            placeholder="message"
            className="form-input"
            onKeyPress={handleKeyPress}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <button className="btn btn-light" onClick={() => sendMessage(message)}>
          Send Message
        </button>
      </header>
      <main>
        <section>
          <h2>Chat</h2>
          <ul id="chat-logs-section"></ul>
        </section>
      </main>
    </section>
  );
  // }
};

export default ChatComponent;
