import api from 'utils/api';
import {
  UPDATE_SHOOT_DRONE_ADMIN
} from 'actions/types';


const updateShoot = (shootUuid, payload) => async dispatch => {
  console.log('[Action] Updating Shoot')

  try {
    const res = await api.put(`/shoot/${shootUuid}`, payload);
    return true;

  } catch (err) {
    alert('Something went wrong')
    return false;
  }
};

const updateShootDates = (payload) => async dispatch => {
  console.log('[Action] updateShootDates')

  try {
    const res = await api.put(`/shoot/updateDates`, payload);
    return true;

  } catch (err) {
    alert('Something went wrong')
    return false;
  }
};

const updateShootDroneAdmin = (reduxActiveItem, droneAdmin) => async dispatch => {
  dispatch({
    type: UPDATE_SHOOT_DRONE_ADMIN,
    payload: {
      activeItem: reduxActiveItem,
      droneAdmin
    }
  });
}

export {
  updateShoot,
  updateShootDates,
  updateShootDroneAdmin
}