import React from "react";
import { withTranslation } from "react-i18next";
import { getAreaValue } from "utils/format";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Moment from "moment";
import { extendMoment } from "moment-range";

import "./Charts.style.scss";

const moment = extendMoment(Moment);

const getChartData = (fields, shoots, users, dateStart, dateEnd) => {
  const dates = fields.map((el) => el.createdAt);
  const datesShoots = shoots.map((el) => el.createdAt);
  const datesUsers = (users || []).map((el) => el.createdAt);

  const sortedDates = dates.sort((a, b) => {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  });
  const dMax = moment.max(dates.map((el) => moment(el)));
  const dMin = moment.min(dates.map((el) => moment(el)));

  const range = moment.range(dateStart, dateEnd);

  const wksRange = Array.from(range.by("week"));
  let wks = wksRange.map((m) => m.format("MMM D"));
  let wksMoments = wksRange.map((m) => m);

  let cumulativeFields = wks.map(() => 0);
  let cumulativeShoots = wks.map(() => 0);
  let cumulativeUsers = wks.map(() => 0);

  wks.forEach((y, i) => {
    cumulativeFields[i] = dates.filter(
      (date) => moment(date) < wksMoments[i]
    ).length;
    cumulativeShoots[i] = datesShoots.filter(
      (date) => moment(date) < wksMoments[i]
    ).length;
    cumulativeUsers[i] = datesUsers.filter(
      (date) => moment(date) < wksMoments[i]
    ).length;
  });

  return wks.map((el, i) => {
    return {
      name: el,
      fields: cumulativeFields[i],
      shoots: cumulativeShoots[i],
      users: cumulativeUsers[i],
    };
  });
};

const Item = ({
  fields,
  shoots,
  users,
  dateStart,
  dateEnd,
  setDateStart,
  setDateEnd,
}) => {
  const data = getChartData(fields, shoots, users, dateStart, dateEnd);
  console.log(dateStart);
  const totalArea = fields
    .map((el) => {
      return el.area;
    })
    .reduce(
      (previousValue, currentValue) =>
        parseFloat(previousValue) + parseFloat(currentValue),
      0
    );

  return (
    <div>
      <header>
        <h4>Growth</h4>
        <div className="chart-subtitle">
          <p>
            Users: <strong>{users.length}</strong>
          </p>
          <p>
            Fields: <strong>{fields.length}</strong>
          </p>
          <p>
            Total Area: <strong>{getAreaValue(totalArea)} ha</strong>
          </p>
        </div>

        <section className="dates-switch">
          <input
            type="date"
            value={dateStart}
            name="date-start"
            onChange={({ target: { value: dateVal } }) => setDateStart(dateVal)}
          />
          <input
            type="date"
            name="date-end"
            value={dateEnd}
            onChange={({ target: { value: dateVal } }) => setDateEnd(dateVal)}
          />
        </section>
      </header>

      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart
          width={500}
          // height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="shoots"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="users"
            stroke="#333"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="fields" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default withTranslation()(Item);
