import api from '../utils/api';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  LOGOUT,
  AUTH_ERROR,
} from './types';
import { Ability } from '@casl/ability'
import i18n from "i18n";
import moment from 'moment';
import "moment/locale/el";

// Load User
const loadUser = () => async dispatch => {
  try {
    const res = await api.get('/user');
    console.log('[Action Auth] Load User', res.data);

    let permissions;
    if(res.data.permissions){
      permissions = new Ability(res.data.permissions)
    } else {
      permissions = new Ability([])
    }


    //TODO: make dynamic for more languages
    if(['el'].includes(res.data.user.lang)){
      i18n.changeLanguage(res.data.user.lang);
      moment.locale('el')
      document.documentElement.setAttribute('lang', 'el');
    }else {
      moment.locale('en')
      document.documentElement.setAttribute('lang', 'en');
    }

    dispatch({
      type: USER_LOADED,
      payload: {
        user: res.data.user,
        permissions
      }
    });

  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Logout User
const logoutUser = () => async dispatch => {
  try {
    console.log('[Action Auth] Logout User');
    await api.get('/user/logout');

    dispatch({
      type: LOGOUT
    });

  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Login User
const loginUser = ({email, password}) => async dispatch => {
  const body = { email, password };

  try {
    const res = await api.post('/user/login', body);

    window.location.replace('/');

    // dispatch({
    //   type: LOGIN_SUCCESS,
    //   payload: res.data
    // });

    // dispatch(loadUser());
  } catch (err) {

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

const sendPasswordResetRequest = (email) => async dispatch => {
  console.log('[Action Auth] Send Password Reset Request');
  try {
    await api.post('/user/password_reset', { email })
    return true;
  } catch (err) {
    return false;
  }
};

const submitNewPassword = ({ password_reset_token, password }) => async dispatch => {
  try {
    await api.post(`/user/password_reset/${password_reset_token}`, { password })
    return true;
  } catch (err) {
    return false;
  }
};


//Verify Email
const verifyEmailRequest = (verify_email_token) => async dispatch => {
  console.log('[Action Auth] Send Verify Email Request');
  try {
    await api.post('/user/verify_email', { token: verify_email_token })
    return true;
  } catch (err) {
    return false;
  }
};

export {
  loadUser,
  logoutUser,
  loginUser,
  sendPasswordResetRequest,
  submitNewPassword,
  verifyEmailRequest
}