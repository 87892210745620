import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { loadUser } from 'actions/auth';
import store from 'store';
import Router from 'component/Router';
import Loader from 'component/reusable/Loader'
import './App.style.scss'

const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      //TODO: check logic for anonymous routes
      let path = window.location.pathname;
      if(!path.includes('password_reset') &&
          !path.includes('verify_email')){
        await store.dispatch(loadUser());
      }
      setLoading(false);
    })()
  }, []);


  return (
    <Provider store={store}>
      {loading ? <Loader /> : <Router />}
    </Provider>
  );
};

export default App;