import api from 'utils/api';
import {
  LOAD_FIELDS,
  EMPTY_FIELDS,
  SET_ACTIVE_FIELD_ITEM
} from 'actions/types';

// opts = {activeFieldId, keepActiveΙtem: true } || {}
// { activeFieldId, keepActiveΙtem } = {}
const fetchFields = (payload) => async dispatch => {
  console.log('Fetching! action', payload)
  if(!payload){
    payload = {}
  }
  let { activeFieldId = null, keepActiveItem = null } = payload

  console.log(activeFieldId, keepActiveItem)
  try {
    dispatch({
      type: EMPTY_FIELDS,
      payload: {activeFieldId, keepActiveItem }
    });
    console.log('[Action] Fetch Fields')
    const res = await api.get('/fields');
    dispatch({
      type: LOAD_FIELDS,
      payload: {
        list: res.data.data,
        shootData: res.data.shootData,
        activeItemId: activeFieldId,
        keepActiveItem
        // ...opts
      }
    });
  } catch (err) {

  }
};

const setActiveItem = (val) => async dispatch => {
  try {
    dispatch({
      type: SET_ACTIVE_FIELD_ITEM,
      payload: val
    });
  } catch (err) {
    alert(err)
  }
}

export {
  fetchFields,
  setActiveItem,
}