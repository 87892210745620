import React from 'react';
import { Button } from 'react-bootstrap'
import { CgOrganisation } from 'react-icons/cg';
import './List.style.scss'
import Loader from 'component/reusable/Loader'
import { renderAvatar } from 'component/reusable/User'
import { withTranslation } from 'react-i18next'

const renderCompanies = ({ companies, onCompany, activeItem, t }) => {
  console.log(companies, 'the c')
  return companies.map((el, i) => {
    let usersText = el.Users.length > 1 ? 'users' : 'user'

    return <div onClick={onCompany(i)} className={"list-item " + (activeItem === i ? 'active' : '')}>
      {renderAvatar(el, {size: 'xs', icon: 'company'})}
      <div className="details">
        <h4 className="mb-0">{el.title}</h4>
        <p className="mb-0">{el.activity}</p>
        <p className="m-0">{el.Users.length} {t(usersText)}</p>
      </div>
    </div>
  })
}


const List = (props) => {
  const { loading, companies, onCompany, onNewCompany, activeItem, t } = props

  return (
    <section className="company-list grid-2">
      <header>
        <h2>{t('Companies')}</h2>
      </header>
      <div className="main">
        { loading ? <Loader /> : renderCompanies({ companies, onCompany, activeItem, t })}
        { !loading && !companies.length ? <div className="grid-empty">{t('No companies yet')}</div> : '' }
      </div>
     <div className="bottom">
      <Button onClick={onNewCompany}>{t('Add Company')}</Button>
     </div>
    </section>
  )
}

export default withTranslation()(List);