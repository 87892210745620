import api from "utils/api";
import { SET_MAPBOX_TILE } from "actions/types";

const setMapboxTile = (tile) => async (dispatch) => {
  try {
    dispatch({
      type: SET_MAPBOX_TILE,
      payload: tile,
    });
    return true;
  } catch (err) {}
};

export { setMapboxTile };
