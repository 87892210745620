import React from "react";
import { withTranslation } from "react-i18next";
import { convertKelvinToCelcius } from "utils/convert";
import "./AgroSoilData.style.scss";
import moment from "moment";

const AgroSoilData = (props) => {
  const { soilData = {}, t } = props;
  const { dt, t10, moisture, t0 } = soilData;
  var dateString = moment.unix(dt).format("MMM D, YY hh:mm a");
  // var dateString = moment.unix(dt).format("MM/DD/YYYY hh:mm a");

  if (!Object.keys(soilData).length) {
    return null;
  }

  return (
    <section className="agro-soil-data">
      <ul>
        <li>{dateString}</li>
        <li>
          {t("Soil Moist")}: {moisture} m<sup>3</sup>/m<sup>3</sup>
        </li>
        <li>Temp -10cm: {convertKelvinToCelcius(t10)} C</li>
      </ul>
    </section>
  );
};

export default withTranslation()(AgroSoilData);
