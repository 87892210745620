import React, { useState } from "react";
import moment from "moment";
import "../Item.style.scss";
import { formatStatus } from "component/Shoot/utils/formatStatus";
import { renderTable } from "component/Field/utils/renderRow";
import { renderReportCta } from "component/Field/Item/component/utils/reports";
import { withTranslation } from "react-i18next";
import { renderReportName } from "./Reports/Reports.utils";
import { Dropdown } from "react-bootstrap";
const renderDates = (shoot, t) => {
  if (!shoot) return;
  if (!shoot.shootDays) return;

  let { start, end } = shoot.shootDays.confirmed;
  let { start: altStart, end: altEnd } = shoot.shootDays.alternative;
  let rows = [];

  if (start !== end) {
    rows.push(
      [t("Drone Coverage From"), moment(start).format("MMM D, YYYY")],
      [t("Drone Coverage To"), moment(end).format("MMM D, YYYY")]
    );
  } else {
    rows.push([t("Drone Coverage Date"), moment(start).format("MMM D, YYYY")]);
  }

  if (altStart !== altEnd) {
    rows.push(
      [t("Alternative Coverage From"), moment(altStart).format("MMM D, YYYY")],
      [t("Alternative Coverage To"), moment(altEnd).format("MMM D, YYYY")]
    );
  } else {
    rows.push([
      t("Alternative Coverage Date"),
      moment(altStart).format("MMM D, YYYY"),
    ]);
  }
  return renderTable({
    title: t("Drone Coverage Dates"),
    rows,
  });
};

const RenderSatelliteStats = ({ props }) => {
  const { field, selectedReport, shootData, user } = props;
  const { Reports } = field || [];
  const theReport = Reports.find((el) => el.uuid === selectedReport);
  const { satelliteStats } = theReport || {};
  const { dswi, evi, evi2, ndvi, ndwi, nri } = satelliteStats || {};

  const [activeStat, setActiveStat] = useState("dswi");
  console.log(
    field,
    selectedReport,
    "SATELLITE STATS",
    Reports,
    theReport,
    dswi,
    evi,
    evi2,
    ndvi,
    ndwi,
    nri
  );

  const onSelectStat = (val) => {
    console.log(val, "ON SELECT STAT");
    setActiveStat(val);
  };
  let rows = [];
  Object.keys(satelliteStats[activeStat]).forEach((key) => {
    rows.push([key, <span>{satelliteStats[activeStat][key]}</span>]);
  });

  if (!satelliteStats) return;

  return (
    <div>
      {/* <h3 className="my-3">Satellite Stats</h3> */}

      <Dropdown className="mb-1">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {activeStat}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onSelectStat("dswi")}>
            DSWI
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onSelectStat("evi")}>EVI</Dropdown.Item>
          <Dropdown.Item onClick={() => onSelectStat("evi2")}>
            EVI2
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onSelectStat("ndvi")}>
            NDVI
          </Dropdown.Item>
        </Dropdown.Menu>

        {renderTable({
          // title: activeStat,
          rows: rows,
          className: "col-6",
        })}
      </Dropdown>
    </div>
  );
};

const renderReport = ({ props, t }) => {
  const { field, selectedReport, shootData, user } = props;

  let report,
    droneOperator,
    reportRows = [],
    droneAdmin;
  let shoot;

  if (selectedReport !== null) {
    report = field.Reports.find((el) => el.uuid === selectedReport);

    if (report.status === "complete") {
      if (user?.role === "admin") {
        reportRows.push([
          t("Upload images"),
          <span className="uploaded-imgs">{report.numOfInputImgs}</span>,
        ]);
      }
      reportRows.push([
        t("Images with Stressed Plant(s)"),
        <span className="stressed-imgs">{report.numOfOutputImgs}</span>,
      ]);
    }

    reportRows.push(
      [t("Status"), formatStatus(report, t)],
      [t("Uuid"), report.uuid],
      [t("Service"), report.service]
    );

    console.log(report, "the report", selectedReport);

    if (
      !["processing_pending", "processing_data", "complete"].includes(
        report.status
      )
    ) {
      reportRows.push(
        [t("Valid from"), moment(report.validFrom).format("MMM D, YYYY")],
        [t("Valid To"), moment(report.validTo).format("MMM D, YYYY")]
      );
    }
    if (report.service === "e2e") {
      shoot = shootData.find((el) => el.reportId === report.id);
      console.log(shoot, "the sss", shootData, report);
      droneOperator = shoot?.droneOperator;
      droneAdmin = shoot?.droneAdmin;

      if (
        !["pending", "pending_payment", "processing"].includes(report.status)
      ) {
        reportRows.push([t("Photo Shoot Status"), formatStatus(shoot, t)]);
      }

      if (shoot?.dateRange) {
        reportRows.push([
          t("Client Availability"),
          `${moment(shoot.dateRange.start).format("MMM D, YYYY")} - 
                                    ${moment(shoot.dateRange.end).format(
                                      "MMM D, YYYY"
                                    )}`,
        ]);
      }
    }
    reportRows.push([
      t("Created At"),
      moment(report.createdAt).format("MMM D, YYYY"),
    ]);
  } else {
    return;
  }
  return (
    <>
      <div className="col-6">
        {renderTable({
          title: `${t("Report")} ${renderReportName(report)}`,
          rows: reportRows,
          className: "col-6",
        })}
      </div>
      {/* <div className="col-6">
        <RenderSatelliteStats props={props} />
      </div> */}

      {/* {report.soilData && (
        <div>
          <p>
            Time of soil data measurement:{" "}
            {moment.unix(report.soilData[0].dt).format("MMM D, YYYY, m:h:s")}
          </p>
          <p>Moisture: {report.soilData[0].moisture}</p>
        </div>
      )} */}
      {selectedReport !== null &&
      report.status === "processing" &&
      !["admin", "financial_admin", "operation_admin"].includes(user.role) ? (
        <section className="selected-report">
          <div className="action-wrap">{renderReportCta(props)}</div>
        </section>
      ) : (
        ""
      )}
      {/* extra report /shoot details */}
      {renderDates(shoot, t)}

      {droneOperator
        ? renderTable({
            title: t("Drone Pilot"),
            rows: [
              [
                t("Full Name"),
                `${droneOperator.firstName} ${droneOperator.lastName}`,
              ],
              [t("Telephone"), droneOperator.mobile],
              ["Email", droneOperator.email],
              [t("Location"), droneOperator.location],
            ],
            className: "col-6",
          })
        : ""}
    </>
  );
};

export default withTranslation()(renderReport);
