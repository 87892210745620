import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setActiveItem as setActiveNavItem } from 'actions/settings'

import Item from './Item.component';

const ItemContainer = (props) => {
  let { setActiveNavItem } = props

  const onBack = () => setActiveNavItem(null)

  const state = {

  };

  const containerFunctions = {
    onBack
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setActiveNavItem
})(ItemContainer);