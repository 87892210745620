import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Item from './Item.component';

const ItemContainer = ({ companies, activeItem }) => {

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);


  useEffect(() => {
    (async () => {
      if(activeItem !== null){
        setCompany(companies[activeItem])
      }
      console.log(companies, 'the companies113')
      setLoading(false);
    })()
  }, [companies, activeItem]);


  const state = {
    company,
    loading
  };

  const containerFunctions = {

  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  companies: state.company.list.list,
  activeItem: state.company.list.activeItem
});

export default connect(mapStateToProps, {})(ItemContainer);