import api from 'utils/api';
import {
  LOAD_NOTIFICATIONS,
  EMPTY_NOTIFICATIONS,
  SET_NUM_OF_UNREAD_NOTIFICATIONS
} from 'actions/types';


const fetchNotifications = () => async dispatch => {
  console.log('Fetching! action')

  try {
    dispatch({
      type: EMPTY_NOTIFICATIONS
    });
    const res = await api.get('/notifications');

    dispatch({
      type: LOAD_NOTIFICATIONS,
      payload: res.data.notifications
    });

    console.log(res, 'the notifications')
  } catch (err) {
    alert(err)
  }
}
const fetchNumOfUnreadNotifications = () => async dispatch => {
  console.log('Fetching! action')

  try {
    const res = await api.get('/notifications?type=unread');

    dispatch({
      type: SET_NUM_OF_UNREAD_NOTIFICATIONS,
      payload: res.data.notifications
    });

  } catch (err) {
    alert(err)
  }
}

export {
  fetchNotifications,
  fetchNumOfUnreadNotifications,
}