import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchOrders, setActiveItem } from 'actions/orders/list'

import List from './List.component';

const ListContainer = (props) => {
  const { fetchOrders, orders, setActiveItem, activeItem } = props
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      if(await fetchOrders()){
        console.log('ok')
      }
      setLoading(false);
    })()
  }, []);

  const onOrder = i => e => {
    setActiveItem(orders[i].uuid)
  }

  const state = {
    orders,
    loading,
    activeItem
  };

  const containerFunctions = {
    onOrder
  };

  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  orders: state.orders.list,
  activeItem: state.orders.activeItem
});

export default connect(mapStateToProps, {
  fetchOrders,
  setActiveItem
})(ListContainer);