import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Users from './Users.component';

const UsersContainer = ({ users }) => {
  const state = {

  };

  const containerFunctions = {

  };

  return (
    <Users
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {

})(UsersContainer);