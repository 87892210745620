import {
  SHOW_NEW_FIELD,
  SHOW_NEW_COMPANY,
  SHOW_NEW_USER,
  SHOW_NEW_REPORT,
  SHOW_NOTIFICATIONS,
  SHOW_SCHEDULE_SHOOT,
  SHOW_UPLOAD_DATA,
  SHOW_FULL_LOADER,
  SHOW_DIRECTIONS,
} from 'actions/types';

const initialState = {
  showNewField: null,
  showNewCompany: null,
  showNewReport: null,
  showNewUser: null,
  showNotifications: null,
  showFullLoader: null,
  showUploadData: {
    value: null,
    field: null,
    selectedReport: null
  },
  showUploadProgress: {
    value: null,
    cancelToken: null
  },
  showScheduleShoot: {
    value: null,
    report: null
  },
  showDirections: {
    value: null,
    lng: null,
    lat: null,
    file: null,
    reportUuid: null
  },

};


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_NEW_FIELD:
      return {
        ...state,
        showNewField: payload.value
      };
    case SHOW_NEW_COMPANY:
      return {
        ...state,
        showNewCompany: payload.value
      };
    case SHOW_NEW_REPORT:
      return {
        ...state,
        showNewReport: payload.value
      };
    case SHOW_UPLOAD_DATA:
      const { value, field, selectedReport } = payload
      return {
        ...state,
        showUploadData: {
          ...state.showUploadData,
          value,
          field,
          selectedReport
        }
      };
    case SHOW_NOTIFICATIONS:
      return {
        ...state,
        showNotifications: payload.value
      };
    case SHOW_NEW_USER:
      return {
        ...state,
        showNewUser: payload.value
      };
    case SHOW_FULL_LOADER:
      return {
        ...state,
        showFullLoader: payload.value
      };
    case SHOW_SCHEDULE_SHOOT:
      return {
        ...state,
        showScheduleShoot: {
          ...state.showScheduleShoot,
          ...payload
        }
      };
    case SHOW_DIRECTIONS:
      return {
        ...state,
        showDirections: {
          ...state.showDirections,
          ...payload
        }
      };
    default:
      return state;
  }
}