import { combineReducers } from 'redux';

import auth from './auth';
import alert from './alert';
import ui from './ui';
import fields from './fields';
import field from './field';
import users from './users';
import settings from './settings';
import reports from './reports';
import shoots from './shoots';
import shoot from './shoot';
import companyList from './company/list';
import notifications from './notifications';
import cart from './cart';
import orders from './orders';

export default combineReducers({
  auth,
  alert,
  ui,
  fields,
  field,
  users,
  settings,
  reports,
  shoots,
  shoot,
  notifications,
  cart,
  orders,
  company: combineReducers({
    list: companyList, // reducer function
  }),
});