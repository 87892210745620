import React from "react";
import MapBox from "component/Mapbox";
import { formatLocation } from "utils/format";
import "./Item.style.scss";
import renderFieldDetails from "component/Field/utils/renderFieldDetails";
import renderFieldOwner from "component/Field/utils/renderFieldOwner";
import EditDetails from "component/Field/Item/component/EditDetails";
import IconBack from "component/reusable/icon/Back";
import ReportTable from "./component/FieldReportTable";
import FieldReportStatus from "./component/FieldReportStatus";
import SatelliteCalendarStats from "./component/SatelliteCalendarStats";
import SatelliteCalendarGraphs from "./component/SatelliteCalendarGraphs";
import { Can } from "component/Router/Can";
import { MdModeEdit } from "react-icons/md";
import Reports from "component/Field/Item/component/Reports";
import { withTranslation } from "react-i18next";

const renderField = (props) => {
  const {
    field,
    user,
    onNewReport,
    selectedReport,
    onEditFieldDetails,
    downloadKml,
    t,
  } = props;

  console.log("RENDER FIELD", props);

  let activeReport;
  if (field.Reports.length && selectedReport) {
    activeReport = field.Reports.find((el) => el.uuid === selectedReport);
  }

  console.log(activeReport, "ACTIVE!");

  return (
    <>
      {field && <MapBox field={field} selectedReport={selectedReport} />}
      <main className="">
        <header className="mb-2 mb-lg-3">
          <h5>{t("Field Name")}</h5>
          <h2>{field.title}</h2>
          <p>
            {t("Location")}:{" "}
            {field?.location?.country && formatLocation(field.location)}
          </p>
        </header>

        <section className="reports d-flex justify-content-between">
          <Reports props={props} />

          <Can do="update" a={"Field"}>
            <button
              className="btn c-btn-lg btn-light ml-auto mr-3 edit-info"
              onClick={onEditFieldDetails}
            >
              <MdModeEdit size={20} />
              <span className="ml-3">{t("Edit Info")}</span>
            </button>
          </Can>

          <Can do="create" a={"Report"}>
            <button className="btn c-btn-lg btn-primary" onClick={onNewReport}>
              {t("Choose Service")}
            </button>
          </Can>
        </section>
        {["admin"].includes(user.role) && <div className="spacer"></div>}
        <SatelliteCalendarStats props={props} />
        <SatelliteCalendarGraphs props={props} />
        <div className="row reports-field-details">
          {selectedReport !== null ? (
            <>
              {["b2b_admin", "b2c_admin"].includes(user.role) ? (
                <div className="col-md-12">
                  <FieldReportStatus props={props} />
                </div>
              ) : (
                ""
              )}
              {/* <div className="col-md-12"> */}
              <ReportTable props={props} />
              {/* </div> */}
            </>
          ) : (
            ""
          )}

          <div className="col-md-6">{renderFieldDetails(props)}</div>
          {["admin"].includes(user.role) ? (
            <div className="col-md-6">{renderFieldOwner(field?.Users[0])}</div>
          ) : (
            ""
          )}

          {selectedReport && activeReport.service === "manual" ? (
            <div className="col-md-6 mt-4">
              <form onSubmit={downloadKml}>
                <button type="submit" className="btn btn-light">
                  {t("Download Kml")}
                </button>
              </form>
            </div>
          ) : (
            ""
          )}
        </div>
      </main>
    </>
  );
};

const Field = (props) => {
  const { field, loading, showEditDetails, activeItem, onBack } = props;

  let isActiveClass = activeItem !== null ? "is-active" : "";

  return (
    <>
      <section
        className={`company-item field-item grid-3 mt-0 grid-w-100 ${isActiveClass}`}
      >
        <div className="icon-back-sticky">
          <IconBack layout="fixed" onBack={onBack} />
        </div>
        <div className="inner">{!loading && field && renderField(props)}</div>
        {showEditDetails ? (
          <EditDetails showEditDetails={showEditDetails} props={props} />
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default withTranslation()(Field);
