import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import Mapbox from './Mapbox.component';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapboxContainer = ({ field2, activeItem, shoots }) => {
  const mapRef = React.useRef()
  const [stateMap, setStateMap] = useState(null)

  useEffect(() => {
    if(activeItem === null){
      return
    }

    let shoot = shoots.find(el => el.uuid === activeItem)
    if(!shoot) return
    let field = shoot.field

    if(stateMap){
      stateMap.flyTo({
        center: [field.mapDisplay.lng, field.mapDisplay.lat],
        zoom: field.mapDisplay.zoom,
        speed: 1, // make the flying slow
        curve: 1, // change the speed at which it zooms out,
        animate: false
      });

      let data = {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': field.polygonPoints
        }
      }

      if(stateMap && stateMap.getSource && stateMap.getSource('maine') && data){
        stateMap.getSource('maine').setData(data);
      }
    }

    if(!stateMap){
      mapRef.current.innerHTML = ''
      let map = new mapboxgl.Map({
        container: mapRef.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v10',
        // style: 'mapbox://styles/mapbox/satellite-v8',
        center: [field.mapDisplay.lng, field.mapDisplay.lat],
        zoom: field.mapDisplay.zoom
      })

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl());

      // Add geolocate control to the map.
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true,
          className: 'geolocate'
        })
      );


      map.on('load', function (e) {
        map.resize();

        map.addSource('maine', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              'coordinates': field.polygonPoints
            }
          }
        });
        map.addLayer({
          'id': 'maine',
          'type': 'fill',
          'source': 'maine',
          'layout': {},
          'paint': {
            'fill-color': '#088',
            'fill-opacity': 0.4
          }
        });
      });
      setStateMap(map)
    }

  }, [shoots, activeItem])

  useEffect(() => {
    setStateMap(null)
  }, [])

  const state = {
    mapRef,
  };

  const containerFunctions = {
  };

  return (
    <Mapbox
      {...state}
      {...containerFunctions}
    />
  )
}

const mapStateToProps = (state) => ({
  activeItem: state.shoots.activeItem,
  shoots: state.shoots.list
});

export default connect(mapStateToProps, {})(MapboxContainer);