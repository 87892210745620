import React from 'react';
import { Modal, Button, ToggleButtonGroup, Form } from 'react-bootstrap'
import { VscChromeClose } from 'react-icons/vsc'
import './EditDetails.style.scss'
import { renderCol } from 'component/reusable/form/renderCol'
import { withTranslation } from 'react-i18next'
import Variety from 'component/Field/NewField/Form/Variety'

const DeleteForm = (props) => {
  const { confirmField, onFieldDelete, onConfirmChange, field, t } = props.props

  console.log(props, 'the props delete')
  return (
    <form onSubmit={onFieldDelete} className="delete-field">
      <h3>{t('Delete Field')}</h3>
      <p>{t('Once you delete a field, there is no going back. Please be certain.')}</p>
      <div className={`form-group`}>
        <label htmlFor="confirm-field">{t('Confirm Field Name')}</label>
        <input id="confirm-field" name="confirm-field" type="text"
          onChange={onConfirmChange}
          value={confirmField}
          className="form-control"
          required={true} />
      </div>
      <button type="submit" className="btn btn-danger c-btn-lg w-100"
        disabled={(field?.title != confirmField)}
      >{t('Delete Field')}</button>
  </form>
  )
}


const EditForm = (props) => {
  const { formData, onChange, onSubmitForm, t, handleFieldTypeChange, fieldType } = props
  let colProps = {
    onChange, 
    formData,
    wrapClassName: 'col-12'
  }

  return (
    <>
      <Modal.Body className="">
        <form onSubmit={onSubmitForm} className="edit-field row">
            {renderCol({inputName: 'title', inputLabel: t('Field Name'), pattern: ".{3,50}", 
            title:"Must be between 3 and 50 characters", ...colProps})}

            <div className="col-md-12">
              <Variety props={props} />
            </div>


            {renderCol({inputName: 'age', inputLabel: t('Vineyard Age (years)'),
              min: 0, max: 2000, step: 1, type: 'number', ...colProps })}

            {renderCol({inputName: 'avgPlantHeight', inputLabel: t('Average Plant Height (m)'),
              min: 0, max: 500, step: 0.1, type: 'number',...colProps })}
            {renderCol({inputName: 'altitude', inputLabel: t('Mean Sea Level (m)'),
              min: 0, max: 2000,  type: 'number', ...colProps})}
            {renderCol({inputName: 'slope', inputLabel: t('Slope (%)'),
              min: 0, max: 100, type: 'number',...colProps })}

            {/* TODO REFACTOR: used the same in create field */}
            <div className="col-md-12">
              <ToggleButtonGroup name="userType"
                type="radio"
                value={fieldType}
                onChange={handleFieldTypeChange}
                className=""
              >
                <Form.Label as="legend">{t('Vineyard Type')}</Form.Label>

                <Form.Check
                  type="radio"
                  label={`${t('Vinification')}`}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  value={1}
                />
                <Form.Check
                  type="radio"
                  label={`${t('Table Grape')}`}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios2"
                  value={2}
                />
                <Form.Check
                  type="radio"
                  label={`${t('Raisin Grape')}`}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios3"
                  value={3}
                />
              </ToggleButtonGroup>
            </div>



          <button type="submit" className="btn btn-primary c-btn-lg w-100">{t('Update Field')}</button>
        </form>
        <DeleteForm  props={props} />
      </Modal.Body>
    </>
  );
};



const EditDetails = (props) => {
  let { modalVisibility, handleClose, t } = props

  return (
    <div className="new-company new-report">
      <Modal
        show={modalVisibility}
        onHide={handleClose}
        dialogClassName="modal-edit-field aside-modal-action"
        backdropClassName="modal-backdrop-edit-field aside-modal-backdrop-action"
      >
        <header className="d-flex align-items-center justify-content-between">
          <Modal.Title>{t('Edit Field')}</Modal.Title>
          <Button variant="reset" onClick={handleClose}><VscChromeClose size={28} /></Button>
        </header>

        <main className="main">
          {EditForm(props)}
        </main>
      </Modal>
    </div>
  )
}

export default withTranslation()(EditDetails);