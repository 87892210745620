import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateReportBbox } from "actions/reports";
import i18n, { __i18n } from "i18n";
import HdPhoto from "./HdPhoto.component";

const HdPhotoContainer = (props) => {
  const {
    showDirections,
    fields,
    activeItem,
    updateReportBbox,
    layout = "",
    report,
    acFile: theAcFile,
  } = props;

  console.log(props, "THE HD PHOTO PROPS");
  const [annotationData, setAnnotationData] = useState([]);
  const [bboxes, setBboxes] = useState({});
  const [selectedBbox, setSelectedBbox] = useState(null);
  const [commentInfoVisibility, setCommentInfoVisibility] = useState(false);

  console.log(theAcFile, "THE AC FILE");

  useEffect(() => {
    let acReport;
    let acFile;

    if (layout === "pin-hd-photo") {
      acReport = report;
      acFile = acReport?.outputData.find((el) => el.file == theAcFile);
      console.log(report, layout, "LAYOUT");
    } else {
      let acField = fields.find((el) => el.id == activeItem);
      acReport = acField.Reports.find(
        (el) => el.uuid == showDirections.reportUuid
      );
      acFile = acReport?.outputData.find(
        (el) => el.file == showDirections.file
      );
    }

    console.log(acReport, acFile, bboxes, "all");
    if (acFile?.bboxes) {
      let acBbox = acFile.bboxes[showDirections.bboxId];

      if (layout === "pin-hd-photo") {
        setBboxes(acFile?.bboxes);
      } else {
        setBboxes({ [showDirections.bboxId]: acBbox });
        setCommentInfoVisibility(!acBbox?.comment);
      }
      // setBboxes({[showDirections.bboxId]: acBbox});
    }
  }, []);

  useEffect(() => {
    let acField = fields.find((el) => el.id == activeItem);
    let acReport;
    if (layout === "pin-hd-photo") {
      acReport = report;
    } else {
      acReport =
        acField.Reports.find((el) => el.uuid == showDirections.reportUuid) ||
        {};
    }
    let { scale = {} } = acReport || {};
    let { ratio = {} } = scale;

    console.log(bboxes, "1!!!!");
    // TODO: refactor, used in transform db
    let formattedAnnotationData = Object.keys(bboxes).map((key) => {
      const { comment, thr_coords } = bboxes[key];
      const { x, y, w, h } = thr_coords;

      console.log(comment, bboxes[key]);
      let x1 = parseInt(x * ratio.x);
      let y1 = parseInt(y * ratio.y);
      let w1 = parseInt(w * ratio.x);
      let h1 = parseInt(h * ratio.y);

      console.log(x1, y1, w1, h1, x, y, w, h, "COORDINATES");

      return {
        id: key,
        comment: __i18n(comment),
        mark: {
          x: x1,
          y: y1,
          width: w1,
          height: h1,
          type: "RECT",
        },
      };
    });
    setAnnotationData([...formattedAnnotationData]);
  }, [bboxes]);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const onChange = async (data) => {
    console.log(__i18n, "the __i18n", i18n);
    // TODO: get english key to store in DB
    // i18n.store[i18n.language].translation

    if (selectedBbox) {
      let changedAnnotation = data.find((el) => el.id === selectedBbox);
      let bbox = bboxes[selectedBbox];

      if (changedAnnotation.comment !== bbox.comment) {
        let engKey = changedAnnotation.comment.trim();
        if (i18n.language !== "en") {
          let transObj = i18n.store.data[i18n.language].translation;
          engKey = getKeyByValue(transObj, changedAnnotation.comment.trim());
        }

        let payload = {};
        payload.comment = engKey;
        payload.bboxId = selectedBbox;

        if (layout === "pin-hd-photo") {
          payload.reportUuid = report.uuid;
          payload.file = theAcFile;
        } else {
          payload.reportUuid = showDirections.reportUuid;
          payload.file = showDirections.file;
        }

        setCommentInfoVisibility(!changedAnnotation.comment);

        console.log(!changedAnnotation.comment, "comment bool");
        if (await updateReportBbox(payload)) {
          console.log("update success");
        }
      }

      setSelectedBbox(null);
    }
  };

  const onSelect = (selectedId) => {
    if (selectedId) {
      setSelectedBbox(selectedId);
    }
  };

  const state = {
    showDirections,
    annotationData,
    commentInfoVisibility,
    layout,
    fields,
    activeItem,
    report,
    acFile: theAcFile,
  };

  const containerFunctions = {
    onChange,
    onSelect,
    setCommentInfoVisibility,
  };

  return <HdPhoto {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({
  showDirections: state.ui.showDirections,
  fields: state.fields.list,
  activeItem: state.fields.activeItem,
});

export default connect(mapStateToProps, {
  updateReportBbox,
})(HdPhotoContainer);
