import {
  LOAD_SHOOT,
  EMPTY_SHOOT
} from 'actions/types';

const initialState = {
  item: null,
};


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SHOOT:
      return {
        ...state,
        item: payload
      };
    case EMPTY_SHOOT:
      return {
        ...state,
        item: null
      };
    default:
      return state;
  }
}