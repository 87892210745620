import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCompanies, setActiveItem } from 'actions/company/list'
import Item from './Item.component';
import { updateUserAddress, deleteUserAddress } from 'actions/user/address/create'

const ItemContainer = (props) => {
  const { updateUserAddress, deleteUserAddress, addressId } = props
  const { handleCreate, addresses } = props.props
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(null)
  const [activeAddress, setActiveAddress] = useState({})

  let initialFields = {
    city: '',
    country: '',
    state: '',
    zip_postcode: '',
    line_1: '',
    line_2: '',
    line_3: '',
  }

  const [editAddressFields, setEditAddressFields] = useState(initialFields);


  useEffect(() => {
    if(!addresses.length) return

    let activeAddr = addresses.find(el => el.id === addressId)
    setActiveAddress({...activeAddress, ...activeAddr});

    const { city, country, state, zip_postcode, line_1, line_2, line_3 } = activeAddr

    setEditAddressFields({
      ...editAddressFields,
      city,
      country,
      state,
      zip_postcode,
      line_1,
      line_2,
      line_3
    })
  }, [addressId]);

  const submitForm = async (values) => {
    values.prevAddressId = activeAddress.id
    if(await updateUserAddress(values)){
      await handleCreate();
      setShow(false)
    }
  }

  const onDeleteAddress = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')){
      console.log(activeAddress.id, 'the id')
      if(await deleteUserAddress({ prevAddressId: activeAddress.id })){
        await handleCreate();
        setShow(false)
      }
    }
  }

  const setShowFn = val => e => {
    setShow(val)
  }


  const state = {
    loading,
    show,
    editAddressFields
  };

  const containerFunctions = {
    setShowFn,
    submitForm,
    onDeleteAddress,
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  companies: state.company.list.list,
  activeItem: state.company.list.activeItem
});

export default connect(mapStateToProps, {
  fetchCompanies,
  updateUserAddress,
  deleteUserAddress,
})(ItemContainer);