import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCompanies, setActiveItem } from 'actions/company/list'
import { fetchOrders } from 'actions/orders/list'
import Item from './Item.component';
import { setActiveItem as setActiveNavItem } from 'actions/settings'

const ItemContainer = (props) => {
  const { fetchOrders, setActiveNavItem } = props
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);


  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching orders')
      let { data } = await fetchOrders();
      console.log(data, 'the data')
      if(data){
        setOrders([...orders,...data.orders])
        console.log('setting', data.orders)
      }
      console.log('addresses', orders)
      setLoading(false);
    })()
  }, []);


  const onBack = () => setActiveNavItem(null)

  const state = {
    loading,
    orders
  };

  const containerFunctions = {
    onBack
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  companies: state.company.list.list,
  activeItem: state.company.list.activeItem
});

export default connect(mapStateToProps, {
  fetchOrders,
  setActiveNavItem,
})(ItemContainer);