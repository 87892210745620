import React, { useState } from 'react';
import { withTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'
import { VscChromeClose } from 'react-icons/vsc'
import './Calendar.style.scss'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import Shoots from 'route/Shoots/Shoots.component';
import { formatLocation, formatArea } from 'utils/format'
import GoogleMapsButton from 'component/reusable/Button/GoogleMaps'
import { calculateEvents } from './Calendar.utils'

const localizer = momentLocalizer(moment)
const allViews = Object.keys(Views).map(k => Views[k]);



const renderEventInfo = (props, uuid, eventInfoActiveDate) => {
  const { shoots, t } = props

  let fUuid = uuid;
  let category;
  if(uuid?.includes('_')){
    fUuid = uuid.split("_")[0]
    category = uuid.split("_")[1]
  }
  const theShoot = shoots.find(el => el.uuid == fUuid);
  if(! theShoot) return null;
  const { field, owner } = theShoot;
  const { title, location, area } = field
  console.log(theShoot, 'theShoot')

  let invoiceDetails;
  if(owner && owner?.Companies[0]?.invoiceDetails){
    invoiceDetails = {...owner?.Companies[0]?.invoiceDetails}
  }
  return (
    <section>
      <div>
        <h2 className={`bg-light mb-4 p-2 category-${category}`}>{eventInfoActiveDate}</h2>
        <h4 className="mb-2">{title}</h4>
        <p>{t('Location')}: {formatLocation(location)}</p>
        <p>{t('Size')}: {formatArea(area)}</p>
        <GoogleMapsButton field={field} />
      </div>

      {invoiceDetails ? 
        <div className="mt-4">
          <h4 className="mb-2">{t('Company Info')}</h4>
          <p>{invoiceDetails.firmTitle}</p>
          <p>T: {invoiceDetails.telephone}</p>
          <p>E: {invoiceDetails.email}</p>
          <p>{invoiceDetails?.address}, {invoiceDetails?.city}, {invoiceDetails?.state}, {invoiceDetails?.zipCode}</p>
        </div>
      : null}
    </section>
  )
}

const RenderModal = ({props}) => {
  const { shoots } = props

  const [eventInfoVisibility, setEventInfoVisibility] = useState(false)
  const [eventInfoActiveUuid, setEventInfoActiveUuid] = useState(null)
  const [eventInfoActiveDate, setEventInfoActiveDate] = useState(null)

  let events = calculateEvents(shoots)

  const onEvent = (event) => {
    const { id, start, end, category } = event
    console.log(event, 'THE EVENT')
    setEventInfoVisibility(true)
    setEventInfoActiveUuid(id)
    setEventInfoActiveDate(moment(start).format('DD - MM - YYYY'))
  }

  return (
    <main>
      <Calendar
        views={["month", "day", "agenda"]}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={onEvent}
        drilldownView="agenda"
        eventPropGetter={event => ({className: 'category-' + event.category.toLowerCase()})}
        style={{ height: window.innerHeight / 1.2 }}
      />
      <Modal
        show={eventInfoVisibility}
        onHide={() => setEventInfoVisibility(false)}
        dialogClassName="modal-full-page modal-calendar event-info"
        backdropClassName="modal-backdrop-full-page modal-backdrop-calendar event-info"
      >
        {renderEventInfo(props, eventInfoActiveUuid, eventInfoActiveDate)}
      </Modal>
    </main>
  )
}

const ShootsCalendar = (props) => {
  const { t } = props
  const [modalVisibility, setModalVisibility] = useState(false)

  const onCalendar = () => {
    setModalVisibility(true)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0)
  }

  return (
    <div>
      <button className="btn btn-light"
        onClick={onCalendar}>
        Calendar
      </button>
      <Modal
        show={modalVisibility}
        onHide={() => setModalVisibility(false)}
        dialogClassName="modal-full-page modal-calendar"
        backdropClassName="modal-backdrop-full-page modal-backdrop-calendar"
      >
        <header className="d-flex align-items-center justify-content-between">
          <Modal.Title>{t('Calendar')}
            <div className="colors-info">
              <span class="green"></span>
              <span>Complete</span>
              <span class="blue"></span>
              <span>Primary</span>
              <span class="yellow"></span>
              <span>Alternative</span>
            </div>
          </Modal.Title>
          <Button variant="reset" onClick={() => setModalVisibility(false)}>
            <VscChromeClose size={28} />
          </Button>
        </header>
        <RenderModal props={props} />
      </Modal>
    </div>
  )
}

export default withTranslation()(ShootsCalendar);