import React from 'react'
import { MdFiberNew } from 'react-icons/md'
import { IoMdDocument, IoMdRefreshCircle } from 'react-icons/io'
import { BsCameraVideo } from 'react-icons/bs';
import moment from 'moment'
import { withTranslation } from 'react-i18next'

const NotificationHeader = (props) => {
  const { notification, t } = props
  const { type } = notification;

  switch (type) {
    case 'new_field':
      return <><MdFiberNew size={24} /><h4>{t('New field added')}</h4></>
    case 'new_report':
      const service = notification.details.type
      return <><IoMdDocument size={24} /><h4>{t('Report added')} ({service})</h4></>
    case 'order_status_update':
      return (
        <>
          <IoMdRefreshCircle size={24} />
          <h4>{t('Order Status Update')}</h4>
        </>
      )
    case 'new_shoot':
      const { start, end } = notification.details.dateRange
      let startDate = moment(start).format("DD/MM")
      let endDate = moment(end).format("DD/MM/YY")
      let dateString = `(${startDate} - ${endDate})`
      return <><BsCameraVideo size={24} /><h4>{t('Photo Shoot Booking')} {dateString}</h4></>
    case 'shoot_status_update':
      let { newStatus } = notification.details;
      let updatedStatus;
      switch (newStatus){
        case 'shoot_assigned_to_drone_admin':
          updatedStatus = t('Assigned to Drone Operator');
          break;
        case 'shoot_assigned_to_drone_operator':
          updatedStatus = t('Assigned to Drone Pilot');
          break;
        case 'processing_pending':
          updatedStatus = t('Client sets availability for Photo Shoot');
          break;
        case 'confirmed_shoot_dates':
          updatedStatus = t('Confirmed Photo Shoot Dates');
          break;
        default:
          updatedStatus = t('Unknown new status');
      }
      return <><IoMdRefreshCircle size={24} /><h4>{t('Photo Shoot Status changed')}:<br />
        <span className="new-status">{updatedStatus}</span>
      </h4></>
    case 'report_status_update':
      let reportNewStatus = notification?.details?.newStatus;
      let reportUpdatedStatus;
      switch (reportNewStatus){
        case 'complete':
          reportUpdatedStatus = t('AI Processing completed');
          break;
        default:
          reportUpdatedStatus = t('Unknown new status');
      }
      return <><IoMdRefreshCircle size={24} /><h4>{t('Report Status changed')}:<br />
        <span className="new-status">{reportUpdatedStatus}</span>
      </h4></>
    default:
      return t('Unknown Notification type');
  }
}

export default withTranslation()(NotificationHeader)