import {
  LOAD_CART,
  EMPTY_CART,
  NUM_OF_ITEMS_IN_CART
} from 'actions/types';

const initialState = {
  list: [],
  numOfItemsInCart: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CART:
      return {
        ...state,
        list: [...state.list, ...payload],
      };
    case EMPTY_CART:
      return {
        ...state,
        list: []
      };
    case NUM_OF_ITEMS_IN_CART:
      return {
        ...state,
        numOfItemsInCart: payload
      };
    default:
      return state;
  }
}