import React from 'react';
import Loader from 'component/reusable/Loader'
import './Loader.style.scss'


const FullScreenLoader = (props) => {

  return (
    <div className="loader-wrap fade-in">
      <Loader />
    </div>
  )
}

export default FullScreenLoader;