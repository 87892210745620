import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Alert } from 'react-bootstrap'
import { BiReset } from 'react-icons/bi';
import GuestHeader from 'route/SignUp/component/GuestHeader'
import Benefits from 'route/SignUp/component/Benefits'
import Loader from 'component/reusable/Loader'
import { renderCol } from 'component/reusable/form/renderCol'
import { useParams } from "react-router-dom";
import './NewPassword.style.scss';

const PasswordResetForm = (props) => {
  const { passwordsDoNotMatch, requestError, onChange, formData } = props

  return (
    <>
      <p>Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter</p>

      <div className="form-groups">
        {renderCol({inputName: 'password', onChange, formData, useMapping: true, wrapClassName: '', })}
        {renderCol({inputName: 'confirmPassword', onChange, formData, useMapping: true, wrapClassName: '', })}
      </div>
      { passwordsDoNotMatch ?
      <div className="alert-wrap pass-do-no-match">
        <Alert variant="danger" className="fade-in">
          Password and Confirm Password do not match
        </Alert>
      </div>
      : ''}
      { requestError ?
      <div className="alert-wrap pass-do-no-match">
        <Alert variant="danger" className="fade-in">
          Request could not be processed. Make sure you didn't change the provided Reset url or the the url has expired.
        </Alert>
      </div>
      : ''}
      <Button type="submit" className="m-btn m-btn-primary w-100">
        Submit New Password
      </Button>
    </>
  )
}

const NewPasswordChangedSuccess = () => {
  return (
    <>
      <p>Password changed successfully. You can now Sign In with your new Password</p>
      <Link to="/login" className="btn btn-light w-100 mt-3">Return to Sign In page</Link>
    </>
  )
}

const NewPassword = (props) => {
  const { loading, onSubmit } = props
  let { password_reset_token } = useParams();

  return (
    <section className="guest-page">
      <GuestHeader />
      { loading ?
        <div className="loader-wrap fade-in">
          <Loader />
        </div>
        : ''}
      <main className="" aria-label="Sign Up Page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 left">
              <Benefits />
            </div>
            <div className="col-md-6 right">
              <div aria-label="Login Page" className="login-page inner vertical-center">
                <Form onSubmit={onSubmit(password_reset_token)}>
                  <h1>Change Password</h1>
                  {!props.successRequest && PasswordResetForm(props)}
                  {props.successRequest && <NewPasswordChangedSuccess />}
                  <Row className="no-gutters mt-4">
                    <div className="col-md-12">
                      <Link to="/login" className="link-small">Sign In</Link>
                    </div>
                    <div className="col-md-12">
                    <Link to="/signup" className="link-small">Create Account</Link>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  )
}

export default NewPassword;
