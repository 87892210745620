import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import "./SatelliteCalendarStats.style.scss";
import { GrSatellite } from "react-icons/gr";
import { BiCloud } from "react-icons/bi";
import { GrValidate } from "react-icons/gr";
import Loader from "component/reusable/Loader";

const renderCalendar = (props) => {
  const { satelliteData, onClickDate, activeDate, showSelectHint } = props;
  if (!satelliteData) return null;

  return satelliteData.map((el, i) => {
    const { type, dt, dc, cl } = el;
    return (
      <div
        key={dt}
        className={`calendar-item ${activeDate === i ? "active-item" : ""}`}
        onClick={onClickDate(i)}
      >
        <h6>{moment.unix(dt).format("MMM D, YYYY")}</h6>
        {/* <p>{moment.unix(dt).format("HH:mm")}</p> */}
        <p>
          <span>
            <BiCloud />
            {parseInt(cl || 0)}
          </span>
          <span>
            <GrValidate size={14} />
            {parseInt(dc || 0)}
          </span>
        </p>
        <p>
          <GrSatellite size={12} />
          {type}
        </p>
      </div>
    );
  });
};

const SatelliteCalendarStats = (props) => {
  const {
    satelliteData,
    activeStat,
    onSelectStat,
    mapboxTile,
    onUnselect,
    showSelectHint,
  } = props;

  // if (!satelliteData && false) return <Loader />;
  // if (true) return <Loader />;

  return (
    <section className="satellite-calendar">
      <h3>Historical Satellite Data</h3>
      <div
        className={`satellite-calendar-stats ${mapboxTile ? "active" : ""} ${
          activeStat ? "active-stat" : ""
        }`}
      >
        {!satelliteData ? (
          <Loader />
        ) : (
          <>
            <div className="left">
              <div className="left-inner">
                <Dropdown className="mb-1">
                  <Dropdown.Toggle
                    variant={activeStat ? "success" : "light"}
                    id="toggle-satellite-type"
                    className={`dropdown ${
                      showSelectHint ? "active-btn thar-two" : ""
                    }`}
                  >
                    <h5>Layer</h5>
                    {!activeStat && <>Select </>}
                    {(activeStat || "").toUpperCase()}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {activeStat && (
                      <Dropdown.Item onClick={onUnselect}>
                        Unselect
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={onSelectStat("dswi")}>
                      DSWI
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onSelectStat("ndwi")}>
                      NDWI
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onSelectStat("evi2")}>
                      EVI2
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onSelectStat("ndvi")}>
                      NDVI
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={onSelectStat("truecolor")}>
                truecolor
              </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="right">{renderCalendar(props)}</div>
          </>
        )}
      </div>
    </section>
  );
};

export default withTranslation()(SatelliteCalendarStats);
