import i18n from 'i18n';

export const varietiesData = [
  "Agiorgitiko",
  "Assyrtiko",
  "Moschofilero",
  "Xinomavro",
  "Cabernet Sauvignon",
  "Chardonnay",
  "Merlot",
  "Sauvignon Blanc",
  "Syrah",
  "Viognier",
  "Malagousia",
  "Mavrodaphne",
  "Mavrotragano",
  "White Muscat",
  "Muscat of Alexandria",
  "Mouchtaro",
  "Savatiano",
  "Sultana",
  "Aidani",
  "Athiri",
  "Vilana",
  "Vidiano",
  "Kotsifali",
  "Kidonitsa",
  "Limnio",
  "Limniona",
  "Liatiko",
  "Mandelaria",
  "Monemvasia",
  "Debina",
  "Roditis",
  "Robola",
  "Avgoustiatis",
  "Vlahiko",
  "Dafni",
  "Thrapsathiri",
  "Messenikola Blanc",
  "Negoska",
  "Plyto",
  "Sideritis",
  "Fokiano",
  "Abbuoto",
  "Abouriou",
  "Alexandroouli",
  "Akhasheni",
  "Abrusco",
  "Acolon",
  "Ada Karasi",
  "Affenthaler",
  "Aglianico",
  "Aglianicone",
  "Agni",
  "Agronomica",
  "Agua Santa",
  "Aidani Mavro",
  "Aladasturi",
  "Albaranzeuli nero",
  "Albarossa",
  "Aleatico",
  "Alexander",
  "Alfrocheiro",
  "Alicante Henri Bouschet",
  "Allegro",
  "Amaral",
  "Ancellotta",
  "André",
  "Antey Magarachsky",
  "Aramon Noir",
  "Areni",
  "Argaman",
  "Argant",
  "Arinarnoa",
  "Arinto de Bucelas",
  "Arnsburger",
  "Arrouya",
  "Ashughaji",
  "Aspiran Bouschet",
  "Aspiran Noir",
  "Assario",
  "Asuretuli Shavi",
  "Aubun",
  "Avanà",
  "Avarengo",
  "Băbească neagră",
  "Babić",
  "Bachet Noir",
  "Baco Noir",
  "Baga",
  "Barbera",
  "Barbera del Sannio",
  "Barbera Sarda",
  "Barsaglina",
  "Bastardo Magarachsky",
  "Beaunoir",
  "Béclan",
  "Beichun",
  "Bekari",
  "Belat",
  "Béquignol Noir",
  "Biborkadarka",
  "Black Queen",
  "Blatina",
  "Blauburger",
  "Blauer Arbst",
  "Blauer Wildbacher",
  "Blaufränkisch",
  "Bobal",
  "Boğazkere",
  "Bombino Nero",
  "Bonamico",
  "Bonarda Piemontese",
  "Bonda",
  "Bondola",
  "Bondoletta",
  "Bonvedro",
  "Borba",
  "Borracal",
  "Bouchalès",
  "Bourrisquou",
  "Bouteillan Noir",
  "Bovale Grande",
  "Bovale Sardo",
  "Bracciola Nera",
  "Brachetto del Piemonte",
  "Brancellao",
  "Braquet Noir",
  "Brugnola",
  "Brujidera",
  "Brun Argenté",
  "Brun Fourca",
  "Brunal",
  "Brunello",
  "Bubbierasco",
  "Buket",
  "Busuioaca de Bohotin",
  "Caberinta",
  "Cabernet Carbon",
  "Cabernet Carol",
  "Cabernet Colonjes",
  "Cabernet Cortis",
  "Cabernet Cubin",
  "Cabernet Dorio",
  "Cabernet Dorsa",
  "Cabernet Franc",
  "Cabernet Gernischt",
  "Cabernet Jura",
  "Cabernet Mitos",
  "Cabernet Moravia",
  "Cabernet Pfeffer",
  "Cabernet Severny",
  "Cabertin",
  "Caddiu",
  "Cagnulari",
  "Caino Bravo",
  "Caiño Tinto",
  "Calabrese",
  "Calabrese di Montenuovo",
  "Caladoc",
  "Calandro",
  "Calitor Noir",
  "Çalkarası",
  "Callet",
  "Caloria",
  "Camaraou Noir",
  "Camarate",
  "Camarese",
  "Campbell Early",
  "Canaiolo Nero",
  "Canari Noir",
  "Canina Nera",
  "Cannamela",
  "Carcajolo Nero",
  "Caricagiola",
  "Carignan",
  "Carinena",
  "Carménère",
  "Carmine",
  "Carminoir",
  "Carnelian",
  "Carrasquin",
  "Casavecchia",
  "Cascade",
  "Casculho",
  "Casetta",
  "Castagnara",
  "Castelão",
  "Castets",
  "Castiglione",
  "Catalanesca",
  "Catanese Nero",
  "Cavrara",
  "Centesimino",
  "Centurian",
  "Cereza",
  "Cesanese",
  "César",
  "Cevat Kara",
  "Chambourcin",
  "Charbono",
  "Chancellor",
  "Charentsi",
  "Chatus",
  "Chelois",
  "Chenanson",
  "Chichaud",
  "Chidiriotiko",
  "Chisago",
  "Chkhaveri",
  "Chondromavro",
  "Cianorie",
  "Cienna",
  "Ciliegiolo",
  "Cinsaut",
  "Colombana nera",
  "Colorino",
  "Complexa",
  "Cornalin see Rouge du Pays",
  "Cornifesto",
  "Corvina",
  "Counoise",
  "Croatina",
  "Darnekuša",
  "Dobričić",
  "Dolcetto",
  "Domina",
  "Dornfelder",
  "Douce noir",
  "Douce Noire grise",
  "Dunkelfelder",
  "Duras",
  "Dureza",
  "Durif",
  "Ederena",
  "Enfariné noir",
  "Espadeiro",
  "Étraire",
  "Fer",
  "Ferrón",
  "Fetească neagră",
  "Forcallat tinta",
  "Fortana",
  "Frappato",
  "Freisa",
  "Frühroter Veltliner",
  "Fumin",
  "Gaglioppo",
  "Gamashara",
  "Gamay",
  "Gamaret",
  "Garanoir",
  "Garnatxa",
  "Garró",
  "Ġellewża",
  "Girò",
  "Gouget noir",
  "Graciano",
  "Grand noir de la Calmette",
  "Grisa nera",
  "Greco nero",
  "Grignolino",
  "Gropello",
  "Grolleau",
  "Gros Verdot",
  "Gueuche noir",
  "Helfensteiner",
  "Heroldrebe",
  "Hondarribi Beltza",
  "Hron",
  "Humagne Rouge",
  "Joubertin ",
  "Juan García",
  "Jurancon noir",
  "Kadarka",
  "Kakhet",
  "Kalecik Karasi",
  "Kindzmarauli ",
  "Krasnostop Zolotovsky",
  "Kratosija",
  "Lacrima di Morro",
  "Lagrein",
  "Lambrusco",
  "Listan negro",
  "Loureira Tinta",
  "Madrasa",
  "Magarach Bastardo",
  "Magaratch Ruby",
  "Magliocco Canino",
  "Magliocco Dolce",
  "Magyarfrankos",
  "Malbec",
  "Malvasia di Schierano",
  "Mammolo",
  "Mandolari",
  "Manseng noir",
  "Manto negro",
  "Mara",
  "Maratheftiko",
  "Marselan",
  "Marsigliana",
  "Marzemino",
  "Mauzac noir",
  "Mavro",
  "Mavroudi",
  "Mayorquin",
  "Meghrabujr",
  "Melnik",
  "Mencía ",
  "Merenzao",
  "Merille",
  "Milgranet",
  "Millot ",
  "Mission",
  "Molinara",
  "Mondeuse",
  "Monica",
  "Montepulciano",
  "Montù",
  "Moreto",
  "Moristel",
  "Mornen noir",
  "Morrastel Bouschet",
  "Mourisco tinto",
  "Mourvèdre",
  "Mouyssaguès",
  "Mtevandidi",
  "Mujuretuli",
  "Muscardin",
  "Muscat Bouschet",
  "Muscat Hamburg",
  "Nebbiolo",
  "Negrara",
  "Négrette",
  "Negroamaro",
  "Negru de Dragasani",
  "Nerkarat",
  "Nero Buono di Cori",
  "Nero d'Avola",
  "Nerello Mascalese ",
  "Neretto di Bairo",
  "Neyret",
  "Nielluccio",
  "Ninčuša",
  "Nocera",
  "Notardomenico",
  "Oeillade noire",
  "Ojaleshi",
  "Öküzgözü",
  "Oseleta",
  "Pais",
  "Pallagrello nero",
  "Pamid",
  "Pascale di Cagliari",
  "Pelaverga",
  "Peloursin",
  "Perdal",
  "Perricone",
  "Persan",
  "Petit Bouschet",
  "Petit Rouge",
  "Petit Verdot",
  "Piccola nera",
  "Piedirosso",
  "Pignerol",
  "Pignola Valtellinese",
  "Pignolo",
  "Pineau d'Aunis",
  "Pinot Meunier ",
  "Pinot noir ",
  "Pinotage",
  "Piquepoul noir",
  "Plassa",
  "Plavina",
  "Pollera nera",
  "Portan",
  "Poulsard",
  "Prieto Picudo",
  "Prokupac",
  "Raboso",
  "Ramisco",
  "Reberger",
  "Refosco ",
  "Rimava",
  "Roesler",
  "Rondinella",
  "Rossese",
  "Rossignola",
  "Rossolino nero",
  "Rotberger",
  "Rouchet",
  "Rubintos",
  "Ruby Cabernet",
  "Rufete ",
  "Sagrantino",
  "Sangiovese",
  "San Giuseppe nero",
  "Saperavi",
  "Schiava ",
  "Schioppettino",
  "Schönburger",
  "Sciacarello",
  "Sciascinoso ",
  "Segalin ",
  "Servanin",
  "Sgaretta",
  "Shiroka Melnishka Losa",
  "Sousão",
  "St. Laurent ",
  "Saint-Macaire",
  "Sumoll",
  "Susumaniello",
  "Tannat",
  "Tarrango",
  "Tazzelenghe",
  "Tempranillo ",
  "Teran",
  "Termarina rossa",
  "Teroldego ",
  "Terret noir",
  "Thiniatiko",
  "Tibouren",
  "Tinta Amarela",
  "Tinta Barroca",
  "Tinta Caiada",
  "Tinta Carvalha",
  "Tinta Francisca",
  "Tinta Madeira",
  "Tinta Miuda",
  "Tinta Negra Mole ",
  "Tinto Cão",
  "Touriga Franca",
  "Touriga Nacional ",
  "Turán",
  "Trepat",
  "Trevisana nera",
  "Trincadeira",
  "Usakhelauri",
  "Uva di Troia",
  "Uvalino",
  "Uva Rara",
  "Uva Tosca",
  "Vaccarese ",
  "Valdiguié ",
  "Valentino nero",
  "Vermentino nero",
  "Vespolina",
  "Vien de Nus",
  "Volidza ",
  "Vranac",
  "Vuillermin",
  "Wildbacher",
  "Wrothham Pinot",
  "Žametovka",
  "Zinfandel ",
  "Zweigelt",
  "Adalmiina",
  "Addoraca",
  "Agh Shani",
  "Airén",
  "Alarije",
  "Albalonga",
  "Albana",
  "Albanella",
  "Albanello bianco",
  "Albaranzeuli bianco",
  "Albarello",
  "Alvarinhο",
  "Albarola",
  "Albillo",
  "Alcañón",
  "Aligoté",
  "Alionza",
  "Altesse",
  "Amigne",
  "Ansonica",
  "Antao Vaz",
  "Arany sárfehér ",
  "Arbane",
  "Arbois",
  "Arilla",
  "Arinto",
  "Arneis",
  "Arrufiac",
  "Arvesiniadu",
  "Asprinio bianco",
  "Aubin",
  "Aubin vert",
  "Aurelius",
  "Auxerrois blanc",
  "Avesso",
  "Azal branco",
  "Barcelo",
  "Bacchus",
  "Baco blanc",
  "Baiyu",
  "Balzac blanc",
  "Banat Riesling ",
  "Baratuciat",
  "Barbera bianca",
  "Bariadorgia",
  "Baroque",
  "Belina",
  "Benedino",
  "Besgano bianco",
  "Bia blanc",
  "Bianca",
  "Biancame",
  "Bianchetta Trevigiana",
  "Bianchetti Genovese",
  "Bianco d'Alessano",
  "Biancolella",
  "Biancu Gentile",
  "Biancone di Portoferraio",
  "Bical ",
  "Bigolona",
  "Blatterle",
  "Bratislavské biele",
  "Boais",
  "Bogdanuša",
  "Bombino bianco",
  "Borba blanca",
  "Bosco",
  "Bourboulenc",
  "Bouvier",
  "Breidecker",
  "Brustiano bianco",
  "Bual ",
  "Budai Zöld",
  "Bukettraube",
  "Burger",
  "Caíño blanco",
  "Camaralet",
  "Canari blanc",
  "Caprettone",
  "Carica l'Asino",
  "Carignan blanc",
  "Cascarolo bianco",
  "Catarratto",
  "Cayetana",
  "Chasan",
  "Chasselas ",
  "Chenel",
  "Chenin blanc",
  "Clairette",
  "Claret de Gers",
  "Claverie",
  "Cococciola",
  "Cocur",
  "Coda di Pecora",
  "Coda di Volpe",
  "Colombard",
  "Completer",
  "Cortese",
  "Crato ",
  "Courbu ",
  "Criolla Grande",
  "Crouchen ",
  "Pearl of Csaba",
  "Cserszegi fűszeres",
  "Cygne blanc",
  "Dattier",
  "Debit",
  "Diagalves",
  "Dimiat",
  "Dinka",
  "Doña Blanca",
  "Donzelinho branco",
  "Doradilla",
  "Doradillo",
  "Drupeggio",
  "Durella",
  "Ehrenfelser",
  "Elbling",
  "Emerald Riesling",
  "Emir Karasi",
  "Encruzado",
  "Erbaluce",
  "Esquitxagos",
  "Escanyavella",
  "Ezerjó",
  "Faber",
  "Falanghina",
  "False Pedro",
  "Farana",
  "Favorita",
  "Fernao Pires ",
  "Ferral",
  " Fetiaska",
  "Fetească regală",
  "Fiano",
  "Fié ",
  "Findling",
  "Flora",
  "Fologosão",
  "Folle blanche ",
  "Forastera",
  "Francavida",
  "Francusa",
  "Freisamer ",
  "Fromenteau",
  "Furmint ",
  "Galego Dourado",
  "Garganega",
  "Garnacha blanca ",
  "Gewürztraminer",
  "Girgentina",
  "Giró blanc",
  "Gloria",
  "Godello",
  "Goldburger",
  "Goldriesling",
  "Gouais blanc",
  "Graisse ",
  "Grasă de Cotnari",
  "Grechetto",
  "Greco bianco",
  "Green Hungarian",
  "Grenache gris",
  "Grillo ",
  "Grk Bijeli",
  "Grolleau gris",
  "Gros Manseng",
  "Grüner Veltliner",
  "Guardavalle",
  "Gutenborner",
  "Hárslevelű",
  "Hondarrabi Zuri",
  "Humagne Blanche",
  "Huxelrebe ",
  "Incrocio Manzoni",
  "Irsai Oliver",
  "Italia",
  "Jacquère",
  "Jampal",
  "Juhfark",
  "Juwel",
  "Kanzler",
  "Keknyelu",
  "Kerner",
  "Knipperle ",
  "Koshu",
  "Karija l'Osü",
  "Krstač",
  "Kujundžuša",
  "Ladikino",
  "Lado",
  "Lagarino bianco",
  "Lagorthi",
  "Lauzet",
  "Len de l'El ",
  "Loureira",
  "Luglienga",
  "Lumassina",
  "Macabeo",
  "Maceratino",
  "Madeleine Angevine",
  "Majarcă Albă",
  "Malvar",
  "Malvasia, includes several sub-varieties",
  "Mantonico bianco",
  "Manteudo",
  "Maria Gomes ",
  "Marsanne",
  "Marzemina bianca",
  "Mauzac",
  "Melon de Bourgogne ",
  "Merlot blanc",
  "Merseguera ",
  "Meslier St-François",
  "Mezesfehér",
  "Minella bianca",
  "Miousap",
  "Molette",
  "Moll",
  "Montonico bianco",
  "Montu",
  "Morio-Muskat",
  "Moscatel Rosada",
  "Moscato Giallo",
  "Mtsvane",
  "Müller-Thurgau ",
  "Muscadelle ",
  "Muscat Blanc à Petits Grains",
  "Muscat Ottonel",
  "Nasco",
  "Neherleschol",
  "Neuburger",
  "Nobling",
  "Nosiola",
  "Nuragus",
  "Ondenc",
  "Optima",
  "Oraniensteiner",
  "Orion",
  "Ortega",
  "Ortrugo",
  "Oz",
  "Pagedebit",
  "Pallagrello bianco",
  "Palomino",
  "Pampanuto ",
  "Parč",
  "Pardillo ",
  "Parellada",
  "Pascal blanc",
  "Passerina",
  "Pecorino ",
  "Pedro Giménez",
  "Pedro Ximénez",
  "Perle",
  "Petit Courbu",
  "Petit Manseng",
  "Petit Meslier",
  "Petite Arvine",
  "Picardin ",
  "Picolit ",
  "Picpoul ",
  "Pigato",
  "Pignoletto",
  "Pinella",
  "Pinot blanc",
  "Pinot gris ",
  "Planta Fina",
  "Planta Nova",
  "Plavai ",
  "Pošip",
  "Prensal",
  "Prié blanc ",
  "Prosecco",
  "Prunesta",
  "Rabigato",
  "Rabo de Ovelha",
  "Raisin blanc",
  "Rauschling ",
  "Regner",
  "Reichensteiner",
  "Retagliado bianco",
  "Rèze",
  "Ribolla Gialla",
  "Riesling ",
  "Rieslaner",
  "Rkatsiteli",
  "Rollo",
  "Romorantin",
  "Rossese bianco",
  "Roter Veltliner",
  "Rotgipfler",
  "Roupeiro ",
  "Roussanne",
  "Rovello bianco",
  "Sabro",
  "Sacy",
  "Ste Marie",
  "Saint-Pierre Doré",
  "Sarfeher ",
  "Sauvignon vert ",
  "Savagnin ",
  "Scheurebe",
  "Sémillon",
  "Septiner",
  "Sercial ",
  "Sereksia ",
  "Siegerrebe",
  "Silvaner",
  "Mátrai Muskotály",
  "Smederevka",
  "Souvignier gris",
  "Spagnol",
  "Sumoll Blanc",
  "Symphony",
  "Tamares ",
  "Tamianka",
  "Taminga",
  "Tempranillo blanco",
  "Téoulier",
  "Terrantez",
  "Terret gris",
  "Timorasso",
  "Torontel ",
  "Tourbat ",
  "Trbljan",
  "Trebbiano",
  "Treixadura ",
  "Trousseau gris",
  "Tsaoussi",
  "Tsolikauri",
  "Vega",
  "Verdea",
  "Verdeca",
  "Verdejo",
  "Verdelho ",
  "Verdello",
  "Verdesse",
  "Verdicchio",
  "Verdiso ",
  "Verdoncho",
  "Verduzzo",
  "Verduzzo Trevigiano",
  "Vermentino ",
  "Vernaccia",
  "Vernaccia di Oristano",
  "Veroga",
  "Versoaln",
  "Vespaiola",
  "Vinhao",
  "Viosinho",
  "Vitovska",
  "Voskehat",
  "Vugava",
  "Weldra",
  "Welschriesling ",
  "Würzer",
  "Xarello",
  "Xynisteri",
  "Zala Gyöngye",
  "Zalema",
  "Zefir",
  "Zenit",
  "Zengő",
  "Zéta ",
  "Zeusz",
  "Zierfandler ",
  "Žilavka",
  "Žlahtina",
  "Agdam Gyzyl Uzumu",
  "Chardonnay Rose",
  "Black Corinth",
  "Black Monukka",
  "Black Rose",
  "Cardinal",
  "Mazzarrone",
  "Red Corinth",
  "Red Globe",
  "Valencia",
  "Red Flame",
  "Richard Walden",
  "Calmeria",
  "Centennial",
  "Malingre Précoce",
  "Moonballs",
  "Perlette",
  "Rozaki",
  "Sugraone",
  "Sultanina",
  "Superior Seedless ",
  "Valvin Muscat",
  "White Corinth",
  "Catawba",
  "Concord",
  "Delaware",
  "Diamond",
  "Fredonia",
  "Isabella",
  "Ives",
  "Niagara",
  "Noah",
  "Canadice",
  "Christmas Rose",
  "Crimson Seedless",
  "Einset",
  "Emperor",
  "Flame Seedless",
  "Reliance Seedless",
  "Rouge",
  "Ruby Roman",
  "Ruby Seedless",
  "Swenson Red",
  "Tudor Premium Red",
  "Suffolk Red",
  "Vanessa",
  "Yates",
  "Alden",
  "Autumn Royal",
  "Beauty Seedless",
  "Bluebell",
  "Buffalo",
  "Coronation",
  "Fantasy Seedless",
  "Glenora",
  "Jupiter",
  "Marroo",
  "Mars",
  "Niabell",
  "Ribier",
  "Steuben",
  "Van Buren",
  "Cassady  ",
  "Golden Muscat  ",
  "Himrod   ",
  "Interlaken   ",
  "Lakemont   ",
  "Marquis   ",
  "Neptune   ",
  "Seneca  ",
  "Riparia Gloire",
  "Riparia Grand Glabre",
  "Riparia Scribner",
  "Riparia Martin",
  "Riparia 89",
  "Americas",
  "Big Red  ",
  "Black Beauty  ",
  "Black Fry",
  "Carlos  ",
  "Cowart",
  "Darlene  ",
  "Dixie Red",
  "Early Fry",
  "Fry  ",
  "Granny Val",
  "Higgins  ",
  "Hunt",
  "Hunter  ",
  "Ison's",
  "Janebell  ",
  "Janet  ",
  "Jumbo  ",
  "Late Fry",
  "Magnolia  ",
  "Muscadine",
  "Nesbit  ",
  "Noble",
  "Pam  ",
  "Pineapple  ",
  "Scarlet  ",
  "Scuppernong",
  "Southland",
  "Sugargate",
  "Agawam",
  "Aurore",
  "Baco 22A (Baco blanc)",
  "Baco noir (Baco 1)",
  "Blanc du Bois",
  "Brianna",
  "Cabernet blanc",
  "Cayuga White",
  "Chardonel",
  "Corot noir",
  "Couderc noir",
  "De Chaunac",
  "Edelweiss",
  "Elvira",
  "Esprit",
  "Flora (grape)",
  "Flot rouge",
  "Frontenac",
  "Goethe",
  "Herbert",
  "Kay Gray",
  "L'Acadie blanc",
  "L'Ambertille",
  "La Crescent",
  "La Crosse",
  "Léon Millot",
  "Louise Swenson",
  "Luci Kuhlmann",
  "Marechal Foch",
  "Marechal Joffre",
  "Marquette",
  "Massasoit",
  "Melody",
  "Merzling",
  "Noiret",
  "Onaka",
  "Phoenix",
  "Plantet",
  "Prairie Star",
  "Ravat blanc ",
  "Ravat noir ",
  "Rayon d'Or",
  "Regent",
  "Rembrandt",
  "Requa",
  "Rondo",
  "Rosette",
  "Rougeon",
  "Royalty",
  "Rubired",
  "St. Croix",
  "St. Pepin",
  "Severny",
  "Solaris",
  "Sovereign Opal",
  "Traminette",
  "Triomphe d'Alsace",
  "Vidal blanc",
  "Vignoles",
  "Villard blanc",
  "Villard noir",
  "Zarya Severa",
  "Boskoop Glory",
  "Honey Red",
  "Kyoho",
  "Pione",
  "Thomcord",
  "Beta",
  "Clinton",
  "Bordo",
  "Jaeger 70",
  "Landal noir",
  "Landot noir",
  "Minnesota 78",
  "Muscat bleu",
  "Seyval blanc",
  "Seyval noir",
  "Other"
]

export const topVarietiesData = [
  "Agiorgitiko",
  "Assyrtiko",
  "Moschofilero",
  "Xinomavro",
  "Cabernet Sauvignon",
  "Chardonnay",
  "Merlot",
  "Sauvignon Blanc",
  "Syrah",
  "Viognier",
  "Malagousia",
  "Mavrodaphne",
  "Mavrotragano",
  "White Muscat",
  "Muscat of Alexandria",
  "Mouchtaro",
  "Savatiano",
  "Sultana",
  "Aidani",
  "Athiri",
  "Vilana",
  "Vidiano",
  "Kotsifali",
  "Kidonitsa",
  "Limnio",
  "Limniona",
  "Liatiko",
  "Mandelaria",
  "Monemvasia",
  "Debina",
  "Roditis",
  "Robola",
  "Avgoustiatis",
  "Vlahiko",
  "Dafni",
  "Thrapsathiri",
  "Messenikola Blanc",
  "Negoska",
  "Plyto",
  "Sideritis",
  "Fokiano",
]


export const topGreekVarietiesData = [
  "Αγιωργίτικο",
  "Ασύρτικο",
  "Μοσχοφίλερο",
  "Ξινόμαυρο",
  "Cabernet Sauvignon",
  "Chardonnay",
  "Merlot",
  "Sauvignon Blanc",
  "Syrah",
  "Viognier",
  "Μαλαγουζιά",
  "Μαυροδάφνη",
  "Μαυροτράγανο",
  "Μοσχάτο άσπρο",
  "Μοσχάτο Αλεξάνδρειας",
  "Μούχταρο",
  "Σαββατιανό",
  "Σουλτανίνα",
  "Αηδάνι",
  "Αθήρι",
  "Βηλάνα",
  "Βιδιανό",
  "Κοτσιφάλι",
  "Κυδωνίτσα",
  "Λημνιό",
  "Λημνιώνα",
  "Λιάτικο",
  "Μανδηλαριά",
  "Μονεμβασιά",
  "Ντεμπίνα",
  "Ροδίτης",
  "Ρομπόλα",
  "Αυγουστιάτης",
  "Βλάχικο",
  "Δαφνί",
  "Θραψαθήρι",
  "Μαύρο Μεσενικόλα",
  "Νεγκόσκα",
  "Πλυτό",
  "Σιδερίτης",
  "Φωκιανό"
]

export const varietiesMapping = (the) => {

  let varietiesI18n = {};

  i18n.languages.forEach(lang => {
    varietiesI18n.el = {}
    varietiesI18n.en = {}
  })

  topVarietiesData.forEach((el, i) => {
    varietiesI18n['el'][el] = topGreekVarietiesData[i];
    varietiesI18n['en'][el] = el;
  })
  return varietiesI18n
}


export const getTopVarieties = () => {
  return varietiesMapping()[i18n.language];
}

export const getI18nVariety = (variety) => {
  let topVarieties = getTopVarieties();
  if(topVarieties[variety]){
    return topVarieties[variety];
  }

  return variety;

}
