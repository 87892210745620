import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFields } from 'actions/field/list'
import List from './List.component';
import { showNewField } from 'actions/ui'


const groupFieldsByRegion = (fields) => {
  let groups = {}
  // let fields = user[0].Fields
  fields.forEach((field, i) => {
    let groupKey = `${field.location.region}-${field.location.country}`
    
    // console.log(field.location)
    // groupKey.replace(/\s/g, '');
    // console.log(groupKey, 'the key')

    if(!groups.groupKey){
      console.log('the setting key')
      groups[groupKey] = []
    }

    // field.i = i
    // console.log('the pushing', groupKey)
    // groups[groupKey].push(field)
  })
  fields.forEach((field, i) => {
    let groupKey = `${field.location.region}-${field.location.country}`
    field.i = i
    groups[groupKey].push(field)
  })

  return groups;
}

const ListContainer = ({ fetchFields, fields, showNewField, activeItem }) => {
  const [loading, setLoading] = useState(true);
  const [fieldGroups, setFieldGroups] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [fieldsWithFilter, setFieldsWithFilter] = useState([...fields]);

  useEffect(() => {
    if(fields.length){
      let groups = groupFieldsByRegion(fields)
      setFieldGroups(groups)
    }
  }, [fields]);

  useEffect(() => {
    (async () => {
      let payload = {}
      if(window.innerWidth < 767){
        payload.activeFieldId = -1
      }
      console.log(payload, 'the payload')
      await fetchFields(payload);
      setLoading(false);
    })()
  }, []);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value)
  }

  const onNewField = () => showNewField(true)


  const state = {
    loading,
    fields,
    fieldGroups,
    searchValue,
    activeItem
  };

  const containerFunctions = {
    onNewField,
    onSearchChange
  };

  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.fields.list,
  activeItem: state.fields.activeItem,
});

export default connect(mapStateToProps, { 
  fetchFields,
  showNewField
 })(ListContainer);