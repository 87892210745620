import moment from 'moment'
import { renderTable } from 'component/Field/utils/renderRow'
import React from 'react'

const renderDates = (shoot = {}, t, layout = "table") => {
  let { shootDays = {} } = shoot

  if(!shootDays) return null;

  let { start, end } = shoot.shootDays.confirmed;
  let { start: altStart, end: altEnd } = shoot.shootDays.alternative;
  let { final } = shoot.shootDays;

  let rows = [];

  let startDayOrDates;
  let endDayOrDates;

  let stringObjFormat = "D MMM YY";
  //TODO: refactor, the same is used in field
  if (start !== end) {
    startDayOrDates = `${moment(start).format("D MMM")} - ${moment(end).format("D MMM YY")}`
    rows.push(
      [t('Drone Coverage From'), moment(start).format("MMM D, YYYY")],
      [t('Drone Coverage To'), moment(end).format("MMM D, YYYY")],
    )
  } else {
    startDayOrDates = moment(start).format(stringObjFormat);
    rows.push([t('Drone Coverage Date'), moment(start).format("MMM D, YYYY")])
  }

  if (altStart !== altEnd) {
    endDayOrDates = `${moment(altStart).format("D MMM")} - ${moment(altEnd).format("D MMM YY")}`
    rows.push(
      [t('Alternative Coverage From'), moment(altStart).format("MMM D, YYYY")],
      [t('Alternative Coverage To'), moment(altEnd).format("MMM D, YYYY")],
    )
  } else {
    endDayOrDates = `${moment(altStart).format(stringObjFormat)}`
    rows.push([t('Alternative Coverage Date'), moment(altStart).format("MMM D, YYYY")])
  }

  if(layout === "stringObj"){
    return (
      <>
      {
        final ?
        moment(final).format(stringObjFormat) :
        <span>{startDayOrDates} · {endDayOrDates}</span>
      }
      </>
    )

  }
  return (
    renderTable({
      title: t('Drone Coverage Dates'),
      rows
    })
  )
}



export {
  renderDates
}