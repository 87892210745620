import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sms from './Sms.component';

const SmsContainer = (props) => {

  const state = {

  };

  const containerFunctions = {

  };

  return (
    <Sms
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SmsContainer);