import React from 'react';

import List from 'component/User/List'
import Item from 'component/User/Item'

const Users = (props) => {

  return (
    <>
      <List />
      <Item />
    </>
  )
}

export default Users;
