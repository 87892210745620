import api from 'utils/api';
import axios from 'axios';

import {
  LOAD_PROFILE,
  EMPTY_PROFILE,
} from 'actions/types';


const removeAvatar = () => async dispatch => {
  try {

    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      },
      withCredentials: true 
  };
    const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/company/update/avatar`, { avatar: null },config)
    return true

  } catch (err) {
    return false
    alert(err)
  }
}


const uploadImage = (img) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('avatar',img);

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        },
        withCredentials: true 
    };

    console.log(formData, 'the payload')
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/company/avatar/upload`, formData,config)

    console.log(res, 'avatar res')
  } catch(err) {
    console.log(err);
  }
}


export {
  uploadImage,
  removeAvatar
}