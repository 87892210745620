import moment from 'moment'


export const computePrice = (props) => {
  const { duration, numOfShoots, hectares, service } = props
  // let h = Math.trunc(hectares);
  let h = hectares/10000
  h = h.toFixed(2);

  console.log(hectares, 'the hectares', h)

  if (duration === null || numOfShoots === null || hectares === null || service === null) {
    return 0
  }

  let basicPrice = service === 0 ? 120 : 100;


  if(service === 0){
    if (duration == 2) {
      basicPrice *= 0.95;
    }
    if (duration == 3) {
      basicPrice *= 0.90;
    }

    if (numOfShoots > 3) {
      basicPrice += (numOfShoots - 3) * 3.75
    } else {
      basicPrice -= (3 - numOfShoots) * 2.90
    }

    let servicePrice = basicPrice * h * duration;
    return servicePrice
  }


  if(service === 1){
    basicPrice = 100;
    let numOfShootsPrice = 0;
    let durationPrice = 0;

    if(numOfShoots > 3) {
      numOfShootsPrice += (numOfShoots - 3) * 0.55
    }

    if (duration == 1) {
      durationPrice = 1*basicPrice;
    }
    if (duration == 2) {
      durationPrice = 0.95*basicPrice;
    }
    if (duration == 3) {
      durationPrice = 0.9*basicPrice;
    }

    return (numOfShootsPrice + durationPrice)*duration*h;
  }
}


export const computeTotal = (itemGroups) => {
  let total = 0
  {Object.keys(itemGroups).map(key => {
    let group = itemGroups[key]
    const { field, reports } = group
    let duration = computeDuration(group)
    let price = 0
    let service = reports[0].service == 'e2e' ? 0 : 1;

    if(group.field && group.reports){
      price = computePrice({ duration, numOfShoots: reports.length, hectares: field.area, service })
    }
    total += price
  })}
  console.log(total, 'the total')
  return total
}



export const computeDiscount = (total) => {
  let discount = 0;


  let levels = [
    { min: 0, max: 5000, disc: 0 },
    { min: 5000, max: 10000, disc: 3 },
    { min: 10000, max: 30000, disc: 5 },
    { min: 30000, max: 50000, disc: 7 },
    { min: 50000, max: 150000, disc: 10 },
    { min: 150000, max: 250000, disc: 15 },
    { min: 250000, max: 500000, disc: 10 },
    { min: 500000, max: 1000000, disc: 20 },
    { min: 1000000, max: 2000000, disc: 25 },
    { min: 2000000, max: 2000000000, disc: 30 },
  ]

  levels.forEach(level => {
    let { min, max, disc } = level
    let discPercent = disc*0.01;

    if(total >= min){
      if(total > max){
        discount += (max - min - 1)*discPercent
      }else {
        discount += (total - min - 1)*discPercent
      }
    }
  })

  return discount

}

export const computeDuration = (group) => {
  let dates = group?.reports?.map(el => el.validTo)
  var min = dates?.reduce(function (a, b) { return a < b ? a : b; }); 
  var max = dates?.reduce(function (a, b) { return a > b ? a : b; });
  let yearNow = moment().format('YYYY');
  let yearThen = moment(max).format('YYYY');
  return yearThen - yearNow
}