import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import ProfileSidenav from 'component/profile/Sidenav'
import UserCompany from 'component/profile/UserCompany';
import UserOrders from 'component/profile/Orders';
import AddressBook from './AddressBook';
import PrivateRoute from 'component/routing/PrivateRoute';
import Settings from 'component/profile/Security';
import Profile from 'component/profile/Profile'
import About from 'component/profile/About'
import './TheLayout.style.scss'

const routes = [
  { path: '/profile/company', name: 'UserCompany', Component: UserCompany, visibility: 'private' },
  { path: '/profile/orders', name: 'UserOrders', Component: UserOrders, visibility: 'private' },
  { path: '/profile/address-book', name: 'AddressBook', Component: AddressBook, visibility: 'private' },
  { path: '/profile/settings', name: 'Security', Component: Settings, visibility: 'private' },
  { path: '/profile/about', name: 'About', Component: About, visibility: 'private' },
  { path: '/profile/', name: 'Profile', Component: Profile, visibility: 'private' },
]

const TheLayout = (props) => {
  const { user, activeNavItem } = props

  return (
    <section className="route-section pageProfile">
      <ProfileSidenav />
      <Switch>
        {routes.map((route, idx) => {
          return (
            <Route
              key={route.name}
              path={route.path}
              exact={true}
              name={route.name}
              render={props => (
                <section className={`route-profile-section ${route.name} ${activeNavItem ? 'active' : ''}`}>
                  {(() => {
                    switch (route.visibility) {
                      case "private":
                        console.log(route.visibility, 'the visibility')
                        return <PrivateRoute component={route.Component} />;
                      default:
                        return <route.component {...props} />
                    }
                  })()
                  }
                </section>
              )} />
          )
        })}
      </Switch>
    </section>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  activeNavItem: state.settings.activeNavItem
});

export default connect(mapStateToProps)(TheLayout);
