import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Fields from './Fields.component';

const FieldsContainer = ({ list }) => {

  const state = {
  };

  const containerFunctions = {

  };

  return (
    <Fields
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
})(FieldsContainer);