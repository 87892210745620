import PropTypes from "prop-types";
import { PureComponent } from "react";
import React from "react";
import MapboxDashboard from "component/MapboxDashboard";
import "./Dashboard.style.scss";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
  // export class Dashboard extends PureComponent {

  // render() {
  return (
    <section className="map">
      <div className="mapbox-wrap">
        <div
          ref={props.mapRef}
          className="mapContainer"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
          }}
        ></div>
      </div>
    </section>
  );
  // }
};

export default Dashboard;
