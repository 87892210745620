import api from 'utils/api';
import { setAlert } from 'actions/alert'

const updateOrder = (payload) => async dispatch => {
  try {
    const res = await api.put('/orders', payload);
    if(res.status === 200){
      dispatch(setAlert('Order Status updated Successfully.', 'success'))
      return true
    } else {
      return false
    }
  } catch (err) {
    alert(err)
    return false
  }
}


export {
  updateOrder
}
