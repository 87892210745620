import React from 'react';
import './Item.style.scss'
import { withTranslation } from 'react-i18next'
import i18n, { __i18n } from 'i18n';
import GreekPrivacyPolicy from './component/GreekPrivacyPolicy';
import EnglishPrivacyPolicy from './component/EnglishPrivacyPolicy';

const Item = (props) => {
  const { t } = props;
  let { language } = i18n;

  console.log(t, 'the t', i18n)

  return (
    <section className="terms">
      <div className="container">
        { language == 'el' && <GreekPrivacyPolicy /> }
        { language == 'en' && <EnglishPrivacyPolicy /> }
      </div>
    </section>

  )
}

export default withTranslation()(Item);