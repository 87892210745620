import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { GiSunrise, GiSunset } from "react-icons/gi";
import { WiHumidity } from "react-icons/wi";
import "./Weather.style.scss";
import api from "utils/api";

const Weather = (props) => {
  const { field } = props;
  let lat, lng;
  if (field) {
    // because is null initially, cannot init obj values otherwise
    const { mapDisplay: { lat: fLat, lng: fLng } = {} } = field;
    lat = fLat;
    lng = fLng;
  }
  const [weatherIcon, setWeatherIcon] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let data = await api.get(`/weather?lat=${lat}&lng=${lng}`);
      setWeatherIcon(data?.data);
    }

    if (field) {
      fetchData();
    }
  }, [field]);

  let color = "black";
  let knots = (weatherIcon?.current?.wind_mph * 0.86898)?.toFixed(2);

  let iconOpts = {
    fill: color,
    size: 30,
  };

  return (
    <section className="section-weather">
      {weatherIcon ? (
        <>
          <span className="temp_c astro">
            <img src={weatherIcon?.current?.condition?.icon} />
          </span>
          <span className="temp_c astro">
            <GiSunrise iconOpts />
            {weatherIcon?.astronomy?.astro?.sunrise}
          </span>
          <span className="temp_c astro">
            <GiSunset iconOpts />
            {weatherIcon?.astronomy?.astro?.sunset}
          </span>
          <span className="temp_c">{weatherIcon?.current?.temp_c} C</span>
          <span className="temp_c">
            <WiHumidity iconOpts />
            {weatherIcon?.current?.humidity} %
          </span>
          <span className="temp_c">{knots} Kn </span>
        </>
      ) : null}
    </section>
  );
};

export default withTranslation()(Weather);
