import api from 'utils/api';
import {
  LOAD_USERS,
  EMPTY_USERS,
  SET_ACTIVE_USER_ITEM,
} from 'actions/types';


const fetchUsers = ({ activeUserId } = {}) => async dispatch => {
  console.log('Fetching! action')

  try {
    dispatch({
      type: EMPTY_USERS
    });
    console.log('[Action] Fetch Users')
    const res = await api.get('/users');
    dispatch({
      type: LOAD_USERS,
      payload: { 
        list: res.data.users,
        activeItemId: activeUserId
      }
    });
  } catch (err) {

  }
};

const setActiveItem = (val) => async dispatch => {
  try {
    dispatch({
      type: SET_ACTIVE_USER_ITEM,
      payload: val
    });
  } catch (err) {
    alert(err)
  }
}


export {
  fetchUsers,
  setActiveItem,
}