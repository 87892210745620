import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from 'react-bootstrap'
import GuestHeader from 'route/SignUp/component/GuestHeader'
import Benefits from 'route/SignUp/component/Benefits'
import Loader from 'component/reusable/Loader'

import './VerifyEmail.style.scss';

const VerifyEmailSuccess = (props) => {
  return (
    <>
      <p>Your account has been successfully verified!</p>
      <p className="mb-5">You can now <Link to="/login">sign in</Link> to your profile and add your fields!</p>
      <Link to="/login" className="btn btn-primary c-btn-lg">Sign in</Link>
    </>
  )
}
const VerifyEmailFailure = (props) => {
  return (
    <>
      <p>Something went wrong and we couldn't verify your account.</p>
      <p>Please <a href="mailto:info@farmair.io">contact us</a> for more information</p>
    </>
  )
}

const PasswordReset = (props) => {
  const { loading, successRequest } = props

  if(loading){
    return (
      <div className="loader-wrap fade-in">
        <Loader />
      </div>
    )
  }

  return (
    <section className="guest-page">
      <GuestHeader />
      <main className="" aria-label="Sign Up Page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 left">
              <Benefits />
            </div>
            <div className="col-md-6 right">
              <div aria-label="Login Page" className="login-page inner vertical-center">
                <Form>
                  <h1>Email Confirmation</h1>
                  {successRequest && VerifyEmailSuccess(props)}
                  {!successRequest && VerifyEmailFailure(props)}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  )
}

export default PasswordReset;
