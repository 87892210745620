export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const MAINTANCE = "MAINTANCE";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

export const WINDOW_LOADED = "WINDOW_LOADED";
export const SET_SIDEBAR_VISIBILITY = "SET_SIDEBAR_VISIBILITY";

export const SHOW_NEW_FIELD = "SHOW_NEW_FIELD";
export const SHOW_NEW_COMPANY = "SHOW_NEW_COMPANY";
export const SHOW_NEW_USER = "SHOW_NEW_USER";
export const SHOW_NEW_REPORT = "SHOW_NEW_REPORT";

export const SHOW_UPLOAD_DATA = "SHOW_UPLOAD_DATA";

export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";
export const SHOW_SCHEDULE_SHOOT = "SHOW_SCHEDULE_SHOOT";

export const SHOW_FULL_LOADER = "SHOW_FULL_LOADER";

export const SHOW_DIRECTIONS = "SHOW_DIRECTIONS";

export const LOAD_FIELDS = "LOAD_FIELDS";
export const EMPTY_FIELDS = "EMPTY_FIELDS";

export const LOAD_CART = "LOAD_CART";
export const EMPTY_CART = "EMPTY_CART";
export const NUM_OF_ITEMS_IN_CART = "NUM_OF_ITEMS_IN_CART";

export const EMPTY_ORDERS = "EMPTY_ORDERS";
export const LOAD_ORDERS = "LOAD_ORDERS";
export const SET_ACTIVE_ORDER_ITEM = "SET_ACTIVE_ORDER_ITEM";

export const LOAD_FIELD = "LOAD_FIELD";
export const EMPTY_FIELD = "EMPTY_FIELD";

export const LOAD_USERS = "LOAD_USERS";
export const EMPTY_USERS = "EMPTY_USERS";

export const LOAD_REPORTS = "LOAD_REPORTS";
export const EMPTY_REPORTS = "EMPTY_REPORTS";
export const UPDATE_BBOX = "UPDATE_BBOX";
export const SET_MAPBOX_TILE = "SET_MAPBOX_TILE";

export const LOAD_SHOOTS = "LOAD_SHOOTS";
export const EMPTY_SHOOTS = "EMPTY_SHOOTS";

export const LOAD_SHOOT = "LOAD_SHOOT";
export const EMPTY_SHOOT = "EMPTY_SHOOT";
export const UPDATE_SHOOT_DRONE_ADMIN = "UPDATE_SHOOT_DRONE_ADMIN";

export const LOAD_DRONE_ADMINS = "LOAD_DRONE_ADMINS";
export const EMPTY_DRONE_ADMINS = "EMPTY_DRONE_ADMINS";
export const LOAD_DRONE_OPERATORS = "LOAD_DRONE_OPERATORS";
export const EMPTY_DRONE_OPERATORS = "EMPTY_DRONE_OPERATORS";

export const LOAD_COMPANIES = "LOAD_COMPANIES";
export const EMPTY_COMPANIES = "EMPTY_COMPANIES";
export const SET_ACTIVE_COMPANY_ITEM = "SET_ACTIVE_COMPANY_ITEM";
export const SET_ACTIVE_USER_ITEM = "SET_ACTIVE_USER_ITEM";
export const SET_ACTIVE_FIELD_ITEM = "SET_ACTIVE_FIELD_ITEM";
export const SET_ACTIVE_SHOOT_ITEM = "SET_ACTIVE_SHOOT_ITEM";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const EMPTY_NOTIFICATIONS = "EMPTY_NOTIFICATIONS";
export const SET_NUM_OF_UNREAD_NOTIFICATIONS =
  "SET_NUM_OF_UNREAD_NOTIFICATIONS";

export const LOAD_PROFILE = "LOAD_PROFILE";
export const EMPTY_PROFILE = "EMPTY_PROFILE";
export const SET_PROFILE_ACTIVE_NAV_ITEM = "SET_PROFILE_ACTIVE_NAV_ITEM";

export const CREATE_SHOOT = "CREATE_SHOOT";

// FIELD STATE
export const SET_SATELLITE_GRAPH_DATA = "SET_SATELLITE_GRAPH_DATA";
