import React from 'react'

export default function GreekTick(){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g fill="none" fillRule="evenodd">
          <g>
              <g transform="translate(-136 -406) translate(136 406)">
                  <circle cx="14" cy="14" r="14" fill="#1C4D00"/>
                  <path stroke="#FFF" strokeWidth="2.4" d="M7.72 15.267L12.998 20 20.72 7"/>
              </g>
          </g>
      </g>
  </svg>
  )
}