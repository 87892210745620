import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PriceBox from './PriceBox.component';

const PriceBoxContainer = ({props}) => {

  const state = {

  };

  const containerFunctions = {
    props
  };

  return (
    <PriceBox
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PriceBoxContainer);