import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { verifyEmailRequest } from 'actions/auth';
import VerifyEmail from './VerifyEmail.component';
import { useParams } from "react-router-dom";


const VerifyEmailContainer = ({ verifyEmailRequest }) => {
  let { verify_email_token } = useParams();
  const [successRequest, setSuccessRequest] = useState(false)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    (async function sendRequest() {
      if((await verifyEmailRequest(verify_email_token))){
        setSuccessRequest(true)
      }else {
        setSuccessRequest(false)
      }
      setLoading(false)
    })();
  },[])

  const state = {
    successRequest,
    loading
  };

  const containerFunctions = {
  };

  return (
    <VerifyEmail
      { ...state }
      { ...containerFunctions }
    />
  )
}

export default connect(null, {
  verifyEmailRequest
})(VerifyEmailContainer);