import mapboxgl from "mapbox-gl";

export const mapboxStyle =
  "mapbox://styles/mapbox/satellite-streets-v10?optimize=true";
// export const mapboxStyle = 'mapbox://styles/mapbox/satellite-streets-v10';

export const pointsSource = (field) => {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: field.polygonPoints,
          },
        },
      ],
    },
    // 'cluster': true,
    // 'clusterRadius': 80
    // cluster: true,
    // clusterMaxZoom: 14, // Max zoom to cluster points on
    // clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
  };
};
export const pointsSources = (fields) => {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: fields.map((el) => {
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: el.polygonPoints,
          },
        };
      }),
    },
  };
};

export const pointsLayer = {
  id: "maine",
  type: "line",
  source: "maine",
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: {
    // 'line-cap': 'round',
    // 'line-join': 'round',
    "line-width": 3,
    "line-opacity": 1,
    "line-color": "#FFAF0B",
    // 'line-color': '#088',
  },
};
export const pointsLayerFn = ({ lineWidth = 3, lineOpacity = 1 }) => {
  return {
    id: "maine",
    type: "line",
    source: "maine",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      // 'line-cap': 'round',
      // 'line-join': 'round',
      "line-width": lineWidth,
      "line-opacity": lineOpacity,
      "line-color": "#FFAF0B",
      // "fill-color": "#888888",
      // 'line-color': '#088',
    },
  };
};

export const geoControl = () => {
  return new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    className: "geolocate",
  });
};
// map.addLayer({
//   'id': 'maine-2',
//   'type': 'fill',
//   'source': 'maine',
//   'layout': {},
//   'paint': {
//     'fill-color': '#088',
//     'fill-outline-color': '#000',
//     'fill-opacity': 0.1
//   }
// });
