import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n'
import { changeLanguage } from 'actions/user/settings'
import { setAlert } from 'actions/alert'
import Item from './Item.component';


const langTextMap = {
  'el': 'Ελληνικά',
  'en': 'English'
}

const ItemContainer = (props) => {
  const { changeLanguage, setAlert } = props
  const [lang, setLang] = useState(null)
  const [langText, setLangText] = useState(null)
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  useEffect(() => {
    setLang(i18n.language);
  }, [])

  useEffect(() => {
    setLangText(langTextMap[lang])
    setShowSubmitBtn((lang != i18n.language))
  }, [lang])

  const onLangClick = val => e => setLang(val);

  const onSubmit = async (e) => {
    e.preventDefault();

    if(await changeLanguage({ lang })){
      i18n.changeLanguage(lang)
      document.documentElement.setAttribute('lang', lang);
      setAlert('Language updated successfully', 'success')
    } else {
      setAlert("We couldn't change the language. Please try again later", 'danger')
    }
  }

  const state = {
    langText,
    showSubmitBtn
  };

  const containerFunctions = {
    onLangClick,
    onSubmit,
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  changeLanguage,
  setAlert
})(ItemContainer);