import React from 'react'

export default function Field() {
  return (
    <svg version="1.1" id="Capa_1" x="0px" y="0px"
	 viewBox="0 0 441.302 441.302" style={{enableBackground: 'new 0 0 441.302 441.302'}}>
<g>
	<path d="M437.396,195.907c-70.233-21.282-143.157-32.072-216.745-32.072c-18.893,0-37.742,0.717-56.514,2.134v-16.144h2.173
		c1.584,0,3.092-0.683,4.136-1.875c1.044-1.191,1.524-2.775,1.316-4.346l-4.333-32.788c-0.229-1.737-1.272-3.26-2.809-4.102
		L138.169,92.21c-1.646-0.903-3.641-0.903-5.289,0l-65.027,34.792c-1.454,0.764-2.489,2.138-2.824,3.746l-5,24
		c-0.381,1.828,0.192,3.725,1.521,5.037c1.33,1.312,3.233,1.86,5.057,1.454l2.307-0.513v18.639
		c-21.881,4.532-43.569,10.045-65.006,16.541c-2.907,0.881-4.55,3.952-3.669,6.859c0.881,2.907,3.954,4.55,6.858,3.669
		c31.527-9.553,63.606-16.953,96.044-22.187L3.899,214.499c-2.906,0.886-4.543,3.959-3.658,6.865
		c0.723,2.371,2.903,3.898,5.259,3.898c0.531,0,1.071-0.078,1.605-0.24l157.035-48.019c6.219-0.477,12.446-0.874,18.681-1.193
		l-126.31,48.869c-2.833,1.096-4.241,4.281-3.145,7.114c0.844,2.181,2.925,3.517,5.131,3.517c0.66,0,1.331-0.12,1.983-0.372
		l155.286-60.079c1.627-0.011,3.255-0.022,4.883-0.022c16.338,0,32.643,0.545,48.892,1.621l-158.127,90.476
		c-2.637,1.508-3.551,4.869-2.042,7.505c1.016,1.775,2.871,2.77,4.779,2.77c0.926,0,1.865-0.234,2.727-0.727L288.993,178
		c13.324,1.237,26.604,2.835,39.827,4.794l-83.243,86.645c-2.104,2.191-2.035,5.672,0.156,7.777c1.067,1.025,2.439,1.534,3.81,1.534
		c1.444,0,2.887-0.566,3.967-1.689l88.56-92.18c14.995,2.502,29.909,5.475,44.727,8.905l-14.383,31.753
		c-1.253,2.767-0.026,6.026,2.74,7.279c0.736,0.333,1.507,0.491,2.266,0.491c2.093,0,4.094-1.201,5.013-3.232l15.257-33.681
		c12.245,3.032,24.422,6.373,36.516,10.038c0.532,0.161,1.068,0.238,1.597,0.238c2.36,0,4.542-1.531,5.262-3.906
		C441.945,199.859,440.303,196.788,437.396,195.907z M138.566,168.338c-2.029,0.224-4.056,0.458-6.083,0.698V152.37h6.083V168.338z
		 M135.525,103.306l21.364,11.714l3.146,23.805h-1.398c-3.038,0-5.5,2.462-5.5,5.5v22.555c-1.191,0.108-2.381,0.221-3.571,0.334
		V146.87c0-3.038-2.462-5.5-5.5-5.5h-17.083c-3.038,0-5.5,2.462-5.5,5.5v23.555c-1.19,0.159-2.381,0.313-3.57,0.478v-26.579
		c0-3.038-2.462-5.5-5.5-5.5h-1.398l3.146-23.805L135.525,103.306z M75.269,135.531l26.96-14.18l-2.482,18.779l-27.212,8.523
		L75.269,135.531z M79.913,157.905l27-8.08v22.692c-9.028,1.387-18.029,2.94-27,4.658V157.905z"/>
	<path d="M308.636,123.191h75.81c2.907,0,5.312-2.262,5.49-5.163l0.011-0.169c0.023-0.341,0.042-0.682,0.042-1.029
		c0-9.021-6.245-16.67-14.737-18.719c-4.455-8.171-13.171-13.469-22.617-13.469c-7.382,0-14.341,3.191-19.171,8.578
		c-2.676-1.115-5.55-1.691-8.508-1.691c-12.29,0-22.288,9.998-22.288,22.288c0,1.684,0.207,3.413,0.615,5.139
		C303.871,121.438,306.086,123.191,308.636,123.191z M324.957,102.529c2.448,0,4.788,0.787,6.766,2.276
		c1.264,0.952,2.877,1.312,4.426,0.99c1.549-0.322,2.885-1.295,3.666-2.672c2.617-4.615,7.53-7.482,12.822-7.482
		c6.045,0,11.569,3.791,13.746,9.433c0.777,2.014,2.661,3.388,4.816,3.511c2.656,0.153,4.962,1.551,6.363,3.604h-63.775
		C314.576,106.734,319.285,102.529,324.957,102.529z"/>
	<path d="M54.848,51.214c0.182,2.898,2.585,5.156,5.489,5.156H165.51c2.549,0,4.765-1.752,5.352-4.233
		c0.528-2.232,0.796-4.467,0.796-6.642c0-15.875-12.915-28.79-28.79-28.79c-4.34,0-8.54,0.961-12.386,2.811
		c-6.318-7.737-15.859-12.366-26.014-12.366c-12.646,0-24.292,7.281-29.952,18.422c-11.31,2.271-19.739,12.262-19.739,24.103
		c0,0.439,0.025,0.87,0.055,1.302L54.848,51.214z M78.595,36.113c2.155-0.124,4.039-1.497,4.816-3.511
		c3.335-8.644,11.797-14.451,21.057-14.451c8.106,0,15.632,4.391,19.641,11.459c0.781,1.376,2.116,2.35,3.666,2.672
		c1.549,0.321,3.162-0.039,4.426-0.991c3.116-2.345,6.805-3.585,10.667-3.585c9.768,0,17.722,7.913,17.79,17.665H66.477
		C68.197,40.237,72.909,36.44,78.595,36.113z"/>
	<path d="M84.61,282.269L2.77,329.097c-2.637,1.508-3.551,4.869-2.042,7.505c1.016,1.775,2.871,2.77,4.779,2.77
		c0.926,0,1.865-0.234,2.727-0.727l81.84-46.827c2.637-1.508,3.551-4.869,2.042-7.505C90.606,281.675,87.245,280.761,84.61,282.269z
		"/>
	<path d="M216.97,299.216L98.352,422.682c-2.104,2.19-2.035,5.672,0.156,7.777c1.067,1.025,2.439,1.534,3.81,1.534
		c1.444,0,2.887-0.566,3.967-1.689l118.618-123.466c2.104-2.191,2.035-5.672-0.156-7.777
		C222.556,296.956,219.075,297.026,216.97,299.216z"/>
	<path d="M371.343,241.233c-2.768-1.253-6.026-0.026-7.279,2.741l-82.621,182.407c-1.253,2.767-0.026,6.026,2.741,7.279
		c0.736,0.333,1.507,0.491,2.266,0.491c2.093,0,4.094-1.202,5.014-3.232l82.621-182.407
		C375.337,245.745,374.11,242.486,371.343,241.233z"/>
	<path d="M5.504,255.812c0.66,0,1.331-0.12,1.983-0.372l31.702-12.265c2.833-1.096,4.241-4.281,3.145-7.114
		c-1.096-2.833-4.282-4.241-7.114-3.145L3.519,245.181c-2.833,1.096-4.241,4.281-3.145,7.114
		C1.217,254.475,3.298,255.812,5.504,255.812z"/>
</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
  )
}
