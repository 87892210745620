import React from 'react';
import moment from 'moment'
import { AiOutlineUser } from 'react-icons/ai'
import './Profile.style.scss'
import Loader from 'component/reusable/Loader'
import { Button } from 'react-bootstrap';
import IconBack from 'component/reusable/icon/Back'
import { formatRole } from 'utils/format'
import { renderCol } from 'component/reusable/form/renderCol'
import { withTranslation } from 'react-i18next'

const renderSectionAvatar = (props) => {
  const { profile, onImageChange, onRemovePhoto, uploadingImage, t } = props

  return (
    <section className="section-upload-photo">
      <legend>{t('Your Photo')}</legend>
      <form action="" encType="multipart/form-data" className="avatar" id="user-avatar-form">
        <div className="PlaceholderAvatar PlaceholderAvatar--xlarge">
          {(profile?.avatar?.xs && !uploadingImage) && <img src={profile.avatar.xs} alt="avatar" className="avatar img-fluid" />}
          {(!profile?.avatar && !uploadingImage) && <AiOutlineUser size={45} />}
          {uploadingImage && <Loader />}
        </div>
        <div>
          {uploadingImage ? <div className="uploading">{t('Uploading...')}</div> : ''}
          {!uploadingImage && !profile?.avatar?.xs &&
            <input type="file" name="filename" className="input-file" data-before={t('Upload your photo')}
              onChange={onImageChange}></input>
          }
          {!uploadingImage && profile?.avatar?.xs &&
            <div className="actions" className={uploadingImage ? 'd-none' : 'actions'}>
              <input type="file" name="filename" className="input-file new" data-before={t('Upload new photo')}
                  onChange={onImageChange}></input>
              <button className="btn" onClick={onRemovePhoto}>{t('Remove Photo')}</button>
            </div>
          }
          <p className="note mb-0">{t('Uploading a photo helps team members recognize you.')}</p>
        </div>
      </form>
    </section>
  )
}


const Profile = (props) => {
  const { showErrorMessage, showUpdateSuccess, showUpdate, onChange, formData, profile, onSubmit,
    onBack, t } = props

  return (
    <div className="tab-profile grid-3">
      {props.loading ? <Loader /> :
        <>
          <div onClick={onBack}><IconBack layout="block" /></div>
          <div className="inner">
            <header>
              <h1>{t('Personal Details')}</h1>
            </header>

            {renderSectionAvatar(props)}

            <form onSubmit={onSubmit}>
              <div className="row">
                {renderCol({
                  inputName: 'firstName', inputLabel: `${t('First Name')}`, onChange, formData,
                  pattern: ".[A-Za-z]{2,50}", title: `${t('Must be an alphabetic string between 2 and 50 characters')}`
                })}

                {renderCol({
                  inputName: 'lastName', inputLabel: `${t('Last Name')}`, onChange, formData,
                  pattern: ".[A-Za-z]{2,50}", title: `${t('Must be an alphabetic string between 2 and 50 characters')}`
                })}

                {renderCol({
                  inputName: 'location', inputLabel: `${t('Location')}`, onChange, formData,
                  pattern: ".{2,50}", title: `${t('Must be between 2 and 50 characters')}`
                })}

                {renderCol({
                  inputName: 'mobile', inputLabel: `${t('Mobile')}`, onChange, formData,
                  pattern: ".{5,20}", title: `${t('Must be between 2 and 50 characters')}`
                })}

                {renderCol({
                  inputName: 'email', inputLabel: 'Email', onChange, formData,
                  type: "email", readOnly: "true"
                })}

              </div>

              <div className="row user-info">
                <div className="col-md-6">
                  <p><strong>{t('Role')}</strong> {formatRole(props?.formData?.role)}</p>
                  <p><strong>{t('Member Since')}</strong>  {moment(props?.formData?.createdAt).format("MMM D, YYYY")}</p>
                </div>
              </div>
              <div className="row action d-flex justify-content-end align-items-center">
                <div className={`btn ${showUpdateSuccess ? "mr-4 text-success d-block" : "d-none"}`}>
                  {t('Profile Updated!')}
            </div>
                <div className={`btn ${showErrorMessage ? "mr-4 text-danger d-block" : "d-none"}`}>
                  {t('Please verify the data')}
            </div>
                <Button className={`btn ${showUpdate ? "btn-success" : "btn-light"}`}
                  type="submit"
                  disabled={!showUpdate}>{t('Save Changes')}
                </Button>
              </div>

            </form>
          </div>
        </>
      }
    </div>
  )
}

export default withTranslation()(Profile);