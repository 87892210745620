import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import api from 'utils/api';

const stripePromise = ({ cartItems, appliedCoupon }) => {
  let couponCode = Object.keys(appliedCoupon) ? appliedCoupon.code : null;

  return api.post('/orders/stripe', {
    cartItems,
    couponCode
  });
  // return  window
  // .fetch(`${process.env.REACT_APP_BASE_URL}/api/orders/stripe`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json"
  //   },
  //   body: JSON.stringify({ cartItems, couponCode })
  // })
}

export default function CheckoutForm({props}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();


  let { submitStripeOrder, setPaymentMethod, cartItems, appliedCoupon } = props
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
      stripePromise({ cartItems, appliedCoupon})
      .then(({data}) => {
        if(data.errorMsg){
          alert(data.msg);
        }else {
          setClientSecret(data.clientSecret);
        }
      });
  }, [appliedCoupon]);


  const cardStyle = {
    style: {
      base: {
        color: "#FFAF0B",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#FFAF0B"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };


  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };


  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setPaymentMethod('stripe')
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      await submitStripeOrder();
      console.log('done')
    }
  };


  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
    </form>
  );
}