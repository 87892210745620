import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Li from "./Li";
import "./List.style.scss";
import Loader from "component/reusable/Loader";
import { Can } from "component/Router/Can";
import { withTranslation } from "react-i18next";
import Sms from "./Sms";

const renderFieldGroups = ({ fieldGroups, activeItem }) => {
  console.log("[renderFieldGroups]", fieldGroups, activeItem);

  return Object.keys(fieldGroups).map((fieldGroup, i) => {
    return (
      <Li
        key={i}
        theKey={i}
        className="list-group"
        fieldGroup={fieldGroups[fieldGroup]}
        activeItem={activeItem}
      />
    );
  });
};

const renderSearch = (props) => {
  const { searchValue, onSearchChange } = props;

  return (
    <>
      <form>
        <label>
          Name:
          <input type="text" value={searchValue} onChange={onSearchChange} />
        </label>
        <input type="submit" value="Submit" />
      </form>
    </>
  );
};

const List = (props) => {
  const { loading, fields, fieldGroups, onNewField, activeItem, t } = props;

  return (
    <section
      className={`company-list grid-2 field-list ${
        activeItem !== null ? "has-active" : ""
      }`}
    >
      <header>
        <h2>{t("Fields")}</h2>
      </header>
      <div className="main">
        {fieldGroups.length ? renderSearch(props) : ""}
        {loading ? <Loader /> : renderFieldGroups({ fieldGroups, activeItem })}
        {!loading && !fields.length ? (
          <div className="grid-empty">{t("No fields yet")}</div>
        ) : (
          ""
        )}
        {/* <Sms /> */}
      </div>
      <Can do="create" a={"Field"}>
        <div className="bottom">
          <Button onClick={onNewField}>{t("Add field")}</Button>
        </div>
      </Can>
    </section>
  );
};

export default withTranslation()(List);
