import React from 'react';
import Loader from 'component/reusable/Loader'
import './Item.style.scss'
import Li from './Li'
import IconBack from 'component/reusable/icon/Back'
import { withTranslation } from 'react-i18next'

const renderOrders = (props) => {
  const { orders, activeItem, onOrder } = props

  if (!orders.length) return
  return orders.map((el, i) => {
    return <Li key={i} theKey={i} className="list-group" order={el} />
    // console.log(el, 'tje e;')
    // return <div className={"list-item " + (activeItem === el.uuid ? 'active' : '')}>
    //   <div className="details">
    //     <h4 className="mb-2">{moment(el.createdAt).format("MMM D, YYYY")}</h4>
    //     <p>{fp(el.finalPrice)}, {el.Reports.length} Reports</p>
    //     <p>
    //       <span className={`status font-weight-bold ${el.status === "pending_payment" ? 'text-warning' : 'text-success'}`}>
    //         {`${el.status === "pending_payment" ? 'Pending' : 'Complete'}`}
    //       </span> / <span>{moment(el.createdAt).format("MMM D, YYYY")}</span>
    //     </p>
    //   </div>
    // </div>
  })

}

const List = (props) => {
  const { loading, orders, onBack, t } = props

  return (
    <section className="company-item grid-3">
      <div onClick={onBack}><IconBack layout="block" /></div>
      <div className="inner">
      <header>
        <h1>{t('Orders')}</h1>
      </header>
      <div className="main cart-page">
        {loading ? <Loader /> : renderOrders(props)}
        {!loading && !orders.length ? <div className="grid-empty">{t('No orders yet')}</div> : ''}
      </div>
      <div className="bottom">
        {/* <Button onClick={onNewCompany}>Add Company</Button> */}
      </div>
      </div>
    </section>
  )
}

export default withTranslation()(List);