import api from '../utils/api';
import {
  LOAD_SHOOT,
  EMPTY_SHOOT
} from './types';
import { setAlert } from 'actions/alert'

const createShoot = (payload) => async dispatch => {
  console.log('[Action] Creating shoot')

  try {
    const res = await api.post('/field/shoot/create', payload);
    if(res.status === 201){
      dispatch(setAlert('Package successfully added to cart.', 'success'))
      return true
    }else {
      return false
    }
  } catch (err) {
    alert('Something went wrong')
  }
};
const updateShoot = (payload) => async dispatch => {
  console.log('[Action] updateShoot')

  try {
    const res = await api.put('/field/shoot/update', payload);
    console.log('res', 'the res', res)
    if(res.status == 201){
      dispatch(setAlert('Report successfully updated.', 'success'))
      return true
    }else {
      return false
    }
  } catch (err) {
    alert('Something went wrong')
  }
};

const fetchShoot = (shootId) => async dispatch => {
  console.log('[Action] Fetching shoot', shootId)

  try {
    dispatch({ type: EMPTY_SHOOT })

    const res = await api.get(`/shoot/${shootId}`);

    dispatch({
      type: LOAD_SHOOT,
      payload: res.data.shoot
    });
  } catch (err) {
    alert('Something went wrong')
  }
};

// TODO: just change name, other route
// const updateShoot = (shootId, payload) => async dispatch => {
//   console.log('[Action] Updating Shoot')

//   try {
//     const res = await api.put(`/shoot/${shootId}`, payload);
//     return true;

//   } catch (err) {
//     alert('Something went wrong')
//     return false;
//   }
// };



export {
  createShoot,
  fetchShoot,
  updateShoot,
}