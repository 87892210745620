import React from 'react';

import './Fields.style.scss'

import List from 'component/Field/List'
import Item from 'component/Field/Item'


const Fields = (props) => {

  return (
    <>
      <List />
      <Item />
    </>
  );
}

export default Fields;