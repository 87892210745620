import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import api from "utils/api";
import SatelliteCalendarGraphs from "./SatelliteCalendarGraphs.component";

const SatelliteCalendarGraphsContainer = (props) => {
  const { satelliteGraphData } = props;

  const state = {
    satelliteGraphData,
  };

  const containerFunctions = {};

  return <SatelliteCalendarGraphs {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({
  satelliteGraphData: state.field.satelliteGraphData,
});

export default connect(mapStateToProps, {})(SatelliteCalendarGraphsContainer);
