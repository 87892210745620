import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const DeleteModal = (props) => {
  const { show, onSubmitDelete, onClose, t } = props

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Are you sure?')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="delete-modal-body">{t("You 're about to delete an item from the cart. This cannot be reverted.")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={onSubmitDelete}>
            {t('Delete Item')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withTranslation()(DeleteModal);