import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import PrivateRoute from "component/routing/PrivateRoute";
import AnonymousRoute from "component/routing/AnonymousRoute";
import "./TheContent.style.scss";
import { connect } from "react-redux";

import SignUp from "route/SignUp";
import Login from "route/Login";
import About from "route/About";
import Dashboard from "route/Dashboard";
import PasswordReset from "route/PasswordReset";
import VerifyEmail from "route/VerifyEmail";
import NewPassword from "route/NewPassword";
import Fields from "route/Fields";
import Users from "route/Users";
import Shoots from "route/Shoots";
import Companies from "route/Companies";
import Cart from "route/Cart";
import TheProfileLayout from "component/profile/TheLayout";
import Orders from "route/Orders";
import Page404 from "route/Page404";
import Terms from "route/Terms";
import PrivacyPolicy from "route/PrivacyPolicy";
import PaymentMethods from "route/PaymentMethods";

const routes = [
  {
    path: "/",
    name: "Home",
    // Component: Dashboard,
    visibility: "dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    Component: Dashboard,
    visibility: "private",
  },
  {
    path: "/signup",
    name: "SignUp",
    Component: SignUp,
    visibility: "anonymous",
  },
  { path: "/login", name: "Login", Component: Login, visibility: "anonymous" },
  { path: "/about", name: "About", Component: About, visibility: "private" },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    Component: PasswordReset,
    visibility: "anonymous",
  },
  {
    path: "/password_reset/:password_reset_token",
    name: "NewPassword",
    Component: NewPassword,
    visibility: "anonymous",
  },
  {
    path: "/verify_email/:verify_email_token",
    name: "VerifyEmail",
    Component: VerifyEmail,
    visibility: "anonymous",
  },
  { path: "/fields", name: "Fields", Component: Fields, visibility: "private" },
  { path: "/users", name: "Users", Component: Users, visibility: "private" },
  { path: "/shoots", name: "Shoots", Component: Shoots, visibility: "private" },
  {
    path: "/Companies",
    name: "Companies",
    Component: Companies,
    visibility: "private",
  },
  { path: "/cart", name: "Cart", Component: Cart, visibility: "private" },
  { path: "/orders", name: "Orders", Component: Orders, visibility: "private" },
];

const TheContent = ({ location, match, user }) => {
  return (
    <Switch>
      {routes.map((route, idx) => {
        return (
          <Route
            key={route.name}
            path={route.path}
            exact={true}
            name={route.name}
            render={(props) => (
              <section className={`route-section ${route.name}`}>
                {(() => {
                  switch (route.visibility) {
                    case "dashboard":
                      if (user?.role) {
                        switch (user.role) {
                          case "admin":
                          // return <Redirect to="/dashboard" />;
                          case "operation_admin":
                          case "b2b_admin":
                          case "b2c_admin":
                          case "b2c_user":
                          case "b2b_user":
                            return <Redirect to="/fields" />;
                          case "drone_admin":
                          case "drone_operator":
                            return <Redirect to="/shoots" />;
                        }
                      } else {
                        return <Redirect to="/login" />;
                      }
                    case "private":
                      if (route.name === "Dashboard") {
                        if (user.role !== "admin") {
                          return <Redirect to="/" />;
                        }
                      }
                      return <PrivateRoute component={route.Component} />;
                    case "anonymous":
                      return <AnonymousRoute component={route.Component} />;
                    default:
                      return <route.component {...props} />;
                  }
                })()}
              </section>
            )}
          />
        );
      })}
      <Route
        component={Terms}
        path="/terms-of-service"
        exact={true}
        name="Terms of Service"
      />
      <Route
        component={PrivacyPolicy}
        path="/privacy-policy"
        exact={true}
        name="Privacy Policy"
      />
      <Route
        component={PaymentMethods}
        path="/payment-methods"
        exact={true}
        name="Payment Methods"
      />
      {user ? <TheProfileLayout /> : <Redirect to="/login" />}
      {/* <PrivateRoute path="/profile" name="Profile" render={props => <TheProfileLayout {...props} />} /> */}
      <Route component={Page404} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(TheContent));

// export default withRouter(TheContent)
