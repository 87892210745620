import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { validateEmail } from 'utils/validate'
import { loginUser } from 'actions/auth';
import { showFullLoader } from 'actions/ui'

import Login from './Login.component';


const LoginContainer = (props) => {
  const { showFullLoader, isAuthenticated, loginUser } = props

  const emailField = useRef(null)
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [btn, setBtn] = useState({
    type: 'btn-secondary',
    enabled: false
  });

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if(validateEmail(formData.email)){
      setBtn({...btn, type: 'btn-success', enabled: true});
    }else {
      setBtn({...btn, type: 'btn-secondary', enabled: false});
    }
  }, [formData]);


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    showFullLoader(true)
    console.log(formData,'submitting')
    try {
      await loginUser(formData);
    } catch (err) {
      setLoading(false)
    }
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const state = {
    formData,
    btn,
    emailField,
    loading
  };

  const containerFunctions = {
    onChange,
    onSubmit,
  };

  return (
      <Login
        // { ...this.props }
        { ...state }
        { ...containerFunctions }
      />
  );

}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
// export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, { 
  loginUser,
  showFullLoader,
})(LoginContainer);