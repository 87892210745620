import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  MAINTANCE,
} from 'actions/types';

const initialState = {
  isAuthenticated: null,
  loading: true,
  user: null,
  permissions: null
};


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false
      };
    case USER_LOADED:
      console.log('[reducer] user loaded')
      return {
        ...state,
        loading: false,
        user: payload.user,
        permissions: payload.permissions
      };
    case LOGOUT:
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        permissions: null
      };
    case MAINTANCE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}