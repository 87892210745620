import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import api from 'utils/api';
import { validateEmail } from 'utils/validate'
import { sendPasswordResetRequest } from 'actions/auth';

import PasswordReset from './PasswordReset.component';



const PasswordResetContainer = ({ sendPasswordResetRequest }) => {

  const [formData, setFormData] = useState({
    email: ''
  });
  const [btn, setBtn] = useState({
    type: 'btn-secondary',
    enabled: false
  });
  const [successRequest, setSuccessRequest] = useState(false)


  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if(validateEmail(formData.email)){
      setBtn({...btn, type: 'btn-success', enabled: true});
    }else {
      setBtn({...btn, type: 'btn-secondary', enabled: false});
    }
  }, [formData.email]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let success = await sendPasswordResetRequest(formData.email)

    if(success){
      setSuccessRequest(true);
    }
  }

  const state = {
    formData,
    btn,
    successRequest
  };

  const containerFunctions = {
    onChange,
    onSubmit
  };

  return (
    <PasswordReset
      { ...state }
      { ...containerFunctions }
    />
  )
}

export default connect(null, { sendPasswordResetRequest })(PasswordResetContainer);