import React from 'react';
import { CgOrganisation } from 'react-icons/cg';
import moment from 'moment'
import './UserCompany.style.scss'
import Loader from 'component/reusable/Loader'
import { renderAvatar } from 'component/reusable/User'
import InvoiceForm from './Component/InvoiceForm'
import IconBack from 'component/reusable/icon/Back'
import { withTranslation } from 'react-i18next' 

const renderUsers = (company) => {

  const { Users } = company
  if (company.Users && company.Users.length > 1) {
    return (
      <div className="users-wrap">
        <div className="row">
          {Users.map(user => {
            console.log(user, 'the suer')
            return (
              <div className="col-sm-4">
                <div className="user">
                  <div>
                    {renderAvatar(user, { size: 'xs', icon: 'user' })}
                  </div>
                  <div className="right">
                    <h4>{user.firstName}<br />{user.lastName}</h4>
                    <p className="email">{user.email}</p>
                    <p className="role">{user.role}</p>
                  </div>

                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  } else {
    return ''
  }
}

const renderSectionAvatar = (props) => {
  const { company, onImageChange, onRemovePhoto, uploadingImage, t } = props

  return (
    <>
      <legend>{t('Company Photo')}</legend>
      <form action="" encType="multipart/form-data" className="avatar" id="company-avatar-form">
        <div className="PlaceholderAvatar PlaceholderAvatar--xlarge">
          {(company?.avatar?.xs && !uploadingImage) && <img src={company.avatar.xs} alt="avatar" className="avatar img-fluid" />}
          {(!company?.avatar && !uploadingImage) && <CgOrganisation size={45} />}
          {uploadingImage && <Loader />}
        </div>
        <div>
          {uploadingImage ? <div className="uploading">{t('Uploading...')}</div> : ''}
          {!uploadingImage && !company?.avatar?.xs &&
            <input type="file" name="filename" className="input-file" data-before={t('Upload your photo')}
                onChange={onImageChange}></input>
          }
          {!uploadingImage && company?.avatar?.xs &&
            <div className="actions" className={uploadingImage ? 'd-none' : 'actions'}>
              <input type="file" name="filename" className="input-file new" data-before={t('Upload new photo')}
                  onChange={onImageChange}></input>
              <button className="btn" onClick={onRemovePhoto}>{t('Remove Photo')}</button>
            </div>
          }
          <p className="note mb-0">{t('Uploading a photo helps team members recognize your company.')}</p>
        </div>
      </form>
    </>
  )
}

const renderCompany = (props) => {
  const { company, t } = props
  return (
    <>
      <div className="section-upload-photo">
        {renderSectionAvatar(props)}
      </div>
      <main className="mt-0 mt-md-5">
        {renderUsers(company) }
        <InvoiceForm props={props} addBEAddress={true} />
        <div className="mt-5">
          <strong>{t('Created At')}</strong> <p>{moment(company.createdAt).format("MMM D, YYYY")}</p>
        </div>
      </main>
    </>
  )
}


const UserCompany = (props) => {
  const { company, loading, match, onBack, t } = props
  return (
    <>
      <section className="company-item grid-3">
        <div onClick={onBack}><IconBack layout="block" /></div>
        <div className="inner">
          <header>
            <h1>{t('Company Info')}</h1>
          </header>
          {!loading && company  && renderCompany(props)}
        </div>
      </section>
    </>
  )
}

export default withTranslation()(UserCompany);