import React from 'react'
import { connect } from 'react-redux'
import {
  TheContent,
  TheSidebar,
} from './index'

import Alert from 'component/Alert';

import './TheLayout.style.scss'
import Notifications from 'component/Notification/List'
import FullScreenLoader from 'component/reusable/FullScreenLoader'

const NewField = React.lazy(() => import('component/Field/NewField'));
const NewCompany = React.lazy(() => import('component/Company/NewCompany'));
const NewReport = React.lazy(() => import('component/Field/NewReport'));
const ScheduleShoot = React.lazy(() => import('component/Field/ScheduleShoot'));
const Directions = React.lazy(() => import('component/Field/MapboxDirections'));
const NewUser = React.lazy(() => import('component/User/NewUser'));
const UploadData = React.lazy(() => import('component/Field/Item/component/UploadData'));


const UserLayout = ({ props }) => {
  const { showNotifications, showNewCompany, showNewUser, showScheduleShoot, cancel, 
    showFullLoader, showUploadData, showDirections } = props

  return (
    <div className="c-wrapper d-flex">
      <nav>
        <TheSidebar  />
      </nav>
      <div className="content d-flex flex-column flex-grow-1">
        <main className="container-fluid-1">
          <Alert />
          <NewField />
          { showNewUser && <NewUser /> }
          <NewReport />
          { showNewCompany && <NewCompany /> }
          { showNotifications && <Notifications /> }
          { showScheduleShoot && <ScheduleShoot /> }
          { showUploadData.value ? <UploadData /> : '' }
          { showDirections.value ? <Directions /> : '' }
          <TheContent />
        </main>
      </div>
      { showFullLoader ? <FullScreenLoader /> : ''}
    </div>
  )
}

const AnonymousLayout = () => {
  return (
    <div>
      <Alert />
      <TheContent />
    </div>
  )
}


const TheLayout = (props) => {
  const { user } = props

  return (
    <div className="c-app c-default-layout">
      { !user && <AnonymousLayout />}
      { user && <UserLayout props={props} />}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  showNotifications: state.ui.showNotifications,
  showNewCompany: state.ui.showNewCompany,
  showNewUser: state.ui.showNewUser,
  showScheduleShoot: state.ui.showScheduleShoot,
  showDirections: state.ui.showDirections,
  showUploadProgress: state.ui.showUploadProgress.value,
  showUploadData: state.ui.showUploadData,
  showFullLoader: state.ui.showFullLoader,
});

export default connect(mapStateToProps)(TheLayout);
