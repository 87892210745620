import React from 'react';
import { Button } from 'react-bootstrap'
import { AiOutlineUser } from 'react-icons/ai'
import './List.style.scss'
import Loader from 'component/reusable/Loader'
import { BiSearch } from 'react-icons/bi'
import { formatRole } from 'utils/format'
import { renderAvatar } from 'component/reusable/User'
import { withTranslation } from 'react-i18next'


const renderUsers = (props) => {
  const { filteredUsers, onUser, activeItem, t } = props

  return filteredUsers.map((el, i) => {
    return <div onClick={onUser(el.id)} className={"list-item " + (activeItem === el.id ? 'active' : '')}>
      { renderAvatar(el, { size: 'xs', icon: 'user' }) }
      <div className="details">
        <h4 className="mb-0">{el.firstName} {el.lastName}</h4>
        <p className="mb-0">{el.email}</p>
        <p className="mb-0">{formatRole(el.role)}</p>
      </div>
    </div>
  })
}

const renderSearch = (props) => {
  const { onSearchChange, searchValue, t } = props

  return (
    <div className="search-group">
      <BiSearch size={24} />
      <input type="text" name="search" id="search"
        className="form-control input-search"
        onChange={onSearchChange}
        value={searchValue}
      placeholder={`${t('Search Users')}`}
    />
    </div>
  )
}


const List = (props) => {
  const { loading, onNewUser, activeItem, t } = props

  return (
    <section className={`grid-2 user-list ${activeItem !== null ? 'has-active' : ''}`}>
      <header>
        <h2>{t('Users')}</h2>
        { renderSearch(props) }
      </header>
      <div className="main">
        { loading ? <Loader />  : renderUsers(props)}
      </div>
     <div className="bottom">
      <Button onClick={onNewUser}>{t('Add User')}</Button>
     </div>
    </section>
  )
}

export default withTranslation()(List);