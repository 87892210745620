import api from 'utils/api';
import { useContext } from 'react';
import {
  LOAD_SHOOTS,
  EMPTY_SHOOTS,
  EMPTY_DRONE_ADMINS,
  SET_ACTIVE_SHOOT_ITEM,
  LOAD_DRONE_ADMINS,
  LOAD_DRONE_OPERATORS,
  EMPTY_DRONE_OPERATORS,
} from 'actions/types';


// opts = {keepActiveΙtem: true, role: userRole } || {}
const fetchShoots = ({role, activeShootId, keepActiveItem} = {}) => async dispatch => {
  console.log('[Action] Fetching shoot')

  //TODO: check if drone admin
  // try {
    dispatch({
      type: EMPTY_SHOOTS,
      payload: {
        keepActiveItem
       }
    });

    const res = await api.get('/shoots');
    console.log('data', res)
    dispatch({
      type: LOAD_SHOOTS,
      // payload: res.data.shoots,
      payload: {
        list: res.data.shoots,
        keepActiveItem,
        // activeItemId: activeShootId
      }
    });

    let canUpdate = ['admin', 'drone_admin'];
    if(canUpdate.includes(role)){
      dispatch({ type: EMPTY_DRONE_ADMINS });
      dispatch({ type: EMPTY_DRONE_OPERATORS });
      const droneAdmins = await api.post('/users/find', { role: 'drone_admin' })
      const companyUsers = await api.get('/users')

      dispatch({
        type: LOAD_DRONE_ADMINS,
        payload: droneAdmins.data.users
      });
      dispatch({
        type: LOAD_DRONE_OPERATORS,
        payload: companyUsers.data.users
      });
    }

  // } catch (err) {
  //   let a = err;
  //   alert('Something went wrong', JSON.stringify(err))
  // }
};

const setActiveItem = (val) => async dispatch => {
  try {
    dispatch({
      type: SET_ACTIVE_SHOOT_ITEM,
      payload: val
    });
  } catch (err) {
    alert(err)
  }
}

export {
  fetchShoots,
  setActiveItem,
}