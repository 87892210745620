import React from 'react';
import './Item.style.scss'
import {  IoMdRefreshCircle, IoMdArrowDropright } from 'react-icons/io'
import { ImCreditCard } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { renderReportCta } from 'component/Field/Item/component/utils/reports';
import { TiTick } from 'react-icons/ti'
import { ImUpload2 } from 'react-icons/im'
import { RiCalendarTodoLine } from 'react-icons/ri'
import { VscServerProcess } from 'react-icons/vsc'
import { formatStatus } from 'component/Shoot/utils/formatStatus'
import { withTranslation } from 'react-i18next'

const getMappingKey = ({status, service}) => {
  return (status === 'processing') ? `${status}_${service}` : status;
}

const statusIconMapping = {
  'pending': <ImCreditCard />,
  'pending_payment': <IoMdRefreshCircle />,
  'processing_e2e': <RiCalendarTodoLine />,
  'processing_manual': <ImUpload2 />,
  'processing_pending': <IoMdRefreshCircle />,
  'processing_data': <VscServerProcess />,
  'complete': <TiTick />,
}

const renderIcon = ({status, service}) => {
  return statusIconMapping[getMappingKey({status, service})];
}
const descriptionMapping = {
  'pending': 'This means that your report is waiting in the cart. Place your order to continue.',
  'pending_payment': 'Means that you placed an order and we are about to verify your purchase.',
  'processing_e2e': 'You can schedule a photo shoot by picking a suitable date range for drone coverage. As soon as we confirm the dates, a team of experts will arrive on the scheduled date for the photo shoot of your field.',
  'processing_manual': 'You can immediately upload the zip folder containing all images to be processed.',
  'processing_pending': 'The shoot is scheduled or the shoot process is taking place.',
  'processing_data': 'Data has been uploaded to this report and our Artificial Intelligence system is processing the dataset.',
  'complete': 'AI processing is over and you can check the results.'

}

const renderDescription = ({ status, service, t}) => {
  return t(descriptionMapping[getMappingKey({status, service})]);
}

const renderCta = (props) => {
  const { propsProps, t, report } = props

  const { status } = report

  console.log(propsProps, 'the propsProps', status)

  let rightArrow = <IoMdArrowDropright className="ml-1"/>;

  switch(status) {
    case 'pending':
      return <Link to ="/cart">{t('My cart')}{rightArrow}</Link>
    case 'pending_payment':
      return <Link to ="/profile/orders">{t('My orders')}{rightArrow}</Link>
    case 'processing':
      return renderReportCta(propsProps, 'text')
    default:
      // code block
  }
}

const Item = (props) => {
  const { report, showCta, t } = props

  const { status, service } = report

  return (
    <section className={`report-overview ${showCta ? '': 'full-left'}`}>
      <div className="left">
        <header>
          <div className="icon-wrap">{renderIcon({status, service})}</div>
          <h4 className="status">{formatStatus(report, t)}</h4>
        </header>
        <div className="main">
          <p>{renderDescription({ status, service, t })}</p>
        </div>
      </div>
      { showCta ?
      <div className="right">
        <div className="cta-wrap">{renderCta(props)}</div>
      </div>
      : '' }
    </section>
  )
}

export default withTranslation()(Item);