import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Charts from "./Charts.component";

const ChartsContainer = ({ fields, shoots, users }) => {
  const [dateStart, setDateStart] = useState("2021-03-01");
  const [dateEnd, setDateEnd] = useState("2021-09-30");

  useEffect(() => {
    console.log(fields, "charts container");
    return () => {
      // cleanup;
    };
  }, [fields]);

  const state = { fields, shoots, users, dateStart, dateEnd };

  const containerFunctions = {
    setDateStart,
    setDateEnd,
  };

  return <Charts {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ChartsContainer);
