import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  ReactPictureAnnotation,
  defaultShapeStyle,
  DefaultInputSection,
} from "react-picture-annotation";
import { __i18n } from "i18n";
import "./Popup.style.scss";
import { transformDbData } from "./Popup.utils";

const getThermalImageSize = (theReport) => {
  let { size = {} } = theReport;
  let { thr = {} } = size;
  let { w, h, x, y } = thr;

  return {
    w,
    h,
  };
};

const renderAnnotation = (props, point) => {
  const { showDirectionsAction, field, selectedReport } = props;

  if (!point || !point.bboxes) return null;

  let theReport = field.Reports.find((el) => el.uuid === selectedReport);
  let { camera = 1, size = {} } = theReport;
  const { file } = point;

  const onChange = (data) => {
    console.log(data, "change");
  };
  const onSelect = (bboxId) => {
    if (!bboxId || !point) return null;
    // if(!point) return null;

    const { bboxes, file } = point;
    console.log(bboxes);
    // let bboxId = Object.keys(bboxes)[0];

    const { lng, lat } = bboxes[bboxId];

    let navLng = lng;
    let navLat = lat;
    if (!lng || !lat) {
      navLng = point.lng;
      navLat = point.lat;
    }

    // ### FARMAIR: update file, to be `${file}-hd.png`
    console.log(bboxId, "select", point, props, theReport);
    showDirectionsAction({
      value: true,
      lng: navLng,
      lat: navLat,
      file: file,
      reportUuid: selectedReport,
      bboxId: bboxId,
      // w: theReport?.size?.hd?.w,
      // h: theReport?.size?.hd?.h,
    });
  };

  let { w, h } = getThermalImageSize(theReport);
  // to swsto
  let imgUrl = `${process.env.REACT_APP_S3}reports/${selectedReport}/${file}`;
  // let imgUrl = `https://d31brp2px8evwo.cloudfront.net/reports/40WNWX0KEG9CCVWS/c6965b0b-3a6f-4023-8f41-a310c0a0b51b.png`;

  if (process.env?.NODE_ENV == "development") {
    let { w, h } = size?.thr || {};
    imgUrl = `http://via.placeholder.com/${w}x${h}`;
  }

  const annoData = transformDbData(point, camera);
  console.log("annoData", annoData);

  if (w > window.innerWidth) {
    let ratio = w / h;
    w = window.innerWidth - 30;
    h = w / ratio;
  }

  console.log(w, h, "---COMPUTED");

  return (
    <ReactPictureAnnotation
      image={imgUrl}
      onSelect={onSelect}
      onChange={onChange}
      width={w}
      height={h}
      annotationData={annoData}
      annotationStyle={{
        ...defaultShapeStyle,
        shapeStrokeStyle: "#ffaf0b",
        transformerBackground: "black",
        transformerSize: "0",
        padding: 2,
        lineWidth: 3,
        fontBackground: "rgba(255, 255, 255, .5)",
      }}
      inputElement={(value, onChange, onDelete) => (
        <DefaultInputSection {...{ value, onChange, onDelete }} />
      )}
    />
  );
};

const navigateToSinglePoint = ({ props, point, selectedReport, theReport }) => {
  // if Parrot cam we don't have coors for the bbox, nav to image coords
  const { lng, lat, bboxes, file } = point;
  const { showDirectionsAction } = props;

  console.log("navigate to", bboxes);

  if (!Object.keys(bboxes).length) return null;

  // ### FARMAIR: update file, to be `${file}-hd.png`
  showDirectionsAction({
    value: true,
    lng,
    lat,
    file: file,
    reportUuid: selectedReport,
    bboxId: Object.keys(bboxes)[0],
    // w: theReport?.size?.hd?.w,
    // h: theReport?.size?.hd?.h,
  });
};

const renderAction = (props, point = null) => {
  const { selectedReport, t, field } = props;
  if (!point || !point.bboxes || !field) return null;

  let theReport = field.Reports.find((el) => el.uuid === selectedReport);

  const { camera } = theReport;
  const { bboxes } = point;

  return (
    <div className="popup-action-wrap">
      {camera == 2 ? ( //2 ?
        <p>{__i18n("Click on each Box to Navigate")}</p>
      ) : (
        <button
          className="btn btn-primary c-btn-lg w-100"
          onClick={() =>
            navigateToSinglePoint({ props, point, selectedReport, theReport })
          }
        >
          {__i18n("Navigate to Image")}
        </button>
      )}
      <p className="pseudocolor">
        {__i18n(
          "Pseudocoloring overlapping the plant(s), even if outside the boxes, also needs your attention."
        )}
      </p>
    </div>
  );
};

const Popup = ({ props, point, setHdPhotoVisibility, hdPhotoVisibility }) => {
  return (
    <div className="map-popup-wrap">
      <section className="section-map-popup">
        <button
          className="btn btn-light hd-photo"
          onClick={() => setHdPhotoVisibility(!hdPhotoVisibility)}
        >
          HD PHOTO
        </button>
        <div
          className="img-wrap"
          onMouseDown={() => {
            if (window.innerWidth > 1024) {
              document.body.classList.add("selected");
            }
          }}
          onMouseUp={(e) => {
            if (window.innerWidth > 1024) {
              document.body.classList.remove("selected");
            }
          }}
        >
          {renderAnnotation(props, point)}
        </div>
        {renderAction(props, point)}
      </section>
    </div>
  );
};

export default withTranslation()(Popup);
