import api from 'utils/api';
import axios from 'axios';
import { updatePassword } from './security'
import {
  LOAD_PROFILE,
  EMPTY_PROFILE,
  SET_PROFILE_ACTIVE_NAV_ITEM,
} from 'actions/types';


const fetchProfile = (payload) => async dispatch => {
  console.log('[Action] Fetching profile')

  try {
    dispatch({
      type: EMPTY_PROFILE
    });
    const res = await api.post('/user/find', payload);
    dispatch({
      type: LOAD_PROFILE,
      payload: res.data.user
    });
  } catch (err) {

  }
};

const removeAvatar = () => async dispatch => {
  try {

    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      },
      withCredentials: true 
  };
    const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/update/avatar`, { avatar: null },config)
    return true

  } catch (err) {
    return false
    alert(err)
  }
}


const uploadImage = (img) => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('avatar',img);
    // const res = await api.post('/user/avatar/upload', payload);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        },
        withCredentials: true 
    };

    console.log(formData, 'the payload')
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/avatar/upload`, formData,config)

    console.log(res, 'avatar res')
  } catch(err) {
    console.log(err);
  }
}

const setActiveItem = (val) => dispatch => {
  console.log('[ACTION] setActiveItem')
  dispatch({
    type: SET_PROFILE_ACTIVE_NAV_ITEM,
    payload: {
      value: val,
    }
  });
}


export {
  fetchProfile,
  uploadImage,
  removeAvatar,
  updatePassword,
  setActiveItem,
}