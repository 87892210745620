const getUsedForString = (val) => {

  switch(val) {
    case 1:
      return 'Vinification';
    case 2:
      return 'Table Grape';
    case 3:
      return 'Raisin Grape';
    default:
      return 'Other';
  }
}

const getUsedForValue = (usedFor) => {
  switch(usedFor) {
    case 'Vinification':
      return 1;
    case 'Table Grape':
      return 2;
    case 'Raisin Grape':
      return 3;
    default:
      return 1;
  }
}

export {
  getUsedForString,
  getUsedForValue
}