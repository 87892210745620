import {
  LOAD_FIELDS,
  EMPTY_FIELDS,
  SET_ACTIVE_FIELD_ITEM,
  UPDATE_BBOX,
  SET_MAPBOX_TILE,
} from "actions/types";
import { __i18n } from "i18n";

const initialState = {
  list: [],
  shootData: [],
  activeItem: null,
  mapboxTile: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FIELDS:
      // console.log('the payload reducer', payload)
      // let activeItem
      // if(state.activeItem && payload.keepActiveItem){
      //   activeItem = state.activeItem;
      // }else {
      //   if(payload?.data?.length){
      //     activeItem = 0
      //   }
      // }
      let activeItem;
      const { activeItemId = null, keepActiveItem } = payload;

      console.log(payload, "[REDUX]");
      console.log(activeItemId, "reduc", payload.list[0], "the 0");
      if (payload.list.length) {
        if (keepActiveItem) {
          activeItem = state.activeItem;
        } else {
          if (activeItemId) {
            activeItem = activeItemId === -1 ? null : activeItemId;
          } else {
            console.log("else");
            activeItem = payload.list[0].id;
          }
        }
      }

      return {
        ...state,
        list: [...state.list, ...payload.list],
        shootData: [...state.shootData, ...payload.shootData],
        activeItem,
      };
    case EMPTY_FIELDS:
      let extra = {};
      if (!payload.keepActiveItem) {
        extra.activeItem = null;
      }
      return {
        ...state,
        list: [],
        shootData: [],
        ...extra,
      };
    case SET_ACTIVE_FIELD_ITEM:
      return {
        ...state,
        activeItem: payload,
      };
    case UPDATE_BBOX:
      let newList = updateBbox({ payload, state });
      return {
        ...state,
        list: [...newList],
      };
    case SET_MAPBOX_TILE:
      return {
        ...state,
        mapboxTile: payload,
      };
    default:
      return state;
  }
}

const updateBbox = ({ payload, state }) => {
  let list = state.list;

  let acFieldIndex = list.findIndex((el) => el.id === state.activeItem);
  let theReportIndex = list[acFieldIndex].Reports.findIndex(
    (el) => el.uuid === payload.reportUuid
  );
  let outputDataIndex = list[acFieldIndex].Reports[
    theReportIndex
  ].outputData.findIndex((el) => {
    return el.file === payload.file;
  });

  list[acFieldIndex].Reports[theReportIndex].outputData[outputDataIndex].bboxes[
    payload.bboxId
  ].comment = __i18n(payload.comment);

  return list;
};
