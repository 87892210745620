import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Calendar from './Calendar.component';

const CalendarContainer = ({props}) => {
  const { shoots } = props

  const state = {
    shoots
  };

  const containerFunctions = {

  };

  return (
    <Calendar
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CalendarContainer);