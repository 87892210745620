import api from 'utils/api';
import { setAlert } from 'actions/alert'
import {
  LOAD_USERS,
  EMPTY_USERS,
  SHOW_NEW_USER,
} from 'actions/types';


const createUserAddress = (payload) => async dispatch => {
  console.log('[Action] Create user address')

  try {
    const res = await api.post('/user/address', payload);
    if(res.status === 201){
      dispatch(setAlert('New Address successfully added.', 'success'))
      return true;
    }else {
      return false
    }


    // dispatch({ type: SHOW_NEW_USER, payload: { value: false } });
  } catch (err) {
    alert(err);
    return false
  }
};
const updateUserAddress = (payload) => async dispatch => {
  console.log('[Action] Update user address')

  try {
    const res = await api.post('/user/update/address', payload);
    if(res.status === 201){
      dispatch(setAlert('Address successfully updated.', 'success'))
      return true;
    }else {
      alert('Something Went Wrong.')
    }


    // dispatch({ type: SHOW_NEW_USER, payload: { value: false } });
  } catch (err) {
    alert(err);
    return false
  }
};

const deleteUserAddress = (payload) => async dispatch => {
  console.log('[Action] Delete user address', payload)

  try {
    const res = await api.put('/user/delete/address', payload);
    if(res.status === 200){
      dispatch(setAlert('Address successfully deleted.', 'success'))
      return true;
    }else {
      alert('Something Went Wrong.')
    }
  } catch (err) {
    alert(err);
    return false
  }
};


export {
  createUserAddress,
  updateUserAddress,
  deleteUserAddress,
}