import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import api from "utils/api";
import { connect } from "react-redux";
import { getUnixDate } from "utils/convert";
import AgroSoilData from "./AgroSoilData.component";

const AgroSoilDataContainer = (props) => {
  const { activeItem, fields, report } = props;
  const [field, setField] = useState(null);
  const [soilData, setSoilData] = useState({});

  useEffect(() => {
    let acField = fields.find((el) => el?.id === activeItem);
    setField(acField);
    console.log(acField, "AGRO SOIL DATA", report);

    async function fetchAgroSoilData() {
      try {
        const { data: soilData } = await api.get(
          `/agro/getSoilData?agroId=${acField.agroId}&dateStart=${getUnixDate(
            new Date()
          )}`
        );
        const { agroSoilData } = soilData;
        console.log(agroSoilData, "agrosoildata response");
        if (agroSoilData) {
          setSoilData(agroSoilData);
        } else {
          setSoilData({});
        }
      } catch (err) {
        console.log("AgroSoilData", err);
        setSoilData({});
      }
    }

    // console.log(soilData, "SOIL DATA");
    // return;
    console.log(report, "[agrosoildata] ther report", acField);

    if (report && report.soilData) {
      setSoilData(report.soilData);
      console.log(report.soilData, "[agrosoildata] report soil data");
    } else if (acField && acField.agroId) {
      fetchAgroSoilData();
      console.log(acField, "[agrosoildata]  acfield");
    } else {
      console.log("[agrosoildata]  no soil data");
      setSoilData({});
    }

    return () => {
      // setField(null);
      // setSoilData({});
    };
  }, [
    report,
    activeItem,
    // fields,
    // soilData,
  ]);

  const state = {
    field,
    soilData,
  };

  const containerFunctions = {};

  return <AgroSoilData {...state} {...containerFunctions} />;
};

const mapStateToProps = (state) => ({
  activeItem: state.fields.activeItem,
  fields: state.fields.list,
});

export default connect(mapStateToProps, {})(AgroSoilDataContainer);
