import React from 'react';
import './List.style.scss'
import { HiOutlineArrowCircleLeft } from 'react-icons/hi'
import Leaf from 'component/reusable/icon/Leaf'
import DeleteModal from '../Component/DeleteModal'
import OrderPlacement from '../Component/OrderPlacement'
import Cart from '../Component/Cart'
import PaymentMethods from '../Component/PaymentMethods'
import BillingInfo from '../Component/BillingInfo'
import Loader from 'component/reusable/Loader'
import { withTranslation } from 'react-i18next'

const renderStep = (props) => {
  const { cartStep } = props
  switch(cartStep) {
    case 0:
      return <Cart props={props} />
    case 1:
      return <BillingInfo props={props} />
    case 2:
      return <PaymentMethods props={props} />
    case 3:
      return <OrderPlacement props={props} />
    default:
  }
}

const renderTitle = (cartStep, t) => {
  switch(cartStep) {
    case 0:
      return t('Cart')
    case 1:
      return t('Billing Info')
    case 2:
      return t('Payment Method')
    default:
  }
}

const List = (props) => {
  const { loading, showDeleteModal, onSubmitDelete, onClose,
    setCartStepFn, cartStep, t } = props

  return (
    <section className="container-sm cart-page">
      { cartStep <3 ?
          <header className="header-wrap">
            { cartStep > 0 ?
            <button className="btn btn-light btn-back" onClick={setCartStepFn((cartStep-1))}>
              <HiOutlineArrowCircleLeft size={24} strokeWidth={1} /> 
            </button>
            : ''}
            <h2>{renderTitle(cartStep, t)}</h2>
          </header> : ''
      }
      {loading ?  <Loader /> : renderStep(props)}

     <DeleteModal show={showDeleteModal} onClose={onClose} onSubmitDelete={onSubmitDelete} />

     <div className="leaf-wrap">
        <Leaf />
      </div>
    </section>
  )
}

export default withTranslation()(List);


