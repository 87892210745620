import React from 'react';
import Leaf from 'component/reusable/icon/Leaf'
import GreenTick from 'component/reusable/icon/GreenTick'
import './Item.style.scss'


const Item = (props) => {

  return (
    <div className="benefits-wrap">
      <div className="benefits">
        <h2>Early Detection of Plant Stress</h2>
        <ul className="benefits">
          <li>
            <div className="icon-wrap"><GreenTick /></div>
            <h3>Clear view of the problem</h3>
          </li>
          <li>
            <div className="icon-wrap"><GreenTick /></div>
            <h3>Map illustration of the stressed plants</h3>
          </li>
          <li>
            <div className="icon-wrap"><GreenTick /></div>
            <h3>Timeline with the track record of your field</h3>
          </li>
          <li>
            <div className="icon-wrap"><GreenTick /></div>
            <h3>Discounts for long-term contracts</h3>
          </li>
        </ul>
      </div>
      <div className="leaf-wrap">
        <Leaf />
      </div>
    </div>
  )
}

export default Item;