import React from 'react'
import moment from 'moment'
import { Button, Collapse, Modal } from 'react-bootstrap'
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './ScheduleShoot.style.scss'
import { VscChromeClose } from 'react-icons/vsc'

const ScheduleShoot = (props) => {
  const {
    dates1, handleDateChange1, handleFocusChange1, focus1,
    dates2, handleDateChange2, handleFocusChange2, focus2,
    isOutsideRange, onSubmit, showConfirmShoot, setShowConfirmShootFn
  } = props

  return (
    <div className="new-company book-shoot">
      <Modal
        show={showConfirmShoot}
        onHide={setShowConfirmShootFn(false)}
        dialogClassName="modal-book-shoot"
        backdropClassName="modal-backdrop-book-shoot"
      >
        <header className="d-flex align-items-center justify-content-between">
          <Modal.Title>Confirm Photo Shoot</Modal.Title>
          <Button variant="reset" onClick={setShowConfirmShootFn(false)}><VscChromeClose size={28} /></Button>
        </header>
        <main className="main">
          <p className="mb-3">Choose a suitable date range for drone coverage and an alternative in case of bad conditions</p>
          <div className="date-ranges">
            <h5 className="mb-4">Confirm Dates</h5>
            <DateRangePicker
              startDate={dates1.start}
              endDate={dates1.end}
              onFocusChange={handleFocusChange1}
              onDatesChange={handleDateChange1}
              displayFormat="MMM D"
              focusedInput={focus1}
              startDateId="startDate"
              endDateId="endDate"
              startDatePlaceholderText="Start"
              endDatePlaceholderText="End"
              numberOfMonths={1}
              minimumNights={0}
              isOutsideRange={isOutsideRange}
              singleDateRange={true}
            />
          </div>
          <div className="date-ranges mt-5">
            <h5 className="mb-4">Alternative Dates</h5>
            <DateRangePicker
              startDate={dates2.start}
              endDate={dates2.end}
              onFocusChange={handleFocusChange2}
              onDatesChange={handleDateChange2}
              displayFormat="MMM D"
              focusedInput={focus2}
              startDateId="startDate"
              endDateId="endDate"
              startDatePlaceholderText="Start"
              endDatePlaceholderText="End"
              numberOfMonths={1}
              minimumNights={0}
              isOutsideRange={isOutsideRange}
              singleDateRange={true}
            />
          </div>


        </main>

        <div className="bottom btn-wrap">
          <button className="btn btn-primary c-btn-lg w-100" onClick={onSubmit}>Add Confirm Dates</button>
        </div>
      </Modal>
    </div>
  )
}

export default ScheduleShoot;