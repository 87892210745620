import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import { Button } from "react-bootstrap";
import "react-pro-sidebar/dist/css/styles.css";
import { fetchCart } from "actions/field/find";
import { ImCreditCard } from "react-icons/im";
import { AiOutlineUser, AiOutlineDashboard } from "react-icons/ai";
import { GiField } from "react-icons/gi";
import { MdReceipt } from "react-icons/md";
import { BsCameraVideo } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";
import { IoMdNotificationsOutline } from "react-icons/io";
import { showNotifications } from "actions/ui";
import { Can } from "component/Router/Can";
import { fetchNumOfUnreadNotifications } from "actions/notification/list";
import { VscAccount } from "react-icons/vsc";
import { useLocation } from "react-router-dom";
import Logo from "component/reusable/icon/Logo";
import { useTranslation } from "react-i18next";

import { setNumOfItemsInCart } from "actions/cart";
import { TheHeader } from "./index";

import "./TheSidebar.style.scss";

const TheSidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const {
    showNotifications,
    numOfUnreadNotifications,
    fetchNumOfUnreadNotifications,
    user,
    fetchCart,
    numOfItemsInCart,
    setNumOfItemsInCart,
  } = props;

  useEffect(() => {
    (async () => {
      console.log("[useEffect] fetching unread notifications");
      await fetchNumOfUnreadNotifications();
      let rolesWithCart = ["b2b_admin", "b2c_admin"];

      if (rolesWithCart.includes(user.role)) {
        let fields = await fetchCart();
      }
    })();
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onCollapseToggle = () => setIsCollapsed(!isCollapsed);
  const onNotifications = () => showNotifications(true);
  const imageUrl =
    "https://images.unsplash.com/photo-1564840923002-e36824af2dae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80";
  const { t } = useTranslation();

  return (
    <ProSidebar breakPoint="xl" collapsed={isCollapsed}>
      <SidebarHeader>
        <header
          className="d-flex align-items-center"
          style={{ height: "50px", paddingLeft: "22px" }}
        >
          <Link to="/" title="farmAIr App">
            <Logo />
          </Link>
        </header>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="square">
          {/* <MenuItem icon={<FaGem />}>Dashboard <Link to="/" /></MenuItem> */}
          <Can do="read" a={"AdminOrders"}>
            <MenuItem
              icon={<AiOutlineDashboard />}
              className={`${pathname.includes("dashboard") ? "active" : ""}`}
            >
              {t("Dashboard")}
              <Link to="/dashboard" />
            </MenuItem>
          </Can>
          <Can do="read" a={"Fields"}>
            <MenuItem
              icon={<GiField />}
              className={`${pathname.includes("fields") ? "active" : ""}`}
            >
              {t("Fields")}
              <Link to="/fields" />
            </MenuItem>
          </Can>
          <Can do="read" a={"Notification"}>
            <div onClick={onNotifications} className="menu-item-notification">
              <MenuItem icon={<IoMdNotificationsOutline />}>
                {t("Notifications")}
                {numOfUnreadNotifications > 0 && (
                  <span className="counter">{numOfUnreadNotifications}</span>
                )}
              </MenuItem>
            </div>
          </Can>

          <Can do="read" a={"Users"}>
            <MenuItem
              icon={<AiOutlineUser />}
              className={`${pathname.includes("users") ? "active" : ""}`}
            >
              {t("Users")} <Link to="/users" />
            </MenuItem>
          </Can>

          <Can do="read" a={"Shoots"}>
            <MenuItem
              icon={<BsCameraVideo />}
              className={`${pathname.includes("shoots") ? "active" : ""}`}
            >
              {t("Shoots")} <Link to="/shoots" />
            </MenuItem>
          </Can>

          <Can do="read" a={"Companies"}>
            <MenuItem
              icon={<CgOrganisation />}
              className={`${pathname.includes("companies") ? "active" : ""}`}
            >
              {t("Companies")} <Link to="/companies" />
            </MenuItem>
          </Can>

          <Can do="read" a={"AdminOrders"}>
            <MenuItem
              icon={<MdReceipt />}
              className={`${pathname.includes("orders") ? "active" : ""}`}
            >
              {t("Orders")} <Link to="/orders" />
            </MenuItem>
          </Can>

          <Can do="read" a={"Cart"}>
            <MenuItem
              icon={<ImCreditCard />}
              className={`${pathname.includes("cart") ? "active" : ""}`}
            >
              {t("Cart")} <Link to="/cart" />
              {numOfItemsInCart > 0 ? (
                <span className="counter">{numOfItemsInCart}</span>
              ) : (
                ""
              )}
            </MenuItem>
          </Can>

          <MenuItem
            icon={<VscAccount />}
            className={`${pathname.includes("profile") ? "active" : ""}`}
          >
            {t("Profile")} <Link to="/profile/" />
          </MenuItem>
          {/* <SubMenu title="Components" icon={<FaHeart />}>
            <MenuItem>Component 1</MenuItem>
            <MenuItem>Component 2</MenuItem>
          </SubMenu> */}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <ul className="footer-links">
          <li>
            <Link to="/payment-methods">{t("Payment Methods")}</Link>
          </li>
          <li>
            <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
          </li>
          <li>
            <Link to="/terms-of-service">{t("Terms of Service")}</Link>
          </li>
        </ul>
        <p>
          Copyright &copy; 2020 -2021
          <br /> farmAIr, Inc. - All Rights Reserved
        </p>
        {/* <Button variant="dark" onClick={onCollapseToggle}><MdKeyboardArrowLeft fill="#d2d4d1" /></Button> */}
      </SidebarFooter>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => ({
  numOfUnreadNotifications: state.notifications.numOfUnreadNotifications,
  user: state.auth.user,
  numOfItemsInCart: state?.cart?.numOfItemsInCart,
  // sidebarShow: state.ui.sidebarShow
});

export default connect(mapStateToProps, {
  showNotifications,
  fetchNumOfUnreadNotifications,
  fetchCart,
  setNumOfItemsInCart,
})(TheSidebar);
