import React from 'react'

export const renderRow = (props) => {
  const { left, right } = props;
  let tooltip = right.props.children?.props?.children ? right.props.children.props.children :
                right.props.children;

  
  let className = 'col-md-6'
  if(props.className){
    className = props.className
  }
  return (
    <div className="row" key={left.props.children}>
      <div className={className}>{left}
      </div>
      <div className={className} title={tooltip}>
        {right}
      </div>
    </div>
  )
}

export const renderTable = (props) => {
  const { rows, title, className } = props


  return (
    <section className="table-wrap">
      {title ? <h3>{title}</h3> : ''}
      <div className="container">
        { rows.map(el => {

          let span = el[2];
          return renderRow({
            left: <strong>{el[0]}{span ? <span>{span}</span>: ''}</strong>, 
            right: <span>{el[1]}</span>,
            className
            })
        }) }
      </div>
    </section>
  )
}