import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import "./Agro.style.scss";
import api from "utils/api";
import "./Deploy.style.scss";

const DeployComponent = (props) => {
  const { serverLogs } = props;

  const onDeployProduction = async () => {
    const { data = {} } = await api.get("/deploy/production");
    console.log("click", data);
  };

  const [pass, setPass] = useState(null);
  const isDev = pass === "dev";

  return (
    <section className="agro-settings">
      <header>
        <h4>Actions</h4>
        <div className="form-group">
          <input
            type="password"
            id="devpass"
            value={pass}
            placeholder="pass"
            className="form-input"
            onChange={(e) => setPass(e.target.value)}
          />
        </div>

        <button
          className="btn btn-light"
          onClick={onDeployProduction}
          disabled={!isDev}
        >
          Deploy Production
        </button>
      </header>
      <main>
        <section>
          {serverLogs && serverLogs.length > 0 && <h3>Server Logs</h3>}
          <ul id="deploy-logs-section">
            {(serverLogs || []).map((log, i) => (
              <span key={i}>{log}</span>
            ))}
          </ul>
        </section>
      </main>
    </section>
  );
  // }
};

export default DeployComponent;
