const abioticFactors = [
  '',
  'Drought',
  'Flooding',
  'Salinity',
  'Soil Acidification',
  'Frost',
  'Heat',
  'Nutrients deficiencies',
]

const bioticBacterialDiseases = [
  "",
  "Happy disease (bacterial necrosis)",
  "Crown gall",
  "Pierce's disease"
]

const bioticFungal = [
  '',
  'Esca',
  'Botrytis cinerea',
  'Phomopsis dieback',
  'Downy mildew',
  'Powedery mildew',
  'Eutypa dieback',
  'Verticillium wilt',
  'Petri disease',
  'Botryosphaeriae dieback',
  'Black foot'
]

const bioticVirus = [
  "",
  "Grapevine leafroll disease",
  "Grapevine fanleaf virus",
  "Grapevine rugose wood", 
  "Grapevine fleck virus, GFkV",
  "Grapevine angular mosaic"
]

const bioticMites = [
  "",
  "Erinose",
  "Grapeleaf rust mite",
  "Bunch mite",
  "Two-spotted mite"
]

const bioticInsects = [
  "",
  "European vine moth",
  "Grape phylloxera",
  "Mealybug"
]

const bioticNematodes = [
  "",
  "Citrus",
  "Root lesion",
  "Root knot",
  "Ring",
]

export {
  abioticFactors,
  bioticBacterialDiseases,
  bioticFungal,
  bioticVirus,
  bioticMites,
  bioticInsects,
  bioticNematodes
}