import React from 'react';

import './Mapbox.style.scss'


const Mapbox = (props) => {

  return (
    <div className="mapbox-wrap">
      <div ref={props.mapRef} className="mapContainer"></div>
    </div>
  )
}

export default Mapbox;