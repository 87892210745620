import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAddresses } from 'actions/user/address/get'
import Item from './Item.component';
import { setActiveItem as setActiveNavItem } from 'actions/settings'

const ItemContainer = (props) => {
  const { fetchAddresses, setActiveNavItem } = props
  const [addresses, setAddresses] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('address', addresses)
  }, [addresses])


  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching addressed')
      let data = await fetchAddresses();
      if(data){
        setAddresses([...addresses,...data.Addresses])
        console.log('setting', data.Addresses)
      }
      console.log('addresses', addresses)
      console.log(data, 'the data')
      setLoading(false);
    })()
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    let data = await fetchAddresses();
    setAddresses([...data.Addresses])
    setLoading(true);
    console.log('handling')
  }



  const onBack = () => setActiveNavItem(null)


  const state = {
    loading,
    addresses,
  };

  const containerFunctions = {
    handleCreate,
    onBack,
    handleCreate,
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
  fetchAddresses,
  setActiveNavItem,
})(ItemContainer);