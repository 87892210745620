import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateInvoice } from 'actions/company/update/invoice'

import InvoiceForm from './InvoiceForm.component';

const InvoiceFormContainer = (props) => {
  const { updateInvoice, addBEAddress } = props
  const { company, informParent, addresses } = props.props
  const [loading, setLoading] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [formData, setFormData] = useState({
    vatId: '',
    firmTitle: '',
    firmActivity: '',
    telephone: '',
    email: '',
    city: '',
    address: '',
    zipCode: '',
    taxAuthority: '',
    state: '',
    country: '',
  });


  useEffect(() => {
    setFormData({...formData, ...company.invoiceDetails})
  }, [company])

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if(!showUpdate){
      setShowUpdate(true)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    let payload = { ...formData }
    if(addBEAddress && !addresses.length){
      payload.addBEAddress = true;
    }

    console.log(payload, 'to submit', props)

    if(await updateInvoice(payload)){
      setShowUpdateSuccess(true)
      if(informParent){
        informParent(true);
      }
      setTimeout(() => {
        setShowUpdateSuccess(false)
      }, 5000)
    } else {
      setShowErrorMessage(true)
      setTimeout(() => {
        setShowErrorMessage(false)
      }, 3000)
    }
  }

  const state = {
    formData,
    showUpdate,
    showErrorMessage,
    showUpdateSuccess,
  };

  const containerFunctions = {
    onChange,
    onSubmit,
  };

  return (
    <InvoiceForm
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  updateInvoice
})(InvoiceFormContainer);