import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchShoots, setActiveItem } from 'actions/shoot/list'
import List from './List.component';
import { initialFiltersReport, initialFiltersShoot } from './List.config'
import { prepareOwners } from './List.utils'


const ListContainer = ({ fetchShoots, stateShoots, setActiveItem, activeItem, userRole }) => {
  const [loading, setLoading] = useState(true);
  const [shoots, setShoots] = useState(null);
  const [owners, setOwners] = useState([]);
  const [open, setOpen] = useState(false);

  const [activeReportFilters, setActiveReportFilters] = useState(initialFiltersReport)
  const [activeShootFilters, setActiveShootFilters] = useState(initialFiltersShoot)

  const [numOfShootsThatNeedAssignment, setNumOfShootsThatNeedAssignment] = useState(null)

  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching shoots')
      await fetchShoots({role: userRole})
      console.log(shoots, 'the shoots')
      setLoading(false);
    })()
  }, []);

  useEffect( ()=> {
    setShoots(stateShoots);

    console.log(prepareOwners(stateShoots), 'COMPANIES')

    setOwners([...prepareOwners(stateShoots)]);

    let shootsThatNeedAssignment = stateShoots.filter(el => {
      return el.status === 'unassigned' && el.report.status === 'processing_pending'
    })
    setNumOfShootsThatNeedAssignment(shootsThatNeedAssignment.length)
  }, [stateShoots])


  useEffect( ()=> {
    console.log(shoots, 'the shoots')
    // where filter is true, add it in an array
    let includeOnly = Object.keys(activeReportFilters).filter(el => (activeReportFilters[el]))  
    if(!includeOnly.length){
      includeOnly = [...Object.keys(activeReportFilters)]
    }

    let newShoots = stateShoots.filter(el => includeOnly.includes((el.report.status)))

    let includeShootsOnly = Object.keys(activeShootFilters).filter(el => (activeShootFilters[el]))
    if(!includeShootsOnly.length){
      includeShootsOnly = [...Object.keys(activeShootFilters)]
    }

    let filteredShoots = newShoots.filter(el => includeShootsOnly.includes(el.status))

    setShoots([...filteredShoots]);

    let newOwners = [...owners];
    console.log(newOwners, 'OWNERS NEW')
    // setOwners([]);

    // setOwners(prepareOwners(filteredShoots));
    // console.log(prepareOwners(filteredShoots), 'PREPARE SHOOOTS', filteredShoots)
    console.log(activeReportFilters, includeOnly, 'only!', newShoots, stateShoots)
  }, [activeReportFilters, activeShootFilters])

  const onClearReportFilters = () => setActiveReportFilters({...initialFiltersReport})
  const onClearShootFilters = () => setActiveShootFilters({...initialFiltersShoot})

  const setOpenDom = val => e => setOpen(val)

  // const onItem = uuid => e => setActiveItem(uuid)

  const onReportStatus = (val) => e => {
    let updated = !activeReportFilters[val];
    setActiveReportFilters({...activeReportFilters, [val]: updated})
  }

  const onShootStatus = (val) => e => {
    let updated = !activeShootFilters[val];
    setActiveShootFilters({...activeShootFilters, [val]: updated})
  }

  const onNeedAssignment = () => {
    setActiveReportFilters({...initialFiltersReport, processing_pending: true})
    setActiveShootFilters({...initialFiltersShoot, unassigned: true})
  }

  const state = {
    loading,
    shoots,
    activeItem,
    open,
    userRole,
    activeReportFilters,
    activeShootFilters,
    numOfShootsThatNeedAssignment,
    owners
  };

  const containerFunctions = {
    // onItem,
    setOpenDom,
    onReportStatus,
    onShootStatus,
    onClearReportFilters,
    onClearShootFilters,
    onNeedAssignment,
    setActiveItem
  };

  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  stateShoots: state.shoots.list,
  activeItem: state.shoots.activeItem,
  userRole: state.auth.user.role
});

export default connect(mapStateToProps, { 
  fetchShoots,
  setActiveItem,
 })(ListContainer);