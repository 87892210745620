import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next'
import './HdPhoto.style.scss'
import {
  ReactPictureAnnotation,
  defaultShapeStyle,
  // DefaultInputSection,
} from "react-picture-annotation";
import Annotate from './Annotate'
import { MdErrorOutline } from 'react-icons/md'
import { VscChromeClose } from 'react-icons/vsc'
import {__i18n} from 'i18n'
const renderAnnotation = (props, pageSize) => {

  let { showDirections, fields, activeItem, 
          onChange, onSelect, annotationData, layout, report, acFile } = props

  const { reportUuid, file } = showDirections

  let imgUuid
  let hdPhotoName;

  console.log(props, 'THE PROPS!!')

  if(layout === 'pin-hd-photo'){
    imgUuid = report?.uuid;
    hdPhotoName = `${acFile?.split('.')[0]}-hd.jpg`;
  }else {
    imgUuid = reportUuid
    hdPhotoName = `${file?.split('.')[0]}-hd.jpg`;
  }


  // ### FARMAIR: better update hd file here -hd.png
  console.log(process.env, 'PROCESS ENV')
  let imgUrl = `${process.env.REACT_APP_S3}reports/${imgUuid}/${hdPhotoName}`;
  if(process.env?.NODE_ENV == 'development'){
    let img_w = 1632;
    let img_h = 1224;
    if(report && report.size && report.size.hd){
      let {w, h} = report.size.hd
      img_w = w;
      img_h = h;
    } 
    imgUrl = `http://via.placeholder.com/${img_w}x${img_h}`; //1632x1224
  }

  let { width, height} = pageSize;
  let lineWidth = 3;
  if(layout == "direction"){
    let acField = fields.find(el => el.id == activeItem);
    let acReport = acField.Reports.find(el => el.uuid == showDirections.reportUuid);
    let {size = {}} = acReport;
    let imgW = size?.hd?.w || 1;
    let imgH = size?.hd?.h || 1;
    let aspect = imgW / imgH;
    height = window.innerWidth / aspect;
    
    if(window.innerWidth > 992){
      lineWidth = 10;
    }
    for(let i = 0; i < annotationData.length; i++){
      annotationData[i].comment = ""
    }
  }

  console.log(imgUrl, 'imgUrl', annotationData);


  return (
    <ReactPictureAnnotation
      image={imgUrl}
      onSelect={onSelect}
      onChange={onChange}
      width={width}
      height={height}
      annotationData={annotationData}
      annotationStyle={{
        ...defaultShapeStyle,
        shapeStrokeStyle: "#ffaf0b",
        transformerSize: "0",
        lineWidth: `${lineWidth}`
      }}
      inputElement={(value, onChange, onDelete) => (
        <Annotate
          {...{ value, onChange, onDelete }}
        />
      )}
    />
  )
}

const HdPhoto = (props) => {
  const { active, setActive, commentInfoVisibility, setCommentInfoVisibility,
     t } = props;


  const [pageSize, setPageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const onResize = () => {
    setPageSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);


  return (
    <section className="image-annotation">
      <div className="img-wrap" onMouseDown={() => {
        document.body.classList.add('selected')
      }} onMouseUp={(e) => {
        document.body.classList.remove('selected')
      }}>
        {renderAnnotation(props, pageSize)}
      </div>

      {commentInfoVisibility ?
        <div className="add-comment-wrap fade-in">
          <button 
            onClick={() => setCommentInfoVisibility(false)}
            className="btn btn-reset close-comment-info">
              <VscChromeClose size={12}/>
          </button>
          <div className="left">
              <MdErrorOutline size={32} />
          </div>
          <div className="right">
              <p>{t('Click on the box to add an Abiotic or a Biotic Stress factor.')}</p>
          </div>
        </div>
      : null}

    </section>
  )
}

export default withTranslation()(HdPhoto);