import {
  NUM_OF_ITEMS_IN_CART
} from 'actions/types';


const setNumOfItemsInCart = (val) => async dispatch => {
  try {
    dispatch({
      type: NUM_OF_ITEMS_IN_CART,
      payload: val
    });
  } catch (err) {
    alert(err)
  }
}

export {
  setNumOfItemsInCart,
}