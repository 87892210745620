import {
  LOAD_PROFILE,
  EMPTY_PROFILE,
  SET_PROFILE_ACTIVE_NAV_ITEM,
} from 'actions/types';

const initialState = {
  profile: null,
  activeNavItem: null,
};


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_PROFILE:
      return {
        ...state,
        profile: payload
      };
    case EMPTY_PROFILE:
      return {
        ...state,
        profile: null,
      };
    case SET_PROFILE_ACTIVE_NAV_ITEM:
      return {
        ...state,
        activeNavItem: payload.value,
      };
    default:
      return state;
  }
}