import React, { useState } from 'react';
import { BsCameraVideo } from 'react-icons/bs';
import { formatLocation, formatArea } from 'utils/format'
import './List.style.scss'
import Loader from 'component/reusable/Loader'
import { formatStatus } from 'component/Shoot/utils/formatStatus'
import { MdReceipt } from 'react-icons/md';
import { renderAvatar } from 'component/reusable/User'
import { withTranslation } from 'react-i18next'
import ListHeader from './ListHeader.component'
import { Button, Collapse } from 'react-bootstrap'
import moment from "moment"
import  { renderDates } from '../Item/Shoot.utils'
import  Calendar from './Calendar'

const renderShoots = (props, shootsList = [], iter, layout = "list") => {
  let { shoots, onItem, activeItem, setActiveItem, t } = props
  // console.log(props, 'THE SHOOTS LENGTH')
  if (shootsList.length) {
    shoots = [];
    shoots = [...shootsList];
  }

  return shoots.map((el, i) => {
    const { report, field } = el
    const { title, location } = field;


    let dateRange
    if (el.dateRange) {
      dateRange =`${moment(el.dateRange.start).format("D MMM YYYY")} - 
      ${moment(el.dateRange.end).format("MMM D YYYY")}`
    }

    return (
      <div onClick={() => setActiveItem(el.uuid)} key={el.uuid}
        className={"list-item " + (activeItem === el.uuid ? 'active' : '')}
      >
        {layout === "list" && renderAvatar(field, { size: 'xs', icon: 'field' })}
        <div className="details">
        {layout === "list" ?
          <>
            <h4 className="mb-0">{title}</h4>
            <p>{formatLocation(location)}</p>
          </>
        : null }
        {layout === 'company' ?
          <>
            {dateRange ? dateRange : null}
          </>
        : null}
          <p className="shoot-status"><MdReceipt size={16} />{formatStatus(report, t)}</p>
          <p className="shoot-status">
            <BsCameraVideo size={16} />{
            el.shootDays ? renderDates(el, t, "stringObj")
            : formatStatus(el, t) }
          </p>
        </div>
      </div>
    )
  })
}

const renderCompanies = (props = {}) => {
  console.log(props, 'COMPANIES')

  let { shoots = [], owners = [] } = props

  if(!shoots.length || !owners.length) return null;

  console.log(owners, 'ONWERS!!!!')
  return (
    <section className="companies-shoots fade-in">
      <ul>
        {owners.map((el, i) => {
          let { shootsFields = {} } = el
          let fieldsLen = Object.keys(shootsFields).length;

          return (
            <li key={`onwer-key-${i}`} id={`collapse-owner-content${i}`}
            className="level-1">
              <Button
                variant="light"
                onClick={(e) => {
                  let dom = document.querySelector(`#collapse-owner-content${i}`);
                  dom.classList.contains('show') ? dom.classList.remove('show') : dom.classList.add('show');
                }}
                aria-controls={`collapse-owner-content${i}`}
              >
                <h4>{el?.title}</h4>
                <h6>{fieldsLen} Fields and {el?.shoots?.length} Shoots</h6>
              </Button>
              <Collapse in={false}>
                <div>
                  {renderFieldShoots(props, el, i)}
                </div>
              </Collapse>
            </li>
          )
        })}
      </ul>
    </section>
  )
}


const renderFieldShoots = (props, owner, iter) => {
  if(!owner || !owner.shootsFields) return null; 

  console.log(owner, 'OWNER')

  return (
    <ul>
      {
        Object.keys(owner.shootsFields).map((key,i) => {
          let field = owner.shootsFields[key][0].field
          let shootsFieldsLen = owner.shootsFields[key].length

          return (
            <li key={`field-shoot-li-${i}`} className="level-2"
            id={`collapse-owner${iter}-field-content${i}`}>
              <Button
              variant="secondary"
                onClick={(e) => {
                  let dom = document.querySelector(`#collapse-owner${iter}-field-content${i}`);
                  return dom.classList.contains('show') ? dom.classList.remove('show') : dom.classList.add('show');
                }}
                aria-controls={`collapse-owner${iter}-field-content${i}`}
              >
                <div className={"list-item "}>
                  {renderAvatar(field, { size: 'xs', icon: 'field' })}
                  <div className="details">
                    <h4 className="mb-0">{field?.title}</h4>
                    <p>{formatLocation(field?.location || {})}</p>
                    <p>{shootsFieldsLen} Shoots · {formatArea(field?.area || {})}</p>
                    {/* <p className="shoot-status"><MdReceipt size={16} />{formatStatus(report, t)}</p>
                    <p className="shoot-status"><BsCameraVideo size={16} />{formatStatus(el, t)}</p> */}
                  </div>
                  <div className="report-info d-none">
                      <p>4</p>
                      <p>7</p>
                  </div>
                </div>
              </Button>
              <Collapse in={false}>
                <div className="fade-in level-3">
                  {renderShoots(props, owner.shootsFields[key], i, "company")}
                </div>
              </Collapse>
            </li>
          )
        })
      }
    </ul>
  )
}


const List = (props) => {
  const { loading, shoots, t } = props
  const [viewCompany, setViewCompany] = useState(false);

  return (
    <section className="grid-2 shoot-list">
      <ListHeader props={props} />
      <div className="main">
        {
          !loading ?
            shoots.length ? 
            <>
             {viewCompany ? renderCompanies(props) : renderShoots(props) }
            </>
              : <div className="empty text-center">{t('No photo shoots yet')}</div>
            : <Loader />}
      </div>
      <div className="shoots-actions-wrap">
        <button className="btn btn-light" onClick={() => setViewCompany(!viewCompany)}>
            Switch View
        </button>
        <Calendar props={props} />

      </div>
    </section>
  )
}

export default withTranslation()(List);