import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCompanies, setActiveItem } from 'actions/company/list'
import Item from './Item.component';
import { createUserAddress } from 'actions/user/address/create'

const ItemContainer = (props) => {
  const { createUserAddress, fetchCompanies, companies, activeItem, handleCreate } = props

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [show, setShow] = useState(null)


  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching addresses')
      // await fetchCompanies();
      // setCompany(companies[0])
      setLoading(false);
    })()
  }, []);

  const submitForm = async (values) => {
    console.log(values)
    if(await createUserAddress(values)){
      await handleCreate();
      setShow(false)
    }
  }

  const setShowFn = val => e => {
    setShow(val)
  }


  const state = {
    company,
    loading,
    show
  };

  const containerFunctions = {
    setShowFn,
    submitForm
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  companies: state.company.list.list,
  activeItem: state.company.list.activeItem
});

export default connect(mapStateToProps, {
  fetchCompanies,
  createUserAddress,
})(ItemContainer);