import React from 'react';
import { formatStatus } from 'component/Shoot/utils/formatStatus'
import { BsFilter } from 'react-icons/bs'
import { withTranslation } from 'react-i18next'
import { MdErrorOutline } from 'react-icons/md'


const renderNeedAssignmentGroup = (props) => {
  const { t, numOfShootsThatNeedAssignment, onNeedAssignment } = props;

  if(!numOfShootsThatNeedAssignment){
    return (
      <p className="no-assignment"><MdErrorOutline size={20}/>
      <span className="ml-2">{t('There are no shoots that need assignment')}</span></p>
    )
  }else {
    return (
      <div className="filter-group mb-3">
        <button className="btn c-btn-sm btn-danger btn-need-action"
          onClick={onNeedAssignment}>
          <MdErrorOutline size={20} />
          <span className="mx-2">{t('Need Assignment')} (<strong>{numOfShootsThatNeedAssignment}</strong>)</span>
        </button>
      </div>
    )
  }
}

const renderHeaderTitle = (props) => {
  const { t, numOfShootsThatNeedAssignment } = props;

  return (
    <header>
      <h2>{t('Shoots')}
      <>
        {numOfShootsThatNeedAssignment ?
          <span className="ml-2 text-danger">({numOfShootsThatNeedAssignment})</span>
        : ''}
      </>
      </h2>
    </header>
  )
}

const renderHeader = ({props}) => {
  const { open, setOpenDom, userRole, onReportStatus, onShootStatus, activeReportFilters,
    activeShootFilters,onClearShootFilters, onClearReportFilters, t} = props

  let reportStatuses = ['processing_pending', 'processing_data', 'complete'];
  let shootStatuses = ['unassigned','assigned_to_drone_admin', 'assigned_to_drone_operator',
  'confirmed_shoot_dates', 'complete'];

  if(['admin', 'financial_admin', 'operation_admin'].includes(userRole)){
    reportStatuses = [...reportStatuses, 'pending', 'pending_payment','processing',]
  }

  let formatAsNeeded = reportStatuses.map(el => ({status: el}))
  let formatShootStatusesAsNeeded = shootStatuses.map(el => ({status: el}))

  return (
    <>
      <button
        onClick={setOpenDom(!open)}
        aria-controls={`collapse-shoot-header`}
        aria-expanded={open}
        className="toggler"
      >
        {renderHeaderTitle(props)}
        <BsFilter size={32} />
      </button>
      { open ?
        <div id={`collapse-shoot-header`} className="fade-in">

          { renderNeedAssignmentGroup(props) }

          <div className="filter-group">
            <div className="filter-title-wrap">
              <h4>{t('Report Status')}</h4>
              { Object.keys(activeReportFilters).filter(el => (activeReportFilters[el])).length ? 
                <span onClick={onClearReportFilters} className="clear">{t('Clear')}</span>
                : ''
              }
            </div>
            <ul>
              {reportStatuses.map((el,i) => (
                <li key={el} onClick={onReportStatus(el)} 
                    className={`${activeReportFilters[el] ? 'active' : ''}`}>
                  {formatStatus(formatAsNeeded[i], t)}
                </li> )
                )}
            </ul>
          </div>

          <div className="filter-group">
            <div className="filter-title-wrap">
              <h4>{t('Photo Shoot Status')}</h4>
              { Object.keys(activeShootFilters).filter(el => (activeShootFilters[el])).length ?
                <span onClick={onClearShootFilters} className="clear">{t('Clear')}</span>
                : ''
              }
            </div>
            <ul>
                {shootStatuses.map((el,i) => (
                  <li key={el} onClick={onShootStatus(el)} 
                      className={`${activeShootFilters[el] ? 'active' : ''}`}>
                    {formatStatus(formatShootStatusesAsNeeded[i], t)}
                  </li> )
                  )}
              </ul>
          </div>
        </div>
        : ''}
    </>
  )
}



export default withTranslation()(renderHeader);