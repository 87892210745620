import React from 'react';
import { Button, Collapse } from 'react-bootstrap'
import { formatLocation, formatArea, getAreaString } from 'utils/format'
import { RiArrowDropDownFill } from 'react-icons/ri'
import './Li.style.scss'
import { renderAvatar } from 'component/reusable/User'
import { withTranslation } from 'react-i18next'

const renderGroupItems = (fieldGroup, onField, activeItem) => {
  return (
    fieldGroup.map((el) => {
      return (
        <div onClick={onField(el.id)}
          className={`c-list-group-item ${activeItem === el.id ? "active" : ""}`}
          key={el.i}>
          {renderAvatar(el, { size: 'xs', icon: 'field' })}

          <div className="details">
            <h4 className="mb-0">{el.title}</h4>
            <p>{formatLocation(el.location)}</p>
            <p>{formatArea(el.area)}</p>
            <div>
            </div>
          </div>
        </div>
      )
    })
  )
}


const Li = (props) => {
  const { theKey, fieldGroup, open, onField, setOpen, setOpenDom, groupDisplay, activeItem, t } = props

  console.log('Li', fieldGroup);

  let fieldsWord = fieldGroup.length > 1 ? 'fields' : 'field';

  return (
    <div className={`c-list-group lg-${theKey}`} key={theKey}>
      <Button
        onClick={setOpenDom(!open)}
        aria-controls={`collapse-field-${fieldGroup[0].title}`}
        aria-expanded={open}
      >
        <h4>{groupDisplay.locationTitle}</h4>
        <p>{fieldGroup.length} {t(fieldsWord)} ·
          {getAreaString(groupDisplay.totalArea)}</p>
        <RiArrowDropDownFill size={32} />
      </Button>
      <Collapse in={open}>
        <div id={`collapse-field-${fieldGroup[0].title}`}>
          {renderGroupItems(fieldGroup, onField, activeItem)}
        </div>
      </Collapse>
    </div>
  );
}

export default withTranslation()(Li);