import React from 'react';
import { withTranslation } from 'react-i18next'
import './Item.style.scss'


const Item = (props) => {
  let { t } = props

  return (
    <section className="terms">
      <div className="container">
        <h1 className="mb-4">{t('Payment Methods')}</h1>
        <section className="mb-4">
          <h2>{t('Payment with credit or debit card (Visa, Mastercard, Maestro, American Express, Discover, Diners Club, JCB)')}</h2>
          <div className="mt-2">
            <p>{t('We use Stripe, a dedicated and highly secured payment processing company, to offer you the option of paying with your credit or debit card. Stripe handles all of the international payment, including currency exchange, we do not keep any of your card’s data. You will be able to complete your payment within our platform (no redirections, pop-up windows) and have a smooth and secured purchasing experience.')}</p>
          </div>
        </section>
        <section>
          <h2>{t('Wire Transfer')}</h2>
          <div className="mt-2">
            <h5>{t('Αccount holder')}:</h5>
            <p><strong>farmAIr, Inc.</strong></p>
            <p><strong>{t('Bank details')}:</strong></p>
            <p>BIC: TRWIBEB1XXX</p>
            <p>IBAN: BE14 9671 7402 9783</p>
            <p>Address: TransferWise Europe SA, Avenue Louise 54, Room S52, Brussels. 1050 Belgium</p>
            <p className="mt-3">{t("Send us a copy of your bank's swift report at")} <a href="mailto:sales@farmair.io">sales@farmair.io</a> {t('to complete your ordering process.')}</p>
          </div>
        </section>
      </div>
    </section>

  )
}

export default withTranslation()(Item);