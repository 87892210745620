import {
  LOAD_NOTIFICATIONS,
  EMPTY_NOTIFICATIONS,
  SET_NUM_OF_UNREAD_NOTIFICATIONS,
} from 'actions/types';

const initialState = {
  list: [],
  numOfUnreadNotifications: null
};


export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_NUM_OF_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        numOfUnreadNotifications: payload,
      };
    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        list: [...state.list, ...payload],
        // numOfUnreadNotifications: payload.length ? 0 : null
      };
    case EMPTY_NOTIFICATIONS:
      return {
        ...state,
        list: [],
        activeItem: null
      };
    default:
      return state;
  }
}