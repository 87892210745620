import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Item from './Item.component';

const ItemContainer = (props) => {

  const state = {

  };

  const containerFunctions = {

  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ItemContainer);