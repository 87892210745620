import i18n from "i18next";
import en from "./en.json";
import el from "./el.json";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
  // we init with resources
  resources: {
    en: {
      translation: en
    },
    el: {
      translation: el
    }
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  // ns: ["translations"],
  // defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    // formatSeparator: ","
  },

  // react: {
  //   wait: true
  // }
});

export const __i18n = (msg) => {
  let lang = i18n.language;

  if(i18n.store.data[lang].translation[msg]){
    return i18n.store.data[lang].translation[msg];
  }else {
    return msg;
  }

}

export default i18n;
