import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from 'react-bootstrap'
import FormGroup from 'component/reusable/form/FormGroup';
import GuestHeader from 'route/SignUp/component/GuestHeader'
import Benefits from 'route/SignUp/component/Benefits'
import Loader from 'component/reusable/Loader'

import './PasswordReset.style.scss';

const PasswordResetForm = (props) => {
  return (
    <>
      <p>Enter your user account's verified email address and we will send you a link to reset your password.</p>

      <div className="form-groups">
        <FormGroup props={props} opts={{
          name: "email",
          type: "email",
          label: "Email address",
          invalidFeedback: "Please choose a valid email.",
          required: true
        }} />
      </div>
      <Button type="submit" disabled={!props.btn.enabled}
        className={`${props.btn.type} w-100`}>
        Reset Password
      </Button>
    </>
  )
}

const PasswordResetSuccess = () => {
  return (
    <>
      <p>Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.</p>
      <Link to="/login" className="btn btn-light w-100 mt-3">Return to Sign In page</Link>
    </>
  )
}

const PasswordReset = (props) => {
  const { loading } = props
  return (
    <section className="guest-page">
      <GuestHeader />
      { loading ?
        <div className="loader-wrap fade-in">
          <Loader />
        </div>
        : ''}
      <main className="" aria-label="Sign Up Page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 left">
              <Benefits />
            </div>
            <div className="col-md-6 right">
              <div aria-label="Login Page" className="login-page inner vertical-center">
                <Form className="needs-validation"
                  onSubmit={props.onSubmit} noValidate validated={props.validated}>
                  <h1>Forgot Password</h1>

                  {!props.successRequest && PasswordResetForm(props)}
                  {props.successRequest && <PasswordResetSuccess />}
                  <Row className="no-gutters mt-4">
                    <div className="col-md-12">
                      <Link to="/login" className="link-small">Sign In</Link>
                    </div>
                    <div className="col-md-12">
                    <Link to="/signup" className="link-small">Create Account</Link>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  )
}

export default PasswordReset;
