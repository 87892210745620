import React, { useState } from 'react'
import moment from 'moment'
import api from 'utils/api';

const ShootDay = (shoot, t, fetchShootsFn) => {
  let { shootDays } = shoot
  let startD = shootDays?.confirmed?.start
  let endD = shootDays?.alternative?.end

  console.log(shootDays, 'shootDays');

  const [updated, setUpdated] = useState(null)
  const [updating, setUpdating] = useState(null)
  const [updatedValue, setUpdatedValue] = useState(null)

  const onFinalDate = async (e) => {
    let a = moment(`${e.target.value} 12:00`);
    setUpdating(true);
    setUpdatedValue(`${e.target.value} 12:00`)

    let updatedShootDates = {
      ...shootDays,
      final: a.toISOString()
//   confirmed: {
//     start: startDate1,
//     end: endDate1
//   },
//   alternative: {
//     start: startDate2,
//     end: endDate2
//   }
    }

    const payload = {
      shootUuid: shoot.uuid,
      shootDays: {...updatedShootDates},
      updateType: "final"
    }

    try {
      const res = await api.put(`/shoot/updateDates`, payload);
      await fetchShootsFn()
      setUpdating(false);
      setUpdated(true);
      setTimeout(() => {
        setUpdated(false);
      }, 4000)
    } catch (err) {
      alert('Something went wrong')
    }
  }

  if(!shootDays || !shootDays?.confirmed) return null

  return (

    <div className="mt-4">
      {shootDays?.final ?
        <>
        <div className="mt-3">
         <h3 className="mb-3">Shoot Day: {
           updatedValue ?
           moment(updatedValue).format('ll')
           :
         moment(shootDays.final).format('ll')}
         </h3>
        </div>

        </>
      : null}
      <h4 className="mb-3">Set Shoot Days</h4>
      <div className="mb-3">
        {updating ?
        <p>Updating...</p>
        : updated ?
        <div>
          <p>Shoot Day: {moment(updatedValue).format('ll')}</p>
        </div>
        :
        <>
          <label for="start" className="">Shoot date:</label><br/>
          <input type="date" id="start" name="trip-start"
                  onChange={onFinalDate}
                  // value={null}
                  value={moment(startD).subtract(1, 'days').format('YYYY-MM-DD')}
                  min={moment(startD).format('YYYY-MM-DD')} max={moment(endD).format('YYYY-MM-DD')}>
          </input>
        </>
        }

      </div>

    </div>
  )
}

export default ShootDay;