import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setActiveItem } from 'actions/user/list'
import Item from './Item.component';

const ItemContainer = (props) => {
  const { users, activeItem, setActiveItem } = props

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);


  useEffect(() => {
    (async () => {
      if(activeItem !== null){
        let activeUser = users.filter(el => el.id === activeItem)[0]
        setUser(activeUser)
      }
      console.log(users, 'users')
      setLoading(false);
    })()
  }, [users, activeItem]);

  const onBack = () => {
    console.log('on back')
    setActiveItem(null)
  }

  const state = {
    user,
    loading,
    activeItem
  };

  const containerFunctions = {
    onBack
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  users: state.users.list,
  activeItem: state.users.activeItem
});

export default connect(mapStateToProps, {
  setActiveItem
})(ItemContainer);