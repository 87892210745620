import api from 'utils/api';
import { setAlert } from 'actions/alert'
import {
  LOAD_COMPANIES,
  EMPTY_COMPANIES,
} from 'actions/types';


const createOrder = (payload) => async dispatch => {
  console.log('[Action] createCompany')

  try {
    const res = await api.post('/orders/create', payload);
    console.log(res);
    if(res.status === 201){
      return true
    } else {
      if(res?.data?.errorMsg){
        dispatch(setAlert(res.data.msg, 'danger'))
      }
      return false
    }
    // alert(res.data)
    // dispatch({
    //   type: LOAD_COMPANIES,
    //   payload: res.data.companies
    // });
    // dispatch({
    //   type: EMPTY_COMPANIES
    // });
    // const res2 = await api.get('/companies');

    // dispatch({
    //   type: LOAD_COMPANIES,
    //   payload: res2.data.companies
    // });

    // dispatch(setAlert('New Company successfully created.', 'success'))

    console.log(res, 'the order')
  } catch (err) {
    alert(err)
    return false
  }
}

export {
  createOrder,
}