import React from "react";
import moment from "moment";

export const renderReportName = (report) => {
  const { status, processedAt, shootDays } = report;
  const { final } = shootDays || {};

  if (status === "complete" && processedAt) {
    if (final) {
      return moment(final).format("LL");
    }
    return moment(processedAt).format("LL");
  }

  return <>#{report.uuid.substring(0, 4)}</>;
};
