

export const prepareOwners = (shoots = [], theOwners = []) => {
  if(!shoots || !shoots.length) return [];

  let owners = theOwners || [];

  let companies = [];

  
  shoots.forEach(el => {
    let cmnTitle;

    let isB2bUser = el?.owner?.Companies.length;

    //b2b user
    if(isB2bUser){
      cmnTitle = el?.owner?.Companies[0]?.title
    }else {
      cmnTitle = `${el?.owner?.firstName} ${el?.owner?.lastName}`;
    }

    if (!companies.find(el => el?.title == cmnTitle)) {
      if(isB2bUser){
        companies.push(el?.owner.Companies[0]);
      }else {
        companies.push({...el?.owner, title: cmnTitle});
      }

    }

  })

  console.log(companies, 'the companies', shoots)

  companies.forEach((el, i) => {
    if(el){
      companies[i].shoots = []
      companies[i].shootsFields = {}
    }
  });

  shoots.forEach(el => {
    let company = companies.find(cmn => cmn?.title == el?.owner?.Companies[0]?.title)
    if(el?.owner?.Companies.length){
      company = companies.find(cmn => cmn?.title == el?.owner?.Companies[0]?.title)
    }else {
      company = companies.find(cmn => cmn?.title == `${el?.owner?.firstName} ${el?.owner?.lastName}`)
    }
    

    if(company){
      if (!company.shoots.find(shoot => shoot?.uuid == el.uuid)) {
        company.shoots.push(el);
      }
  
      if(!company.shootsFields[el?.field?.title]){
        company.shootsFields[el?.field?.title] = []
      }
      company.shootsFields[el?.field?.title].push(el)
    }

  });
  return companies;
}
