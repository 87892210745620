import { LOAD_FIELD, EMPTY_FIELD } from "actions/types";

import api from "../utils/api";
import { setAlert } from "actions/alert";
import { showNewField } from "actions/ui";
import { fetchFields } from "actions/field/list";
import axios from "axios";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const uploadImage = (img) => async (dispatch) => {
  try {
    /* first upload avatar */
    var file = dataURLtoFile(img, "snap.png");
    const formData = new FormData();
    formData.append("avatar", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: true,
    };
    console.log(formData, "the payload");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/field/avatar/upload`,
      formData,
      config
    );
    return res;

    console.log(res, "avatar res");
  } catch (err) {
    console.log(err);
  }
};

const createField = (payload) => async (dispatch) => {
  try {
    console.log("[Action] Field Create");

    let file = payload.snapShot;
    delete payload.snapShot;
    const { data } = await dispatch(uploadImage(file));
    const { md, xs } = data.file;

    payload.avatar = {
      md: md.Location,
      xs: xs.Location,
    };

    const res = await api.post("/field/create", payload);

    if (res.status == 201) {
      console.log("sussefully created");
      dispatch(
        setAlert(
          "New Field successfully created. You can now choose a service",
          "success"
        )
      );
      dispatch(showNewField(false));
      dispatch(fetchFields());
    }
    // dispatch({
    //   type: LOGIN_SUCCESS,
    //   payload: res.data
    // });

    // dispatch(loadUser());
  } catch (err) {}
};

const updateField = (payload) => async (dispatch) => {
  try {
    console.log("[Action] Field Update");

    const res = await api.post("/field/update", payload);

    if (res.status == 201) {
      console.log("sussefully updated");
      dispatch(setAlert("Field successfully updated", "success"));
      // dispatch(showNewField(false))
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deleteField = (payload) => async (dispatch) => {
  try {
    console.log("[Action] Field deleteField");

    const res = await api.post("/field/delete", payload);

    if (res.status == 201) {
      console.log("sussefully deleted");
      dispatch(setAlert("Field successfully deleted", "success"));
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const fetchField = (uuid) => async (dispatch) => {
  try {
    console.log("[Action] Fetch Fields");
    const res = await api.get(`/field/${uuid}`);
    console.log(res);
    dispatch({
      type: LOAD_FIELD,
      payload: res.data.field,
    });
    // const reports = await api.get(`/field/${uuid}/reports`);
  } catch (err) {}
};

export { createField, fetchField, updateField, deleteField };
