import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import  { updatePassword } from 'actions/settings/index.js'
import  { deleteAccount } from 'actions/settings/security.js'
import Security from './Security.component';
import { setActiveItem as setActiveNavItem } from 'actions/settings'

const SecurityContainer = (props) => {
  const { updatePassword, setActiveNavItem, user, deleteAccount } = props

  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  });
  const [loading, setLoading] = useState(null)
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(null)
  const [wrongOldPassword, setWrongOldPassword] = useState(null)
  const [weakNewPassword, setWeakNewPassword] = useState(null)
  const [updateSuccess, setUpdateSuccess] = useState(false)

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }


  const [deleteEmailVal, setDeleteEmailVal] = useState(null)
  const [showDeleteEmailBtn, setShowDeleteEmailBtn] = useState(null)

  const setDeleteEmailValFn = (e) => setDeleteEmailVal(e.target.value)

  useEffect(() => {
    setShowDeleteEmailBtn(user.email === deleteEmailVal)
  }, [deleteEmailVal])



  const onSubmitDelete = async (e) => {
    e.preventDefault();
    await deleteAccount(user)
    window.location.reload();
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('submitt', formData)
    // if(!validateForm()) return;
    setLoading(true)

    let payload = {
      oldPassword: formData.password, 
      newPassword: formData.newPassword
    };
    console.log(payload, 'the payload')

    if(await updatePassword(payload)) {
      setUpdateSuccess(true)
      setFormData({...formData,
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      });
      setTimeout(() => {
        setUpdateSuccess(false)
      }, 5000)
    } else {
      setWrongOldPassword(true)
      setTimeout(() => {
        setWrongOldPassword(false)
      }, 5000)
    }
    setLoading(false)
  }

  const onBack = () => setActiveNavItem(null)

  const state = {
    onSubmit,
    formData,
    passwordsDoNotMatch,
    wrongOldPassword,
    weakNewPassword,
    updateSuccess,
    loading,
    deleteEmailVal,
    showDeleteEmailBtn
  };

  const containerFunctions = {
    onChange,
    onBack,
    setDeleteEmailValFn,
    onSubmitDelete,
  };

  return (
    <Security
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { 
  updatePassword,
  deleteAccount,
  setActiveNavItem,
})(SecurityContainer);
