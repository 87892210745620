import React from 'react';
import { formatLocation } from 'utils/format'
import { RiImageAddFill } from 'react-icons/ri'
import { computeDuration } from 'component/Cart/utils/price'
import { groupFieldsByReportType } from 'component/Cart/utils/cartItems'
import { renderAvatar } from 'component/reusable/User'
import './CartRow.style.scss'
import { withTranslation } from 'react-i18next'

const CartRow = (props) => {
  const { order, t } = props
  if (!order?.Reports?.length) {
    return t('No Reports in this order');
  }

  const reports = order.Reports;

  let fields = reports.map(el => el.Fields[0])

  fields = fields.filter(el => el !== undefined)

  if(!fields.length){
    return (
      <p className="py-3">{t('Fields related to this order have been deleted')}</p>
    )
  }


  fields = fields.map((el, i) => {
    let theFieldId = el.id
    let rr = reports.filter(el => { return theFieldId == el?.Fields[0].id})
    return {
      ...el,
      Reports: rr,
    }
  })
  let itemGroups = groupFieldsByReportType(fields)

  console.log(itemGroups, '--------------itemGroups')

  return (
    <>
      <div className="fade-in">
      <div className="cart-row header">
          <div className="c1"><p>{t('Field')}</p></div>
          <div className="c2"><p>{t('Service')}</p></div>
          <div className="c4"><p>{t('Reports')}</p></div>
          <div className="c3"><p>{t('Duration')}</p></div>
        </div>
        <>
          {Object.keys(itemGroups).map(key => {
              let field = itemGroups[key].field;

              return (
                <div className="cart-row" key={key}>
                  <div className="c1">
                    {renderAvatar(field, {size: 'xs', icon: 'field'})}
                    <div className="right">
                      <h6>{field.title}</h6>
                      <p>{formatLocation(field.location)}</p>
                    </div>
                  </div>
                  <div className="c2">
                    <p className="legend">{t('Service')}</p>
                    {
                      // reports[0].service === 'e2e'
                      itemGroups[key].reports[0].service === 'e2e' ? <div className="main">
                        <RiImageAddFill size={30} />
                        <h6>{t('E2E')}</h6>
                      </div> :  <div className="main">
                        <RiImageAddFill size={30} />
                        <h6>{t('BYOI')}</h6>
                      </div>
                    }
                  </div>
                  <div className="c4">
                    <p className="legend">{t('Num. Reports')}</p>
                    <h6>{itemGroups[key].reports.length}</h6>
                  </div>
                  <div className="c3">
                    <p className="legend">{t('Y. Duration')}</p>
                    <h6>{computeDuration(itemGroups[key])}</h6>
                  </div>
                </div>
              )
            })
          }
          </>
    </div>
    </>
  )
}

export default withTranslation()(CartRow);
