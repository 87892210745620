import api from '../utils/api';
import {
  EMPTY_REPORTS,
  LOAD_REPORTS,
  UPDATE_BBOX,
} from './types';


const fetchReports = (fieldId) => async dispatch => {
  console.log('[Action] Fetching reports for', fieldId)

  try {
    dispatch({
      type: EMPTY_REPORTS
    });

    const res = await api.get(`/field/reports?fieldId=${fieldId}`);
    dispatch({
      type: LOAD_REPORTS,
      payload: res.data.reports
    });
  } catch (err) {
    alert('err')
  }
};

const updateReportStatus = ({ reportUuid, newStatus }) => async dispatch  => {
  const payload = { reportUuid, newStatus };

  try {
    const res = await api.post(`/reports/update/status`, payload);
    console.log('[Action updateReportStatus]', res);

    return true;
  } catch (err) {
    alert('Something went wrong. Please try again.')
    return false;
  }
}

const updateReportBbox = ({ reportUuid, file, bboxId, comment }) => async dispatch  => {
  const payload = { reportUuid, file, bboxId, comment };

  try {
    const res = await api.post(`/reports/update/bbox`, payload);
    console.log('[Action updateReportBboxComment]', res);

    dispatch({
      type: UPDATE_BBOX,
      payload
    });

    return true;
  } catch (err) {
    alert('Something went wrong. Please try again.')
    return false;
  }
}


export {
  fetchReports,
  updateReportStatus,
  updateReportBbox,
}