import React from 'react';
import { Button } from 'react-bootstrap';
import './InvoiceForm.style.scss'
import { countryList } from 'utils/datasets/countryList'
import { withTranslation } from 'react-i18next'
import { renderCol } from 'component/reusable/form/renderCol'


const InvoiceForm = (props) => {

  const { onChange, formData, showUpdate,
    showErrorMessage,
    showUpdateSuccess, onSubmit, t } = props

  let colProps = {
    onChange,
    formData,
    useMapping: true,
    t
  }

  return (
    <div className="invoice-form">
      <h4>{t('Invoice Details')}</h4>
      <form action="" onSubmit={onSubmit}>
        <div className="row">
          {renderCol({inputName: 'firmTitle', ...colProps })}
          {renderCol({inputName: 'vatId', ...colProps })}
          {renderCol({inputName: 'firmActivity', ...colProps })}
          {renderCol({inputName: 'email', inputLabel: t('Company Email'), onChange, formData, type: 'email'})}

          {renderCol({inputName: 'telephone', inputLabel: t('Company Telephone'), onChange, formData, 
                      type: 'number', min: 0, step: 1})}

          {renderCol({inputName: 'city', ...colProps })}
          {renderCol({inputName: 'address', ...colProps })}
          {renderCol({inputName: 'zipCode', ...colProps })}
          {renderCol({inputName: 'taxAuthority', ...colProps })}
          {renderCol({inputName: 'state', ...colProps })}
          {renderCol({inputName: 'country', ...colProps })}
        </div>

        <div className="action d-flex justify-content-end align-items-center">
          <div className={`btn ${showUpdateSuccess ? "mr-4 text-success d-block" : "d-none"}`}>
            {t('Invoice Details Updated!')}
            </div>
          <div className={`btn ${showErrorMessage ? "mr-4 text-danger d-block" : "d-none"}`}>
            {t('Please verify the data')}
            </div>
          <Button className={`btn ${showUpdate ? "btn-success" : "btn-light"}`}
            type="submit"
            disabled={!showUpdate}>{t('Save Changes')}
            </Button>
        </div>
      </form>
    </div>
  )
}

export default withTranslation()(InvoiceForm);