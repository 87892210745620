import React from 'react';
import './Item.style.scss'
import Add from './Add'
import Edit from './Edit'
import IconBack from 'component/reusable/icon/Back'
import { withTranslation } from 'react-i18next'


const renderAddress = (address, props) => {
  const { onEditClick } = props

  return (
    <div className="list-item-wrap col-md-6">
      <div className="list-item">
        <div className="edit-btn-wrap">
          <Edit addressId={address.id} props={props}/>
        </div>
        <div className="inner">
          <h4>{address.city}, {address.country}</h4>
          <h5>{address.zip_postcode}, {address.state}</h5>
          <p>{address.line_1}</p>
          {address.line_2 ?
          <p>{address.line_2}</p>
          : ''}
          {address.line_3 ?
          <p>{address.line_3}</p>
          : ''}
        </div>
      </div>

    </div>
  )
}

const Item = (props) => {
  const { loading, addresses, handleCreate, onBack, t } = props
  return (
    <>
    <section className="company-item grid-3">
      <div onClick={onBack}><IconBack layout="block" /></div>
      <div className="inner">
        <header>
        <h1>{t('Address Book')}</h1>
        </header>
        <div className="main">
        <div className="row">
        {!addresses.length ?
        <p className="col-12">{t("You haven't added any addresses yet.")}</p>
        :
          addresses.map(el => {
            return (
                renderAddress(el, props)
            )
          })
        }
        </div>
        </div>
        <div className="bottom">
          <div className="btn-wrap">
          <Add handleCreate={handleCreate}/>
          </div>
        </div>
      </div>
      </section>
    </>
  )
}

export default withTranslation()(Item);