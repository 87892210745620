import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCompanies, setActiveItem } from 'actions/company/list'
import List from './List.component';
import { showNewCompany } from 'actions/ui'

const ListContainer = ({ fetchCompanies, companies, showNewCompany,setActiveItem, activeItem }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching companies')
      await fetchCompanies();
      setLoading(false);
    })()
  }, []);

  const onNewCompany = () => showNewCompany(true)

  
  const onCompany = i => e => {
    setActiveItem(i)
  }

  const state = {
    loading,
    companies,
    activeItem
  };

  const containerFunctions = {
    onNewCompany,
    onCompany
  };

  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  companies: state.company.list.list,
  activeItem: state.company.list.activeItem
});

export default connect(mapStateToProps, { 
  fetchCompanies, 
  showNewCompany,
  setActiveItem
 })(ListContainer);