import React from 'react'
import { Stripe } from './Stripe/Stripe.component'
import PriceBox from 'component/Cart/reusable/PriceBox'
import './PaymentMethods.style.scss'
import { useTranslation } from 'react-i18next'

const renderWireTransfer = (props, t) => {
  const { placeOrder } = props

  return (
    <div className="payment-method wire-transfer">
      <h2>{t('Wire Transfer')}</h2>
      <div className="mt-2">
        <h5>{t('Αccount holder')}:</h5>
        <p><strong>farmAIr, Inc.</strong></p>
        <p><strong>{t('Bank details')}:</strong></p>
        <p>BIC: TRWIBEB1XXX</p>
        <p>IBAN: BE14 9671 7402 9783</p>
        <p>Address: TransferWise Europe SA, Avenue Louise 54, Room S52, Brussels. 1050 Belgium</p>
        <p className="mt-3">{t("Send us a copy of your bank's swift report at")} <a href="mailto:sales@farmair.io">sales@farmair.io</a> {t('to complete your ordering process.')}</p>
      </div>
      <form onSubmit={placeOrder}>
        {/* <div className="form-check-group">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="invalidCheck" required />
              <label className="form-check-label" htmlFor="invalidCheck">
                Agree to <a href="/terms-and-conditions" target="_blank">terms and conditions</a>
              </label>
            </div>
          </div> */}

        <div className="btn-wrap">
          <button className="btn btn-primary c-btn-lg"
            type="submit">{t('Place Order')}
            </button>
        </div>

      </form>
    </div>
  )
}

const renderStripe = (props, t) => {
  return (
    <div className="payment-method stripe">
      <h2 className="mb-2">{t('Card Payment')}</h2>
      <p className="by-stripe">{t('By Stripe')}</p>
      <Stripe props={props} />
    </div>
  )
}



const PaymentMethods = ({ props }) => {
  const { t } = useTranslation();

  return (
    <div className="step3 fade-in inner">
      <div className="body">
        <div className="row">
          <div className="col-md-6">
            {renderStripe(props, t)}
          </div>
          <div className="col-md-6">
            {renderWireTransfer(props, t)}
          </div>
        </div>
        <PriceBox props={props} />
      </div>
    </div>
  )
}

export default PaymentMethods;