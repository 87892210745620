import React from 'react'
import { withTranslation } from 'react-i18next'
import axios from 'axios';

const DownloadKml = ({shoot, t}) => {

  const downloadKml = async (e) => {
    e.preventDefault()
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/reports/download/kml`, {
      reportUuid: shoot.report.uuid
    },{
      headers: {
          'Content-Type': 'application/json'
      }
    })

    const url = window.URL.createObjectURL(new Blob([res.data.kml]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${shoot.report.uuid}.kml`); //or any other extension
    document.body.appendChild(link);
    link.click();

  }


  return (
    <form onSubmit={downloadKml}>
      <button type="submit" className="btn btn-light">
        {t('Download Kml')}
      </button>
    </form>
  )
}

export default withTranslation()(DownloadKml);