import React from 'react'

const EnglishPrivacyPolicy = () => {

  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Updated at 2021-02-14</p>
      <h2>Background&nbsp;</h2>
      <p>farmAIr, Inc. and our group of companies (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;farmAIr&rdquo;) are committed to respecting your privacy and personal data. This Privacy Policy describes how we collect and process your personal data collected through our website (https://www.farmAIr.io); Online platform; and any of our services and applications that reference this Privacy Notice (together "Services").</p>
      <p>By using the Service, you are telling us that you agree to our collection and use of data in accordance with this Privacy Policy. If you don&rsquo;t agree with anything in this Privacy Policy, then (i) please let us know and (ii) do not request the Service from us. This Privacy Policy also applies if you contact us or we contact you about our Services, whether by telephone, email, text message, post, push notifications or via third party platforms (including websites or social media platforms).</p>
      <h2>Definitions and key terms</h2>
      <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</p>
      <p>&nbsp;&nbsp;-<span >Cookie</span>:&nbsp;small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</p>
      <p>&nbsp;&nbsp;-<span >Company</span>: when this policy mentions &ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our,&rdquo; it refers to farmAIr, Inc., (651 N Broad St, Suite 206, Middletown, DE 19709, Delaware, U.S.A.) that is responsible for your information under this Privacy Policy.</p>
      <p>&nbsp;&nbsp;-<span >Country</span>: where farmAIr is based, in this case is USA</p>
      <p>&nbsp;&nbsp;-<span >Customer</span>:&nbsp;refers to the company, organization or person that signs up to use the farmAIr Service to manage the relationships with your consumers or service users.</p>
      <p>&nbsp;&nbsp;-<span >Device</span>:&nbsp;any internet connected device such as a phone, tablet, computer or any other device that can be used to visit farmAIr and use the services.</p>
      <p>&nbsp;&nbsp;-<span >IP address</span>: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</p>
      <p>&nbsp;&nbsp;-<span >Personnel</span>:&nbsp;refers to those individuals who are employed by farmAIr or are under contract to perform a service on behalf of one of the parties.</p>
      <p>&nbsp;&nbsp;<span >-Personal Data</span>: any information that directly, indirectly, or in connection with other information &mdash; including a personal identification number &mdash; allows for the identification or identifiability of a natural person.</p>
      <p>&nbsp;&nbsp;-<span >Service</span>: refers to the service provided by farmAIr as described in the relative terms (if available) and on this platform.</p>
      <p>&nbsp;&nbsp;-<span >Third-party service</span>:&nbsp;refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</p>
      <p>&nbsp;&nbsp;-<span >Website</span>: farmAIr&rsquo;s site, which can be accessed via this URL: <a href="https://www.farmair.io" target="_blank" rel="noopener"><span >https://www.farmAIr.io</span></a></p>
      <p>&nbsp;&nbsp;-<span >You</span>: a person or entity that is registered with farmAIr to use the Services.</p>
      <h2>What Information Do We Collect?</h2>
      <p>We collect information from you when you visit our website/app, register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
      <p><span >Identity &amp; contact information:</span> Our Service gives you the ability to register for an account or to create and update a user profile. We collect information that you provide to us in the course of registering for an account or creating or updating a user profile. This information may include, for example, name, postal address, telephone number, e-mail address, profile picture and related demographic information about you. Some personal information is required for you to register for the account or to create the profile, and some is optional. If you register as a business then we save invoice information when you pay for our &ldquo;Service&rdquo;.&nbsp; If you do not provide information that is required, you may not be able to access some of the Services.</p>
      <p><span >Payment and billing information:</span> when using our paid Services, you will be asked for your billing information, including name and contact information. You might also provide payment card details, which we collect via secure payment processing services. If you do not provide the payment and billing information that is required, you may not be able to use these paid Services.</p>
      <p><span >Navigational and Usage information:</span> information we collect about your computer/ device and your visits to our website or mobile applications, including your IP address, browser type, geographical location, how long you visit our website and also which pages you visit as well as unique device identifiers and other diagnostic data.</p>
      <p><span >Location Data:</span> We may use and store information about your location if you give us permission to do so. We use this data to provide features of our Service and to improve and customize our Service. You can enable or disable location services when you use our Service at any time, through your device settings, however this will impact our ability to provide you with some parts of the Service.</p>
      <p><span >Cookies:</span> We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use all portions of our Service.</p>
      <p><span >Local Storage:</span> Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header.</p>
      <p><span >Interactive Data:</span> Our Service may contain interactive functionality that allows you to engage with other users on the Service, to upload images, map data, location data and other content, participate in surveys, and otherwise to interact with the Service and with other users. If you wish to use any interactive functionality on our Service, you will need to provide us with personal information which we will collect, and failure to provide requested information may result in you not being able to use the interactive functionality.</p>
      <p><span >Crop Field Data:</span> We use and store meta-data of the images of your field such as geolocation data so as to avoid improper use of our &ldquo;Service&rdquo;. For example, if you choose the package &ldquo;Bring your own Images&rdquo; you can only upload images of the field which you paid for, if you upload images from another field, the images will be considered invalid.</p>
      <p><span >Advertising &amp; Support: </span>If you email us, call us, or otherwise reach out to us, we&rsquo;ll gather the information you provide so that we may best answer your questions and provide you the support you deserve. We may also use personally identifiable data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you, provided that if you are a new customer, you have opted-in to receiving these commercial communications. If you are an existing customer, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any electronic communication we send. Please be aware that if you opt-out (or do not opt-in, as applicable) of receiving commercial communications from us, you will continue to receive administrative messages from us regarding our Service (like an update to this Privacy Policy).</p>
      <p>When you use our Service, some of this information is collected automatically. For example, we may collect your browser&rsquo;s Internet Protocol (IP), your browser type, the nature of the device from which you are visiting the Service (e.g., a personal computer or a mobile device), the identifier for any handheld or mobile device that you may be using, the website that you visited immediately prior to accessing any web-based Service, the actions you take on our Service, and the content, features, and activities that you access and participate in on our Service. We also may collect information regarding your interaction with e-mail messages, such as whether you opened, clicked on, or forwarded a message. If you are a drone pilot or and/or you operate a drone via our Service, we automatically collect, among other information, information identifying the drone, Iight path, Light logs and sensor data.</p>
      <h2>How Do We Use The Information We Collect?</h2>
      <p>Any of the information we collect from you may be used in one of the following ways:</p>
      <ul>
        <li>To provide, operate &amp; maintain our &ldquo;Service&rdquo;</li>
        <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
        <li>To improve our website/app (we continually strive to improve our website/app offerings based on the information and feedback we receive from you)</li>
        <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
        <li>To process transactions</li>
        <li>To administer a contest, promotion, survey or other site feature</li>
        <li>To send periodic emails</li>
      </ul>
      <h2>How Long Do We Keep Your Information?</h2>
      <p>farmAIr will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We generally retain your personally identifiable data for as long as you keep an active account with us or we are providing you with a Service.&nbsp;</p>
      <p>We also retain certain data in a depersonalized or aggregated form for legitimate business reasons, eg. to train our Machine Learning algorithms and Object-Detection algorithms and to improve our Service or create new Services. We will also retain and use your personally identifiable data to the extent necessary to comply with our legal obligations (for example, to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <p>When we dispose of personally identifiable information, we aim to use secure means, such as either physically or electronically erasing this information or making it anonymous in a non-recoverable manner. Any personal data provided to our service providers will be retained in accordance with those service providers&rsquo; data retention policies, which we make sure are in line with applicable laws.</p>
      <h2>Could my information be transferred to other countries?</h2>
      <p>Your information, including personally identifiable data may be transferred to &mdash; and maintained on &mdash; computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
      <p>If you are located outside of the United States of America and choose to provide information to us, please note that we transfer the data, including personally identifiable information, to Europe or other jurisdictions and process it there. Your consent to this Privacy Policy and your provision of such information represents your agreement to that transfer.</p>
      <p>farmAIr will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your personally identifiable information will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
      <h2>Third parties services</h2>
      <p>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").</p>
      <p>You acknowledge and agree that farmAIr shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. farmAIr does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
      <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</p>
      <h2>Do we share the information we collect with third parties?</h2>
      <p>We do not sell or rent your personally identifiable information to others. We may use third-party service providers to help us operate our business and the Services or administer activities on our behalf, such as analysing the behaviour of users on our website. We may share your personally identifiable information with these third parties for those limited purposes. The processing of information on this basis will always be based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures we have set in place.</p>
      <p>We may share your personally identifiable information with our affiliates to provide and develop our Services. We may combine information internally across the different Services covered by this Privacy Policy to help improve our Services and help our Services be more relevant and useful to you and others.</p>
      <p>We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.&nbsp;</p>
      <h2>Sale of Business</h2>
      <p>We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of farmAIr or any of its Corporate Affiliates (as defined herein), or that portion of farmAIr or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.</p>
      <h2>How Do We Protect Your Data?</h2>
      <p>The security of your data is important to us but please remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personally identifiable information, we cannot guarantee its absolute security. We have taken measures to protect your information and that of your staff and customers that you entrust to us. These measures include: (i) encrypting data in transit and data at rest where appropriate; (ii) using trusted access management platforms which allows us to manage employee access to information depending on their roles; (iii) firewalls; (iv) backup and recovery systems.</p>
      <p>We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file.&nbsp;</p>
      <h3>Breach</h3>
      <p>In the event of a breach involving the security of your personally identifiable information, we will notify the relevant authority. For United States of America this will be the relevant Information Regulator or if you are an EEA member- the supervisory authority in the EU, as well as any parties whose personally identifiable information have been accessed or acquired by an unauthorized party.</p>
      <p>This notification will be published without undue delay, and at the latest within seventy-two hours after us having become aware of the security breach. The notification will contain the following information:</p>
      <p><span >A description of the nature of the breach;</span></p>
      <ul>
        <li>The categories and number of persons affected by the breach;</li>
        <li>A description of the possible consequences of the security compromise;</li>
        <li>A description of the measures taken or proposed to be taken by farmAIr to remedy the breach;</li>
        <li>A recommendation of the measures that any affected persons should take in order to mitigate the possible adverse effects of the security compromise;</li>
        <li>The identity of the unauthorized person, if known, who accessed or acquired the personal information; and the contact details of the designated representative of farmAIr where further information can be obtained.</li>
      </ul>
      <h2>"Do not track" signals</h2>
      <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
      <h2>Service Providers</h2>
      <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. By using our Service, you consent to these Service Providers having access to your personally identifiable data, to perform these tasks on our behalf. These Service Providers are obligated not to disclose or use this information for any other purpose.</p>
      <p>We do not have access to, or control over, the actions of a Service Provider. Each Service Provider uses information that it collects in accordance with its own privacy and security policies.</p>
      <h2>Analytics</h2>
      <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
      <h3>Google Analytics</h3>
      <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="http://www.google.com/intl/en/policies/privacy/)" target="_blank" rel="noopener"><span >http://www.google.com/intl/en/policies/privacy/</span></a></p>
      <h3>Facebook Pixel</h3>
      <p>Facebook pixel&nbsp;is an analytics tool that allows you to measure the effectiveness of your advertising by understanding the actions people take on your website. You can use the&nbsp;pixel&nbsp;to: Make sure your ads are shown to the right people. Facebook pixel may collect information from your device when you use the service. Facebook pixel collects information that is held in accordance with its Privacy Policy</p>
      <h2>Behavioural remarketing</h2>
      <p>farmAIr uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
      <h3>Google AdWords</h3>
      <p>Google AdWords remarketing service is provided by Google Inc. You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads%20%20%20%20" target="_blank" rel="noopener"><span >http://www.google.com/settings/ads&nbsp;&nbsp;&nbsp;&nbsp;</span></a></p>
      <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout </a>- for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web <a href="about:blank" target="_blank" rel="noopener">page: <span >http://www.google.com/intl/en/policies/privacy/</span></a></p>
      <h3>Twitter</h3>
      <p>Twitter remarketing service is provided by Twitter Inc.</p>
      <p>You can opt-out from Twitter's interest-based ads by following their instructions:</p>
      <p><a href="https://support.twitter.com/articles/20170405%20%20%20%20%20%20" target="_blank" rel="noopener"><span >https://support.twitter.com/articles/20170405&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></a></p>
      <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <a href="https://twitter.com/privacy%20%20%20%20%20%20%20%20" target="_blank" rel="noopener"><span >https://twitter.com/privacy&nbsp; &nbsp; &nbsp; &nbsp; </span></a></p>
      <h3>Facebook</h3>
      <p>Facebook remarketing service is provided by Facebook Inc.</p>
      <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/164968693837950" target="_blank" rel="noopener"><span >https://www.facebook.com/help/164968693837950</span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p>To opt-out from Facebook's interest-based ads follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217" target="_blank" rel="noopener"><span >https://www.facebook.com/help/568137493302217</span></a>&nbsp;&nbsp;&nbsp;</p>
      <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/</a><a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">(http://www.aboutads.info/choices/), the Digital Advertising Alliance of Canada in </a>Canada <a href="http://youradchoices.ca/" target="_blank" rel="noopener">http://youradchoices.ca/ (http://youradchoices.ca/) </a>or the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener">http://www.youronlinechoices.eu/ (http://www.youronlinechoices.eu/), </a>or opt-out using your mobile device settings. For more information on the privacy practices of Facebook, please <a href="about:blank" target="_blank" rel="noopener">visit Facebook's Data Policy: <span >https://www.facebook.com/privacy/explanation</span></a>&nbsp;&nbsp;</p>
      <h2>Payments</h2>
      <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors). We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
      <p>The payment processor we work with is:</p>
      <h3>Stripe</h3>
      <p>Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy" target="_blank" rel="noopener"><span >https://stripe.com/us/privacy</span></a></p>
      <h2>Links to Other Websites</h2>
      <p>This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by farmAIr. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website&rsquo;s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
      <h2>Kids' Privacy</h2>
      <p>Our Service does not address anyone under the age of 18 ("Kids"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Kids have provided us with personally identifiable information, please contact us. If we become aware that we have collected personally identifiable information from Kids without verification of parental consent, we will take immediate steps to remove that information from our systems.</p>
      <h2>Changes to Our Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      <h2>When does farmAIr use end user information from third parties?</h2>
      <p>farmAIr will collect End User Data necessary to provide the farmAIr services to our customers.</p>
      <p>End users may voluntarily provide us with information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.&nbsp;</p>
      <h2>When does farmAIr use customer information from third parties?</h2>
      <p>We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming a farmAIr customer, we receive information from a third party that provides automated fraud detection services to farmAIr.</p>
      <h2>How Do We Use Your Email Address?</h2>
      <p>By submitting your email address on this website/app, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</p>
      <h2>Can I update or correct my information?</h2>
      <p>The rights you have to request updates or corrections to the information farmAIr collects depend on your relationship with farmAIr. Personnel may update or correct their information as detailed in our internal company employment policies.</p>
      <p>Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can&nbsp;contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by&nbsp;cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.</p>
      <p>You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.</p>
      <p>If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.</p>
      <h2>Personnel</h2>
      <p>If you are a farmAIr worker or applicant, we collect information you voluntarily provide to us. We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants.</p>
      <p>You may contact us in order to (1) update or correct your information, (2) change your preferences with respect to communications and other information you receive from us, or (3) receive a record of the information we have relating to you. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.</p>
      <h2>Affiliates</h2>
      <p>We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by or is under common control with farmAIr, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.</p>
      <h2>Governing Law</h2>
      <p>By using farmAIr or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website, or use our services. Continued use of the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.</p>
      <h2>Your Consent</h2>
      <p>We've updated our&nbsp;Privacy Policy&nbsp;to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website/app, registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its terms.</p>
      <h2>Information about General Data Protection Regulation (GDPR)</h2>
      <p>We may be collecting and using information from you if you are from the European Economic Area (EEA), and in this section of our Privacy Policy we are going to explain exactly how and why is this data collected, and how we maintain this data under protection from being replicated or used in the wrong way.</p>
      <h3>What is GDPR?</h3>
      <p>GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by companies and enhances the control&nbsp;the EU residents have, over their personal data.</p>
      <p>The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU residents. Our customers&rsquo; data is important irrespective of where they are located, which is why we have implemented GDPR controls as our baseline standard for all our operations worldwide.</p>
      <h3>What is personal data?</h3>
      <p>Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information that could be used on its own, or in combination with other pieces of information, to identify a person. Personal data extends beyond a person&rsquo;s name or email address. Some examples include financial information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity.</p>
      <p>The Data Protection Principles include requirements such as:</p>
      <p>&nbsp;&nbsp;-Personal data collected must be processed in a fair, legal, and transparent way and should only be used in a way that a person would reasonably expect.</p>
      <p>&nbsp;&nbsp;-Personal data should only be collected to fulfil a specific purpose and it should only be used for that purpose. Organizations must specify why they need the personal data when they collect it.</p>
      <p>&nbsp;&nbsp;-Personal data should be held no longer than necessary to fulfil its purpose.</p>
      <p>&nbsp;&nbsp;-People covered by the GDPR have the right to access their own personal data. They can also request a copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.</p>
      <h3>Why is GDPR important?</h3>
      <p>GDPR adds some new requirements regarding how companies should protect individuals' personal data that they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breach. Beyond these facts it's simply the right thing to do. At farmAIr we strongly believe that your data privacy is very important and we already have solid security and privacy practices in place that go beyond the requirements of this new regulation.</p>
      <h3>Individual Data Subject's Rights - Data Access, Portability and Deletion</h3>
      <p>We are committed to helping our customers meet the&nbsp;data subject rights requirements of GDPR. farmAIr processes or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.</p>
      <p>We are aware that if you are working with EU customers, you need to be able to provide them with the ability to access, update, retrieve and remove personal data. We got you! We've been set up as self service from the start and have always given you access to your data and your customers data. Our customer&nbsp;support team is here for you to answer any questions you might have about working with the API.</p>
      <h2>California Residents</h2>
      <p>The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
      <p>We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:</p>
      <p>&nbsp;&nbsp;-Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.</p>
      <p>&nbsp;&nbsp;-Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</p>
      <p>&nbsp;&nbsp;-Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</p>
      <p>&nbsp;&nbsp;-Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
      <p>We do not sell the Personal Information of our users.</p>
      <p>For more information about these rights, please contact us.</p>
      <h2>Contact Us</h2>
      <p>Don't hesitate to contact us if you have any questions.</p>
      <p>&nbsp;-Via Email:&nbsp; info@farmAIr.io</p>
    </>
  )
}

export default EnglishPrivacyPolicy;