import React from 'react';
import Loader from 'component/reusable/Loader'
import Add from 'component/profile/AddressBook/Add'
import InvoiceForm from 'component/profile/UserCompany/Component/InvoiceForm'
import './BillingInfo.style.scss'
import { Link } from 'react-router-dom'
import { renderCol } from 'component/reusable/form/renderCol'
import { AiFillEdit } from 'react-icons/ai'
import { withTranslation } from 'react-i18next'

const renderAddBillingAddress = (props) => {
  const { t } = props
  return (
    <div>
      <p>{t("You haven't added any billing address yet.Please add one to continue")}</p>
    </div>
  )
}

const renderAddress = (address, i, props) => {
  const { setSelectedAddressFn, selectedAddress } = props

  if (!address.User_Address.isBilling) {
    return null
  }
  return (
    <div className="col-md-6" key={i}>
      <div className="billing-address">
        <div className={`form-check${selectedAddress === i ? ' active' : ''}`} 
            onClick={setSelectedAddressFn(i)} 
            onMouseUp={setSelectedAddressFn(i)}>
          <input className="form-check-input" type="radio" name="exampleRadios" 
            id={`address-${i}`} value={i} 
            checked={selectedAddress === i ? true : false} />
          <label className="form-check-label" htmlFor={`address-${i}`}>
            <h4>{address.city}, {address.country}</h4>
            <h5>{address.zip_postcode}, {address.state}</h5>
            <p>{address.line_1}</p>
            {address.line_2 ?
              <p>{address.line_2}</p>
              : ''}
            {address.line_3 ?
              <p>{address.line_3}</p>
              : ''}
          </label>
        </div>
      </div>
    </div>

  )
}

const renderSelectBillingAddress = (props) => {
  const { addresses, handleCreate, t } = props

  return (
    <section className="section-addresses">
      <h5 className="section-title">{t('Select a billing Address')}</h5>
      <div className="billing-addresses">
        <div className="row">
          {
            addresses.map((el, i) => {
              return renderAddress(el, i, props)
            })
          }
        </div>
      </div>
      <div className="billing-address-actions">
        <Add handleCreate={handleCreate} />
        <Link to="/profile/address-book" className="btn btn-light c-btn-lg btn-edit-invoice">
            <AiFillEdit className="mr-2"/>{t('Edit address book')}
        </Link>
      </div>
    </section>
  )
}

const nextButton = (props) => {
  const { goToNextStep, setCartStepFn, t, user } = props

  console.log(props, 'add telephone props')
  let isB2cWithNoMobile = user?.role === 'b2c_admin' && !user?.mobile;
  return (
    <div className="bottom bottom-wrap fade-in">
      {isB2cWithNoMobile ?
      <section className="add-telephone-section">
        <div className="add-telephone-wrap">
          <Link to="/profile" className="btn btn-danger c-btn-lg">
            <AiFillEdit className="mr-2"/>{t('My Profile')}
          </Link>
          <p>{t('Please provide us your mobile number to proceed.')}</p>
        </div>
      </section>

      : null}
      <div className="btn-wrap container-sm ">
        <button className="btn btn-primary c-btn-lg"
          disabled={isB2cWithNoMobile}
          onClick={setCartStepFn(2)}>{t('Proceed to Payment')}
        </button>
      </div>
    </div>
  )
}

const renderNextButton = (props) => {
  const { user, selectedAddress, company, selectedTransaction } = props

  if (user.role === 'b2b_admin') {
    if (selectedTransaction === 1) {
      if (company?.invoiceDetails?.vatId) {
        return nextButton(props)
      }
    } else {
      if (selectedAddress !== null) {

        return nextButton(props)
      }
    }
  } else {
    if (selectedAddress !== null) {
      return nextButton(props)
    }
  }
  return ''
}


const InvoiceInfo = ({ invoiceDetails, t }) => {
  const { vatId, firmTitle, firmActivity, city, address, zipCode,
    taxAuthority, state, country, email, telephone } = invoiceDetails

  let formData = invoiceDetails

  let colProps = {
    formData,
    useMapping: true,
    t,
    readOnly: "true"
  }

  return (
    <>
      <div className="completed row">
        {renderCol({ inputName: 'firmTitle', ...colProps })}
        {renderCol({ inputName: 'vatId', ...colProps })}
        {renderCol({ inputName: 'firmActivity', ...colProps })}
        {renderCol({ inputName: 'taxAuthority', ...colProps })}
        {renderCol({
          inputName: 'email', inputLabel: 'Email', type: "email", readOnly: "true", formData
        })}
        {renderCol({ inputName: 'telephone', ...colProps })}
        {renderCol({ inputName: 'city', ...colProps })}
        {renderCol({ inputName: 'address', ...colProps })}
        {renderCol({ inputName: 'zipCode', ...colProps })}
        {renderCol({ inputName: 'state', ...colProps })}
        {renderCol({ inputName: 'country',...colProps })}
      </div>
      <div className="btn-wrap mt-4">
        <Link to="/profile/company" className="btn btn-light c-btn-lg btn-edit-invoice">
          <AiFillEdit className="mr-2"/>{t('Edit invoice details')}
      </Link>
      </div>
    </>
  )
}

const renderSelectTransactionType = (props) => {
  const { user, setSelectedTransactionFn, selectedTransaction, company, t } = props

  if (user.role !== 'b2b_admin') {
    return ''
  }

  return (
    <section className="section-transaction">
      <h4 className="mb-3">{t('Transaction Type')}</h4>

      <div className="form-checks form-check-group">
        <div className="form-check mb-2" onClick={setSelectedTransactionFn(0)}>
          <input className="form-check-input" type="radio" name="transaction"
            id="transaction-receipt" checked={selectedTransaction === 0 ? true : false} />
          <label className="form-check-label" htmlFor="transaction-receipt">{t('Receipt')}</label>
        </div>
        <div className="form-check" onClick={setSelectedTransactionFn(1)}>
          <input className="form-check-input" type="radio" name="transaction"
            id="transaction-invoice" checked={selectedTransaction === 1 ? true : false} />
          <label className="form-check-label" htmlFor="transaction-invoice">{t('Invoice')}</label>
        </div>
      </div>
      { selectedTransaction === 1 ?
        <div className="invoice-wrap fade-in">
          {!company?.invoiceDetails?.vatId ?
            <InvoiceForm props={props} addBEAddress={true} /> :
            <InvoiceInfo invoiceDetails={company.invoiceDetails} t={t} />
          }
        </div>
      : ''}
    </section>
  )
}

const renderBody = (props) => {
  const { t, selectedTransaction, addresses } = props;
  return (
    <div className="body">
    {renderSelectTransactionType(props)}
    <>
      {selectedTransaction === 0 ?
        <>
          <h4 className="my-3">{t('Billing Address')}</h4>
          {!addresses.length ? renderAddBillingAddress(props) : ''}
          {addresses.length ? renderSelectBillingAddress(props) : ''}
        </>
        : ''}
    </>
  </div>
  )
}

const renderBottom = (props) => {
  const { addresses, handleCreate } = props
  return (
    <>
      {!addresses.length ? <div className="bottom">
      <div className="btn-wrap">
        <Add handleCreate={handleCreate} />
      </div>
      </div> : renderNextButton(props)}
    </>
  )
}

const BillingInfo = (props) => {
  const { loading} = props

  return (
    <section className="section-cart-billing fade-in inner">
      {loading ? <Loader />  : renderBody(props)}
      { renderBottom(props) }
    </section>
  )
}

export default withTranslation()(BillingInfo);