import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Item from './Item.component';

const showCta = ({status, service}) => {
  if(['pending', 'pending_payment', 'processing'].includes(status)){
    return true;
  }
  return false;
}


const ItemContainer = ({props}) => {

  const { field, selectedReport } = props

  const report = field.Reports.find(el => el.uuid === selectedReport);
  const service = report.service;

  const state = {
    showCta: showCta({status: report.status, service}),
    propsProps: props,
    report
  };

  const containerFunctions = {

  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ItemContainer);