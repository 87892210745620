import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { Button, Row, Col } from 'react-bootstrap'
import GuestHeader from 'route/SignUp/component/GuestHeader'
import Benefits from 'route/SignUp/component/Benefits'
import FormGroup from 'component/reusable/form/FormGroup';
import './Login.style.scss';

const Login = (props) => {
  const { loading, onSubmit, validated } = props
  return (
    <section className="guest-page">
      <GuestHeader />
      <main className="" aria-label="Sign Up Page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 left">
              <Benefits />
            </div>
            <div className="col-md-6 right">
              <div aria-label="Login Page" className="login-page inner vertical-center">
                <form autoComplete="off" className="needs-validation"
                  onSubmit={onSubmit} noValidate validated={validated}
                >
                  <h1>Sign In</h1>
                  <div className="form-groups">
                    <FormGroup props={props} opts={{
                      name: "email",
                      type: "text",
                      label: "Email address",
                      invalidFeedback: "Please choose a valid email.",
                      required: true
                    }} />
                    <FormGroup props={props} opts={{
                      name: "password",
                      type: "password",
                      label: "Password",
                      invalidFeedback: "Please choose a valid password.",
                      required: true
                    }} />
                  </div>
                  <Button type="submit" disabled={!props.btn.enabled}
                    className={`${props.btn.type} w-100 c-btn-lg`}>
                    Sign In
                  </Button>
                  <Row className="no-gutters mt-4">
                    <div className="col-md-12">
                      <Link to="/passwordreset" className="link-small">Forgot Password?</Link>
                    </div>
                    <div className="col-md-12">
                    <Link to="/signup" className="link-small">Create Account</Link>
                    </div>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>

  );

}

export default Login;