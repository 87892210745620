import React from 'react';
import Loader from 'component/reusable/Loader'
import './List.style.scss'
import { MdReceipt } from 'react-icons/md';
import { formatLocation, formatArea, formatPrice as fp } from 'utils/format'
import moment from 'moment'
import { formatStatus } from 'component/Shoot/utils/formatStatus'
import { renderAvatar } from 'component/reusable/User'
import { withTranslation } from 'react-i18next'

const renderOrders = (props) => {
  const { orders, activeItem, onOrder, t } = props

  if (!orders.length) return
  return orders.map((el, i) => {
    return <div onClick={onOrder(i)} className={"list-item " + (activeItem === el.uuid ? 'active' : '')}>
      {renderAvatar(null, {size: 'xs', icon: 'order'})}
      <div className="details">
        <h4 className="mb-2">{el?.user?.firstName} {el?.user?.lastName}</h4>
        <p>{fp(el.finalPrice)}, {el?.Reports.length} {t('Reports')}</p>
        <p>
            {formatStatus(el, t)} / <span>{moment(el.createdAt).format("MMM D, YYYY")}</span>
        </p>
      </div>
    </div>
  })

}


const List = (props) => {
  const { loading, orders, t } = props
  return (
    <section className="company-list grid-2">
      <header>
        <h2>{t('Orders')}</h2>
      </header>
      <div className="main">
        {loading ? <Loader /> : renderOrders(props)}
        {!loading && !orders.length ? <div className="grid-empty">{t('No orders yet')}</div> : ''}
      </div>
      <div className="bottom">
        {/* <Button onClick={onNewCompany}>Add Company</Button> */}
      </div>
    </section>
  )
}

export default withTranslation()(List);