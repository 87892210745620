import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import IconField from "component/reusable/icon/Field";
import { MdReceipt } from "react-icons/md";

const icons = {
  user: <AiOutlineUser size={75} />,
  company: <CgOrganisation size={75} />,
  field: <IconField />,
  order: <MdReceipt size={75} />,
};

export const renderAvatar = (obj, opts) => {
  let imageSrc;
  let icon;

  let avatar;
  if (obj && obj?.avatar) {
    avatar = obj.avatar;
  }

  // console.log('rendering!!', avatar )
  if (opts?.size) {
    icon = icons[opts.size];
  }
  if (opts?.icon) {
    icon = icons[opts.icon];
  } else {
    icon = icons.user;
  }
  if (avatar) {
    if (opts?.size) {
      imageSrc = obj.avatar[opts.size];
    } else {
      imageSrc = obj.avatar.xs;
    }
  }

  //TODO: refactor database to not save hostname, only uri,
  //TODO: because now we are using cdn and in general, host might change
  if (imageSrc?.includes("farmair.s3.eu-central-1.amazonaws.com")) {
    let cdnImageSrc = imageSrc.split("amazonaws.com/")[1];
    cdnImageSrc = `${process.env.REACT_APP_S3}${cdnImageSrc}`;
    imageSrc = cdnImageSrc;
  }

  return (
    <figure className={`avatar-wrap ${opts?.icon}`}>
      {!avatar || !Object.keys(avatar).length ? icon : ""}
      {avatar && Object.keys(avatar).length ? (
        <img src={imageSrc} alt={`avatar`} className="img-fluid" />
      ) : (
        ""
      )}
    </figure>
  );
};
