import React from 'react';
import MapBox from './Mapbox'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { formatLocation, formatArea } from 'utils/format'
import { VscChromeClose } from 'react-icons/vsc'
import moment from 'moment'
import './Shoot.style.scss'
import Loader from 'component/reusable/Loader'
import renderFieldDetails from 'component/Field/utils/renderFieldDetails'
import renderFieldOwner from 'component/Field/utils/renderFieldOwner'
import { formatStatus } from 'component/Shoot/utils/formatStatus'
import { renderTable } from 'component/Field/utils/renderRow'
import ConfirmShoot from 'component/Shoot/Item/component/ConfirmShoot'
import { withTranslation } from 'react-i18next'
import { renderDates } from './Shoot.utils'
import GoogleMapsButton from 'component/reusable/Button/GoogleMaps'
import DownloadKml from './DownloaKml'
import shootDay from './ShootDay'

const renderNoActionDropDown = ({ type, props }) => {
  const { shoot } = props
  let subj = type.slice(0, -1) // droneAdmins -> droneAdmin
  if (!shoot || !shoot[subj]) return
  return (
    <>
      <div className="admin-name">
        {shoot[subj].firstName} {shoot[subj].lastName}
      </div>
      <div>{shoot[subj].email}</div>
    </>
  )
}

const RenderSelectedUser = ({ type, props }) => {
  const { droneAdmins, droneOperators, selectedDroneOperator, selectedDroneAdmin,
    onSelectUserClick, onSubmitUser, t } = props

  let da;
  if (type === 'droneAdmins') {
    da = droneAdmins[selectedDroneAdmin]
  } else {
    da = droneOperators[selectedDroneOperator]
  }
  console.log(da, 'the da-----------')
  if (!da) return
  return (
    <div className="mt-4 selected-drone-admin">
      <div className="d-flex">
        <h6 className="mr-3">{t('Selected User')}</h6>
        <VscChromeClose onClick={onSelectUserClick({ type, i: null })} className="cursor-pointer" />
      </div>

      { da && <p className="mb-3">{da.firstName} {da.lastName}</p>}
      <div>
        <button className="btn c-btn-lg btn-primary"
          onClick={onSubmitUser(type)}
        >{t('Save')}</button>
      </div>
    </div>
  )
}

const SelectUser = ({ type, props }) => {
  const { droneAdmins, onSelectUserClick, selectedDroneAdmin,
    selectedDroneOperator, t } = props
  let usersToSelect = props[type]
  if (!usersToSelect) return
  let roleToSelect = type === 'droneAdmins' ? 'drone_admin' : 'drone_operator'

  return (
    <div className="mt-4 ">
      <div className="mb-2">{t('No User Assigned')}</div>
      <DropdownButton variant="light" id={`dropdown-select-${type}`} title={`${t('Select User')}`} key={type}>
        {usersToSelect.map((user, i) => {
          if (user.role === roleToSelect) {
            return <Dropdown.Item as="button" key={i} eventKey={i} onClick={onSelectUserClick({ type, i })}>
              {user.firstName} {user.lastName}
            </Dropdown.Item>
          } else {
            return null;
          }
        })}
      </DropdownButton>
      { type == "droneAdmins" && selectedDroneAdmin !== null ?
        <RenderSelectedUser type="droneAdmins" props={props} />
        : ''
      }
      { type == "droneOperators" && selectedDroneOperator !== null ?
        <div>
          <RenderSelectedUser type="droneOperators" props={props} />
        </div>
        : ''
      }
    </div>
  )
}



const uploadButton = (props) => {
  const { onUploadData, shoot, t } = props
  let { report } = shoot
  let showBtn = true;

  if (['pending', 'pending_payment', 'processing_data', 'complete'].includes(report.status)){
    showBtn = false;
  }

  return (
    showBtn ?
    <>
    <h4>{t('Upload Dataset')}</h4>
      <button className="mt-4 btn btn-primary c-btn-lg" 
          onClick={onUploadData}>{t('Upload Dataset')}
      </button>
    </>
    : ''
  )
}



const renderOperationDetails = (props) => {
  const { shoot, setShowConfirmShootFn, onUploadData, userRole, t, fetchShootsFn } = props

  console.log(props, 'COMPNONENT PROPS')
  return (
    <section className="operation-details">
      {shoot.shootDays ?
        renderDates(shoot, t)
        : ''}
      {shoot.dateRange ?
        renderTable({
          title: t('Client Availability'),
          rows: [
            [t('From'), moment(shoot.dateRange.start).format("MMM D, YYYY")],
            [t('To'), moment(shoot.dateRange.end).format("MMM D, YYYY")]
          ]
        })
        : ''}
      <div className="mt-4 bg-light px-4 py-3">
        <h4>{t('Drone Operator')}</h4>
        {shoot.droneAdmin ?
          renderNoActionDropDown({ type: 'droneAdmins', props }) :
          <SelectUser type="droneAdmins" props={props} key="droneAdmins" />
        }
      </div>
      <div className="mt-4 bg-light px-4 py-3">
        <h4>{t('Drone Pilot')}</h4>
        {shoot.droneOperator ?
          renderNoActionDropDown({ type: 'droneOperators', props }) :
          <SelectUser type="droneOperators" props={props} key="droneOperators" />
        }
      </div>
      {shoot.droneAdmin && shoot.dateRange && !shoot.shootDays 
       && !['drone_operator'].includes(userRole) ?
        <div className="my-4 mb-3 bg-light px-4 py-3">
          <h4 className="mb-3">{t('Confirm Dates')}</h4>
          <button className="btn c-btn-lg btn-primary" onClick={setShowConfirmShootFn(true)}>
            {t('Select Confirm Dates')}
        </button>
        </div>
        : ''}
      <br/>
      <br/>
      <section className="bg-light py-4 p-3">
        {uploadButton(props)}
        {shootDay(shoot, t, fetchShootsFn)}
      </section>

    </section>
  )
}


const renderItem = (props) => {
  const { shoot, showConfirmShoot, downloadKml, t } = props
  const { field } = shoot
  console.log(shoot, props, '11111111111111')
  let coords = field?.polygonPoints[0];

  console.log(coords[0], '00')

  return (
    <>
      <MapBox field={shoot.field} />

      <main className="">
        <header className="mb-5">
          <h5>{t('Field Name')}</h5>
          <h2>{shoot.field.title}</h2>
          <p>{t('Location')}: {shoot.field?.location?.country && formatLocation(shoot.field.location)}</p>
        </header>
        <div className="row">
          <div className="col-md-6">
            {
              renderTable({
                title: t('Photo Shoot Details'),
                rows: [
                  [t('Photo Shoot Status'), formatStatus(shoot, t)],
                  [t('Report Status'), formatStatus(shoot.report, t)],
                  [t('Shoot Uuid'), shoot.uuid],
                  [t('Entity Id'), shoot.id],
                  [t('Report Uuid'), shoot?.report?.uuid],
                  [t('Updated At'), moment(shoot.updatedAt).format("MMM D, YY | hh:mm")],
                  [t('Created At'), moment(shoot.createdAt).format("MMM D, YY | hh:mm")],
                ]
              })
            }
          </div>

          <div className="col-md-6">
            {renderFieldDetails(shoot)}
          </div>

          <div className="col-md-6">
            {renderOperationDetails(props)}
          </div>

          {shoot.owner ?
            <div className="col-md-6">{renderFieldOwner(shoot.owner)}</div>
          : ''}

          <div className="col-md-6 mt-4">
            <DownloadKml shoot={shoot} />
            <GoogleMapsButton field={field} />
          </div>

        </div>
      </main>
      { showConfirmShoot ? <ConfirmShoot props={props} /> : ''}
    </>
  )
}


const Shoot = (props) => {
  const { loading, shoot } = props

  return (
    <section className="company-item field-item shoot-item grid-3 mt-0 grid-w-100">
      <div className="inner">
        {!loading && shoot?.field && renderItem(props)}
        {loading ? <Loader /> : ''}
      </div>
    </section>

  )
}

export default withTranslation()(Shoot);