import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { showNewReport as showNewReportAction} from 'actions/ui';
import { updateField, deleteField } from 'actions/field'
import EditDetails from './EditDetails.component';
import { setActiveItem } from 'actions/company/list';
import { fetchFields } from 'actions/field/list'
import { validAge, validMeter, validInteger } from 'utils/validate'
import { getUsedForValue, getUsedForString } from 'component/Field/NewField/Form/Form.utils'

const EditDetailsContainer = (props) => {
  const { showEditDetails, showNewReportAction, updateField, deleteField, fetchFields } = props
  const { informParent } = props.props
  const [modalVisibility, setModalVisibility] = useState(false);
  const [fieldType, setFieldType] = useState(1);

  let { field = null } = props.props

  const handleFieldTypeChange = val => setFieldType(val);

  const [formData, setFormData] = useState({
    title: null, 
    variety: null,
    age: null, 
    avgPlantHeight: null, 
    altitude: null, 
    slope: null, 
    fieldId: null
  });

  const [confirmField, setConfirmField ] = useState(null);

  useEffect(() => {
    if(field){
      let { title, variety, age, avgPlantHeight, altitude, slope, id, usedFor } = field
      age = age < 1 ? 1  : age;
      altitude = altitude < 1 ? 1  : altitude;
      slope = slope < 1 ? 1  : slope;
      avgPlantHeight = avgPlantHeight < 1 ? 1  : avgPlantHeight;
      setFormData({
        title,
        variety, 
        age: validAge(age), 
        avgPlantHeight: validMeter({m: avgPlantHeight }),
        altitude: validInteger({val: altitude }), 
        slope: validInteger({val: slope, max: 100 }), 
        fieldId: id
      })
      setFieldType(getUsedForValue(usedFor))
    }
  }, [field])


  const onChange = e => {
    if(e.target.name === 'age'){
      setFormData({ ...formData, age: validAge(e.target.value) });
    }else if(e.target.name === 'avgPlantHeight') {
      setFormData({ ...formData, avgPlantHeight: validMeter({m: e.target.value, min: 0.5 }) });
    }else if(e.target.name === 'altitude'){
      setFormData({ ...formData, altitude: validInteger({val: e.target.value}) });
    }else if(e.target.name === 'slope'){
      setFormData({ ...formData, slope: validInteger({val: e.target.value, max: 100}) });
    }else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }

  const onConfirmChange = e => {
    setConfirmField(e.target.value)
  }



  const handleClose = () => {
    setModalVisibility(false)
    setTimeout(() => {
      showNewReportAction(false)
      informParent(false)
    }, 350)

  }

  useEffect(() => {
    setModalVisibility(showEditDetails)
    console.log('[showEditDetails] container')
  }, [showEditDetails])



  const onSubmitForm = async (e) => {
    e.preventDefault();
    console.log(formData)
    let payload = {
      ...formData,
      usedFor: getUsedForString(fieldType)
    }
    if(await (updateField(payload))){
      console.log('ok')
      await fetchFields({keepActiveItem: true});
      handleClose();
    }
    // createCompany(values)
  }

  const onFieldDelete = async (e) => {
    e.preventDefault();
    let payload = { fieldId: formData.fieldId }
    if(await (deleteField(payload))){
      console.log('ok')
      await fetchFields();
      handleClose();
    }
  }


  const state = {
    modalVisibility,
    formData,
    confirmField,
    field,
    fieldType,
  };

  const containerFunctions = {
    handleClose,
    onSubmitForm,
    onChange,
    onConfirmChange,
    onFieldDelete,
    setFormData,
    handleFieldTypeChange
  };

  return (
    <EditDetails
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
  showNewReportAction,
  updateField,
  deleteField,
  fetchFields,
})(EditDetailsContainer);

