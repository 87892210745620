import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { setActiveItem } from 'actions/field/list'
import { connect } from 'react-redux';

import Li from './Li.component';

const LiContainer = ({ theKey, fieldGroup, setActiveItem, activeItem, order }) => {
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(null);
  const [groupDisplay, setGroupDisplay] = useState({
    locationTitle: null,
    totalArea: null
  })

  useEffect(() => {
    // let location = fieldGroup[0].location;
    // let locationTitle = `${location.region}, ${location.country}`
    // let totalArea = 0;
    // fieldGroup.forEach(field => {
    //   totalArea += field.area
    // })
    // setGroupDisplay({...groupDisplay, locationTitle, totalArea })
    // setKey(theKey);
  }, [])

  const setOpenDom = val => e => setOpen(val)

  // const onField = i => e => {
  //   setActiveItem(i)
  // }

  const state = {
    order,
    open,
    // groupDisplay,
    key,
    activeItem,
  };

  const containerFunctions = {
    setOpen,
    setOpenDom,
    // onField
  };

  return (
    <Li
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setActiveItem })(LiContainer);