import HdPhoto from "component/Field/MapboxDirections/Form/HdPhoto";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { VscChromeClose } from "react-icons/vsc";
import "./Mapbox.style.scss";

import Weather from "component/Mapbox/Weather";
import AgroSoilData from "component/Mapbox/AgroSoilData";

const Mapbox = (props) => {
  const { hdPhotoVisibility, setHdPhotoVisibility, report, acFile } = props;

  console.log(hdPhotoVisibility, "HDPHOTOVISIBILITY]", report);

  return (
    <div className="mapbox-wrap">
      <div
        ref={props.mapRef}
        className="mapContainer"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
        }}
      ></div>

      <section className="map-bottom-wrap">
        <Weather />
        <AgroSoilData report={report} />
      </section>

      <Modal
        show={hdPhotoVisibility}
        onHide={() => setHdPhotoVisibility(false)}
        dialogClassName="modal-annotate annotate hd-photo"
        backdropClassName="modal-backdrop-annotate annotate hd-photo"
      >
        <Modal.Body className="p-0">
          <HdPhoto layout="pin-hd-photo" report={report} acFile={acFile} />
          <div className="close-wrap">
            <Button variant="reset" onClick={() => setHdPhotoVisibility(false)}>
              <VscChromeClose size={28} />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Mapbox;
