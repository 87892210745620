import { SET_SATELLITE_GRAPH_DATA } from "actions/types";

const setSatelliteGraphData = (satelliteGraphData) => async (dispatch) => {
  dispatch({
    type: SET_SATELLITE_GRAPH_DATA,
    payload: satelliteGraphData,
  });
};

export { setSatelliteGraphData };
