import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useRef } from 'react';
import api from 'utils/api';

import SignUp from './SignUp.component';

const SignUpContainer = () => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [userType, setUserType] = useState(1);
  const [showSignUpComplete, setShowSignUpComplete] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });

  const recaptchaRef = useRef();

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onUserTypeChangeFn = val => e => setUserType(val);

  const onSubmit = async (e) => {
    e.preventDefault();

    const role = userType === 1 ? 'b2b_admin' : 'b2c_admin';

    setLoading(true)
    try {
      const token = await recaptchaRef.current.executeAsync();

      const payload = {...formData, role, recaptchaResponse: token}

      console.log(token, 'the token')
      const createdUser = await api.post(`/user/create`, payload);
      setShowSignUpComplete(true)

      // const loggedIn = await api.post('/user/login', {
      //   email: formData.email,
      //   password: formData.password
      // })
      // window.location.replace('/')

      setLoading(false)
    } catch (err) {
      console.log('Something went wrong. Please try again later.')
      // alert('Something went wrong. Please try again later.')
      setLoading(false)
    }
  }

  const state = {
    validated,
    userType,
    formData,
    loading,
    recaptchaRef,
    showSignUpComplete
  }

  const containerFunctions = {
    onSubmit,
    onChange,
    onUserTypeChangeFn
  };


  return (
      <SignUp
        // { ...this.props }
        { ...state }
        { ...containerFunctions }
      />
  );
}

export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SignUpContainer);