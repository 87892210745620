import React from 'react';
import { Button, } from 'react-bootstrap'
import { Link } from "react-router-dom";
import GuestHeader from './component/GuestHeader'
import Benefits from './component/Benefits'
import './SignUp.style.scss';
import FullScreenLoader from 'component/reusable/FullScreenLoader'
import { renderCol } from 'component/reusable/form/renderCol'
import ReCAPTCHA from "react-google-recaptcha";

const renderSingUpComplete = (props) => {
  return (
    <div className="verify-complete fade-in">
      <h1>Thanks for signing up!</h1>
      <p>You 're almost ready to start enjoying the farmAIr App.</p>
      <p className="mb-3">Please verify your email address by visiting your email inbox and following the provided link.</p>
      <p className="mb-5">Once this is done, you will be able to sign in to your account.</p>
      <Link to="/login" className="btn btn-primary c-btn-lg">Sign in</Link>
    </div>
  )
}


const renderSingUpForm = (props) => {
  const { userType, onUserTypeChangeFn, loading, formData, onSubmit, onChange, recaptchaRef } = props

  return (
    <form onSubmit={onSubmit} className="edit-field">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      />
      <h1>Sign Up</h1>
      <div className="form-groups">
        {renderCol({inputName: 'firstName', onChange, formData, useMapping: true, wrapClassName: ''})}
        {renderCol({inputName: 'lastName', onChange, formData, useMapping: true, wrapClassName: ''})}
        {renderCol({inputName: 'email', onChange, formData, useMapping: true, wrapClassName: ''})}
        {renderCol({inputName: 'password', onChange, formData, useMapping: true, wrapClassName: '', })}

        <div className="user-type-wrap">
          <legend>Sign Up as:</legend>
          <div className="form-group form-radio">
            <label htmlFor="check-1" className="form-radio-label"
              onClick={onUserTypeChangeFn(1)}>
              <input type="radio" id="check-1" className="form-control"
                checked={userType === 1} />
              <span>Business</span>
            </label>
          </div>
          <div className="form-group form-radio"
            onClick={onUserTypeChangeFn(2)}>
            <label htmlFor="check-2" className="form-radio-label">
              <input type="radio" id="check-2" className="form-control"
                checked={userType === 2} />
              <span>Personal</span>
            </label>
          </div>
        </div>

        <div className="form-check-group mb-3">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="terms-use" required />
            <label className="form-check-label" htmlFor="terms-use">
              Agree to <a href="/privacy-policy" target="_blank">privacy policy</a>
            </label>
          </div>
        </div>

        <div className="form-check-group mb-3">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="terms-conditions" required />
            <label className="form-check-label" htmlFor="terms-conditions">
              Agree to <a href="/terms-of-service" target="_blank">terms of service</a>
            </label>
          </div>
        </div>



        <Button variant="primary" type="submit" className="w-100 c-btn-lg">Create Account</Button>
        <div className="login-wrap">
          <p>Did you have an account? <span><Link to="/login">Sign In</Link></span></p>
        </div>

      </div>
    </form>
  )
}

const SignUp = (props) => {
  const { loading, showSignUpComplete } = props
  return (
    <section className="guest-page">
      <GuestHeader />
      { loading ? <FullScreenLoader /> : ''}
      <main className="" aria-label="Sign Up Page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 left">
              <Benefits />
            </div>
            <div className="col-md-6 right">
              <div className="inner sign-up-wrap vertical-align">
                { showSignUpComplete ?
                  renderSingUpComplete(props) :
                  renderSingUpForm(props)
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  )

}

export default SignUp;