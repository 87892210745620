import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import React from 'react';

import './About.style.scss';
import { Link } from "react-router-dom";

export class About extends PureComponent {

  render() {
    return (
        <main aria-label="Login Page">
          <h1>About  -----  Page</h1>
          <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut dolore ipsum illum nam laborum consectetur excepturi aliquam voluptatum eius ipsa expedita, quam rem eligendi doloribus veniam suscipit quas illo id?</div>
          <Link to="/login">Login</Link>
          <Link to="/signup">SignUp</Link>
            {/* <ContentWrapper>
            </ContentWrapper> */}
        </main>
    );
}
}

export default About;