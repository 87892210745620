import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { AbilityContext } from './Can'
import TheLayout from 'container/TheLayout.js';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const AppRouter = ({ permissions }) => {
  return (
    <AbilityContext.Provider value={permissions}>
      <Router>
        <React.Suspense fallback={loading}>
          <Fragment>
            {/* <Navbar /> */}
            <Switch>
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </Fragment>
        </React.Suspense>
      </Router>
    </AbilityContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions
});

export default connect(mapStateToProps)(AppRouter);
