import React from 'react';

import './Item.style.scss'


const Item = (props) => {

  return (
    <section className="page-404">
        <h1>404</h1>
        <h2>We couldn't find this page : /</h2>
    </section>
  )
}

export default Item;