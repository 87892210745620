import React from 'react';
import moment from 'moment'
import './Item.style.scss'
import { renderAvatar } from 'component/reusable/User'
import { formatRole } from 'utils/format'
import { withTranslation } from 'react-i18next';


const renderUsers = ({ Users }) => {
  return (
    <div className="row">
    {Users.map(user => {
      return (
        <div className="col-sm-6">
         <div className="user">
           <div>
           {renderAvatar(user, {icon: 'user'})}
           </div>
            <div className="right">
              <h4>{user.firstName}<br /> {user.lastName}</h4>
              <p>{user.email}</p>
              <p>{formatRole(user.role)}</p>
            </div>

         </div>
     </div>
      )
    })}
    </div>
  )
}


const renderCompany = (company, t ) => {
  return (
    <>
    <header>
        {renderAvatar(company, {size: 'md', icon: 'company'})}
        <h2>{ company.title }</h2>
        <p>{ company.email }</p>
        <p>{ company.telephone }</p>
    </header>
    <main className="mt-5">
      {/* <h4 className="mb-4">Users</h4> */}
      { company.Users.length ? renderUsers(company) : 'No Users Added yet..' }
      <div className="mt-5">
      <strong>{t('Created At')}</strong> <p>{moment(company.createdAt).format("MMM D, YYYY")}</p>
      </div>
    </main>

    </>
  )
}


const Item = ({ company, loading, t }) => {

  return (
    <section className="company-item grid-3 ">
      <div className="inner fade-in">
          { !loading && company && company.title &&  renderCompany(company, t)}
          { !loading && !company && <div>{t('No company selected')}</div>}
      </div>

      </section>

  )
}

export default withTranslation()(Item);