import React from 'react';

import './Loader.style.scss'


const Loader = (props) => {

  return (
    <div className="loader-box">
      <div className="loader"></div>
    </div>
  )
}

export default Loader;