import React, { useState } from 'react';
import './Item.style.scss'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import { countryList } from 'utils/datasets/countryList'
import { AiFillPlusSquare } from 'react-icons/ai'
import { withTranslation } from 'react-i18next'

//TODO: refactor to use renderCol component
const addressFields = {
  // type: [],
  city: '',
  country: '',
  state: '',
  zip_postcode: '',
  line_1: '',
  line_2: '',
  line_3: ''
}
let options = {
  // type: { type: 'checked', required: true },
  city: { label: 'City', required: true },
  country: { label: 'Country', type: 'select', required: true },
  state: { label: 'State', required: true },
  zip_postcode: { label: 'Post Code', required: true },
  line_1: { label: 'Line 1', required: true },
  line_2: { label: 'Line 2' },
  line_3: { label: 'Line 3' },
}


const renderGroup = ({ name, opts, t }) => {
  const type = opts?.type ? opts?.type : 'text'

  if(type === 'checked'){
    return (
      <div role="group" aria-labelledby="checkbox-group" className="form-group">
      <label>
        <Field type="checkbox" name="checked" value="isBilling" className="form-control"/>
        <span>This is default Billing Address</span>
      </label>
      <label>
        <Field type="checkbox" name="checked" value="isShipping" className="form-control" />
        <span>This is default Shipping Address</span>
      </label>
    </div>
    )
  }
  return (
    <div className="form-group">
      <label htmlFor={name}>{t(opts?.label)}</label>
      {type === 'text' ?
        <Field type="text" name={name} className="form-control" required={opts?.required} />
        : ''}
      {type === 'select' ?
        <Field as="select" name={name} className="form-control" required={opts?.required}>
          <option value=''></option>
          {name === 'country' ?
            countryList.map(el => {
              return <option value={el}>{el}</option>
            })
            : ''}
        </Field>
        : ''}
    </div>
  )
}


const renderAddressForm = (props) => {
  const { setShowFn, submitForm, t } = props

  return (
    <div>
      <Formik initialValues={addressFields}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values)
          setTimeout(() => {
            setSubmitting(false);
          })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Modal.Body className="delete-modal-body">
              {Object.keys(addressFields).map(el => {
                return renderGroup({ name: el, opts: options[el], t })
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={setShowFn(false)}>{t('Cancel')}</Button>
              <Button variant="primary" type="submit">{t('Save Address')}</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>

    </div>
  )
};


const Item = (props) => {

  const { show, setShowFn, t } = props

  return (
    <>
      <button className="btn btn-light c-btn-lg btn-new-address"
        onClick={setShowFn(true)}
      ><AiFillPlusSquare className="mr-2"/>{t('Add new address')}
      </button>
      <Modal
        show={show}
        onHide={setShowFn(false)}
        dialogClassName="modal-new-address"
        backdropClassName="modal-backdrop-new-address"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Billing Address')}</Modal.Title>
        </Modal.Header>
          {renderAddressForm(props)}
      </Modal>
    </>
  )
}

export default withTranslation()(Item);