import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import React from 'react';
import Router from './Router.component';
import PWAPrompt from 'react-ios-pwa-prompt'

// export const mapStateToProps = (state) => ({});

// export const mapDispatchToProps = (dispatch) => ({ });

export class RouterContainer extends PureComponent {

  render() {
    return (
        <>
          <Router />
          <PWAPrompt 
            promptOnVisit={1}
            timesToShow={3}
            copyBody="Add our farmAIr App to home screen and use it in fullscreen"
          />
        </>
    );
  }
}

export default connect(null, null)(RouterContainer);