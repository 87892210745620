import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateOrder } from 'actions/orders/update'
import { fetchOrders } from 'actions/orders/list'
import Item from './Item.component';

const ItemContainer = (props) => {
  const { orders, activeItem, updateOrder, fetchOrders } = props
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    (async () => {
      if(activeItem !== null){
        let activeOrder = orders.filter(el => el.uuid == activeItem)[0]
        console.log(activeOrder, 'the order')
        setOrder(activeOrder)
      }
      setLoading(false);
    })()
  }, [orders, activeItem]);

  const onStatusClick = i => e => {
    setSelectedStatus(i)
  }

  const onSubmitStatusUpdate = async (e) => {
    const status = selectedStatus === 0 ? 'pending_payment' : 'complete'
    setLoading(true)
    if(await updateOrder({ order, status })){
      await fetchOrders({ keepActiveItem: true })
    }
    setSelectedStatus(null);
    setLoading(false);
  }


  const state = {
    loading,
    order,
    selectedStatus
  };

  const containerFunctions = {
    onStatusClick,
    onSubmitStatusUpdate
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  orders: state.orders.list,
  activeItem: state.orders.activeItem
});

export default connect(mapStateToProps, {
  updateOrder,
  fetchOrders,
})(ItemContainer);