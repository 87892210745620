import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dashboard from "./Dashboard.component";
import api from "utils/api";
import io from "socket.io-client";

const ENDPOINT = "http://localhost:5000";

const DashboardContainer = ({ user }) => {
  const [fields, setFields] = useState([]);
  const [shoots, setShoots] = useState([]);
  const [overview, setOverview] = useState({});
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [serverLogs, setServerLogs] = useState([]);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  useEffect(() => {
    if (socket) {
      socket.emit("hello", "world FROM CLIENT MESSAGE\n\n\n");

      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });

      socket.on("FromAPI", (data) => {
        // console.log("FromAPI", data, serverLogs);

        if (data) {
          var node = document.createElement("LI");
          var textnode = document.createTextNode(data);
          node.appendChild(textnode);

          let el = document.getElementById("deploy-logs-section");
          el.appendChild(node);
          el.scrollTop = el.scrollHeight;
        }
      });

      socket.on("ToChat", (data) => {
        console.log("ToChat", data);

        if (data) {
          let msg = `<strong>${data.user}</strong> ${data.msg}`;
          let el = document.getElementById("chat-logs-section");
          el && el.insertAdjacentHTML("beforeend", `<li>${msg}</li>`);
          el && (el.scrollTop = el.scrollHeight);
        }
      });
    }
  }, [socket]);

  const onSendSocketMessage = async (msg) => {
    console.log("onSendSocketMessage", msg);
    let username = user.firstName[0] + user.lastName[0];
    socket.emit("FromChat", { user: username, msg });
  };

  useEffect(() => {
    const fetchField = async () => {
      const res = await api.get("/fields");
      const resOverview = await api.get("/analytics/overview");
      const companyUsers = await api.get("/users");
      const resOrders = await api.get("/orders");
      setFields(res.data.data);
      setShoots(res.data.shootData);
      setOverview(resOverview.data);
      setUsers(companyUsers.data.users);
      setOrders(resOrders.data.orders);
      setTimeout(() => {
        console.log(shoots, "THE SHOOTS", res.data.shootData);
      }, 1500);
    };

    fetchField();
    return () => {};
  }, []);

  const state = {
    fields,
    shoots,
    overview,
    users,
    orders,
    serverLogs,
  };

  const containerFunctions = {
    onSendSocketMessage,
  };

  return <Dashboard {...state} {...containerFunctions} />;
};

export const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
