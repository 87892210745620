import React, { useState } from 'react';
import './Item.style.scss'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import { countryList } from 'utils/datasets/countryList'
import { AiFillEdit } from 'react-icons/ai'
import { withTranslation } from 'react-i18next'


const addressFields = {
  city: '',
  country: '',
  state: '',
  zip_postcode: '',
  line_1: '',
  line_2: '',
  line_3: ''
}

let options = {
  // type: { type: 'checked', required: true },
  city: { label: 'City', required: true },
  country: { label: 'Country', type: 'select', required: true },
  state: { label: 'State', required: true },
  zip_postcode: { label: 'Post Code', required: true },
  line_1: { label: 'Line 1', required: true },
  line_2: { label: 'Line 2' },
  line_3: { label: 'Line 3' },
}


const renderGroup = ({ name, opts, t }) => {
  const type = opts?.type ? opts?.type : 'text'
  //TODO: use renderCol
  return (
    <div className="form-group">
      <label htmlFor={name}>{t(opts?.label)}</label>
      {type === 'text' ?
        <Field type="text" name={name} className="form-control" required={opts?.required} />
        : ''}
      {type === 'select' ?
        <Field as="select" name={name} className="form-control" required={opts?.required}>
          <option value=''></option>
          {name === 'country' ?
            countryList.map(el => {
              return <option value={el}>{el}</option>
            })
            : ''}
        </Field>
        : ''}
    </div>
  )
}


const renderAddressForm = (props) => {
  const { setShowFn, submitForm, editAddressFields, onDeleteAddress, t } = props

  console.log(editAddressFields, 'the fields')
  // if(!editAddressFields.city) return '';
  return (
    <div>
      <Formik initialValues={editAddressFields}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values)
          setTimeout(() => {
            setSubmitting(false);
          })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Modal.Body className="delete-modal-body">
              {Object.keys(editAddressFields).map(el => {
                return renderGroup({ name: el, opts: options[el], t })
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" className="ml-auto mr-0" onClick={onDeleteAddress}>{t('Delete Address')}</Button>
              <div>
                <Button variant="secondary" className="mr-3" onClick={setShowFn(false)}>{t('Cancel')}</Button>
                <Button variant="primary" type="submit">{t('Save Address')}</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>

    </div>
  )
};


const Item = (props) => {

  const { show, setShowFn, onClick, t } = props

  return (
    <>
      <button className="btn btn-primary c-btn-lg"
        onClick={setShowFn(true)}
      ><AiFillEdit/></button>
      <Modal
        show={show}
        onHide={setShowFn(false)}
        dialogClassName="modal-new-address"
        backdropClassName="modal-backdrop-new-address"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Address')}</Modal.Title>
        </Modal.Header>
          {renderAddressForm(props)}
      </Modal>
    </>
  )
}

export default withTranslation()(Item);