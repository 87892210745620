import React from 'react';
import { Modal, Button } from 'react-bootstrap'
import Loader from 'component/reusable/Loader'
import './List.style.scss'
import { VscChromeClose } from 'react-icons/vsc'
import { formatLocation, formatArea } from 'utils/format'
import { renderAvatar } from 'component/reusable/User'
import { withTranslation } from 'react-i18next'
import NotificationHeader from './utils/notificationHeader'
import moment from 'moment'


const getNotificationDetails = ({ notification, t }) => {
  const { type } = notification;
  let title, location, avatar;

  switch (type) {
    case 'order_status_update':
      let { newStatus, order } = notification.details
      let { createdAt, numOfReport } = order
      return (
        <div>
          <p>{t('Order created at')} {moment(createdAt).format("DD/MM/YY")} {t('has a new status of')}: {t(newStatus)}</p>
        </div>
      )
    case 'new_field':
    case 'new_report':
    case 'new_shoot':
    case 'shoot_status_update':
    case 'report_status_update':
      ({ title, location, avatar } = notification.details.field)

      return <>
        { avatar ?
          renderAvatar({avatar}, {size: 'xs', icon: 'field'})
        : '' }
        <div className="title-location">
          <p>{title}</p>
          <p className="location">{formatLocation(location)}</p>
        </div>
      </>
    default:
      return t('Unknown Notification type');
  }
}


const renderNotification = ({ notification, t }) => {
  let read = false;
  if (notification.Users[0].User_Notification.read) {
    read = true
  }

  return (
    <div className={`notification-item ${read ? "read" : "unread"} ${notification.type}`}>
      <header><NotificationHeader notification={notification} /></header>
      <div className="date-time">
        {moment(notification.createdAt).fromNow()}
      </div>
      <div className="details">
        {getNotificationDetails({ notification, t })}
      </div>
    </div>
  )
}

const renderNotifications = (props) => {
  const { notifications, loading, t } = props;

  if (loading) {
    return (
      <div><Loader /></div>
    )
  } else {
    console.log(notifications, 'the notifications')
    if (!notifications.length) {
      return (
        <div className="empty">{t('No notifications yet...')}</div>
      )
    } else {
      return notifications.map(el => renderNotification({ notification: el, t }))
    }
  }
}

const List = (props) => {
  const { handleClose, modalVisibility, t } = props

  return (
    <div className="notifications">
      <Modal
        show={modalVisibility}
        onHide={handleClose}
        dialogClassName="modal-notifications"
        backdropClassName="modal-backdrop-notifications"
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title>{t('Notifications')}</Modal.Title>
          <Button variant="reset" onClick={handleClose}><VscChromeClose size={28} /></Button>
        </Modal.Header>
        <Modal.Body>
          {renderNotifications(props)}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default withTranslation()(List);