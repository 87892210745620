import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, setActiveItem } from 'actions/user/list'
import List from './List.component';
import { showNewUser } from 'actions/ui'

const ListContainer = ({ fetchUsers, users, showNewUser, setActiveItem, activeItem }) => {
  const [loading, setLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    (async () => {
      console.log('[useEffect] fetching users')
      let payload = {}
      if(window.innerWidth < 767){
        payload.activeUserId = -1
      }
      await fetchUsers(payload);
      setLoading(false);
    })()
  }, []);

  useEffect(() => {
    setFilteredUsers([...users])
  }, [users])

  const onSearchChange = (e) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    let filteredUsers = users.filter(el => {
      let c1 = el.firstName.toLowerCase().includes(searchValue.toLowerCase())
      return c1
    })
    setFilteredUsers([...filteredUsers])
  }, [searchValue])

  const onNewUser = () => showNewUser(true)


  const onUser = id => e => {
    setActiveItem(id)
  }

  const state = {
    loading,
    users,
    activeItem,
    filteredUsers,
    searchValue,
  };

  const containerFunctions = {
    onNewUser,
    onUser,
    onSearchChange,
  };

  return (
    <List
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  users: state.users.list,
  activeItem: state.users.activeItem
});

export default connect(mapStateToProps, { 
  fetchUsers,
  setActiveItem,
  showNewUser
 })(ListContainer);