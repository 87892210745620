import React from 'react';
import { withTranslation } from 'react-i18next'
import { Dropdown, DropdownButton, Button } from 'react-bootstrap'

import './Item.style.scss'


const Item = (props) => {
  const { t, onLangClick, langText, showSubmitBtn, onSubmit } = props;

  return (
    <section className="settings-section change-language">
      <header>
        <h3>{t('Application Language')}</h3>
        <p>{t('Select your preferred language for headlines, buttons, and other text from farmAIr App.')}</p>
      </header>
      <form action="" onSubmit={onSubmit}>
        <div className="dd-wrap">
          <DropdownButton id="dd-language-button" title={`${t('Select Language')}`}>
            <Dropdown.Item onClick={onLangClick('el')}>Ελληνικά</Dropdown.Item>
            <Dropdown.Item onClick={onLangClick('en')}>English</Dropdown.Item>
          </DropdownButton>
          <span className="selected-language-text">
            {langText}
          </span>
        </div>

        {showSubmitBtn ?
          <div className="btn-wrap fade-in mt-3">
            <Button variant="primary" type="submit" className="c-btn c-btn-lg w-100">
              {t('Change Language')}
            </Button>
          </div>
          : ''}
      </form>
    </section>
  )
}

export default withTranslation()(Item);