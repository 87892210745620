import React from 'react';


const getProcessingLabel = (report, t = null) => {
  const { service } = report

  if(t) {
    return service === 'manual' ? t('Awaiting Data') : t('Schedule Shoot');
  } else {
    return service === 'manual' ? 'Awaiting Data' : 'Schedule Shoot';
  }

}

const __ = (phrase, t) => {
  if (t){
    return t(phrase)
  }else {
    return phrase
  }
}


export const formatStatus = (report, t = null) => {
  if(!report) return
  const { status } = report

  switch(status) {
    case 'pending':
      return <span className="text-warning fw700">{__('Pending', t)}</span>;
    case 'pending_payment':
      return <span className="text-warning fw700">{__('Pending Confirmation', t)}</span>;
    case 'processing':
      return <span className="text-success fw700">{getProcessingLabel(report, t)}</span>;
    case 'processing_pending':
      return <span className="text-success fw700">{__('Processing Pending', t)}</span>;
    case 'processing_data':
      return <span className="text-success fw700">{__('Processing data', t)}</span>;
    case 'complete':
      return <span className="text-success fw700">{__('Complete', t)}</span>;
    case 'unassigned':
      return <span className="text-warning fw700">{__('Unassigned', t)}</span>;
    case 'assigned_to_drone_admin':
      return <span className="text-success fw700">{__('Assigned To Drone Operator', t)}</span>;
    case 'assigned_to_drone_operator':
      return <span className="text-success fw700">{__('Assigned To Drone Pilot', t)}</span>;
    case 'confirmed_shoot_dates':
      return <span className="text-success fw700">{__('Confirmed Photo Shoot Dates', t)}</span>;
    default:
      return <span className="text-warning fw700">{__('Unknown Status', t)}</span>;
  }
}