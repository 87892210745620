import PropTypes from "prop-types";
import { PureComponent, useState } from "react";
import React from "react";
import MapboxDashboard from "component/MapboxDashboard";
import Charts from "./Charts";
import "./Dashboard.style.scss";
import { Link } from "react-router-dom";
import Pie from "./Pie.component";
import Bar from "./Bar.component";
import Tabs from "react-bootstrap/Tabs";
import { Row, Col, Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Agro from "./Agro.component";
import Deploy from "./Deploy.component";
import Chat from "./Chat.component";
// import Sonnet from "react-bootstrap";
import Analytics from "./Analytics.component";

const renderAnalytics = (overview) => {
  const { fields, shoots } = overview;
  const { totalNumOfFields } = fields || {};
  const { totalNum } = shoots || {};
  return (
    <div>
      <h3>Fields total num: {totalNumOfFields}</h3>
      <h3>Shoots total num: {totalNum}</h3>
    </div>
  );
};
function ControlledTabs(props) {
  const [key, setKey] = useState("home");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Agro">
        <Agro {...props} />
      </Tab>
      <Tab eventKey="profile" title="Deploy">
        <Deploy {...props} />
      </Tab>
      <Tab eventKey="contact" title="Chat">
        <Chat {...props} />
      </Tab>
    </Tabs>
  );
}
const Dashboard = (props) => {
  const { overview, users } = props;
  const [showSettings, setShowSettings] = useState(false);

  return (
    <main aria-label="Dashboard" className="container dashboard">
      <h1>Dashboard</h1>

      {/* <section className="analytics">
        <Analytics overview={overview} users={users} />
      </section> */}

      <main aria-label="Dashboard" className="container dashboard">
        {/* <Charts {...props} />

        <section className="recharts">
          <Bar {...props} />
          <Pie {...props} />
        </section>

        <section>
          <h4 className="mt-5 mb-3">Area Covered</h4>
          <MapboxDashboard {...props} />
        </section> */}

        <section className="tabs mt-5 settings-wrap">
          <div className="settings-header">
            <h2 className="mb-3 mr-3">Settings</h2>
            <button
              className="btn"
              onClick={() => setShowSettings(!showSettings)}
            >
              {showSettings ? "Hide" : "Show"}
            </button>
          </div>

          {showSettings && <ControlledTabs {...props} />}
        </section>
      </main>
    </main>
  );
  // }
};

export default Dashboard;
