import React from 'react';

// import './Shoots.style.scss'

import List from 'component/Cart/List'
// import Item from 'component/Shoot/Item'

const Cart = (props) => {

  return (
    <>
      <List />
      {/* <Item /> */}
    </>
  )
}

export default Cart;