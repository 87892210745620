import React from 'react';
import { computeDiscount, computeTotal } from 'component/Cart/utils/price'
import { groupFieldsByReportType } from 'component/Cart/utils/cartItems'
import { formatPrice } from 'utils/format'
import { withTranslation } from 'react-i18next'
import './PriceBox.style.scss'


const renderAppliedCoupon = (props, discAmountFromCoupon) => {
  let { t } = props;
  let { appliedCoupon } = props.props

  if (!appliedCoupon || !Object.keys(appliedCoupon).length) return null;

  let { code, discountPercent } = appliedCoupon

  return (
    <div className="row discount-coupon">
      <div className="col-6">
        <h4>{t('Coupon')}: {code}</h4>
      </div>
      <div className="col-6">
        <p>-{discountPercent}% (-{formatPrice(discAmountFromCoupon)})</p>
      </div>
    </div>
  )
}


const PriceBox = (props) => {
  let { t } = props
  const { cartItems, appliedCoupon } = props.props

  let itemGroups = groupFieldsByReportType(cartItems)
  let total = computeTotal(itemGroups)
  let discount = computeDiscount(total);
  let finalPrice = total - discount

  let discAmount = (((total - finalPrice)/finalPrice)*100).toFixed(2)

  let discAmountFromCoupon;

  if(appliedCoupon && Object.keys(appliedCoupon).length){
    discAmountFromCoupon = finalPrice*(appliedCoupon.discountPercent/100);
    finalPrice -= discAmountFromCoupon;
  }

  console.log(itemGroups, total, discount, finalPrice, 'all')

  return (
    <div className="price-wrap">
      { discount > 0 ? <>
      <div className="row original-price">
        <div className="col-6">
          <h4>{t('Original Price')}:</h4>
        </div>
        <div className="col-6">
          <p>{formatPrice(total)}</p>
        </div>
      </div>
      <div className="row discount-amount-price">
        <div className="col-6">
          <h4>{t('Discount')}:</h4>
        </div>
        <div className="col-6">
          <p>{discAmount}%</p>
        </div>
      </div>
      <div className="row discount-price">
        <div className="col-6">
          <h4>{t('You Save')}:</h4>
        </div>
        <div className="col-6">
          <p>{formatPrice(discount)}</p>
        </div>
      </div>
      </> : null }

      {renderAppliedCoupon(props, discAmountFromCoupon)}

      <div className="row">
        <div className="col-6">
          <h4>{t('Final Price')}:</h4>
        </div>
        <div className="col-6">
          <p className="final-price price">
            {formatPrice(finalPrice)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(PriceBox);