import api from 'utils/api';
import {
  LOAD_ORDERS,
  EMPTY_ORDERS,
  SET_ACTIVE_ORDER_ITEM,
} from 'actions/types';

const fetchOrders = (opts) => async dispatch => {
  try {
    dispatch({
      type: EMPTY_ORDERS,
      payload: {...opts}
    });
    const res = await api.get('/orders');
    console.log(res);
    if(res.status === 200){
      dispatch({
        type: LOAD_ORDERS,
        payload: {
          orders: res.data.orders,
          ...opts
        }
      });
      return res;
    } else {
      return false
    }
    console.log(res, 'the order')
  } catch (err) {
    alert(err)
    return false
  }
}

const setActiveItem = (val) => async dispatch => {
  try {
    console.log('val0-----', val)
    dispatch({
      type: SET_ACTIVE_ORDER_ITEM,
      payload: val
    });
  } catch (err) {
    alert(err)
  }
}

export {
  fetchOrders,
  setActiveItem
}