
import moment from 'moment'


const calculateEvents = (shoots = []) => {
  if(!shoots || !shoots.length) return;

  const scheduledShoots = shoots.filter(el => !!el?.shootDays?.confirmed.start);

  console.log(scheduledShoots, 'THE SHOOTS')
  let calcEvents = [];

  scheduledShoots.forEach(el => {
    const { uuid, report, field, shootDays: {confirmed, alternative, final = null}, status } = el
    const { title } = field

    let hectares = field?.area/1000/10;
    let hrs = hectares*0.25 // 15 lepta to strema

    if(final){
      calcEvents.push({
        id: `${uuid}_final`,
        title,
        start: moment(final).toDate(),
        end: moment(final).add(hrs, 'hours').toDate(),
        category: 'final'
      })
    }else if(report?.processedAt){
      calcEvents.push({
        id: `${uuid}_processed`,
        title,
        start: moment(report?.processedAt).toDate(),
        end: moment(report?.processedAt).add(hrs, 'hours').toDate(),
        category: 'final'
      })
    }else if(confirmed && alternative){
      calcEvents.push({
        id: `${uuid}_confirmed`,
        title,
        start: moment(confirmed.start).toDate(),
        end: moment(confirmed.start).add(hrs, 'hours').toDate(),
        category: 'confirmed'
      })
      calcEvents.push({
        id: `${uuid}_alternative`,
        title,
        start: moment(alternative.start).toDate(),
        end: moment(alternative.start).add(hrs, 'hours').toDate(),
        category: 'alternative'
      })
    }
  });

  return calcEvents;
}

export {
  calculateEvents
}