import React from 'react'
import { __i18n } from 'i18n';

const inputMinMapping = {
  avgPlantHeight: 0,
  telephone: 0
}
const inputMaxMapping = {
  avgPlantHeight: 100,
}
const inputStepMapping = {
  avgPlantHeight: 0.1,
  telephone: 1
}

const inputTypeMapping = {
  firstName: 'text',
  lastName: 'text',
  password: 'password',
  confirmPassword: 'password',
  email: 'email',
  fieldTitle: 'text',
  avgPlantHeight: 'number',
  telephone: 'number'
}

const inputPatternMapping = {
  firstName: ".[A-Za-z]{1,50}",
  lastName: ".[A-Za-z]{1,50}",
  password: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}",
  confirmPassword: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}",
  fieldTitle: ".{1,200}",
  firmTitle: ".[a-zA-Z0-9-_., ]{2,100}",
  vatId: ".[a-zA-Z0-9]{5,20}",
  firmActivity: ".[a-zA-Z0-9-_., ]{2,100}",
  city: ".[a-zA-Z0-9-_., ]{2,100}",
  address: ".[a-zA-Z0-9-_., ]{2,100}",
  zipCode: ".[0-9- ]{2,10}",
  taxAuthority: ".[a-zA-Z0-9-_.,' ]{2,100}",
  state: ".[a-zA-Z0-9-_., ]{2,100}",
  country: ".[a-zA-Z0-9-_., ]{2,100}"
}

const inputLabelMapping = {
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  email: 'Email address',
  fieldTitle: 'Field Name',
  avgPlantHeight: 'Average Plant Height (m)',
  firmTitle: "Company Name",
  vatId: "VAT Number",
  firmActivity: "Activity",
  city: "City",
  address: "Address",
  zipCode: 'Post Code',
  taxAuthority: 'Tax Authority',
  state: 'State',
  country: 'Country',
  telephone: 'Telephone'
}

const inputTitleMapping = {
  firstName: "Must be between 2 and 50 English characters.",
  lastName: "Must be between 2 and 50 English characters.",
  password: 'Must be 8 characters or more, containing at least 1 number and 1 capital letter',
  confirmPassword: 'Must be 8 characters or more, containing at least 1 number and 1 capital letter',
  email: 'Please choose a valid email format',
  fieldTitle: 'Must be less than 200 characters.',
  firmTitle: "Must be between 2 and 100 English characters",
  vatId: "Must be between 5 and 20 English characters or numbers",
  firmActivity: "Must be between 2 and 100 English characters",
  city: "Must be between 2 and 100 English characters",
  address: "Must be between 5 and 100 English characters or numbers",
  zipCode: "Must be between 2 and 10 numbers",
  taxAuthority: "Must be between 2 and 100 English characters",
  state: "Must be between 2 and 100 English characters",
  country: "Must be between 2 and 100 English characters"
  // country: "Must be between 2 and 100 English characters"
}


export const renderCol = (props) => {
  let { inputName, inputLabel, onChange, formData, type = 'text',
      readOnly = false, wrapClassName = 'col-md-6', useMapping = false,
      min = null, max = null, step = null, pattern = null, title = null, t 
  } = props


  if(useMapping){
    pattern = inputPatternMapping[inputName];
    type = inputTypeMapping[inputName];
    title = t ? t(inputTitleMapping[inputName]) : inputTitleMapping[inputName];
    inputLabel = t ? t(inputLabelMapping[inputName]) : inputLabelMapping[inputName];
    min = inputMinMapping[inputName];
    max = inputMaxMapping[inputName];
    step = inputStepMapping[inputName];
  }

  if(readOnly){
    title = null;
  }

  return (
    <div className={wrapClassName}>
      <div className={`form-group ${inputName} ${type}`}>
        <label htmlFor={inputName}>{inputLabel}</label>
        <input id={inputName} name={inputName} type={type}
          onChange={onChange}
          value={formData[inputName]}
          className="form-control"
          min={min}
          max={max}
          step={step}
          pattern={pattern}
          title={title}
          readOnly={readOnly}
          required={true} />
      </div>
    </div>
  )
}