import api from 'utils/api';
import { setAlert } from 'actions/alert'
import {
  LOAD_USERS,
  EMPTY_USERS,
  SHOW_NEW_USER,
} from 'actions/types';


const updateUser = (payload) => async dispatch => {
  console.log('[Action] Update user')

  try {
    const res = await api.put('/user/update', payload);
    return true
    // dispatch(setAlert('New User successfully added.', 'success'))
    // dispatch({ type: EMPTY_USERS });
    // const res2 = await api.get('/users');
    // dispatch({
    //   type: LOAD_USERS,
    //   payload: {
    //     list: res2.data.users
    //   }
    // });

    // dispatch({ type: SHOW_NEW_USER, payload: { value: false } });
  } catch (err) {
    return false
    alert(err)
  }
};


export {
  updateUser,
}