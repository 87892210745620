import api from 'utils/api';


const updateInvoice = (payload) => async dispatch => {
  console.log('[Action] Update Company Invoice')

  try {
    const res = await api.put('/company/update/invoice', payload);
    return true
  } catch (err) {
    return false
    alert(err)
  }
};


export {
  updateInvoice,
}