import {
  SHOW_NEW_FIELD,
  SHOW_NEW_COMPANY,
  SHOW_NOTIFICATIONS,
  SHOW_NEW_USER,
  SHOW_NEW_REPORT,
  SET_NUM_OF_UNREAD_NOTIFICATIONS,
  SHOW_SCHEDULE_SHOOT,
  SHOW_UPLOAD_DATA,
  SHOW_FULL_LOADER,
  SHOW_DIRECTIONS,
} from './types';



const showNewField = (val) => dispatch => {
  console.log('[ACTION] show showNewField')
  dispatch({
    type: SHOW_NEW_FIELD,
    payload: {
      value: val
    }
  });
}

const showNewCompany = (val) => dispatch => {
  console.log('[ACTION] show showNewCompany')
  dispatch({
    type: SHOW_NEW_COMPANY,
    payload: {
      value: val
    }
  });
}

const showNewReport = (val) => dispatch => {
  console.log('[ACTION] show showNewReport')
  dispatch({
    type: SHOW_NEW_REPORT,
    payload: {
      value: val
    }
  });
}

const showFullLoader = (val) => dispatch => {
  console.log('[ACTION] show showFullLoader')
  dispatch({
    type: SHOW_FULL_LOADER,
    payload: {
      value: val
    }
  });
}

const showUploadData = (payload) => dispatch => {
  console.log('[ACTION] showUploadData')
  const {value, field, selectedReport} = payload
  dispatch({
    type: SHOW_UPLOAD_DATA,
    payload: {
      value,
      field,
      selectedReport,
    }
  });
}
const showScheduleShoot = ({value, report}) => dispatch => {
  console.log('[ACTION] show Schedule Photo Shoot')
  dispatch({
    type: SHOW_SCHEDULE_SHOOT,
    payload: {
      value,
      report
    }
  });
}

const showDirections = ({value, lng, lat, file, reportUuid, bboxId}) => dispatch => {
  console.log('[ACTION] showDirections')
  dispatch({
    type: SHOW_DIRECTIONS,
    payload: {
      value,
      lng,
      lat,
      file,
      reportUuid,
      bboxId,
      // w,
      // h
    }
  });
}


const showNewUser = (val) => dispatch => {
  console.log('[ACTION] show showNewCompany')
  dispatch({ type: SHOW_NEW_USER, payload: { value: val } });
}
const showNotifications = (val) => dispatch => {
  console.log('[ACTION] show showNotifications')
  if(!val){
    setTimeout(() => dispatch({ type: SHOW_NOTIFICATIONS, payload: { value: val } }), 500)
  }else {
    dispatch({ type: SET_NUM_OF_UNREAD_NOTIFICATIONS, payload: null });
    dispatch({ type: SHOW_NOTIFICATIONS, payload: { value: val } });
  }
}


export {
  showNewField,
  showNewCompany,
  showNotifications,
  showNewUser,
  showNewReport,
  showScheduleShoot,
  showUploadData,
  showFullLoader,
  showDirections,
}