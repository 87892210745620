import React from 'react';

import './Shoots.style.scss'

import List from 'component/Shoot/List'
import Item from 'component/Shoot/Item'

const Shoots = (props) => {

  return (
    <>
      <List />
      <Item />
    </>
  )
}

export default Shoots;