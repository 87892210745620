import React from 'react';
import { AiOutlineUser } from 'react-icons/ai'
import moment from 'moment'
import './Item.style.scss'
import { formatRole } from 'utils/format'
import IconBack from 'component/reusable/icon/Back'
import { renderTable } from 'component/Field/utils/renderRow'
import { withTranslation } from 'react-i18next'
import { renderAvatar } from 'component/reusable/User'

const renderUserCompany = (user, t) => {
  console.log(user, 'asfsafaffsd')
  let company = null
  if(user.Companies.length){
    company = user.Companies[0]
  }

  if(company) {
    const { title, email, telephone, activity } = company
    return (
      renderTable({
        title: t('Company Info'),
        rows: [
          [t('Company Name'), title],
          [t('Company Email'), email],
          [t('Company Telephone'), telephone],
          [t('Activity'), activity],
        ]
      })
    )
  }else {
    return null;
  }

}


const renderUser = (user, t) => {
  const { email, location, mobile, firstName, lastName, role, 
    createdAt, uuid, id, lang } = user
  return (
    <>
      <header>
        {renderAvatar(user, { size: 'md' })}
        <h2 className="mb-4">{firstName} {lastName}</h2>
        {renderTable({
          rows: [
            [t('Email'), email],
            [t('Mobile'), mobile],
            [t('Location'), location],
            [t('Role'), formatRole(role)],
            [t('Uuid'), uuid],
            [t('Id'), id],
            [t('Application Language'), lang],
            [t('Member Since'), moment(createdAt).format("MMM D, YYYY")]
          ]
        })}

        {renderUserCompany(user, t)}
      </header>
      <main className="mt-5">
      </main>
      {/* <p>{user && user.Companies[0].title}</p> */}
    </>
  )
}


const Item = (props) => {
  const { activeItem, user, loading, onBack, t } = props

  return (
    <section className={`company-item grid-3 ${activeItem !== null ? 'is-active' : ''}`}>
      <span onClick={onBack}><IconBack layout="block" /></span>

      <div className="inner">
        {!loading && user?.firstName && renderUser(user, t)}
      </div>
    </section>
  )
}

export default withTranslation()(Item);