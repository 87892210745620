import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Item from './Item.component';
import store from 'store';
import { logoutUser } from 'actions/auth';
import { setActiveItem} from 'actions/settings';

const ItemContainer = (props) => {
  const { activeNavItem, setActiveItem } = props
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(false);
    })()
  }, []);

  const onLogout = () => {
    store.dispatch(logoutUser())
    // props.history.push("/login")
    // window.location.replace(window.location.origin)
  }

  const setActiveNavItem = val => e => {
    setActiveItem(val);
  }

  const state = {
    loading,
    activeNavItem
  };

  const containerFunctions = {
    onLogout,
    setActiveNavItem,
  };

  return (
    <Item
      { ...state }
      { ...containerFunctions }
    />
  )
}

const mapStateToProps = (state) => ({
  activeNavItem: state.settings.activeNavItem
});

export default connect(mapStateToProps, {
  setActiveItem
})(ItemContainer);