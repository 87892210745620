import React from 'react';
import { withTranslation } from 'react-i18next'

import './Annotate.style.scss'
import {
  abioticFactors,
  bioticBacterialDiseases,
  bioticFungal,
  bioticVirus,
  bioticMites,
  bioticInsects,
  bioticNematodes
} from './Annotate.config'

const Annotate = (props) => {
  const { onChange, value, showOther, setShowOther, t } = props

  console.log(value, 'the value')
  return (
    <div className="annotate-wrap">
      <section className="annotate">
        <h4>{t('Choose below')}:</h4>

        {!showOther ? 
          <>
            <h3>{t('ABIOTIC')}</h3>
            <select id="lang" onChange={onChange} value={t(value)}>
              {abioticFactors.map(el => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>

            <h3 className="mt-4">{t('BIOTIC')}</h3>
            <h6>{t('Bacterial diseases')}</h6>
            <select id="lang2" onChange={onChange} value={t(value)}>
              {bioticBacterialDiseases.map((el, i) => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>

            <h6>{t('Fungal diseases')}</h6>
            <select id="lang3" onChange={onChange} value={t(value)}>
              {bioticFungal.map((el, i) => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>

            <h6>{t('Virus diseases')}</h6>
            <select id="lang4" onChange={onChange} value={t(value)}>
              {bioticVirus.map((el, i) => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>

            <h6>{t('Mites')}</h6>
            <select id="lang5" onChange={onChange} value={t(value)}>
              {bioticMites.map((el, i) => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>

            <h6>{t('Insects')}</h6>
            <select id="lang6" onChange={onChange} value={t(value)}>
              {bioticInsects.map((el, i) => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>


            <h6>{t('Nematodes')}</h6>
            <select id="lang7" onChange={onChange} value={t(value)}>
              {bioticNematodes.map((el, i) => {
                return (
                  <option value={t(el)}>{t(el)}</option>
                )
              })}
            </select>
          </>
          : null}

        <p className="note">{t('Click on the image to save')}<span className="text-danger ml-2">*</span></p>

        <div className="btn-wrap my-3">
          <button
            className="btn btn-light btn-sm"
            onClick={(e) => setShowOther(!showOther)}>{showOther ? t('Hide Other') : t('Other')}</button>
        </div>

        {showOther ?
          <input id="input-val" type="text" onChange={onChange} value={t(value)} />
        : null}

      </section>
    </div>

  )
}

export default withTranslation()(Annotate);